const VimeoAccount = require("vimeo").Vimeo;
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new VimeoAccount(
    VIMEO_CLIENT_ID, // ID
    VIMEO_CLIENT_SECRET, // 秘密鍵
    VIMEO_ACCESS_TOKEN // アクセストークン
);
export const vimeoMovieDelete = {
    methods: {
        /**
         * VIMEOの動画を削除する
         * @param {*} videoId ビデオのID
         */
        deleteMovie: function (videoId) {
            console.log("vimeoの動画を削除します");
            console.log(videoId);
            client.request(
                {
                    method: "DELETE",
                    path: "/videos/" + videoId,
                },
                function (error, body, status_code, headers) {
                    console.log(error);
                    console.log(body);
                    console.log(status_code);
                    console.log(headers);
                    console.log("vimeoの動画を削除しました");
                }
            );
        }
    }
}
