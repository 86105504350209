<template>
  <v-app>
    <Menu />
    <ProjectBar />
    <DropDown />
    <ProjectPublishedSettingComponent />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </v-app>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import ProjectPublishedSettingComponent from "@/components/PublishedSetting/PublishedSettingComponent.vue";
import ProjectBar from "@/components/ProjectInfoBar/ProjectInfoBar.vue";
import DropDown from "@/components/DropDown/DropDownComponent.vue";
import Menu from "@/components/Menu/MenuComponent.vue";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../utility/authStateCheck";
export default {
  name: "Published",
  components: {
    ProjectPublishedSettingComponent,
    ProjectBar,
    DropDown,
    Menu,
    VueLoading,
  },
  mounted() {
    //認証情報があるか確認
    this.authStateCheck(this.$store, this.$router);
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
  computed: {
    changeProjectCheck() {
      return this.$store.state.changeProjectCheck;
    },
  },
  watch: {
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //プロジェクトを切り替えた際、ローディングを表示するか表示しないか判定する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    changeProjectCheck: function (newValue, old) {
      console.log("---------changeProjectCheck----------");
      console.log(newValue);
      if (newValue == true) {
        this.waitLoading();
      } else {
        this.downloaded();
      }
    },
  },
  methods: {
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999999;
  display: none;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
  display: none;
}
</style>