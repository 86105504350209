import { reloadManageGetter } from "../utility/reloadManageGetter"
import { sessionGetter } from "./sessionGetter"
import { sessionDelete } from "./sessionDelete"
import { vimeoMovieDelete } from "./vimeoMovieDelete";
import { firebaseStorageDelete } from "./firebaseStorageDelete";
export const reloadManager = {
    mixins: [reloadManageGetter, sessionGetter, sessionDelete, vimeoMovieDelete, firebaseStorageDelete],
    data: function () {
        return {
            vuexStore: this.$store,
        }
    },
    methods: {
        /**
         * ビデオアップロード最中にリロードが走った場合におこなう処理。（ロールバック的な役割）
         */
        videoUploadManager: async function (firebase) {
            console.log("ビデオのアップロードが全て完了したかのチェック処理");
            //アップロードが全て完了したかどうかの真偽値
            const isSessionUploadAllSucessed = this.getterSessionAllUploadClear();

            //ブラウザに保存されているセッションを取得
            const isSessionMovieDownloadSuccess = this.getterSessionMovieDownloadSuccess();
            const isSessionImageDownloadSuccess = this.getterSessionImageDownloadSuccess();
            const isSessionMovieUploadSuccess = this.getterSessionMovieUploadSuccess();
            const isSessionImageUplaodSuccess = this.getterSessionImageUplaodSuccess();
            const sessionUploadImageName = this.getterSessionUploadImageName();
            const issessionUploadImageUrl = this.getterSessionUploadImageUrl();
            const sessionVideoHash = this.getterSessionVideoHash();
            const isSessionVimeoWhiteListRegisterSuccess = this.getterSessionVimeoWhiteListRegisterSuccess();
            const isSessionReserveVimeoFolderSuccess = this.getterSessionReserveVimeoFolderSuccess();
            const isSessionUserRegisterSuccess = this.getterSessionUserRegisterSuccess();
            const isSessionScreenChangeDBSuccess = this.getterSessionScreenChangeDBSuccess();
            const isSessionStorageClinetDBChangeSuccess = this.getterSessionClientStorageDBChangeSucess();
            const isSessionStorageProjectDBChangeSuccess = this.getterSessionProjectStorageDBChangeSucess();
            const isSessionScreenUsedMovieNumberSuccess = this.getterSessionProjectStorageDBChangeSucess();
            //const isSessionStorageUsedMovieNumberSuccess = this.getterSessionStorageUsedMovieNumberSuccess();
            const sessionClientId = this.getterSessionClientId();
            const sessionProjectId = this.getterSessionProjectId();
            const sessionUserFileName = this.getterSessionUserFileName();
            const sessionVideoDB = this.getterSessionVideoDB();
            const store = this.$store;


            if (isSessionUploadAllSucessed == "false") {//アップロードが完了せずにリロードされた場合
                console.log("アップロード処理は途中で中断されました");
                if (this.sessionCheck(isSessionMovieDownloadSuccess) && this.sessionCheck(isSessionImageDownloadSuccess)) {
                    if (this.sessionCheck(isSessionMovieUploadSuccess)) {
                        if (this.sessionCheck(isSessionImageUplaodSuccess)) {
                            if (this.sessionCheck(issessionUploadImageUrl)) {
                                if (this.sessionCheck(isSessionReserveVimeoFolderSuccess) && this.sessionCheck(isSessionVimeoWhiteListRegisterSuccess)) {
                                    if (this.sessionCheck(isSessionUserRegisterSuccess)) {
                                        console.log("----isSessionUserRegisterSuccessのIF文に入りました----");
                                        console.log(isSessionScreenChangeDBSuccess);
                                        console.log(isSessionStorageClinetDBChangeSuccess);
                                        console.log(isSessionStorageProjectDBChangeSuccess);
                                        console.log(isSessionScreenUsedMovieNumberSuccess);

                                        //vimeoから動画の削除
                                        this.deleteMovie(sessionVideoHash);

                                        //Firebase Storageに保存されている画像を削除
                                        this.deleteImage(sessionClientId, sessionProjectId, sessionUploadImageName, firebase);

                                        //Firebaseに保存されているユーザーを削除
                                        this.deleteUser(sessionClientId, sessionProjectId, sessionUserFileName, firebase);

                                        if (isSessionScreenChangeDBSuccess) {
                                            console.log("セッションビデオDBは"+sessionVideoDB);
                                            console.log("画面のDB容量をもとに戻します");
                                            console.log()

                                            //画面上のDB容量をもとに戻す処理
                                            this.rollBackScreenDB(sessionVideoDB);
                                        }
                                        if (isSessionStorageClinetDBChangeSuccess) {
                                            console.log("クライアントのDB容量をもとに戻します");
                                            //Firebase StorageのクライアントDB容量をもとに戻す処理
                                            await this.rollBackStorageClientDB(sessionClientId, firebase, store);
                                        }
                                        if (isSessionStorageProjectDBChangeSuccess) {
                                            console.log("プロジェクトのDB容量をもとに戻します");
                                            //Firebase StorageのプロジェクトDB容量をもとに戻す処理
                                            await this.rollBackStorageProjectDB(sessionClientId, firebase, store);
                                        }
                                        if (isSessionScreenUsedMovieNumberSuccess) {
                                            console.log("画面上の使用本数をもとに戻します");
                                            //画面上の使用本数をもとに戻す処理
                                            this.rollBackScreenUsedNumber();
                                        }
                                        /*
                                        if (isSessionStorageUsedMovieNumberSuccess) {
                                            //Firebase Storage上の使用本数をもとに戻す処理（まだ未作）
                                        }
                                        */

                                    } else {//ユーザー登録がされていなかった場合（動画のフォルダ移動及びホワイトリストへの登録はできている）

                                        console.log("ユーザー登録がされていませんでした");

                                        //vimeoから動画の削除
                                        this.deleteMovie(sessionVideoHash);

                                        //Firebase Storageに保存されている画像を削除
                                        this.deleteImage(sessionClientId, sessionProjectId, sessionUploadImageName, firebase);
                                    }

                                } else {//動画のホワイトリストへの登録及びフォルダ移動がされていなかった場合（画像URLのダウンロードまではできていた）

                                    //vimeoから動画の削除
                                    this.deleteMovie(sessionVideoHash);

                                    //Firebase Storageに保存されている画像を削除
                                    this.deleteImage(sessionClientId, sessionProjectId, sessionUploadImageName, firebase);
                                }
                            } else {//画像のURLがダウンロードされていなかった場合（画像のアップロードまではできていた）

                                //vimeoから動画の削除
                                this.deleteMovie(sessionVideoHash);

                                //Firebase Storageに保存されている画像を削除
                                this.deleteImage(sessionClientId, sessionProjectId, sessionUploadImageName, firebase);
                            }
                        } else {//画像がアップロードされていなかった場合（動画アップロードまではできていた）
                            console.log("画像のアップロードがされませんでした");

                            //vimeoから動画の削除
                            this.deleteMovie(sessionVideoHash);//まだ未作成関数
                        }
                    } else {//動画がアップロードされていなかった場合（動画or画像のダウンロードまではできていた）
                        console.log("動画及び画像のダウンロード自体はできていました");
                    }
                } else {//動画or画像がダウンロードされていなかった場合は処理を終了
                    console.log("動画及び画像のダウンロードがされていませんでした。")
                }
            }
            console.log("問題ありませんでした");
            //セッションを全て削除する
            this.deleteAllSession();
        },
        /**
         * リロードするまでに処理がどこまで進んだのかをチェック
         * @param {*} isSession 
         * @returns 
         */
        sessionCheck: function (isSession) {
            if (isSession) {
                return true;
            } else {
                return false;
            }
        },
    }
}