export const tikQModalMixin = {
  methods: {
    /**
     * エラーエクセルモーダルを表示/非表示にする機能
     * @param  check 表示/非表示を決定するパラメータ 
     * @return なし
     */
    tikQModalManage: function (check) {
      if (check == "display") {
        const modalContents = document.getElementById("tikq-modal");
        const overlayContents = document.getElementById("tikq-overlay");


        //モーダルコンテンツの表示
        modalContents.style.visibility = "visible";
        modalContents.style.opacity = 1;
        modalContents.style.zIndex = 500;

        //オーバーレイコンテンツの表示
        overlayContents.style.visibility = "visible";
        overlayContents.style.opacity = 1;
        overlayContents.style.zIndex = 500;


      } else if (check == "nonDisplay") {
        const modalContents = document.getElementById("tikq-modal");
        const overlayContents = document.getElementById("tikq-overlay");


        //モーダルコンテンツの非表示
        modalContents.style.visibility = "none";
        modalContents.style.opacity = 0;
        modalContents.style.zIndex = -1;

        //オーバーレイコンテンツの非表示
        overlayContents.style.visibility = "none";
        overlayContents.style.opacity = 0;
        overlayContents.style.zIndex = -1;

      }
    },
    /**
     * エラー内容のコピーする内容を格納し、コピーメソッドを呼ぶ
     * 行数が０→一列目を意味する
     * 
     * @param なし
     * @return なし
     */
    errorDetailCopy: function () {
      var errorListModalDiv = document.getElementById("js-errorList-modal");
      console.log("コピーメソッドを呼び出します");
      console.log(this.$store.state.errorPartList);

      //画面上部のコピーアラートを表示
      errorListModalDiv.style.display = "block";
      errorListModalDiv.style.zIndex = 700;

      //コピーしたいメッセージを全て配列に格納していく
      var copyText = [];
      var errorText;
      for (var i = 0; i < this.$store.state.errorPartList.length; i++) {
        if (this.$store.state.errorPartList[i][0] == "全列") {
          if (this.$store.state.errorPartList[i][1] == "0") {
            errorText =
              "全列" +
              1 +
              "行目:" +
              this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
            copyText += errorText + "\n";
          } else {
            if (this.$store.state.errorPartList[i][2] == "12") {
              errorText =
                "全列" + "2行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";
            } else if (this.$store.state.errorPartList[i][2] == "15") {
              errorText =
                "全列" +
                (Number(this.$store.state.errorPartList[i][1]) + Number(1)) +
                "行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";

            } else if (this.$store.state.errorPartList[i][2] == "16") {
              errorText =
                "全列" +
                (Number(this.$store.state.errorPartList[i][1]) + Number(1)) +
                "行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";

            } else if (this.$store.state.errorPartList[i][2] == "17") {
              errorText =
                "全列" +
                (Number(this.$store.state.errorPartList[i][1]) + Number(1)) +
                "行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";

            } else if (this.$store.state.errorPartList[i][2] == "18") {
              errorText =
                "全列" +
                (Number(this.$store.state.errorPartList[i][1]) + Number(1)) +
                "行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";

            } else if (this.$store.state.errorPartList[i][2] == "19" || this.$store.state.errorPartList[i][2] == "21") {
              errorText =
                "全列" +
                (Number(this.$store.state.errorPartList[i][1]) + Number(1)) +
                "行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";

            } else {
              errorText =
                "全列" +
                this.$store.state.errorPartList[i][1] +
                "行目:" +
                this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]];
              copyText += errorText + "\n";
            }
          }
        } else {
          if (this.$store.state.errorPartList[i][2] == 22) {
            copyText += this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]] + "\n";
          } else {
            console.log("ここだよ！！");
            errorText =
              this.$store.state.errorPartList[i][0] +
              "列目" +
              this.$store.state.errorPartList[i][1] +
              "行目:" +
              this.$store.getters.getErroeMessage(this.$store.state.errorPartList[i][2]);
            copyText += errorText + "\n";
            console.log("エラー内容は" + this.$store.state.errorTextMessageList[this.$store.state.errorPartList[i][2]])
          }
        }
      }

      //クリップボードにエラー内容をコピーする
      this.copyToClipboard(copyText);
    },
    /**
      * エラー内容のコピーする内容を格納し、コピーメソッドを呼ぶ
      * 
      * @param copyText コピー内容が格納された配列
      * @return コピー内容
      */
    copyToClipboard: function (copyText) {
      console.log("---コピーする内容です--");
      console.log(copyText);
      // テキストコピー用の一時要素を作成
      const pre = document.createElement("pre");

      // テキストを選択可能にしてテキストセット
      pre.style.webkitUserSelect = "auto";
      pre.style.userSelect = "auto";
      pre.textContent = copyText;

      // 要素を追加、選択してクリップボードにコピー
      document.body.appendChild(pre);
      document.getSelection().selectAllChildren(pre);
      const result = document.execCommand("copy");

      // 要素を削除
      document.body.removeChild(pre);

      return result;
    },
  }
}