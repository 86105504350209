export const setVuex = {
    data: function () {
        return {
            vuexStore: this.$store

        }
    },
    methods: {
        /**
        * 動画ダウンロードが成功したかどうかの判定値をvuexにセットする
        */
        setVuexMovieDownloadSuccess: function (data) {
            console.log("vuexに値をセットします");
            this.vuexStore.commit("setVuexMovieDownloadSuccess", data);
        },
        /**
         * 画像ダウンロードが成功したかどうかの判定値をvuexにセットする
         */
        setVuexImageDownloadSuccess: function (data) {
            this.vuexStore.commit("setVuexImageDownloadSuccess", data);
        },
        /**
         * 動画のアップロードが成功したかどうかの判定値をvuexにセットする
         */
        setVuexMovieUploadSuccess: function (data) {
            this.vuexStore.commit("setVuexMovieUploadSuccess", data);
        },
        /**
        * 画像のアップロードが成功したかどうかの判定値をvuexにセットする
        */
        setVuexImageUplaodSuccess: function (data) {
            this.vuexStore.commit("setVuexImageUplaodSuccess", data);
        },
        /**
        * Firebase Storageに保存された画像の名前をvuexにセットする
        */
        setVuexUploadImageName: function (data) {
            this.vuexStore.commit("setVuexUploadImageName", data);
        },
        /**
        * Firebase Storageに保存された画像の保存先urlをvuexにセットする
        */
        setVuexUploadImageUrl: function (data) {
            this.vuexStore.commit("setVuexUploadImageUrl", data);
        },
        /**
        * アップロードしたvimeoの動画urlをvuexにセットする
        */
        setVuexVimeoVideoUrl: function (data) {
            this.vuexStore.commit("setVuexVimeoVideoUrl", data);
        },
        /**
        * 動画のハッシュ値をvuexにセットする
        */
        setVuexVideoHash: function (data) {
            console.log("登録しようとしているハッシュ値は"+data+"です");
            this.vuexStore.commit("setVuexVideoHash", data);
        },
        /**
        * viemoの動画にホワイトリストを登録したかどうかの判定値をvuexにセットする
        */
        setVuexVimeoWhiteListRegisterSuccess: function (data) {
            this.vuexStore.commit("setVuexVimeoWhiteListRegisterSuccess", data);
        },
        /**
        * vimeoの動画のフォルダ移動を行なったかの判定値をvuexにセットする
        */
        setVuexReserveVimeoFolderSuccess: function (data) {
            this.vuexStore.commit("setVuexReserveVimeoFolderSuccess", data);
        },
        /**
        * ユーザー登録が成功したかどうかの判定値をvuexにセットする
        */
        setVuexUserRegisterSuccess: function (data) {
            this.vuexStore.commit("setVuexUserRegisterSuccess", data);
        },
        /**
        * 画面上のDB使用量を更新したかどうかの判定値をvuexにセットする
        */
        setVuexScreenChangeDBSuccess: function (data) {
            this.vuexStore.commit("setVuexScreenChangeDBSuccess", data);
        },
        /**
        * Firebase StorageのClientDB使用量を更新したかどうかの判定値をvuexにセットする
        */
        setVuexClientStorageDBChangeSuccess: function (data) {
            this.vuexStore.commit("setVuexClientStorageDBChangeSuccess", data);
        },
        /**
       * Firebase StorageのプロジェクトDB使用量を更新したかどうかの判定値をvuexにセットする
       */
        setVuexProjectStorageDBChangeSuccess: function (data) {
            this.vuexStore.commit("setVuexProjectStorageDBChangeSuccess", data);
        },
        /**
        * 画面上の使用本数を更新したかどうかの判定値をvuexにセットする
        */
        setVuexScreenUsedMovieNumberSuccess: function (data) {
            this.vuexStore.commit("setVuexScreenUsedMovieNumberSuccess", data);
        },
        /**
        * Firebase Storageの使用本数を更新したかどうかの判定値をvuexにセットする
        */
        setVuexStorageUsedMovieNumberSuccess: function (data) {
            this.vuexStore.commit("setVuexStorageUsedMovieNumberSuccess", data);
        },
        /**
        * ビデオデータをvuexにセットする
        */
        setVuexVideoData: function (data) {
            this.vuexStore.commit("setVuexVideoData", data);
        },
        /**
        * 画像データをvuexにセットする
        */
        setVuexImageData: function (data) {
            this.vuexStore.commit("setVuexImageData", data);
        },
        /**
        * 動画アップロード数をvuexにセットする
        */
        setVuexUploadCount: function (data) {
            this.vuexStore.commit("setVuexUploadCount", data);
        },
        /**
         * クライアントIDをvuexにセットする
         */
        setVuexClientId: function (data) {
            this.vuexStore.commit("setVuexClientId", data);
        },
        /**
         * プロジェクトIDをvuexにセットする
         */
        setVuexProjectId: function (data) {
            this.vuexStore.commit("setVuexProjectId", data);
        },
        /**
         * ユーザーのファイル名をvuexにセットする
         */
        setVuexUserFileName: function (data) {
            this.vuexStore.commit("setVuexUserFileName", data);
        },
        /**
         * アップロードする動画容量をvuexにセットする
         */
        setVuexVideoDB:function(data){
            this.vuexStore.commit("setVuexVideoDB", data);
        },
        /**
         * 動画が全てアップロード完了したかどうかの真偽値をvuexにセットする
         */
        setVuexUploadAllSucessed:function(data){
            this.vuexStore.commit("setVuexUploadAllSucessed", data);
        }
    }
}