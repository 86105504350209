import { sessionGetter } from "./sessionGetter";
export const firebaseStorageDelete = {
    mixins: [sessionGetter],
    data: function () {
        return {
            vuexStore: this.$store

        }
    },
    methods: {
        /**
         * firebaseStorageにある画像の削除
         */
        deleteImage: function (clientId, projectId, imageName, firebase) {
            console.log("画像削除を開始");
            const storageRef = firebase.storage().ref();
            //画像を保存しているパス
            const imagePass = storageRef.child("/img/" + clientId + "/" + projectId + "/" + imageName);
            imagePass.delete().then(() => {
                // File deleted successfully
                console.log("画像削除成功");
            }).catch((error) => {
                console.log("画像削除失敗");
                console.log(error);
            });
        },
        /**
         * firebase Storageに保存されているユーザーファイルの削除
         */
        deleteUser: function (clientId, projectId, userfileName, firebase) {
            console.log("ユーザー削除を開始");
            var userFilePass = firebase
                .storage()
                .ref()
                .child(
                    "/user/" + clientId + "/" + projectId + "/" + userfileName + ".json"
                );
            userFilePass.delete();
            console.log("ユーザー削除成功");
        },
        /**
         * スクリーン上のDB容量をもとに戻す
         */
        rollBackScreenDB: function (videoData) {
            console.log("スクリーン上のDB容量をもとに戻します");
            this.vuexStore.commit("rollBackDB", videoData);
        },
        /**
         * スクリーン上の使用本数をもとに戻す
         */
        rollBackScreenUsedNumber: function () {
            console.log("スクリーン上の使用本数をもとに戻します");
            //videoのコンテンツ数
            const deleteVideoContent = 1;

            //画面上のデータを更新する
            this.vuexStore.commit("rollBackVideoContentsNumber", deleteVideoContent);
        },
        /**
         * Firebase StorageのクライアントDBをもとに戻す
         */
        rollBackStorageClientDB: function (clientId, firebase, store) {
            console.log("storageのクライアントDB容量をもとにもどします");
            console.log(clientId);
            console.log(firebase);
            console.log(store);
            var clientUpdate = new Promise((resolve) => {


                //DB容量のセッション
                const dbSesion = this.getterSessionVideoDB();
                console.log("DBセッションのDB容量は" + dbSesion + "です");

                //登録するjsonのパスを設定
                const clientInfostorageRef = firebase.storage().ref("/db/" + clientId + ".json");

                //クライアントの情報をセットする
                const clientInfo = store.state.clientInfo[0];

                console.log("クライアント情報");
                console.log(clientInfo);

                //現在のクライアントDBを数値に変換
                const nowClientDb = store.state.totalDB;

                console.log("現在のクライアントDBは" + nowClientDb + "です");

                //動画の容量を更新する
                clientInfo.used_db = nowClientDb
                console.log("更新した動画DB容量は" + clientInfo.used_db + "です")

                const nowClientContentsNumber = store.state.usedNumber;

                //動画の本数を更新する
                clientInfo.used_number = Number(nowClientContentsNumber) - 1;

                console.log("クライアント使用本数")
                console.log(clientInfo.used_number);
                console.log(clientInfo);

                //jsonフォーマットに変換する
                var clientJsonFormat = JSON.stringify(clientInfo);

                //アップロードするデータをBlobオブジェクトに
                const clientInfoClient = new Blob([clientJsonFormat], {
                    type: "application/json",
                });


                //データ更新
                clientInfostorageRef.put(clientInfoClient).then(() => {
                    /*
                    //画面上のデータも更新
                    const totalData = store.state.totalDB;
                    const sendData = parseInt(totalData) - parseInt(dbSesion);
                    //画面上のデータを更新する
                    store.commit("setTotalDB", sendData);
                    */
                    console.log("クライアントのロールバックを完了しました" + clientInfoClient);
                    resolve("update完了");
                })

            })
            return clientUpdate
        },
        /**
         * Firebase StorageのプロジェクトDBとプロジェクトの本数をもとに戻す
         */
        rollBackStorageProjectDB: function (clientId, firebase, store) {
            console.log("storageのプロジェクトDB容量をもとにもどします");
            console.log(clientId);
            console.log(firebase);
            console.log(store);

            //DB容量のセッション
            // const dbSesion = this.getterSessionVideoDB();

            //ロールバックするDBのプロジェクトID
            const selectedProjectId = this.getterSessionProjectId();

            console.log("セッションプロジェクトID")
            console.log(selectedProjectId);
            console.log(store.state.projectInfo);

            var projectInfo;

            //ロールバックするDBのプロジェクト情報を取得する
            for (var i = 0; i < store.state.projectInfo[0].length; i++) {
                if (store.state.projectInfo[0][i].project_id == selectedProjectId) {
                    projectInfo = store.state.projectInfo[0][i];
                }
            }

            console.log("プロジェクトの情報")
            console.log(projectInfo);
            var projectUpdate = new Promise((resolve) => {

                //ロールバック用jsonパスの設定
                var projectInfoRef = firebase.storage().ref(
                    "/db/" + clientId + "/" + selectedProjectId + ".json"
                );

                //現在のクライアントDBを数値に変換
                const nowProjectDb = parseInt(projectInfo.used_data_contents);

                console.log("現在のプロジェクトDB容量は" + nowProjectDb);

                //動画の容量を更新する
                projectInfo.used_data_contents = nowProjectDb;

                console.log("更新後のプロジェクトDB容量は" + projectInfo.used_data_contents + "です");

                //動画の本数を更新する
                // projectInfo.used_number -= 1;

                console.log("動画の本数は");
                console.log(projectInfo.used_number);
                var data = {
                    "projectId": selectedProjectId,
                    "project_name": projectInfo.project_name,
                    "vimeo_folder_pass": projectInfo.vimeo_folder_pass,
                    "used_data_contents": projectInfo.used_data_contents,
                    "used_number": projectInfo.used_number
                }

                //jsonフォーマットに変換する
                var projectJsonFormat = JSON.stringify(data);

                //アップロードするデータをBlobオブジェクトに
                const projectInfoClient = new Blob([projectJsonFormat], {
                    type: "application/json",
                });

                //データ更新
                projectInfoRef.put(projectInfoClient).then(() => {
                    console.log("プロジェクトのロールバックを完了しました");
                    resolve("updata完了");
                })

                console.log(projectInfoClient + projectInfoRef);
                resolve("テスト");

            })
            return projectUpdate;

        },
        /**
         * Firebase Storageの使用本数をもとに戻す
         */
        rollBackStorageUsedNumber: function () { },
    }
}