<template>
  <ul class="topnav">
    <div class="navigation">
      <li class="client-name">{{ clientInfo[0].client_name }}</li>
      <div class="data">
        <li class="used-content">
          <p>使用データ容量</p>
        </li>
        <li class="value">
          <p>
            {{ Math.floor(usedDB) }}DB/{{ clientInfo[0].contract_plan }}DB使用中
          </p>
        </li>
        <li class="used-content-number">
          <p>使用本数</p>
        </li>
        <li class="value">
          <p>{{ uploadNum }}本</p>
        </li>
        <li class="contract-plan">
          <p>契約プラン</p>
        </li>
        <li class="value">
          <p>Limit{{ clientInfo[0].contract_plan }}DBプラン</p>
        </li>
        <li class="right">
          <button class="logout" v-on:click="logout()">ログアウト</button>
        </li>
      </div>
    </div>
  </ul>
</template>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script>
export default {
  name: "ProjectBarComponent",
  computed: {
    clientInfo: {
      get() {
        return this.$store.state.clientInfo;
      },
      set() {
        return this.$store.state.clientInfo;
      },
    },
    usedDB: {
      get() {
        return this.$store.state.totalDB;
      },
      set() {
        return this.$store.state.totalDB;
      },
    },
    uploadNum: {
      get() {
        return this.$store.state.usedNumber;
      },
      set() {
        return this.$store.state.usedNumber;
      },
    },
  },
  data: function () {
    return {};
  },
  methods: {
   /**
    * ログアウト
    */
    logout: function () {
      if (this.$store.state.allUploadClear == true) {
        firebase.auth().onAuthStateChanged((user) => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              console.log("ログアウトしました");
              this.$store.commit("allReset");
              this.$router.go({
                path: this.$router.currentRoute.path,
                force: true,
              });
            })
            .catch((error) => {
              console.log(`ログアウト時にエラーが発生しました (${error})`);
            });
        });
      } else {
        var result = window.confirm(
          "動画アップロード中です。データが損なわれる可能性がありますが、よろしいですか？"
        );
        if (result == true) {
          firebase.auth().onAuthStateChanged((user) => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                console.log("ログアウトしました");
                this.$store.commit("allReset");
                this.$router.go({
                  path: this.$router.currentRoute.path,
                  force: true,
                });
              })
              .catch((error) => {
                console.log(`ログアウト時にエラーが発生しました (${error})`);
              });
          });
        }
      }
    },
  },
};
</script>
<style scoped>
@media (min-width: 0px) {
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
  ul.topnav {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: #3f3d56 0% 0% no-repeat padding-box;
    height: 54px;
  }
  ul.topnav li {
    float: left;
  }

  .used-content {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }
  .used-content-number {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .contract-plan {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  p {
    position: relative;
    top: -8px;
    left: 2px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 5px;
  }

  .value {
    position: relative;
    height: 20px;
    top: 10px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .navigation {
    margin-top: 0.3%;
    margin-left: 9%;
  }

  .data {
    float: right;
  }

  .client-name {
    font-size: 12px;
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    margin-left: 80px;
    display: block;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
  }

  .logout {
    font-size: 5px;
    margin-left: 24px;
    font-family: "Noto Sans JP", sans-serif;
    width: 120px;
    height: 64px;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: -8px;
    color: white;
  }
}
@media (min-width: 1000px) {
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
  ul.topnav {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: #3f3d56 0% 0% no-repeat padding-box;
    height: 54px;
  }
  ul.topnav li {
    float: left;
  }

  .used-content {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }
  .used-content-number {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .contract-plan {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  p {
    position: relative;
    top: -8px;
    left: 2px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 5px;
  }

  .value {
    position: relative;
    height: 20px;
    top: 10px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .navigation {
    margin-top: 0.3%;
    margin-left: 9%;
  }

  .data {
    float: right;
  }

  .client-name {
    font-size: 12px;
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    margin-left: 80px;
    display: block;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
  }

  .logout {
    font-size: 5px;
    margin-left: 24px;
    font-family: "Noto Sans JP", sans-serif;
    width: 120px;
    height: 64px;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: -8px;
    color: white;
  }
}
@media (min-width: 1136px) {
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
  ul.topnav {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: #3f3d56 0% 0% no-repeat padding-box;
    height: 54px;
  }
  ul.topnav li {
    float: left;
  }

  .used-content {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }
  .used-content-number {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
    margin-left: 32px;
  }

  .contract-plan {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
    margin-left: 32px;
  }

  p {
    position: relative;
    top: -10px;
    left: 2px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 12px;
  }

  .value {
    position: relative;
    height: 20px;
    top: 12px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .navigation {
    margin-top: 0.3%;
    margin-left: 9%;
  }

  .data {
    float: right;
  }

  .client-name {
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    margin-left: 80px;
    display: block;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
  }

  .logout {
    font-size: 12px;
    margin-left: 24px;
    font-family: "Noto Sans JP", sans-serif;
    width: 120px;
    height: 64px;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: -8px;
    color: white;
  }
}
@media (min-width: 1356px) {
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
  ul.topnav {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: #3f3d56 0% 0% no-repeat padding-box;
    height: 54px;
  }
  ul.topnav li {
    float: left;
  }

  .used-content {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }
  .used-content-number {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
    margin-left: 32px;
  }

  .contract-plan {
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    background: #de7b37 0% 0% no-repeat padding-box;
    font-size: 12px;
    height: 22px;
    top: 10px;
    border-radius: 2px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
    margin-left: 32px;
  }

  p {
    position: relative;
    top: -10px;
    left: 2px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 12px;
  }

  .value {
    position: relative;
    height: 20px;
    top: 12px;
    display: block;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .navigation {
    margin-top: 0.3%;
    margin-left: 9%;
  }

  .data {
    float: right;
  }

  .client-name {
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    margin-left: 80px;
    display: block;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
  }

  .logout {
    font-size: 12px;
    margin-left: 24px;
    font-family: "Noto Sans JP", sans-serif;
    width: 120px;
    height: 64px;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: -8px;
    color: white;
  }
}
</style>
