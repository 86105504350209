var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"1200px"}},[_c('slick',{ref:"slick",staticClass:"itemlists",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.publishedContents),function(user,index){return _c('div',{key:index,staticClass:"itemlist_item",class:{
        image: user.contents_kinds == '画像',
        movie: user.contents_kinds == '動画',
      },attrs:{"data-target":("#item0" + (index + 1))}},[_c('div',{staticClass:"itemlist_item_wrap"},[(user.user_name_status == '1')?_c('p',{staticClass:"itemlist_item_name"},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),(user.contents_kinds == '動画')?_c('div',{staticClass:"publish-itemlist_item_play"},[_c('img',{attrs:{"src":require("../../assets/play.png")}})]):_vm._e(),(user.contents_kinds == '画像')?_c('img',{staticClass:"main-img",attrs:{"src":user.main_image_url}}):_vm._e(),(user.contents_kinds == '動画')?_c('img',{staticClass:"main-img main_thumb",attrs:{"src":user.main_thumb_image_url,"alt":""}}):_vm._e(),_c('div',{staticClass:"itemlist_item_body"},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"itemlist_item_thumb"},[_c('img',{staticClass:"thumb-img",attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(
              user.comment_status == '1' ||
              user.title_status == '1' ||
              (user.thumb_status == '1' && user.thumb_image_url != '')
            )?_c('div',{staticClass:"itemlist_item_txt"},[(user.title_status == '1' && user.comment_status == '1')?_c('p',[_vm._v(" "+_vm._s(user.title)+" ")]):_vm._e(),(user.title_status == '0' && user.comment_status == '1')?_c('p'):_vm._e(),(user.title_status == '1' && user.comment_status == '0')?_c('p',[_vm._v(" "+_vm._s(user.title)),_c('br')]):_vm._e(),(user.title_status == '0' && user.comment_status == '0')?_c('p'):_vm._e()]):_vm._e()])])])}),0),_c('div',{staticClass:"overray"},[_c('div',{staticClass:"overray_wrap"},[_c('div',{staticClass:"overray_inner"},[_vm._m(0),_vm._m(1),_vm._l((_vm.publishedContents),function(user,index){return _c('div',{key:index,staticClass:"overray_item",attrs:{"id":("item0" + (index + 1))}},[(user.contents_kinds == '動画')?_c('div',{staticClass:"overray_item_wrap"},[_vm._m(2,true),(user.user_name_status == '1')?_c('p',{staticClass:"overray_item_name"},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),_vm._m(3,true),_vm._m(4,true),_c('div',{staticClass:"overray_item_video",staticStyle:{"width":"100%","margin":"0%","height":"100%","z-index":"99999999999999999999"},attrs:{"id":index + 77777}},[_c('iframe',{staticClass:"overray_item_video",staticStyle:{"margin":"0%","position":"absolute","top":"0","left":"0","width":"100%","height":"100%","z-index":"-99999999999999999"},attrs:{"src":'https://player.vimeo.com/video/' +
                  user.video_id +
                  '&controls=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=0&loop=1',"frameborder":"0","width":"100%","allow":"autoplay; fullscreen;","allowfullscreen":"","title":"hand1.mp4"}})]),_c('div',{staticClass:"itemlist_item_body_overray"},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"overray_itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(user.comment_status == '1' || user.title_status == '1')?_c('div',{staticClass:"overray_itemlist_item_txt"},[(
                    user.title_status == '1' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])]),_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '1' && user.comment_status == '0'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '0'
                  )?_c('div'):_vm._e()]):_vm._e()]),_c('div',{staticClass:"overray_item_bottom"},[(_vm.serviceLogo == '1')?_c('a',{staticClass:"overray_item_bottom_ico",attrs:{"href":"https://mindfree.jp/","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/ico_mf.png"),"alt":"MindFree"}})]):_vm._e(),(_vm.serviceLogo == '0')?_c('a',{staticClass:"overray_item_bottom_ico"}):_vm._e(),(user.cta_status == '1' && user.cta != '')?_c('a',{staticClass:"overray_item_bottom_btn",attrs:{"href":user.cta}},[_c('img',{attrs:{"src":require("../../assets/btn.png"),"alt":"詳しくはこちら"}})]):_vm._e()])]):_vm._e(),(user.contents_kinds == '画像')?_c('div',{staticClass:"overray_item_wrap"},[(user.user_name_status == '1')?_c('p',{staticClass:"overray_item_name"},[_vm._v(" "+_vm._s(user.user_name)+" ")]):_vm._e(),_vm._m(5,true),_c('img',{staticStyle:{"z-index":"9999999999999999999"},attrs:{"src":user.main_image_url,"alt":""}}),_c('div',{staticClass:"itemlist_item_body_overray"},[(user.thumb_status == '1' && user.thumb_image_url != '')?_c('div',{staticClass:"overray_itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),(
                  (user.thumb_status == '1' && user.thumb_image_url != '') ||
                  user.title_status == '1' ||
                  user.comment_status == '1'
                )?_c('div',{staticClass:"overray_itemlist_item_txt"},[(
                    user.title_status == '1' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])]),_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '1' && user.comment_status == '0'
                  )?_c('div',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(user.title))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '1'
                  )?_c('div',[_c('div',[_c('span',{staticClass:"comment-text"},[_vm._v(_vm._s(user.comment))])])]):_vm._e(),(
                    user.title_status == '0' && user.comment_status == '0'
                  )?_c('div'):_vm._e()]):_vm._e()]),_c('div',{staticClass:"overray_item_bottom"},[(_vm.serviceLogo == '1')?_c('a',{staticClass:"overray_item_bottom_ico",attrs:{"href":"https://mindfree.jp/","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/ico_mf.png"),"alt":"MindFree"}})]):_vm._e(),(_vm.serviceLogo == '0')?_c('a',{staticClass:"overray_item_bottom_ico"}):_vm._e(),(user.cta_status == '1' && user.cta != '')?_c('a',{staticClass:"overray_item_bottom_btn",attrs:{"href":user.cta}},[_c('img',{attrs:{"src":require("../../assets/btn.png"),"alt":"詳しくはこちら"}})]):_vm._e()])]):_vm._e()])})],2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_prev"},[_c('img',{attrs:{"src":require("../../assets/arrow_prev_l.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_next"},[_c('img',{attrs:{"src":require("../../assets/arrow_next_l.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_item_time"},[_c('span',{staticClass:"overray_item_time_bar",attrs:{"id":"overray_item_time_bar"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_item_close"},[_c('img',{attrs:{"id":"close_icon","src":require("../../assets/ico_close.png"),"alt":"close"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_item_play"},[_c('img',{attrs:{"src":require("../../assets/play.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overray_item_close"},[_c('img',{attrs:{"id":"close_icon","src":require("../../assets/ico_close.png"),"alt":"close"}})])}]

export { render, staticRenderFns }