export const tikQErrorManage = {
    methods: {
        /**
         * 説明
         * csvでエラー箇所にマーカーを引くため、足りない部分を補う
         */
        dataCompensate1: function () {
            const csvData = this.vuexStore.state.csvData;
            //二次元配列の最大の配列の長さを取得
            var firstData = csvData[0].length;
            var maxArgsLength = 0;
            maxArgsLength = firstData;
            if (csvData.length >= 2) {
                //データがヘッダ含め2つ以上あった場合
                for (var i = 0; i < csvData.length - 1; i++) {
                    //一番最初とその次の要素の数字を比べる
                    var result = Math.max(maxArgsLength, csvData[i + 1].length);
                    maxArgsLength = result;
                }
            } else if (csvData.length == 1) {
                maxArgsLength = Math.max(this.headerData.length, csvData[0].length);
            }
            for (var t = 0; t < csvData.length; t++) {
                if (csvData[t].length < maxArgsLength) {
                    var roop = maxArgsLength - csvData[t].length;
                    for (var s = 0; s < roop; s++) {
                        this.vuexStore.commit("addTableData", t);
                    }
                }
            }
        },
        /**
         * 説明
         * ボックスのカラーを変更させる
         * @param アップロードに成功したか失敗したかのステータス
         */
        boxColorChange1: function (sucess) {
            if (sucess == true) {
                /*
                const boxContents = document.getElementById("js-wrap-message");
                boxContents.style.backgroundColor = "#CCFFFF";
                */
            } else if (sucess == false) {
                /*
                const boxContents = document.getElementById("js-wrap-message");
                boxContents.style.backgroundColor = "#f9e9eb";
                */
            }
        },

        /**
         * 説明
         * csvアップロードの成功/失敗のメッセージボックスの下部のマージンを整える
         */
        messageBoxMarginChange1: function () {
            /*
            var messageBoxElement = document.getElementById("js-wrap-message");
            if (this.vuexStore.state.csvUploadJudgement === "") {
                messageBoxElement.style.height = "56px";
            } else if (this.vuexStore.state.csvUploadJudgement === true) {
                messageBoxElement.style.height = "56px";
            } else if (this.vuexStore.state.csvUploadJudgement === false) {
                messageBoxElement.style.height = "56px";
            }
            */
        },
        /**
         * 説明
         * エラーエクセル表を作成する設計書
         * @param csvData csvのデータ
         */
        createCSVDataTable1: function (csvData) {
            var table = document.querySelector("table");

            //csvのヘッダを作成
            this.createExcelHeader1(csvData, table);

            //csvのデータをtableに入れていく
            this.createExcelData1(csvData, table);
            console.log("ここまではOKです");

            //エラー箇所のエクセルに色をつける
            this.errorAddColor1();
        },
        /**
         *説明
         *エクセルのヘッダを作成
         *@param csvData csvのデータ
         *@param table csvエクセルのテーブル要素
         * 
         */
        createExcelHeader1: function (csvData, table) {

            //tableに追加する行の作成
            var row = table.insertRow();

            //<th>のタグを作成
            var th0 = document.createElement("th");

            th0.style.width = "50px";

            th0.style.height = "20px";

            //<th>タグを行に追加
            row.appendChild(th0);

            //ヘッダを追記していく
            for (var i = 0; i < csvData[0].length; i++) {
                var th = document.createElement("th");
                th.id = "header";
                th.style.width = "60px";
                th.style.height = "20px";
                th.fontSize = "50px";
                row.appendChild(th);
                th.innerHTML = this.excelHeaderAlphabet[i];
            }
        },
        /**
         * csvのデータ１つ１つにデータを挿入していく
         * @param {*} csvData csvのデータ
         * @param {*} table テーブル要素
         */
        createExcelData1: function (csvData, table) {
            for (var i = 0; i < csvData.length; i++) {
                //tableに追加する行の作成（trタグの作成）
                var row = table.insertRow();

                //divタグを作成する
                var divElement = document.createElement("div");

                divElement.style.backgroundColor = "#FFFF99";

                divElement.style.padding = "10px";

                //セルを埋め込む
                var cell = row.insertCell();

                cell.appendChild(divElement);

                cell.className = "rowNumber";

                cell.textContent = i + 1;

                //作成したthタグにtdタグ（csvの個々のデータ）を埋め込んでいく
                for (var t = 0; t < csvData[i].length; t++) {
                    var divElement2 = document.createElement("div");
                    divElement2.style.padding = "10px";
                    divElement2.innerHTML = "";

                    //セルを埋め込む
                    var cell2 = row.insertCell();

                    cell2.appendChild(divElement2);

                    cell2.className = "value";
                    cell2.style.height = "10px";
                    var id = (i + 1).toString() + "-" + (t + 1).toString();
                    cell2.setAttribute("id", id);
                }
            }
        },
        /**
         * 説明
         * エラー箇所にマーカーを引く
         */
        errorAddColor1: function () {
            const csvData = this.vuexStore.state.csvData;
            var coloredId;
            for (var i = 0; i < this.vuexStore.state.errorPartList.length; i++) {
                const row = this.vuexStore.state.errorPartList[i][1];
                console.log("行は" + row);
                const col = this.vuexStore.state.errorPartList[i][0];
                const errorId = this.vuexStore.state.errorPartList[i][2];

                //全列対象のエラーがあった場合
                if (col == "全列") {
                    if (errorId == "12") {//データが一つもないエラー
                        for (var m = 0; m < csvData[1].length; m++) {
                            coloredId = row + 1 + "-" + (m + 1);
                            document.getElementById(coloredId).children[0].style.backgroundColor = "#FFFF99";
                        }

                    } else {
                        for (var t = 0; t < csvData[row].length; t++) {
                            coloredId = row + 1 + "-" + (t + 1);
                            document.getElementById(coloredId).children[0].style.backgroundColor = "#FFFF99";
                        }
                    }
                } else {
                    if (errorId == 14) {//読み込むデータが100件より多くある場合
                        const max = 100;
                        this.vuexStore.commit("initialCsvErrorData");
                        console.log((csvData.length - 1) - max);
                        console.log(csvData.length);
                        console.log(csvData[0]);
                        for (var s = 0; s < (csvData.length - 1) - max; s++) {//sは超過した分の行数（例一行超過したら１）
                            console.log(s);
                            this.errorManagement("全列", max + (s + 2), 14);
                            console.log(csvData[(max + 1 + s)]);
                            for (var p = 0; p < csvData[(max + 1 + s)].length; p++) {
                                coloredId = max + (s + 2) + "-" + (p + 1);
                                document.getElementById(coloredId).children[0].style.backgroundColor = "#FFFF99";
                            }
                        }
                        break;
                    } else {
                        //◯行-◯列の表記ID
                        coloredId = row + "-" + col;
                        document.getElementById(coloredId).children[0].style.backgroundColor = "#FFFF99";
                    }
                }
            }
        },
        /**
        * 発生したエラーをチェックし、エラー内容を表示/成功内容を表示
        */
        checkError: function () {
            const csvData = this.vuexStore.state.csvData;
            var errorList = this.vuexStore.state.errorPartList;
            if (errorList.length == 0) {//エラーがなかった場合
                this.messageBoxMarginChange1();
                this.boxColorChange1(true);
                this.vuexStore.state.csvUploadJudgement = true;
                //アップロード成功
                this.vuexStore.commit("setUploadSucess", true);
            } else {//エラーがあった場合
                this.dataCompensate1();
                this.boxColorChange1(false);
                this.vuexStore.state.csvUploadJudgement = false;
                this.messageBoxMarginChange1();
                this.createCSVDataTable1(csvData);
                this.vuexStore.state.csvUploadJudgement = false;
                //アップロード失敗
                this.vuexStore.commit("setUploadSucess", false);
            }
        },
        /**
         * DB全般で発生したエラー対応
         * @param {*} errorMassage エラーメッセージ
         * @param {*} errorRow エラー行
         * @returns 
         */
        dbErrorManager: function (errorMassage, errorRow) {
            if (errorMassage == "容量オーバー") {
                this.errorManagement("全列", errorRow, 16);
                return false;

            } else if (errorMassage == "5MB制限") {
                this.errorManagement("全列", errorRow, 15);
                return true;
            } else if (errorMassage == "tiktokアクセス") {
                this.errorManagement("全列", errorRow, 17);
                return true;
            } else if (errorMassage == "downloadError") {
                this.errorManagement("全列", errorRow, 18);
            }
        },

        /**
         * アクセスエラー全般を扱う
         * @param {*} errorMassage エラーメッセージ
         * @param {*} errorRow エラー行
         */
        accessErrorManager: function (errorMassage, errorRow) {
            if (errorMassage == "動画のプライバシー設定またはアクセスエラー") {
                this.errorManagement("全列", errorRow, 18);
                return false;
            } else if (errorMassage == 403) {
                this.errorManagement("全列", errorRow, 19);
                return false;
            } else if (errorMassage == "retryError") {//動画の再アップロードに失敗[アクセスエラーで]
                this.errorManagement("全列", errorRow, 21);
                return false;
            }
        },
        /**
         * 
         * @param col エラー列（0が渡ってきた場合、列は関係なしor全列）
         * @param row エラー行 （0が渡ってきた場合、行は関係なしor全行）
         * @param errorId エラー番号
         */
        errorManagement: function (colData, rowData, errorId) {
            var errorPoint = {
                col: colData,
                row: rowData,
                code: errorId,
            };
            this.vuexStore.commit("setCsvErrorList", errorPoint);
        },
        /**
         * ヘッダ以外のデータが一行も記載されていない場合
         */
        blankRowAdd: function () {
            const csvData = this.vuexStore.state.csvData;
            if (csvData.length != 0) {
                for (var i = 0; i < this.vuexStore.state.errorPartList.length; i++) {
                    if (this.vuexStore.state.errorPartList[i][2] == "12") {
                        this.vuexStore.commit("addBlankData", csvData[0].length);
                    }
                }
            }
        }
    }
}