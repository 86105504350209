<template>
  <div class="container">
    <div class="subject">プロジェクト一覧</div>
    <div class="button-list">
      <button
        class="maneger_list_button"
        v-on:click="changeAdminListHomeScreen()"
      >
        管理者一覧
      </button>
      <button
        class="new-create-button"
        v-on:click="changeProjectCreateScreen()"
      >
        新規作成+
      </button>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr class="topic">
            <th width="20%">プロジェクト名</th>
            <th width="20%">プロジェクト期間</th>
            <th width="30%">使用本数（使用中データ容量）</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="(project, index) in projectsInfo" :key="index">
          <tr height="50">
            <td>
              <router-link
                @click.native="selectedProject(index)"
                :to="{
                  path: 'projectInfo',
                }"
                class="project-link"
                href=""
                >{{ project.project_name }}</router-link
              >
            </td>
            <td>
              {{ project.contract_start_day }}〜{{
                project.contract_finished_day
              }}
            </td>
            <td>
              {{ project.used_number }}本 使用中 （{{
                Math.floor(project.used_data_contents)
              }}DB使用中)
            </td>
            <td></td>
            <td>
              <button
                class="rewrite-button"
                type="submit"
                v-on:click="changeProjectRewriteScreen(index)"
              >
                編集
              </button>
            </td>
            <td>
              <button
                class="delete-button"
                type="submit"
                v-on:click="deleteModalShow(index)"
              >
                削除
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="deleteModal" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-content">
            <p class="reserve-text">削除後復元出来ません。</p>
            <p class="reserve-text-2">削除しますか？</p>
            <div class="btn-modal">
              <div>
                <button
                  class="modal-cancel-button modalClose"
                  type="submit"
                  v-on:click="deleteModalhide()"
                >
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue-js-modal@1.3.31/dist/index.min.js"></script> 
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
const tus = require("tus-js-client");
const Vimeo = require("vimeo").Vimeo;
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new Vimeo(
  VIMEO_CLIENT_ID, // ID
  VIMEO_CLIENT_SECRET, // 秘密鍵
  VIMEO_ACCESS_TOKEN // アクセストークン
);
export default {
  name: "ProjectListHomeComponent",
  data: function () {
    return {
      temporaryData: [],
      projectIndex: "",
    };
  },
  mounted() {
    document.body.className = "login";
    document.querySelector("body").style.background = "";
    document.querySelector("body").style.margin = "0%";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
    document.body.style.overflow = "";
    document.getElementsByTagName("html")[0].style.overflow = "";

    //アラートを表示（もしあれば）
    document.getElementById("alert-block-js").style.display="block";
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
  computed: {
    projectsInfo: {
      get() {
        return this.$store.state.projectInfo[0];
      },
      set() {
        return this.$store.state.projectInfo[0];
      },
    },
    maxUsedDB: {
      get() {
        return this.$store.state.clientInfo[0].contract_plan;
      },
      set() {
        return this.$store.state.clientInfo[0].contract_plan;
      },
    },
  },
  methods: {
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクト編集画面に遷移する
    /////////
    ////////引数：index:プロジェクトインデント番号
    /////////////////////////////////////////////////
    changeProjectRewriteScreen: function (index) {
      this.$store.commit("selectedProjectSetter", index);
      this.$router.push("projectRewrite");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////選択したプロジェクトの番号をstoreにセットする
    /////////
    ////////引数：index:プロジェクトインデント番号
    /////////////////////////////////////////////////
    selectedProject: function (index) {
      this.$store.commit("selectedProjectSetter", index);
      //this.getUser(index);
    },
    /*
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクトの全ユーザー情報を取得する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    getUser: async function (index) {
      console.log("--------getUser---------");
      this.$store.commit("resetApprovalInfo");
      this.$store.commit("getUserCheck", false);
      //クライアントIDを取得
      var clientId = this.$store.state.clientId;

      //プロジェクトIDを取得
      var projectId = this.$store.state.projectInfo[0][index].project_id;

      var instance = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/user/" + clientId + "/" + projectId);
      var list = await listRef.listAll();
      var imgList = [];
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              instance.temporaryData.push(JSON.parse(blob));
              store.commit("setApprovalInfo", JSON.parse(blob));
              store.commit("getUserCheck", true);
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
      }
      if (imgList.length == 0) {
        this.$store.commit("getUserCheck", true);
      }
    },
    */
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクト新規登録画面に遷移する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    changeProjectCreateScreen: function () {
      this.$router.push("projectRegister");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////管理者画面に遷移する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    changeAdminListHomeScreen: function () {
      this.$router.push("managimentList");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////削除モーダルを表示する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteModalShow: function (index) {
      this.projectIndex = index;
      //削除モーダルを表示させる
      document.getElementById("deleteModal").style.display = "block";
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////削除モーダルを非表示にする
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteModalhide: function () {
      //削除モーダルを非表示にする
      document.getElementById("deleteModal").style.display = "none";

      this.waitLoading();

      //プロジェクトを削除する
      this.deleteProject();
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクトを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteProject: function () {
      console.log("--start----deleteProject①---");
      //クライアント情報のDB使用量を変更する
      this.changeClientContentsDB();

      //vimeoから動画を削除
      this.deleteVimeo();

      //プロジェクトをfirebase storageから削除する
      this.deleteProjectFirebaseStorage();
      console.log("-end-----deleteProject20ラスト---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebase storageからプロジェクト情報を全て削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteProjectFirebaseStorage: function () {
      console.log("--start----deleteProjectFirebaseStorage⑥---");
      //クライアントIDを取得
      var clientId = this.$store.state.clientId;

      //プロジェクトIDを取得
      var projectId =
        this.$store.state.projectInfo[0][this.projectIndex].project_id;

      var storageRef = firebase.storage().ref();

      //画像フォルダの削除
      this.deleteImageFirebaseStorage(clientId, projectId, storageRef);

      //公開フォルダの削除
      this.deletePublishigUserFirebaseStorage(clientId, projectId, storageRef);

      //ユーザーフォルダの削除
      this.deleteProjectBelongedUserFirebaseStorage(
        clientId,
        projectId,
        storageRef
      );

      //dbフォルダを削除する
      this.deleteDBFolder(clientId, projectId, storageRef);

      //サービスロゴが格納されているフォルダを削除する
      this.deleteServiceLogoJson(clientId, projectId, storageRef);

      //プロジェクトが格納されているjsonファイルから該当のプロジェクトを削除し、sotrageに保存しなおす
      this.deleteProjectAndRewriteProject();

      console.log("--end----deleteProjectFirebaseStorage19---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebasestorageに保存されている画像フォルダ削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteImageFirebaseStorage: function (clientId, projectId, storageRef) {
      console.log("--start----deleteImageFirebaseStorage⑦---");
      //画像を保存しているパス
      var listReImage = storageRef.child("/img/" + clientId + "/" + projectId);
      // 画像すべて削除
      listReImage.listAll().then((res) => {
        res.items.forEach((itemRef) => {
          itemRef.delete();
        });
      });
      console.log("--end----deleteImageFirebaseStorage⑧---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebasestorageに保存されている公開中のフォルダを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deletePublishigUserFirebaseStorage: function (
      clientId,
      projectId,
      storageRef
    ) {
      console.log("--start----deletePublishigUserFirebaseStorage⑨---");
      //公開しているユーザーが保存されているパス
      var listpublished = storageRef.child(
        "/publish/" + clientId + "/" + projectId
      );
      // すべて削除
      listpublished.listAll().then((res) => {
        res.items.forEach((itemRef) => {
          itemRef.delete();
        });
      });
      console.log("--end----deletePublishigUserFirebaseStorage⑩---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebasestorageに保存されている、プロジェクトに属するユーザーのフォルダを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteProjectBelongedUserFirebaseStorage: function (
      clientId,
      projectId,
      storageRef
    ) {
      console.log("--start----deleteProjectBelongedUserFirebaseStorage11---");

      //プロジェクトに投稿しているユーザーが保存されているパス
      var listprojectUser = storageRef.child(
        "/user/" + clientId + "/" + projectId
      );
      console.log("/user/" + clientId + "/" + projectId);
      // プロジェクトに関するユーザーを全て削除
      listprojectUser.listAll().then((res) => {
        if (res.items.length != 0) {
          res.items.forEach((itemRef) => {
            itemRef.delete();
          });
        }
      });
      console.log("--end----deleteProjectBelongedUserFirebaseStorage12---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebasestorageに保存されている、プロジェクトに属するユーザーのフォルダを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteProjectAndRewriteProject: function () {
      console.log("--start----deleteProjectAndRewriteProject17---");
      var router = this.$router;
      var instance = this;

      //すべてのプロジェクトを取得する
      var allProject = this.$store.state.projectInfo[0];

      //削除するプロジェクトの削除位置
      var deleteIndex = this.projectIndex;

      //削除するプロジェクトの個数
      var deleteQuantity = 1;

      //要素を配列から削除する
      allProject.splice(deleteIndex, deleteQuantity);

      //jsonフォーマットに変換する
      var jsonFormat = JSON.stringify(allProject);

      //このパスに保存する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/project/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.seacretClientId +
            "/project.json"
        );

      const blob = new Blob([jsonFormat], { type: "application/json" });
      ref.put(blob).then(function (result) {
        console.log("--end----deleteProjectAndRewriteProject18---");
        instance.downloaded();
      });
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////vimeoから動画を削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteVimeo() {
      console.log("---start----deleteVimeo④---");
      var folderId =
        this.$store.state.projectInfo[0][this.projectIndex].vimeo_folder_pass;
      console.log(folderId);
      client.request(
        {
          method: "DELETE",
          path: folderId + "?should_delete_clips=" + true,
        },
        function (error, body, status_code, headers) {
          console.log(error);
          console.log(body);
          console.log(status_code);
          console.log(headers);
          console.log("---end----deleteVimeo⑤---");
        }
      );
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////サービスロゴのフォルダを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteServiceLogoJson(clientId, projectId, storageRef) {
      console.log("---start----deleteServiceLogoJson15---");
      //プロジェクトに投稿しているユーザーが保存されているパス
      var serviceLogoJson = storageRef.child(
        "/serviceLogo/" + clientId + "/" + projectId + "/" + "serviceLogo.json"
      );
      serviceLogoJson.delete();
      console.log("---end----deleteServiceLogoJson16---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////DBフォルダーからプロジェクト分のDBを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteDBFolder(clientId, projectId, storageRef) {
      console.log("---start----deleteDBFolder13---");
      //プロジェクトに投稿しているユーザーが保存されているパス
      var projectDb = storageRef.child(
        "/db/" + clientId + "/" + projectId + ".json"
      );
      projectDb.delete();
      console.log("---end----deleteDBFolder14---");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////storeにある全公開中コンテンツの配列から削除するプロジェクトの公開中コンテンツを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deletePublishingContents: function (projectId) {
      this.$store.commit("deletePublishingContents", projectId);
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクトを削除した際、その削除したプロジェクトの使用量DB分をクライアントの使用量DBから引く
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    changeClientContentsDB: function () {
      console.log("----start----changeClientContentsDB②---");
      //クライアント取得
      var client = this.$store.state.clientInfo[0];

      //クライアントの使用量DB
      var clientDb = this.$store.state.clientDB;

      //削除するプロジェクト取得
      var project = this.$store.state.projectInfo[0][this.projectIndex];

      //保存先パス
      var ref = firebase
        .storage()
        .ref()
        .child("db/" + this.$store.state.clientId + ".json");

      //もともとクライアントに登録されているDB容量
      var beforeContentsData = Number(clientDb);

      //差し引く容量
      var deleteData = Number(project.used_data_contents);

      //合計使用量DB
      var newData = beforeContentsData - deleteData;

      client.used_db = newData;

      //2022年4月21日追加
      //削除する本数
      var deleteTotalUploadNum = project.used_number;
      console.log("削除するアップロード本数は");
      console.log(deleteTotalUploadNum);
      var usedNumber = this.$store.state.usedNumber - deleteTotalUploadNum;
      console.log(usedNumber);

      //上部バーの表示を書き換える
      this.$store.commit("rewriteTotalDB", deleteData);
      this.$store.commit("rewriteUploadNumber", deleteTotalUploadNum);

      var clientDBInfo = {
        clientId: this.$store.state.clientId,
        contract_plan: client.contract_plan,
        used_db: newData,
        used_number: usedNumber,
      };

      //プロジェクト情報
      const jsonString = JSON.stringify(clientDBInfo);

      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function () {
        console.log("----end-----changeClientContentsDB③---");
      });
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
//モーダルコンテンツ以外がクリックされた時
addEventListener("click", outsideClose);
function outsideClose(e) {
  if (e.target == document.getElementById("deleteModal")) {
    document.getElementById("deleteModal").style.display = "none";
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
body {
  margin: 0%;
}

.project-link {
  font-family: "Noto Sans JP", sans-serif;
  text-decoration: underline;
  color: #ff7500;
}

.subject {
  font-size: 32px;
  font-family: "Noto Sans JP", sans-serif;
  margin-top: 5%;
}

.topic {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
}

td {
  vertical-align: middle;
  font-family: "Noto Sans JP", sans-serif;
}

.new-create-button {
  margin-right: 10px;
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 88px;
  height: 28px;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

.maneger_list_button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  width: 88px;
  height: 28px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #df7e3b;
  opacity: 1;
  color: #df7e3b;
  margin-right: 8px;
  font-size: 13px;
}

.button-list {
  text-align: right;
  margin-right: 3%;
  margin-bottom: 1%;
}
@media (min-width: 0px) {
  .delete-button {
    margin-left: 15%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .rewrite-button {
    margin-left: 70%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }
}
@media (min-width: 576px) {
  .delete-button {
    margin-left: 15%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .rewrite-button {
    margin-left: 70%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .delete-button {
    margin-left: 0%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .rewrite-button {
    margin-left: 40%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }
}
@media (min-width: 960px) {
  .delete-button {
    margin-left: 15%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .rewrite-button {
    margin-left: 70%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }
}
@media (min-width: 1264px) {
  .delete-button {
    margin-left: 20%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .rewrite-button {
    margin-left: 90%;
    display: inline-block;
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 56px;
    height: 24px;
    border: 0;
    color: #ffffff;
    font-size: 12px;
  }
}

.reserve-text {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 24px;
  margin-top: 5%;
}
.reserve-text-2 {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 24px;
  margin-top: 1%;
  margin-bottom: 30px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgb(82, 80, 80);
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.945);
  color: rgb(0, 0, 0);
  margin: 20% auto;
  width: 30%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: 1s;
}

.modal-reserve-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#20b2aa, 5%, #5f9ea0);
  width: 80px;
  height: 30px;
  border: 0;
  color: #ffffff;
}

.modal-cancel-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  outline: 0;
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
  margin-top: 3%;
}

.btn-modal {
  text-align: center;
  margin-top: 3%;
  margin-bottom: 5%;
}

.btn-modal > div {
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}
</style>
