<template>
  <v-app>
    <Menu />
    <ProjectBar />
    <DropDown />
    <ProjectApprovalSetting />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </v-app>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import ProjectApprovalSetting from "@/components/ProjectApproval/ProjectApprovalSettingComponent.vue";
import ProjectBar from "@/components/ProjectInfoBar/ProjectInfoBar.vue";
import DropDown from "@/components/DropDown/DropDownComponent.vue";
import Menu from "@/components/Menu/MenuComponent.vue";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../utility/authStateCheck";
export default {
  name: "ProjectApproval",
  components: {
    ProjectApprovalSetting,
    ProjectBar,
    DropDown,
    Menu,
    VueLoading,
  },
  mounted() {
    //認証情報があるか確認
    this.authStateCheck(this.$store, this.$router);

    //ユーザー取得
    this.getUser();
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
  methods: {
    getUser: async function () {
      /*
       console.log("-----getUser------")

       this.$store.commit("resetApprovalInfo");
      //クライアントIDを取得
      var clientId = this.$store.state.clientInfo[0].client_id;

      //プロジェクトIDを取得
      var projectId =
        this.$store.state.projectInfo[this.$store.state.selectedProject]
          .project_id;

      var userInfo = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child(
        "/クライアント一覧/" + clientId + "/" + projectId
      );
      var list = await listRef.listAll();
      var imgList = [];
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              userInfo.temporaryData.push(JSON.parse(blob));
              store.commit("setApprovalInfo", JSON.parse(blob));
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
      }
      */
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 2147483647;
  display: none;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
  display: none;
  z-index: 2147483647;
}
</style>