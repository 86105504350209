import { sessionRegister } from "../../utility/sessionRegister"
export const firebaseMethodsMixin = {
    mixins: [sessionRegister],
    methods: {
        /**
        * firebase storageにユーザー情報をアップロードする
        * @param vimeoVideoId (vimeo動画のID)
        * @param imageUrl (サムネイル画像URL)
        * @param postDate (投稿日)
        * @param userName (ユーザー名)
        * @param title (タイトル)
        * @param comment (コメント)
        * @return なし 
        */
        registerUser: function (vimeoVideoId, imageUrl, postDate, userName, title, comment, movieDataSize, clientId, projectId,cta) {
            var titleData = "";
            var commentData = "";
            var ctaData="";

            //タイトル/コメントが記載されている場合はそれらを保持
            if (title != "") {
                titleData = title;
            }
            if (comment != "") {
                commentData = comment;
            }
            if (cta != "") {
                ctaData = cta;
            }

            //ユーザー情報(firbease storageにアップロードされるデータ)
            var data = {
                post_date: postDate,//csvの値
                user_id: "",//この関数で作成
                user_name: userName,//csvの値
                title: titleData,//csvの値
                comment: commentData,//csvの値
                approval_status: "0",//初期値
                contents_kinds: "動画",//今回はtiktokの動画を取り込む仕様なので「動画」 
                main_image_url: "",//tiktok情報から取得した値
                video_id: vimeoVideoId,//
                thumb_image_url: "",//初期値
                user_name_status: "1",//初期値
                title_status: "1",//初期値
                comment_status: "1",//初期値
                cta_status: "1",//初期値
                cta: ctaData,//初期値
                thumb_status: "1",//初期値
                main_thumb_image_url: imageUrl,//初期値
                order: "",//初期値
                uploadDBChangeToMB: movieDataSize,//このユーザーを削除する際に必要なDB容量
                content_fileName: "",//この関数で作成
            };

            //ユーザーIDを作成
            const userId = this.getUniqueStr();
            data.user_id = userId;

            //コンテンツ名を作成
            const contentFileName = this.getUniqueStr();
            data.content_fileName = contentFileName;

            //firebase storageにアップロードする
            this.firebaseStorageUpload(userId, data, clientId, projectId);
        },
        /**
      * firebase storageにユーザー情報をアップロードする具体的処理
      * @param userId (jsonファイルにつく名前)
      * @param userData (登録するユーザーのデータ)
      * @return なし 
      */
        firebaseStorageUpload: function (userId, userData, clientId, projectId) {
            //ユーザーファイル名をセッションに登録する
            this.registerSessionUserFileName(userId);
            const userRef = this.firebase
                .storage()
                .ref()
                .child(
                    "/user/" +
                    clientId +
                    "/" +
                    projectId +
                    "/" +
                    userId +
                    ".json"
                );

            const jsonString = JSON.stringify(userData);

            const blob = new Blob([jsonString], { type: "application/json" });
            userRef.put(blob);

            //ユーザー登録が完了したことをセッションに登録する
            this.registerSessionUserRegisterSuccess(true);

        },
        getUniqueStr: function (myStrong) {
            //クライアントIDを生成する
            var strong = 1000;
            if (myStrong) strong = myStrong;
            return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
        },
        /**
      * 説明
      * クライアントのDB情報を更新する
      * @param なし
      * @return なし
      */
        updateClientInfo: function (clientId) {

            var clientUpdate = new Promise((resolve) => {

                //登録するjsonのパスを設定
                const clientInfostorageRef = this.firebase.storage().ref("/db/" + clientId + ".json");

                //クライアントの情報をセットする
                const clientInfo = this.clientInfo;

                //現在のクライアントDBを数値に変換
                const nowClientDb = parseInt(clientInfo.used_db);

                //動画の容量を更新する
                clientInfo.used_db = nowClientDb + Number(this.movieDataSize);

                //動画の本数を更新する
                clientInfo.used_number += 1;

                //jsonフォーマットに変換する
                var clientJsonFormat = JSON.stringify(clientInfo);

                //アップロードするデータをBlobオブジェクトに
                const clientInfoClient = new Blob([clientJsonFormat], {
                    type: "application/json",
                });

                //データ更新
                clientInfostorageRef.put(clientInfoClient).then(() => {
                    //クライアントDBを更新したことをセッションに登録する
                    this.registerSessionClientStorageDBChangeSuccess(true);
                    const totalData = this.$store.state.totalDB;
                    const sendData = parseInt(totalData) + parseInt(this.movieDataSize);

                    //画面上のデータを更新する
                    this.vuexStore.commit("setTotalDB", sendData);
                    this.updateUploadTotalNumber();

                    //画面上のデータを更新したことをセッションに登録する
                    this.registerSessionScreenChangeDBSuccess(true);

                    resolve("update完了");
                })
            })
            return clientUpdate
        },
        /**
         * 説明
         * プロジェクトのDB情報を更新する
         * @param なし
         * @return なし
         */
        updateProjectInfo: function (clientId, projectId) {
            var projectUpdate = new Promise((resolve) => {

                //登録するjsonのパスを設定
                var projectInfoRef = this.firebase.storage().ref(
                    "/db/" + clientId + "/" + projectId + ".json"
                );

                const projectInfo = this.projectInfo;

                //現在のクライアントDBを数値に変換
                const nowProjectDb = parseInt(projectInfo.used_data_contents);

                //動画の容量を更新する
                projectInfo.used_data_contents = nowProjectDb + parseInt(this.movieDataSize);

                //動画の本数を更新する
                projectInfo.used_number += 1;

                //jsonフォーマットに変換する
                var projectJsonFormat = JSON.stringify(projectInfo);

                //アップロードするデータをBlobオブジェクトに
                const projectInfoClient = new Blob([projectJsonFormat], {
                    type: "application/json",
                });

                //データ更新
                projectInfoRef.put(projectInfoClient).then(() => {

                    //プロジェクトDBを更新したことを伝える
                    this.registerSessionProjectStorageDBChangeSuccess(true);
                    resolve("updata完了");
                })

            })
            return projectUpdate;
        },
        /**
         * 画面上の使用本数を更新する
         */
        updateUploadTotalNumber: function () {

            //アップロードするvideoのコンテンツ数
            const addVideoContent = 1;

            //画面上のデータを更新する
            this.vuexStore.commit("addVideoContentsNumber", addVideoContent);

            //画面の本数を更新したことをセッションに登録する
            this.registerSessionScreenUsedMovieNumberSuccess(true);
        },
        /**
         * プロジェクト情報の更新
         */
        projectJsonUpdate: function (clientId, projectId) {
            const seacretId = this.$store.state.seacretClientId;

            var projectUpdate = new Promise((resolve) => {

                //登録するjsonのパスを設定
                var projectInfoRef = this.firebase.storage().ref(
                    "/project/" + clientId + "/" + seacretId + "/project.json"
                );

                //プロジェクト情報
                const projectInfo = this.$store.state.projectInfo;

                //プロジェクトのDB容量と使用本数をセット
                for (var i = 0; i < projectInfo[0].length; i++) {
                    if (projectInfo[0][i].project_id == projectId) {
                        projectInfo[0][i].used_data_contents += parseInt(this.movieDataSize);
                        projectInfo[0][i].used_number += 1;
                    }
                }

                //jsonフォーマットに変換する
                var projectJsonFormat = JSON.stringify(projectInfo);

                //アップロードするデータをBlobオブジェクトに
                const projectInfoClient = new Blob(projectJsonFormat, {
                    type: "application/json",
                });

                //データ更新
                projectInfoRef.put(projectInfoClient).then(() => {
                    resolve("updata完了");
                })
            })
            return projectUpdate;
        },
        /**
         * クライアント.jsonの更新
         */
        clientJsonUpdate: function (clientId) {
            const seacretId = this.$store.state.seacretClientId;

            var projectUpdate = new Promise((resolve) => {

                //登録するjsonのパスを設定
                var clientInfoRef = this.firebase.storage().ref(
                    "/client/" + seacretId + "/" + clientId + ".json"
                );

                //クライアント情報
                const clientInfo = this.$store.state.clientInfo;

                //クライアントのDB容量と使用本数をセット
                clientInfo[0].used_db += parseInt(this.movieDataSize);
                clientInfo[0].use_number += 1;
                //jsonフォーマットに変換する
                var clientJsonFormat = JSON.stringify(clientInfo);

                //アップロードするデータをBlobオブジェクトに
                const clientjson = new Blob([clientJsonFormat], {
                    type: "application/json",
                });

                //データ更新
                clientInfoRef.put(clientjson).then(() => {

                    resolve("updata完了");
                })
            })
            return projectUpdate;
        },
        /**
         * プロジェクト情報画面の使用本数/使用量DBを更新
         */
        projectInfoScreenUpdate: function (uploadingProjectIndex, db, usedNumber) {
            console.log("プロジェクト情報画面の使用本数と使用量DBを更新します");
            const data = {
                "used_data_contents": db,
                "used_number": usedNumber,
                "uploadingProjectIndex": uploadingProjectIndex
            }
            this.vuexStore.commit("updateProjectInfo", data);
        },
    }
}