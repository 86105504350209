import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    thumbModal: true,
    email: '',
    password: '',
    loginEmail: '',
    loginPassword: '',
    authenticatedUser: '',
    clients: [],//クライアントIDとパスワードのjsonを保持
    clientInfo: [],//クライアント情報を保持
    selectedProject: '',
    selectedAdmin: '',
    projectInfo: [],//プロジェクト情報が格納されるところ
    admins: [],
    modal: { isOpen: false },
    approval: [],//未承認
    approvaled: [],//承認済み
    publishedContents: [],//公開中コンテンツ
    mountedDropDownNumber: '',//ドロップダウンの初期化回数
    user: "",
    reloadCheck: false,
    imgList: "",
    imgListCount: 0,
    currentPass: "",
    temporarilyPublishedData: [],//プロジェクトを切り替えた際、一時的にデータを格納しておく
    clientId: "",//クライアントID
    seacretClientId: "",//クライアントIDと別でクライアント固有の識別子
    clientDB: "",
    userCheck: false,
    changeProjectCheck: false,
    totalDB: "",//上部バーに表示させる残データ容量のDB
    usedNumber: "",//上部バーの動画のアップロード本数
    allPublishingContents: [],
    checked: [],//公開設定画面の非公開コンテンツのチェックボックスを管理
    nowRounte: "プロジェクト一覧",
    errorPartList: [], //読み込んだCSVのエラー箇所を格納する
    errorTextMessageList: {
      /*
      1: "ヘッダ名が規定フォーマットの項目名と異なります。",
      2: "ヘッダ数が規定フォーマットの項目より多いです。",
      3: "ヘッダ数が既定フォーマットの項目より少ないです。",
      4: "ヘッダ名が規定フォーマットの項目名と異なります。",
      5: "CSVにて取り込むデータ数が不足しています。規定フォーマットの項目に合わせたデータ設定をしてください。",
      6: "CSVにて取り込むデータ数がオーバーしています。規定フォーマットの項目に合わせたデータ設定をしてください。",
      7: "日付の形式が異なります。YYYY/MM/DDの形式にしてください。",
      8: "正しいURL形式で設定してください。",
      9: "コメントの文字数が上限を超えています。150文字以内に設定してください。",
      10: "タイトルの文字数が上限を超えています。20文字以内に設定してください。",
      11: "必須項目が設定されておりません。データを設定してください。",
      12: "csvのデータが0件です。1件以上のデータを設定してください。",
      13: "ユーザIDが0件です。1件以上のデータを設定してください。",
      14: "CSV件数オーバーです。取込動画は100件以下を設定してください。",
      15: "容量オーバー:動画のファイルサイズを5MB以下にしてください",
      16: "容量オーバー:契約プラン内で保存できる動画容量をオーバーしました。契約プランの見直しをお願いします。",
      17: "TikTokアクセスエラー:TikTokの動画にアクセスできませんでした。TikTok投稿のプライバシー設定をご確認ください。",
      18: "TikTokアクセスエラー:動画ダウンロードすることができませんでした。TikTok投稿のプライバシー設定をご確認ください。",
      19: "TikTokアクセスエラー:TikTokの動画にアクセスできませんでした。TikTokの投稿を再投稿をお試しください。",
      20: "ヘッダ数が規定の項目数と異なります。",
      21: "動画の再アップロードに失敗しました。",
      22: "ファイルデータが空です。データを入力した状態で再度アップロードしてください。"
      */
    },
    //【アップロード画面】エラーメッセージ
    uploadLoading: false,//【アップロード画面】動画アップロード時に表示するローディングアイコン
    csvUploadJudgement: "",//【アップロード画面】csvのアップロード後のメッセージ表示非表示
    csvData: [],//【アップロード画面】csvのデータ
    uplaodfileName: "",//【アップロード画面】アップロードするcsvのファイル名
    uploadSucess: "",//【アップロード画面】アップロード全体が成功したかどうかの判定値
    isMovieDownloadSuccess: "",//動画のダウンロードが成功したかどうかの判定値
    isImageDownloadSuccess: "",//画像のダウンロードが成功したかどうかの判定値
    isMovieUploadSuccess: "",//動画のアップロードが成功したかどうかの判定値
    isImageUplaodSuccess: "",//画像のアップロードが成功したかどうかの判定値
    uploadImageName: "",//Firebase Storageに保存された画像の名前
    uploadImageUrl: "",//Firebase Storageに保存された画像の保存先url
    vimeoVideoUrl: "",//アップロードしたvimeoの動画url
    videoHash: "",//動画のハッシュ値
    isVimeoWhiteListRegisterSuccess: "",//viemoの動画にホワイトリストを登録したかどうかの判定値
    isReserveVimeoFolderSuccess: "",//vimeoの動画のフォルダ移動を行なったか
    isUserRegisterSuccess: "",//ユーザー登録が成功したかどうかの判定値
    isScreenChangeDBSuccess: "",//画面上のDB使用量を更新したかどうかの判定値
    isClientStorageDBChangeSuccess: "",//Firebase StorageのクライアントDB使用量を更新したかどうかの判定値
    isProjectDBChangeSuccess: "",//Firebase StorageのプロジェクトDB使用量を更新したかどうかの判定値
    isScreenUsedMovieNumberSuccess: "",//画面上の使用本数を更新したかどうかの判定値
    isStorageUsedMovieNumberSuccess: "",//Firebase StorageのDB使用量を更新したかどうかの判定値
    videoData: "",//ビデオデータ
    imageData: "",//画像データ
    uploadCount: 0,//動画アップロード数
    sessionClientId: "",//セッション用クライアントID
    sessionProjectId: "",//セッション用プロジェクトID
    userFileName: "",//セッション用ユーザーファイル名を取得
    videoDB: "",//セッション用アップロードビデオ容量
    allUploadClear: true,//セッション用動画アップロードが全て完了したかどうか
    isUploadSucessColor: false,//動画アップロード成功時に配色をつける
    isUploadFailedColor: false,//動画アップロード失敗時に配色をつける
    uploadingProjectId: "",//動画一括アップロードしたプロジェクトのID
    isRetryUp: false,//動画再アップ中かどうかのステータス
    retryUserData: [],//再度アップロードするユーザーが格納
    isRetryUpSuccess: "",
    retryUploadIndex: "",
    csvUploadBtn: false,
  },
  getters: {
    // ...
    getErroeMessage: (state) => (value) => {
      return state.errorTextMessageList[value];
    }
  },
  mutations: {
    /**
     * エラーメッセージをアプリ読み込み時に登録する
     */
    registerErrorMessage(state){
      Vue.set(state.errorTextMessageList,"1","ヘッダ名が規定フォーマットの項目名と異なります。");
      Vue.set(state.errorTextMessageList,"2","ヘッダ数が規定フォーマットの項目より多いです。");
      Vue.set(state.errorTextMessageList,"3","ヘッダ数が既定フォーマットの項目より少ないです。");
      Vue.set(state.errorTextMessageList,"4","ヘッダ名が規定フォーマットの項目名と異なります。");
      Vue.set(state.errorTextMessageList,"5","CSVにて取り込むデータ数が不足しています。規定フォーマットの項目に合わせたデータ設定をしてください。");
      Vue.set(state.errorTextMessageList,"6","CSVにて取り込むデータ数がオーバーしています。規定フォーマットの項目に合わせたデータ設定をしてください。");
      Vue.set(state.errorTextMessageList,"7","日付の形式が異なります。YYYY/MM/DDの形式にしてください。");
      Vue.set(state.errorTextMessageList,"8","正しいURL形式で設定してください。");
      Vue.set(state.errorTextMessageList,"9","コメントの文字数が上限を超えています。150文字以内に設定してください。");
      Vue.set(state.errorTextMessageList,"10","タイトルの文字数が上限を超えています。20文字以内に設定してください。");
      Vue.set(state.errorTextMessageList,"11","必須項目が設定されておりません。データを設定してください。");
      Vue.set(state.errorTextMessageList,"12","csvのデータが0件です。1件以上のデータを設定してください。");
      Vue.set(state.errorTextMessageList,"13","ユーザIDが0件です。1件以上のデータを設定してください。");
      Vue.set(state.errorTextMessageList,"14","CSV件数オーバーです。取込動画は100件以下を設定してください。");
      Vue.set(state.errorTextMessageList,"15","容量オーバー:動画のファイルサイズを5MB以下にしてください");
      Vue.set(state.errorTextMessageList,"16","容量オーバー:契約プラン内で保存できる動画容量をオーバーしました。契約プランの見直しをお願いします。");
      Vue.set(state.errorTextMessageList,"17","TikTokアクセスエラー:TikTokの動画にアクセスできませんでした。TikTok投稿のプライバシー設定をご確認ください。");
      Vue.set(state.errorTextMessageList,"18","TikTokアクセスエラー:動画ダウンロードすることができませんでした。TikTok投稿のプライバシー設定をご確認ください。");
      Vue.set(state.errorTextMessageList,"19","TikTokアクセスエラー:TikTokの動画にアクセスできませんでした。TikTokの投稿を再投稿をお試しください。");
      Vue.set(state.errorTextMessageList,"20","ヘッダ数が規定の項目数と異なります。");
      Vue.set(state.errorTextMessageList,"21","動画の再アップロードに失敗しました。");
      Vue.set(state.errorTextMessageList,"22","ファイルデータが空です。データを入力した状態で再度アップロードしてください。");
      console.log(state.errorTextMessageList);
    },
    /**
     * 
     */
    uploadState(state, isCsvUploadBtnState) {
      state.csvUploadBtn = isCsvUploadBtnState;
    },
    /**
     * 何件目の再動画アップロードが成功したかのカウント
     * @param {*} state 
     * @param {*} index 
     */
    setRetryUploadIndex(state, index) {
      state.retryUploadIndex = index;
    },
    oneVideoRetrySuccess(state, isSuccess) {
      state.isRetryUpSuccess = isSuccess;
    },
    /**
     * 再度アップロードするデータを初期化
     * @param {*} state 
     */
    initRetryData(state) {
      state.retryUserData = [];
    },
    /**
     * 動画の再度アップロード中のステータス更新
     * @param {*} state 
     * @param {*} isStatus 
     */
    setRetryUploadStatus(state, isStatus) {
      state.isRetryUp = isStatus;
    },
    /**
     * 再度アップロードする動画を格納する
     * @param {*} state 
     * @param {*} data 
     */
    setRetryUserData(state, data) {
      state.retryUserData.push([data.userData, data.errorRowIndex]);
      console.log(state.retryUserData);
    },
    //ユーザーを投稿日付順にソートする
    sortUserLists(state) {
      console.log("----sortUserListsが呼ばれました----");
      state.approval.sort(
        (x, y) => (x.timeCreated) - (y.timeCreated),
      );

      state.approvaled.sort(
        (x, y) => (x.timeCreated) - (y.timeCreated),
      );
      console.log(state.approvaled);
    },
    setUploadingProjectId(state, isValue) {
      state.uploadingProjectId = isValue;
    },
    changeIsUploadSucessColor(state, isValue) {
      state.isUploadSucessColor = isValue;
    },
    changeIsUploadFailedColor(state, isValue) {
      state.isUploadFailedColor = isValue;
    },
    /**
     * アップロードするvideo容量
     * @param {*} state 
     * @param {*} value アップロードするvideoの容量値
     */
    setVuexVideoDB(state, value) {
      state.videoDB = value;
    },
    /**
     * 動画アップロードが全て完了したか
     * @param {*} state 
     * @param {*} isValue 真偽値
     */
    setVuexUploadAllSucessed(state, isValue) {
      state.allUploadClear = isValue;
    },
    /**
     * セッション用クライアントIDを格納
     * @param {*} state 
     * @param {*} data 
     */
    setVuexClientId(state, data) {
      state.sessionClientId = data;
    },
    /**
     * セッション用プロジェクトIDを格納
     * @param {*} state 
     * @param {*} data 
     */
    setVuexProjectId(state, data) {
      state.sessionProjectId = data;
    },
    /**
     * ユーザーのファイル名を取得
     * @param {*} state 
     * @param {*} data 
     */
    setVuexUserFileName(state, data) {
      state.userFileName = data;
    },
    /**
     * 動画のダウンロードが成功したかどうかの判定値をセットする
     * @param {*} state 
     * @param {*} data 
     */
    setVuexMovieDownloadSuccess(state, data) {
      console.log("vuexの関数が呼ばれました。値は" + data + "です");
      state.isMovieDownloadSuccess = data;
    },
    /**
     * 画像のダウンロードが成功したかどうかの判定値をセットする
     * @param {*} state 
     * @param {*} data 
     */
    setVuexImageDownloadSuccess(state, data) {
      state.isImageDownloadSuccess = data;
    },
    /**
    * 動画のアップロードが成功したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexMovieUploadSuccess(state, data) {
      state.isMovieUploadSuccess = data;
    },
    /**
    * 画像のアップロードが成功したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexImageUplaodSuccess(state, data) {
      state.isImageUplaodSuccess = data;
    },
    /**
    * Firebase Storageに保存された画像の名前をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexUploadImageName(state, data) {
      state.uploadImageName = data;
    },
    /**
    * Firebase Storageに保存された画像の保存先urlをセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexUploadImageUrl(state, data) {
      state.uploadImageUrl = data;
    },
    /**
    * アップロードしたvimeoの動画urlをセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexVimeoVideoUrl(state, data) {
      state.vimeoVideoUrl = data;
    },
    /**
    * 動画のハッシュ値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexVideoHash(state, data) {
      state.videoHash = data;
    },
    /**
    * viemoの動画にホワイトリストを登録したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexVimeoWhiteListRegisterSuccess(state, data) {
      state.isVimeoWhiteListRegisterSuccess = data;
    },
    /**
    * vimeoの動画のフォルダ移動を行なったかをセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexReserveVimeoFolderSuccess(state, data) {
      state.isReserveVimeoFolderSuccess = data;
    },
    /**
    * ユーザー登録が成功したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexUserRegisterSuccess(state, data) {
      state.isUserRegisterSuccess = data;
    },
    /**
    * 画面上のDB使用量を更新したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexScreenChangeDBSuccess(state, data) {
      state.isScreenChangeDBSuccess = data;
    },
    /**
    * Firebase StorageのクライアントDB使用量を更新したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexClientStorageDBChangeSuccess(state, data) {
      state.isClientStorageDBChangeSuccess = data;
    },
    /**
    * Firebase StorageのプロジェクトDB使用量を更新したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexProjectStorageDBChangeSuccess(state, data) {
      state.isProjectStorageDBChangeSuccess = data;
    },
    /**
    * 画面上の使用本数を更新したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexScreenUsedMovieNumberSuccess(state, data) {
      state.isScreenUsedMovieNumberSuccess = data;
    },
    /**
    * Firebase StorageのDB使用量を更新したかどうかの判定値をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexStorageUsedMovieNumberSuccess(state, data) {
      state.isStorageUsedMovieNumberSuccess = data;
    },
    /**
    * ビデオデータをセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexVideoData(state, data) {
      state.videoData = data;
    },
    /**
    * 画像データをセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexImageData(state, data) {
      state.imageData = data;
    },
    /**
    * 動画アップロード数をセットする
    * @param {*} state 
    * @param {*} data 
    */
    setVuexUploadCount(state, data) {
      state.uploadCount = data;
    },
    /**
     * アップロードが成功したかどうかの判定値を更新
     * @param {*} state 
     * @param {*} isSucessed アップロードが成功したかどうかの判定値 
     */
    setUploadSucess(state, isSucessed) {
      state.uploadSucess = isSucessed;
    },
    /**
     * 説明
     * アップロードするファイル名をセット
     * @param アップロードするファイル名
     */
    setUplaodFileName(state, fileName) {
      state.uplaodfileName = fileName;
    },
    /**
     * 説明
     * csvのデータを格納する
     * @param data csvデータ
     */
    addCsvData(state, data) {
      state.csvData.push(data);
    },
    /**
     * 説明
     * csvのデータを削除する
     */
    deleteCsvData(state) {
      state.csvData.splice(state.csvData.length - 1, 1);
    },
    /**
     * 説明
     * 日付フォーマットにcsvデータを変換
     * @param sendData 日付フォーマットに変換する際に必要なデータ
     */
    dateFormatChange(state, sendData) {
      console.log("dateFoeamtChageが呼ばれました");
      console.log("新しく登録される投稿日は" + sendData["replace"]);
      /*
      const sendData = {
            data: i + 1,
            replacePoint: this.postedDateLine,
            replaceNum: 1,
            replace: jadge[0].replace(/-/g, "/"),
          };

          this.data[i + 1].splice(
            this.postedDateLine,
            1,
            jadge[0].replace(/-/g, "/")
          );
          */
      state.csvData[sendData["data"]].splice(sendData["replacePoint"], sendData["replaceNum"], sendData["replace"]);
    },
    /**
     * 説明
     * テーブルに配色するため、データを増やす（空のデータ）
     * @param {*} state 
     * @param {*} data 
     */
    addTableData(state, data) {
      console.log("addTableDataが呼ばれました");
      state.csvData[data].push("");
    },
    /**
     * csvのデータが一つもない場合に一行分のデータを追加する
     * @param {*} state 
     * @param {*} data ヘッダ数 
     */
    addBlankData(state, data) {
      state.csvData.push([]);
      for (var i = 0; i < data; i++) {
        state.csvData[1].push(i);
      }
      console.log("csvData");
      console.log(state.csvData);
    },
    /**
    * 説明
    * csvのデータをリセットする
    */
    initCsvData(state) {
      console.log("csvデータがリセットされました")
      state.csvData = [];
    },

    /**
     * 説明
     * csvアップロード後のメッセージの表示非表示を切り替える
     * @param displayBoolean メッセージを表示するか非表示にするかの真偽値
     * 
     */
    changeUploadedMessage(state, displayBoolean) {
      state.csvUploadJudgement = displayBoolean;
    },

    /**
     * 説明
     * 動画アップロード時に表示するアイコンの表示/非表示を管理する
     * @param displayBoolean ローディングを表示にするか非表示にするかの真偽値
     */
    manageUploadIcon(state, displayBoolean) {
      state.uploadLoading = displayBoolean;
    },
    /**
     * 説明
     * csvアップロード時のエラー内容を初期化する
     * @param state ステート
     */
    initialCsvErrorData(state) {
      console.log("-------initialCsvErrorDataが呼ばれました----------");
      state.errorPartList = [];
      console.log("-------errorPartListの中身が空になりました----------");
    },
    /**
     * csvのエラー内容を配列に格納していく
     * @param {*} state 
     * @param {*} value csvのエラー内容が格納された配列
     */
    setCsvErrorList(state, value) {
      var list = [value.col, value.row, value.code];
      state.errorPartList.push(list);
    },
    /**
     * 動画の使用本数を増やす（TikTok動画ダウンロード時に使用）
     * @param {*} state 
     * @param {*} value 動画本数「１」が格納
     */
    addVideoContentsNumber(state, value) {
      state.usedNumber += value;
    },
    /**
     * 画面上の動画のDB容量を減らす（ユーザーが削除された際）
     * @param {*} state 
     * @param {*} db 
     */
    deleteScreenDB(state, db) {
      console.log("変更前のDB容量は" + state.totalDB + "です");
      state.totalDB -= db;
      console.log("削除後のDB容量は" + state.totalDB + "です");
    },
    /**
     * 画面上の動画の使用本数を減らす（ユーザーが削除された際）
     * @param {*} state 
     * @param {*} number
     */
    deleteUsedNumber(state, number) {
      state.usedNumber -= number;
    },
    deleteProjectInfoData(state, data) {
      const id = data.id;
      const db = data.db;
      console.log("画面上のプロジェクトの使用本数を削除します");
      console.log(state.projectInfo[0]);
      for (var i = 0; i < state.projectInfo[0].length; i++) {
        if (id == state.projectInfo[0][i].project_id) {
          state.projectInfo[0][i].used_number -= 1;
          state.projectInfo[0][i].used_data_contents -= db;
        }
      }

    },
    /**
     * 動画の使用本数を減らす
     * @param {*} state 
     * @param {*} value 
     */
    rollBackVideoContentsNumber(state, value) {
      state.usedNumber -= value;
    },
    changeThumbContentsOverModal(state, value) {
      state.thumbModal = value;
    },
    ///////////////////////////////////////
    ///////説明
    //////現在のパスを設定する
    //
    //
    //引数：state:ステート check:boolean
    //////////////////////////////////////////////////////////////////////
    setRoute(state, route) {
      state.nowRounte = route;
    },
    /**
     * 説明
     * プロジェクトを選択した際にローディングを表示させるかどうかの判定をとる
     * @param {*} state 
     * @param {*} value 
     */
    changeProject(state, value) {
      state.changeProjectCheck = value;
    },
    /**
     * プロジェクトを選択した際にユーザーを取得出来たかどうか判定するもの
     * @param {*} state 
     * @param {*} check 
     */
    getUserCheck(state, check) {
      if (check == true) {
        state.userCheck = true;
      } else {
        state.userCheck = false;
      }
    },
    /**
     * DB容量を更新
     * @param {*} state 
     * @param {*} totalDB 
     */
    setTotalDB(state, totalDB) {
      state.totalDB = totalDB;
    },
    /**
     * DB容量のロールバック
     * @param {*} state 
     * @param {*} value 
     */
    rollBackDB(state, value) {
      state.totalDB -= value;
    },
    ///////////////////////////////////////
    ///////説明
    //////ログイン中かどうか
    //
    //
    //引数：state:ステート value:true
    //////////////////////////////////////////////////////////////////////
    setUser(state, value) {
      state.user = value;
    },
    ///////////////////////////////////////
    ///////説明
    //////ログインした際、クライアントIDをセットする
    //
    //
    //引数：state:ステート clientId:クライアントのID
    //////////////////////////////////////////////////////////////////////
    setClientId(state, clientId) {
      state.clientId = clientId;
    },
    ///////////////////////////////////////
    ///////説明
    //////ログインした際、クライアントIDとは別にクライアントを識別する値をセットする
    //
    //
    //引数：state:ステート seacretClientId:クライアントIDとは別にクライアントを識別する値
    //////////////////////////////////////////////////////////////////////
    setSeacretPram(state, seacretClientId) {
      state.seacretClientId = seacretClientId;
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得したクライアントIDとパスをセットする
    //
    //
    //引数：state:ステート clientInfo:クライアント情報
    //////////////////////////////////////////////////////////////////////
    setClientInfo(state, clientInfo) {
      state.clients.push(clientInfo);
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得したクライアント情報をセットする
    //
    //
    //引数：state:ステート clientInfo:クライアント情報
    //////////////////////////////////////////////////////////////////////
    setClientsInfo(state, clientInfo) {
      state.clientInfo = [];
      state.clientInfo.push(clientInfo);
    },
    ///////////////////////////////////////
    ///////説明
    //////公開設定画面を自動でリロードするか否か
    //
    //
    //引数：state:ステート check:boolean
    //////////////////////////////////////////////////////////////////////
    setReloadCheck(state, check) {
      state.reloadCheck = check;
    },
    ///////////////////////////////////////
    ///////説明
    //////
    //
    //
    //引数：state:ステート imgListLength:ユーザーの総数
    //////////////////////////////////////////////////////////////////////
    setimgList(state, imgListLength) {
      state.imgList = imgListLength;
    },
    resetImgListCount(state) {
      state.imgListCount = 0;
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得した管理者情報をセットする
    //
    //
    //引数：state:ステート clientInfo:管理者情報
    //////////////////////////////////////////////////////////////////////
    setAdminInfo(state, adminInfo) {
      if (adminInfo.authority == "1") {
        state.admins.unshift(adminInfo);
      } else {
        state.admins.push(adminInfo);
      }
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得したユーザーリストをセットする
    //
    //
    //引数：state:ステート userList:あるプロジェクトに属するユーザーリスト
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setApprovalInfo(state, userList) {
      if (userList.approval_status == "0") {
        state.approval.push(userList);
        state.imgListCount += 1;
      } else if (userList.approval_status == "1" || userList.approval_status == "2") {
        state.approvaled.push(userList);
        state.imgListCount += 1;
      }
      if (state.imgListCount == state.imgList) {
        //window.location.reload();
      }
    },

    ///////////////////////////////////////
    ///////説明
    //////未承認→承認済みにステータスを変更する
    //
    //
    //引数：state:ステート index:選択したユーザーのインデックス番号
    //////////////////////////////////////////////////////////////////////
    setApprovaled(state, index) {

      //一時保管場所
      var tempData = [];

      //ステータスを変更する
      Vue.set(state.approval[index], 'approval_status', "1");

      tempData.push(state.approval[index]);

      //未承認配列から削除
      state.approval.splice(index, 1);
      state.approvaled.push(tempData[0]);
    },
    ///////////////////////////////////////
    ///////説明
    //////承認済み→未承認にステータスを変更する
    //
    //
    //引数：state:ステート index:選択したユーザーのインデックス番号
    //////////////////////////////////////////////////////////////////
    setUnApproval(state, index) {

      //一時保管場所
      var tempData = [];

      //ステータスを変更する
      Vue.set(state.approvaled[index], 'approval_status', "0");

      tempData.push(state.approvaled[index]);

      //未承認配列から削除
      state.approvaled.splice(index, 1);
      state.approval.push(tempData[0]);
    },
    ///////////////////////////////////////
    ///////説明
    ///////選択されているプロジェクトのインデントをセットする
    //
    //
    //引数：state:ステート index:プロジェクトのインデント
    //////////////////////////////////////////////////////////////////////
    selectedProjectSetter(state, selectedIndex) {
      state.selectedProject = selectedIndex;
    },
    ///////////////////////////////////////
    ///////説明
    ///////選択されている管理者のインデントをセットする
    //
    //
    //引数：state:ステート index:クライアントのインデント
    //////////////////////////////////////////////////////////////////////
    selectedAdminSetter(state, selectedIndex) {
      state.selectedAdmin = selectedIndex;
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得したプロジェクト情報全てをセットする
    //
    //
    //引数：state:ステート projectInfo:プロジェクト情報
    //////////////////////////////////////////////////////////////////////
    setProjectInfo(state, projectInfo) {
      state.projectInfo.push(projectInfo);
    },
    setClientDB(state, clientDB) {
      state.clientDB = clientDB;
    },
    setUploadNumber(state, uploadNum) {
      state.usedNumber = uploadNum;
    },
    ///////////////////////////////////////
    ///////説明
    //////全公開中のコンテンツの数をセットする
    //
    //
    //引数：state:ステート payload:{project_id[プロジェクトID],publishingNum[公開中コンテンツの数]}
    //////////////////////////////////////////////////////////////////////
    setAllPublishingContents(state, payload) {
      state.allPublishingContents.push(payload);
      console.log(state.allPublishingContents);
      console.log(state.allPublishingContents.length);
    },
    resetAllPublishingContents(state) {
      state.allPublishingContents = [];
    },
    deletePublishingContents(state, projectId) {
      console.log("---storeのdeletePublishingContents---")
      console.log(state.allPublishingContents[0]);
      for (var i = 0; i < state.allPublishingContents.length; i++) {
        if (state.allPublishingContents[i].project_id == projectId) {

          state.allPublishingContents[i].splice(i, 1);
          console.log("コンテンツを削除しました")
          break;
        }
      }
    },
    ///////////////////////////////////////
    ///////説明
    //////全公開中のコンテンツの数変更させる
    //
    //
    //引数：state:ステート payload:{pub[コンテンツの数],index[全公開中コンテンツの配列番号]}
    //////////////////////////////////////////////////////////////////////
    changePublisingNum(state, payload) {
      console.log("------changePublisingNum--------");
      state.allPublishingContents[payload.index].publishingNum = payload.pub;
    },
    ///////////////////////////////////////
    ///////説明
    //////DoropDownの初期化回数をカウントする
    //
    //
    //引数：state:ステート mountedNumber:「1」
    //////////////////////////////////////////////////////////////////////
    setMoundedDropDownNumber(state, mountedNumber) {
      state.mountedDropDownNumber = mountedNumber;
    },
    ///////////////////////////////////////
    ///////説明
    /////////プロジェクト管理画面へ遷移した際に値を一度リセットする
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    resetProjectInfo(state) {
      state.projectInfo = [];
    },
    ///////////////////////////////////////
    ///////説明
    /////////プロジェクト管理画面へ遷移した際に値を一度リセットする
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    resetAdminInfo(state) {
      state.admins = [];
    },
    ///////////////////////////////////////
    ///////説明
    /////////承認設定画面へ遷移した際に値を一度リセットする
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    resetApprovalInfo(state) {
      console.log("-----resetApprovalInfoのメソッドが呼ばれました-----")
      state.approval = [];
      state.approvaled = [],//承認済み
        state.publishedContents = []//公開中コンテンツ
      state.approval.splice(0, state.approval.length);
      state.approvaled.splice(0, state.approvaled.length);
      state.publishedContents.splice(0, state.publishedContents.length);

      console.log("削除後の承認済みコンテンツの数は")
      console.log(state.approvaled);
    },
    ///////////////////////////////////////
    ///////説明
    /////////公開中コンテンツをリセットする
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    resetPublishedContents(state) {
      console.log("-----resetPublishedContentsのメソッドが呼ばれました-----")
      state.publishedContents = [];
      console.log("削除後の公開中コンテンツの数は")
      console.log(state.publishedContents);
    },
    /**
     * ログアウト
     * @param {*} state 
     */
    allReset(state) {
      state.loginEmail = "";
      state.loginPassword = "";
      state.user = "";
      state.clients = [];
      state.clientInfo = [];
      state.projectInfo = [];
      state.user = "";
      state.allUploadClear = true;
      state.isRetryUp=false;
      state.retryUserData=[];
     

      //アラート非表示
      document.getElementById("alert-block-js").style.display = "none";
    },
   
    commitModalOpen(state, payload) {
      state.modal = payload
    },
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////説明
    /////////非公開コンテンツから公開中コンテンツに移動する
    //
    //
    //引数：state:ステート contents:コンテンツ情報
    //////////////////////////////////////////////////////////////////////
    addPublishedContents(state, contents) {

      if (contents.approval_status == 2) {
        //編集機能などで保存する際はこちらのステータスを保存する
        contents.checkApproval_status = 2;
      } else {
        //編集機能などで保存する際はこちらのステータスを保存する
        contents.checkApproval_status = 1;
      }

      //ステータスを２にする
      contents.approval_status = 2;
      state.publishedContents.push(contents);

      for (var i = 0; i < state.publishedContents.length; i++) {
        Vue.set(state.publishedContents[i], 'order', i + 1);
      }
    },
    /**
     * 動画一括アップロード時、プロジェクト詳細画面のDB使用量と使用本数を更新する
     * @param {*} state 
     * @param {*} data {used_data_contents:使用量DB,used_number:使用本数,uploadingProjectIndex,更新するプロジェクトのインデックス番号}
     * */
    updateProjectInfo(state, data) {
      const projectDataContents = state.projectInfo[0][data.uploadingProjectIndex].used_data_contents;
      const projectUsedNumber = state.projectInfo[0][data.uploadingProjectIndex].used_number;
      Vue.set(state.projectInfo[0][data.uploadingProjectIndex], 'used_data_contents', Number(projectDataContents) + Number(data.used_data_contents));
      Vue.set(state.projectInfo[0][data.uploadingProjectIndex], 'used_number', Number(projectUsedNumber) + Number(data.used_number));
    },
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////説明
    /////////公開ボタンが押された際、approvalコンテンツの中身を書き換える
    //
    //
    //引数：state:ステート contents:コンテンツ情報
    //////////////////////////////////////////////////////////////////////
    reflectUserInfo(state) {

      console.log("--------reflectUserInfoが呼ばれました-----------");

      //公開されるコンテンツをすべて取得
      var publishContents = state.publishedContents;

      //承認済みユーザーをすべて取得
      var approvalUsers = state.approvaled;

      //公開されるコンテンツの情報を承認済みユーザーの方に反映させる
      for (var i = 0; i < publishContents.length; i++) {
        for (var j = 0; j < approvalUsers.length; j++) {

          //承認済みユーザーのIDと公開されるコンテンツのユーザーIDが一致したら、承認済みユーザーの情報に反映させる
          if (approvalUsers[j].user_id == publishContents[i].user_id) {
            console.log("一致するユーザーが見つかりました");
            /*
            state.approvaled[j].order=i+1;
            state.approvaled[j].title=publishContents[i].title;
            state.approvaled[j].comment=publishContents[i].comment;
            state.approvaled[j].approval_status=publishContents[i].approval_status;
            state.approvaled[j].user_name_status=publishContents[i].user_name_status;
            state.approvaled[j].cta_status=publishContents[i].cta_status;
            state.approvaled[j].title_status=publishContents[i].title_status;
            state.approvaled[j].comment_status=publishContents[i].comment_status;
            state.approvaled[j].thumb_status=publishContents[i].thumb_status;
            state.approvaled[j].thumb_image_url=publishContents[i].thumb_image_url;
            state.approvaled[j].cta=publishContents[i].cta;
            */


            //コンテンツの順番を反映させる
            Vue.set(state.approvaled[j], 'order', i + 1);

            //タイトルを反映させる
            Vue.set(state.approvaled[j], 'title', publishContents[i].title);

            //コメントを反映させる
            Vue.set(state.approvaled[j], 'comment', publishContents[i].comment);

            //承認ステータスを反映させる
            Vue.set(state.approvaled[j], 'approval_status', publishContents[i].approval_status);

            //ユーザー名のステータスを反映させる
            Vue.set(state.approvaled[j], 'user_name_status', publishContents[i].user_name_status);

            //ctaステータスを反映させる
            Vue.set(state.approvaled[j], 'cta_status', publishContents[i].cta_status);

            //タイトルステータスを反映させる
            Vue.set(state.approvaled[j], 'title_status', publishContents[i].title_status);

            //コメントステータスを反映させる
            Vue.set(state.approvaled[j], 'comment_status', publishContents[i].comment_status);

            //製品画像ステータスを反映させる
            Vue.set(state.approvaled[j], 'thumb_status', publishContents[i].thumb_status);

            //製品画像のURLを反映させる
            Vue.set(state.approvaled[j], 'thumb_image_url', publishContents[i].thumb_image_url);

            //ctaURLを反映させる
            Vue.set(state.approvaled[j], 'cta', publishContents[i].cta);

            Vue.set(state.approvaled[j], 'checkApproval_status', 2);

            console.log(state.approvaled[j]);
            console.log("データを反映しました");

            break;
          }
        }
      }
    },
    ///////////////////////////////////////
    ///////説明
    ////////コンテンツを削除した際、表示しているトータルDBを書き換える
    //
    //
    //引数：state:ステート deleteDB:差し引く分のDB
    //////////////////////////////////////////////////////////////////////
    rewriteTotalDB(state, deleteDB) {
      state.totalDB -= deleteDB;
    },
    ///////////////////////////////////////
    ///////説明
    ////////コンテンツを削除した際、表示している使用本数を書き換える
    //
    //
    //引数：state:ステート deleteNum:差し引く分の本数
    //////////////////////////////////////////////////////////////////////
    rewriteUploadNumber(state, deleteNum) {
      state.usedNumber -= deleteNum;
    },
    ///////////////////////////////////////
    ///////説明
    ////////コンテンツのステータスを変更する
    //
    //
    //引数：state:ステート status:表示/非表示フラグの情報が格納されているjsonファイル
    //////////////////////////////////////////////////////////////////////
    changeStatus(state, statusContents) {
      for (var i = 0; i < state.publishedContents.length; i++) {
        if (statusContents.user_id == state.publishedContents[i].user_id) {
          Vue.set(state.publishedContents[i], 'user_name_status', statusContents.user_name_status);
          Vue.set(state.publishedContents[i], 'cta_status', statusContents.cta_status);
          Vue.set(state.publishedContents[i], 'title_status', statusContents.title_status);
          Vue.set(state.publishedContents[i], 'comment_status', statusContents.comment_status);
          Vue.set(state.publishedContents[i], 'thumb_status', statusContents.product_image_status);
          Vue.set(state.publishedContents[i], 'thumb_image_url', statusContents.thumb_image_url);
          Vue.set(state.publishedContents[i], 'cta', statusContents.cta);

        }
      }
    },

    ///////////////////////////////////////
    ///////説明
    ////////コメントの値を変更する
    //
    //
    //引数：state:ステート status:表示/非表示フラグの情報が格納されているjsonファイル
    //////////////////////////////////////////////////////////////////////
    changeCommentWord(state, contents) {
      for (var i = 0; i < state.publishedContents.length; i++) {
        if (contents.user_id == state.publishedContents[i].user_id) {
          Vue.set(state.publishedContents[i], 'comment', contents.comment);
        }
      }
    },
    ///////////////////////////////////////
    ///////説明
    ////////タイトルの値を変更する
    //
    //
    //引数：state:ステート status:表示/非表示フラグの情報が格納されているjsonファイル
    //////////////////////////////////////////////////////////////////////
    changeTitleWord(state, contents) {
      for (var i = 0; i < state.publishedContents.length; i++) {
        if (contents.user_id == state.publishedContents[i].user_id) {
          Vue.set(state.publishedContents[i], 'title', contents.title);
        }
      }
    },
    ///////////////////////////////////////
    ///////説明
    ///////表示/非表示ステータスの編集を登録する
    //
    //
    //引数：state:ステート statusContents:表示/非表示フラグのjsonファイル
    //////////////////////////////////////////////////////////////////////
    allChangeStatus(state, statusContents) {
      for (var i = 0; i < state.publishedContents.length; i++) {
        Vue.set(state.publishedContents[i], 'user_name_status', statusContents.user_name_status);
        Vue.set(state.publishedContents[i], 'cta_status', statusContents.cta_status);
        Vue.set(state.publishedContents[i], 'title_status', statusContents.title_status);
        Vue.set(state.publishedContents[i], 'comment_status', statusContents.comment_status);
        Vue.set(state.publishedContents[i], 'thumb_status', statusContents.product_image_status);
        if (statusContents.thumb_image_url != "") {
          Vue.set(state.publishedContents[i], 'thumb_image_url', statusContents.thumb_image_url);
        }
        Vue.set(state.publishedContents[i], 'cta', statusContents.cta);
      }
    },
    ///////////////////////////////////////
    ///////説明
    ///////公開中コンテンツからコンテンツを削除する
    //
    //
    //引数：state:ステート index:削除するコンテンツのインデント
    //////////////////////////////////////////////////////////////////////
    deletePublishedContents(state, index) {//公開中コンテンツからコンテンツを削除する

      state.publishedContents[index].approval_status = 1;

      //公開中コンテンツの配列から削除
      state.publishedContents.splice(index, 1);

      if (state.publishedContents.length != 0) {//公開中コンテンツの配列が０でなかったら、公開中の順番を更新する
        for (var i = 0; i < state.publishedContents.length; i++) {
          Vue.set(state.publishedContents[i], 'order', i + 1);
        }
      }

    },
    ///////////////////////////////////////
    ///////説明
    ///////公開中コンテンツの順番を入れ替える
    //
    //
    //引数：state:ステート object:コンテンツ
    //////////////////////////////////////////////////////////////////////
    changeOrders(state, object) {
      var tempArg = [];
      //移動させたコンテンツを一時的に格納する
      tempArg.push(state.publishedContents[object.index]);

      //公開中コンテンツから一時的に移動させたコンテンツを削除する
      state.publishedContents.splice(object.index, 1);
      state.publishedContents.splice(object.orderNumber, 0, tempArg[0]);
      for (var i = 0; i < state.publishedContents.length; i++) {
        for (var t = 0; t < state.approvaled.length; t++) {
          if (state.approvaled[t].user_id == state.publishedContents[i].user_id) {
            state.approvaled[t].order = i + 1;
          }
        }
      }

      //jsonの中の要素「order」を入れ替える
      for (var k = 0; k < state.publishedContents.length; k++) {
        Vue.set(state.publishedContents[k], 'order', k + 1);
      }
    },
    /**
     * 承認済みコンテンツを承認した順に並び替える
     * @param {*} state 
     */
    changeApprovaledOrder(state) {
      //番号順にソートをかける
      state.approvaled.sort((a, b) => a.approvaledOrder - b.approvaledOrder);
    },
    ///////////////////////////////////////
    ///////説明
    ///////管理者を削除する
    //
    //
    //引数：state:ステート:value:実際は何も入らない
    //////////////////////////////////////////////////////////////////////
    deleteAdmin(state) {
      state.admins.splice(state.selectedAdmin, 1);
    },
    ///////////////////////////////////////
    ///////説明
    ///////プロジェクト情報を削除する
    //
    //
    //引数：state:ステート:index:選択されたプロジェクト
    //////////////////////////////////////////////////////////////////////
    deleteProjectInfo(state, index) {
      state.projectInfo.splice(index, 1);
    },
    ///////////////////////////////////////
    ///////説明
    ///////ユーザーを削除する
    //
    //
    //引数：state:ステート:data:削除するユーザーのidとステータスが格納されている
    //////////////////////////////////////////////////////////////////////
    deleteUser(state, data) {
      if (data.status == 0) {
        for (var i = 0; i < state.approval.length; i++) {
          if (state.approval[i].user_id == data.userId) {
            state.approval.splice(i, 1);
            break;
          }
        }
      } else if (data.status == 1) {
        for (var t = 0; t < state.approvaled.length; t++) {
          if (state.approvaled[t].user_id == data.userId) {
            state.approvaled.splice(t, 1);
            break;
          }
        }
      } else if (data.status == 2) {
        for (var j = 0; j < state.approvaled.length; j++) {
          if (state.approvaled[j].user_id == data.userId) {
            state.approvaled.splice(j, 1);
            break;
          }
        }
      }
    },
    ///////////////////////////////////////
    ///////説明
    ///////クライアントの使用量DBを変更
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    changeClientDB(state) {
      var projectList = state.projectInfo;

      //クライアントの使用量DB
      var clientUsedDB;

      //各プロジェクトの使用量DB
      var projectUsedDB;

      Vue.set(state.clientInfo[0], 'used_db', 0);

      for (var i = 0; i < projectList.length; i++) {

        //現在のクライアントの使用量DB
        clientUsedDB = state.clientInfo[0].used_db;

        //プロジェクトの使用量DB
        projectUsedDB = state.projectInfo[i].used_data_contents;

        Vue.set(state.clientInfo[0], 'used_db', Number(clientUsedDB) + Number(projectUsedDB));

      }
    }
  },
  actions: {
    actionModalOpen({ commit }, payload) {
      commit('commitModalOpen', payload)
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
