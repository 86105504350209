import { sessionName } from "./sessionNameGetter";
export const sessionDelete = {
    mixins: [sessionName],
    data: function () {
        return {
            vuexStore: this.$store
        }
    },
    methods: {
        /**
    * 動画ダウンロードが成功したかどうかの判定値のセッションを削除する
    */
        deleteSessionMovieDownloadSuccess: function () {
            sessionStorage.removeItem(this.sessionMovieDownloadSuccess);
        },
        /**
         * 画像ダウンロードが成功したかどうかの判定値のセッションを削除する
         */
        deleteImageDownloadSuccess: function () {
            sessionStorage.removeItem(this.sessionImageDownloadSuccess);
        },
        /**
         * 動画のアップロードが成功したかどうかの判定値のセッションを削除する
         */
        deleteMovieUploadSuccess: function () {
            sessionStorage.removeItem(this.sessionMovieUploadSuccess);
        },
        /**
        * 画像のアップロードが成功したかどうかの判定値のセッションを削除する
        */
        deleteImageUplaodSuccess: function () {
            sessionStorage.removeItem(this.sessionMovieUploadSuccess);
        },
        /**
        * Firebase Storageに保存された画像の名前のセッションを削除する
        */
        deleteUploadImageName: function () {
            sessionStorage.removeItem(this.sessionUploadImageName);
        },
        /**
        * Firebase Storageに保存された画像の保存先urlのセッションを削除する
        */
        deleteUploadImageUrl: function () {
            sessionStorage.removeItem(this.sessionUploadImageUrl);
        },
        /**
        * アップロードしたvimeoの動画urlのセッションを削除する
        */
        deleteVimeoVideoUrl: function () {
            sessionStorage.removeItem(this.sessionVimeoVideoUrl);
        },
        /**
        * 動画のハッシュ値のセッションを削除する
        */
        deleteVideoHash: function () {
            sessionStorage.removeItem(this.sessionVideoHash);
        },
        /**
        * viemoの動画にホワイトリストを登録したかどうかの判定値のセッションを削除する
        */
        deleteVimeoWhiteListRegisterSuccess: function () {
            sessionStorage.removeItem(this.sessionVimeoWhiteListRegisterSuccess);
        },
        /**
        * vimeoの動画のフォルダ移動を行なったかの判定値のセッションを削除する
        */
        deleteReserveVimeoFolderSuccess: function () {
            sessionStorage.removeItem(this.sessionReserveVimeoFolderSuccess);
        },
        /**
        * ユーザー登録が成功したかどうかの判定値のセッションを削除する
        */
        deleteUserRegisterSuccess: function () {
            sessionStorage.removeItem(this.sessionUserRegisterSuccess);
        },
        /**
        * 画面上のDB使用量を更新したかどうかの判定値のセッションを削除する
        */
        deleteScreenChangeDBSuccess: function () {
            sessionStorage.removeItem(this.sessionScreenChangeDBSuccess);
        },
        /**
        * Firebase StorageのクライアントDB使用量を更新したかどうかの判定値のセッションを削除する
        */
        deleteClientStorageDBChangeSuccess: function () {
            sessionStorage.removeItem(this.sessionClientStorageDBChangeSuccess);
        },
        /**
       * Firebase StorageのプロジェクトDB使用量を更新したかどうかの判定値のセッションを削除する
       */
        deleteProjectStorageDBChangeSuccess: function () {
            sessionStorage.removeItem(this.sessionProjectStorageDBChangeSuccess);
        },
        /**
        * 画面上の使用本数を更新したかどうかの判定値のセッションを削除する
        */
        deleteScreenUsedMovieNumberSuccess: function () {
            sessionStorage.removeItem(this.sessionScreenUsedMovieNumberSuccess);
        },
        /**
        * Firebase Storageの使用本数を更新したかどうかの判定値のセッションを削除する
        */
        deleteStorageUsedMovieNumberSuccess: function () {
            sessionStorage.removeItem(this.sessionStorageUsedMovieNumberSuccess);
        },
        /**
        * ビデオデータのセッションを削除する
        */
        deleteVideoData: function () {
            sessionStorage.removeItem(this.sessionVideoData);
        },
        /**
        * 画像データのセッションを削除する
        */
        deleteImageData: function () {
            sessionStorage.removeItem(this.sessionImageData);
        },
        /**
        * 動画アップロード数のセッションを削除する
        */
        deleteUploadCount: function () {
            sessionStorage.removeItem(this.sessionUploadCount);
        },
        /**
         * セッション用クライアントIDのセッションを削除する
         */
        deleteClientId: function () {
            sessionStorage.removeItem(this.sessionClientId);
        },
        /**
        * セッション用プロジェクトIDのセッションを削除する
        */
        deleteProjectId: function () {
            sessionStorage.removeItem(this.sessionProjectId);
        },
        /**
         * ユーザーファイル名のセッションを削除する
         */
        deleteUserFileName: function () {
            sessionStorage.removeItem(this.sessionUserFileName);
        },
        /**
         * ビデオ容量のセッションを削除
         */
        deleteUploadVideoDB: function () {
            sessionStorage.removeItem(this.sessionVideoDB);
        },
        /**
         * アップロードが全て完了したかどうかの真偽値のセッションを削除
         */
        deleteAllUploadClear: function () {
            sessionStorage.removeItem(this.sessionAllUploadClear);
        },
        /**
         * すべてのセッションを削除する
         */
        deleteAllSession: function () {
            console.log("---start delete session---");
            this.deleteSessionMovieDownloadSuccess();
            this.deleteImageDownloadSuccess();
            this.deleteMovieUploadSuccess();
            this.deleteImageUplaodSuccess();
            this.deleteUploadImageName();
            this.deleteUploadImageUrl();
            this.deleteVimeoVideoUrl();
            this.deleteVideoHash();
            this.deleteVimeoWhiteListRegisterSuccess();
            this.deleteReserveVimeoFolderSuccess();
            this.deleteUserRegisterSuccess();
            this.deleteScreenChangeDBSuccess();
            this.deleteClientStorageDBChangeSuccess();
            this.deleteProjectStorageDBChangeSuccess();
            this.deleteScreenUsedMovieNumberSuccess();
            this.deleteStorageUsedMovieNumberSuccess();
            this.deleteVideoData();
            this.deleteImageData();
            this.deleteUploadCount();
            this.deleteClientId();
            this.deleteProjectId();
            this.deleteUserFileName();
            this.deleteUploadVideoDB();
            this.deleteAllUploadClear();
            console.log("---suceessed delete session---");
        },
        /**
         * 1つの動画のダウンロード〜アップロードまでが完了した際に削除するセッション
         */
        deleteUploadSession: function () {
            console.log("---start delete session---");
            this.deleteSessionMovieDownloadSuccess();
            this.deleteImageDownloadSuccess();
            this.deleteMovieUploadSuccess();
            this.deleteImageUplaodSuccess();
            this.deleteUploadImageName();
            this.deleteUploadImageUrl();
            this.deleteVimeoVideoUrl();
            this.deleteVideoHash();
            this.deleteVimeoWhiteListRegisterSuccess();
            this.deleteReserveVimeoFolderSuccess();
            this.deleteUserRegisterSuccess();
            this.deleteScreenChangeDBSuccess();
            this.deleteClientStorageDBChangeSuccess();
            this.deleteProjectStorageDBChangeSuccess();
            this.deleteScreenUsedMovieNumberSuccess();
            this.deleteStorageUsedMovieNumberSuccess();
            this.deleteVideoData();
            this.deleteImageData();
            this.deleteUploadCount();
            this.deleteClientId();
            this.deleteProjectId();
            this.deleteUserFileName();
            this.deleteUploadVideoDB();
            console.log("---suceessed delete session---");
        },
    }
}