<template>
  <v-main class="proj_approval">
    <div class="header">
      <v-container
        fluid
        fill
        class=""
        style="padding-left: 50px; margin-top: 5px"
      >
        <v-row style="height: 60px">
          <v-col class="">
            <h2 style="font-weight: bold; font-size: 23px">承認設定</h2>
          </v-col>
        </v-row>
        <nav class="nav-header">
          <ul class="nav-header__menu">
            <li id="approving" class="current_approve">
              <a class="approve_a" v-on:click="approvingChange()">
                <div style="height: 25px; border-bottom: solid">
                  <p class="text-center">承認待ち</p>
                </div>
              </a>
            </li>
            <li id="approved">
              <a class="approve_a" v-on:click="approvedChange()">
                <div style="height: 25px; border-bottom: solid">
                  <p class="text-center">承認済み</p>
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </v-container>
    </div>
    <v-container fluid fill class="proj-wrap" id="body">
      <!--itemlist内の要素は可変になる-->
      <div class="itemlist">
        <!--承認待ち-->
        <v-row v-if="flag"
          ><!--もともとあったclass="itemlist_item"を削除しました-->
          <!--以下の要素内がデータの個数文だけ表示される-->
          <v-col
            v-for="(user, index) in unApprovaled"
            cols="3"
            sm="4"
            lg="3"
            class="mr-0"
            :key="index + 'c'"
          >
            <p v-if="user.approval_status == '0'" class="date">
              {{ user.post_date }}
            </p>
            <v-btn class="delete-button" @click="deleteModalShow(user, index)">
              <span class="material-icons">close</span>
            </v-btn>
            <div
              v-if="user.approval_status == '0'"
              class="itemlist_item card"
              data-target="#item01"
              :class="{
                image: user.contents_kinds == '画像',
                movie: user.contents_kinds == '動画',
              }"
            >
              <div class="itemlist_item_wrap">
                <p class="itemlist_item_name">{{ user.user_name }}</p>
                <div
                  v-if="user.contents_kinds == '動画'"
                  class="itemlist_item_play"
                >
                  <img src="../../assets/play.png" />
                </div>
                <div
                  v-if="user.contents_kinds == '動画'"
                  :id="index"
                  class="overray_item_video"
                  style="width: 100%; height: 100%; z-index: 99999999999"
                >
                  <iframe
                    class="overray_item_video"
                    :src="
                      'https://player.vimeo.com/video/' +
                      user.video_id +
                      '&amp;controls=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=0&amp;loop=1amp;muted=0'
                    "
                    width="540"
                    height="960"
                    frameborder="0"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 0;
                    "
                    allow="autoplay; fullscreen;"
                    allowfullscreen
                  ></iframe>
                </div>
                <!--
                <video v-if="user.contents_kinds == '動画'" playsinline loop>
                  <source src="" type="video/mp4" />
                </video>
                -->
                <div v-if="user.contents_kinds == '画像'" class="">
                  <img :src="user.main_image_url" />
                </div>
                <div class="itemlist_item_body">
                  <div
                    v-if="user.thumb_image_url != ''"
                    class="itemlist_item_thumb"
                  >
                    <img :src="user.thumb_image_url" alt="" />
                  </div>
                  <div class="itemlist_item_txt">
                    <p>{{ user.title }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-3 ml-3 mr-3">コメント</div>
              <div class="mt-3 ml-3 mr-3 comment">
                {{ user.comment }}
              </div>
              <v-btn
                depressed
                class="mt-7 btn-ok"
                v-on:click="setApprovaled(user, index)"
              >
                承認
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!--承認済みの表示-->
        <v-row v-if="!flag"
          ><!--もともとあったclass="itemlist_item"を削除しました-->
          <!--以下の要素内がデータの個数文だけ表示される-->
          <v-col
            v-for="(user, index) in approvaled"
            cols="3"
            sm="4"
            lg="3"
            class="mr-0"
            :key="index + 'd'"
          >
            <p
              v-if="user.approval_status == '1' || user.approval_status == '2'"
              class="date"
            >
              {{ user.post_date }}
            </p>
            <div
              v-if="user.approval_status == '1' || user.approval_status == '2'"
              class="done_itemlist_item card"
              data-target="#item01"
              :class="{
                image: user.contents_kinds == '画像',
                movie: user.contents_kinds == '動画',
              }"
            >
              <div class="itemlist_item_wrap">
                <p class="itemlist_item_name">{{ user.user_name }}</p>
                <div
                  v-if="user.contents_kinds == '動画'"
                  class="itemlist_item_play"
                >
                  <img src="../../assets/play.png" />
                </div>
                <div
                  v-if="user.contents_kinds == '動画'"
                  :id="index"
                  class="overray_item_video"
                  style="width: 100%; height: 100%; z-index: 99999999999"
                >
                  <iframe
                    class="overray_item_video"
                    :src="
                      'https://player.vimeo.com/video/' +
                      user.video_id +
                      '&amp;controls=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=0&amp;loop=1amp;muted=0'
                    "
                    width="540"
                    height="960"
                    frameborder="0"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 0;
                    "
                    allow="autoplay; fullscreen;"
                    allowfullscreen
                  ></iframe>
                </div>
                <!--
                <video v-if="user.contents_kinds == '動画'" playsinline loop>
                  <source src="" type="video/mp4" />
                </video>
                -->
                <div v-if="user.contents_kinds == '画像'" class="">
                  <img :src="user.main_image_url" />
                </div>
                <div class="itemlist_item_body">
                  <div
                    v-if="user.thumb_image_url != ''"
                    class="itemlist_item_thumb"
                  >
                    <img :src="user.thumb_image_url" alt="" />
                  </div>
                  <div class="itemlist_item_txt">
                    <p>{{ user.title }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-3 ml-3 mr-3">コメント</div>
              <div class="mt-3 ml-3 mr-3 comment">
                {{ user.comment }}
              </div>
              <v-btn
                depressed
                class="btn-ng mt-7"
                v-on:click="approvalLift(user, index)"
              >
                承認解除
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div id="deleteModal" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-content">
            <p class="reserve-text">削除後復元出来ません。</p>
            <p class="reserve-text-2">削除しますか？</p>
            <div class="btn-modal">
              <div>
                <button
                  class="modal-cancel-button modalClose"
                  type="submit"
                  v-on:click="deleteContents()"
                >
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-main>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
const tus = require("tus-js-client");
const VimeoAccount = require("vimeo").Vimeo;
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new VimeoAccount(
  VIMEO_CLIENT_ID, // ID
  VIMEO_CLIENT_SECRET, // 秘密鍵
  VIMEO_ACCESS_TOKEN // アクセストークン
);
export default {
  name: "ApprovalSetting",
  mounted() {
    document.getElementById("body").click();
    this.waitLoading();
    this.getUser();
    this.$nextTick(function () {
      document.body.style.overflow = "";
      document.getElementsByTagName("html")[0].style.overflow = "";
    });
  },
  computed: {
    unApprovaled: {
      get() {
        return this.$store.state.approval;
      },
      set() {
        return this.$store.state.approval;
      },
    },
    approvaled: {
      get() {
        return this.$store.state.approvaled;
      },
      set() {
        return this.$store.state.approvaled;
      },
    },
  },
  destroyed() {
    document.getElementById("menuTop").style.pointerEvents = "none";
  },
  data: () => ({
    drawer: null,
    flag: true, //true→承認待ち/false→承認済み
    deleteUser: "", //削除するユーザー情報が格納される
    temporaryData: [],
  }),
  methods: {
    /**
     * 選択中のプロジェクトのユーザーを取得
     */
    getUser: async function () {
      this.$store.commit("resetApprovalInfo");
      this.$store.commit("getUserCheck", false);

      //選択したプロジェクトのインデックス番号を取得
      var projectIndex = this.$store.state.selectedProject;

      //クライアントIDを取得
      var clientId = this.$store.state.clientId;

      //プロジェクトIDを取得
      var projectId = this.$store.state.projectInfo[0][projectIndex].project_id;

      var instance = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/user/" + clientId + "/" + projectId);
      var list = await listRef.listAll();
      var imgList = [];
      var count = 0;
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        const userData = await this.dowonloadUserLink(imgList[i]);
        instance.temporaryData.push(userData);
        store.commit("setApprovalInfo", userData);
        store.commit("getUserCheck", true);
        /*
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              instance.temporaryData.push(JSON.parse(blob));
              store.commit("setApprovalInfo", JSON.parse(blob));
              store.commit("getUserCheck", true);
              count += 1;
              if (imgList.length == count) {
                //承認済みコンテンツを承認した順番に並べ直す
                instance.$store.commit("changeApprovaledOrder");
              }
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
        if (imgList.length == i + 1) {
          setTimeout(() => {
            this.downloaded();
            this.approveTouchAndClickEvent();
          }, 2000);
        }
        */
      }
      this.$store.commit("sortUserLists");
      var select = document.getElementsByName("order");
      for (var s = 0; i < select.length; s++) {
        select[s][s].selected = true;
      }
      //承認済みコンテンツを承認した順番に並べ直す
      instance.$store.commit("changeApprovaledOrder");
      this.$store.commit("getUserCheck", true);
      setTimeout(() => {
        this.downloaded();
        this.approveTouchAndClickEvent();
      }, 2000);
    },
    /**
     * ユーザーデータのダウンロードリンクを取得
     */
    dowonloadUserLink: function (userList) {
      var userData = new Promise((resolve) => {
        userList.getMetadata().then((metadata) => {
          userList.getDownloadURL().then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              var jsonParse = JSON.parse(blob);
              const date = new Date(metadata.timeCreated);
              jsonParse.timeCreated = date.getTime().toString();
              resolve(jsonParse);
            };
            xhr.send();
          });
        });
      });
      return userData;
    },
    /**
     * 削除モーダルの表示
     */
    deleteModalShow: function (user, index) {
      this.deleteUser = user;
      //削除モーダルを表示させる
      document.getElementById("deleteModal").style.display = "block";
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////タッチイベント及びクリックイベントを解除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    approveTouchAndClickEvent: function () {
      document.body.style.overflow = "";
      document.getElementsByTagName("html")[0].style.overflow = "";
      document.getElementById("menuTop").style.pointerEvents = "";
      document.body.style.pointerEvents = "";
      document.getElementsByTagName("html")[0].style.pointerEvents = "";
    },
    /**
     * vimeoから動画を削除する
     * @param {*}videoid ビデオのID
     */
    deleteVimeoMovie(videoId) {
      console.log("----deleteVimeoMovie-----");
      console.log(
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
      );
      var folderId =
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .vimeo_folder_pass;
      console.log(folderId);
      console.log(videoId);
      client.request(
        {
          method: "DELETE",
          path: "/videos/" + videoId,
        },
        function (error, body, status_code, headers) {
          console.log(error);
          console.log(body);
          console.log(status_code);
          console.log(headers);
        }
      );
    },
    /**
     * コンテンツの削除
     */
    deleteContents: function () {
      console.log("削除するDB容量分は" + this.deleteUser.uploadDBChangeToMB);

      //削除モーダルを閉じる
      document.getElementById("deleteModal").style.display = "none";
      var userInfo = this.deleteUser;
      var contentsDB = userInfo.uploadDBChangeToMB;

      //ローディングを回す
      this.waitLoading();
      var userInfo = this.deleteUser;
      var clientId = this.$store.state.clientId;
      var projectId =
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .project_id;
      var userId = userInfo.user_id;
      var contentsFileName = userInfo.content_fileName;
      var contentsDB = userInfo.uploadDBChangeToMB;
      var approvalStatus = userInfo.approval_status;
      var videoId = userInfo.video_id;
      var contentsType = userInfo.contents_kinds;
      if (userInfo.contents_kinds == "画像") {
        //firebase storageから削除する
        this.deleteUserFirebaseStorage(clientId, projectId, userId);
        //firebase storageからimgファイルを削除する
        this.deleteImgFirebaseStorage(clientId, projectId, contentsFileName);
        //データのapprovaledから削除する
        this.deleteUserVueDB(approvalStatus, userId);
        //削除したDB分を引く
        this.deleteUserDB(clientId, projectId, contentsDB, contentsType);
      } else if (userInfo.contents_kinds == "動画") {
        this.deleteVimeoMovie(videoId);

        //firebase storageから削除する
        this.deleteUserFirebaseStorage(clientId, projectId, userId);

        //firebase storageからimgファイルを削除する
        this.deleteImgFirebaseStorage(clientId, projectId, contentsFileName);

        //データのapprovaledから削除する
        this.deleteUserVueDB(approvalStatus, userId);

        //削除したDB分を引く
        this.deleteUserDB(clientId, projectId, contentsDB, contentsType);
      }
    },
    deleteUserFirebaseStorage: function (clientId, projectId, jsonFileName) {
      var userFilePass = firebase
        .storage()
        .ref()
        .child(
          "/user/" + clientId + "/" + projectId + "/" + jsonFileName + ".json"
        );
      userFilePass.delete();
    },
    deleteImgFirebaseStorage: function (clientId, projectId, jsonFileName) {
      var imgFilePass = firebase
        .storage()
        .ref()
        .child("/img/" + clientId + "/" + projectId + "/" + jsonFileName);
      imgFilePass.delete();
    },
    deleteUserVueDB: function (status, userId) {
      var data = {
        userId: userId,
        status: status,
      };
      this.$store.commit("deleteUser", data);
    },
    /**
     * 画面上の使用量DBと使用本数を書き換える
     */
    deleteScreenDBAndUsedNumber: function (deleteDB) {
      console.log("画面上の使用本数とDB容量を更新します");
      this.$store.commit("deleteScreenDB", deleteDB);
      this.$store.commit("deleteUsedNumber", 1);
    },
    deleteUserDB: function (clientId, projectId, contentsDB, contentsType) {
      var instance = this;
      //このパスからクライアントDBデータを取得する
      firebase
        .storage()
        .ref()
        .child("/db/" + clientId + ".json")
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            var clientDBInfo = JSON.parse(blob);
            var clientDB = clientDBInfo.used_db;
            console.log(clientDB);
            var newClientDB = Number(clientDB) - Number(contentsDB);
            console.log(
              "新しいクライアントのDB残高は" + newClientDB + "DBです"
            );
            clientDBInfo.used_db = newClientDB;

            if (contentsType == "動画") {
              var deleteNumber = 1;
              clientDBInfo.used_number -= deleteNumber;
            }
            if (contentsType == "画像") {
              var deleteNumber = 1;
              clientDBInfo.used_number -= deleteNumber;
            }

            //jsonフォーマットに変換する
            var jsonFormat = JSON.stringify(clientDBInfo);
            //このパスに保存する
            const ref = firebase
              .storage()
              .ref()
              .child("/db/" + clientId + ".json");
            const blobClient = new Blob([jsonFormat], {
              type: "application/json",
            });
            ref.put(blobClient).then(function (result) {
              //このパスからプロジェクトDBデータを取得する
              firebase
                .storage()
                .ref()
                .child("/db/" + clientId + "/" + projectId + ".json")
                .getDownloadURL()
                .then((url) => {
                  var xhr = new XMLHttpRequest();
                  xhr.open("GET", url);
                  xhr.onload = function (event) {
                    var blob = xhr.response;
                    var projectDBInfo = JSON.parse(blob);
                    var projectDB = projectDBInfo.used_data_contents;
                    var newProjectDB = Number(projectDB) - Number(contentsDB);
                    projectDBInfo.used_data_contents = newProjectDB;

                    if (contentsType == "動画") {
                      var deleteNumber = 1;
                      projectDBInfo.used_number -= deleteNumber;
                    }
                    if (contentsType == "画像") {
                      var deleteNumber = 1;
                      projectDBInfo.used_number -= deleteNumber;
                    }

                    //jsonフォーマットに変換する
                    var projectJsonFormat = JSON.stringify(projectDBInfo);

                    //このパスに保存する
                    const refProjectDB = firebase
                      .storage()
                      .ref()
                      .child("/db/" + clientId + "/" + projectId + ".json");
                    const blobProject = new Blob([projectJsonFormat], {
                      type: "application/json",
                    });
                    refProjectDB.put(blobProject);

                    //画面上のDBと使用本数を反映
                    instance.deleteScreenDBAndUsedNumber(contentsDB);

                    //ローディング終了
                    instance.downloaded();
                  };
                  xhr.send();
                });
            });
          };
          xhr.send();
        });
    },
    ///////////////////////////////////////////////
    //////////////
    //////////説明
    //////////承認ボタンを押した際、承認済み要素に追加するメソッド
    /////////
    /////////
    /////////
    /////////引数index:承認ボタンを押した際のコンテンツのインデックス番号
    //////////////////////////////
    ///////////////////////////////////////////////////////////////////
    setApprovaled: function (user, index) {
      this.$store.commit("setApprovaled", index);
      this.changeStatusFirebaseStorage(user, "1");
    },
    ///////////////////////////////////////////////
    //////////////
    //////////説明
    //////////承認解除ボタンを押した際、承認待ち要素に追加するメソッド
    /////////
    /////////
    /////////
    /////////引数index:承認解除ボタンを押した際のコンテンツのインデックス番号
    //////////////////////////////
    ///////////////////////////////////////////////////////////////////
    approvalLift: function (user, index) {
      this.$store.commit("setUnApproval", index);
      this.changeStatusFirebaseStorage(user, "0");
    },
    ///////////////////////////////////////////////
    //////////////
    //////////説明
    //////////firebaseStorageに承認ステータスの変更を保存する
    /////////
    /////////
    /////////
    /////////引数user:ユーザー情報
    //////////////////////////////
    ///////////////////////////////////////////////////////////////////
    changeStatusFirebaseStorage: async function (user, approvalStatus) {
      var approvaledLength = this.$store.state.approvaled.length;

      //クライアント一覧/[クライアントID]に保存する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/user/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.projectInfo[0][this.$store.state.selectedProject]
              .project_id +
            "/" +
            user.user_id +
            ".json"
        );
      const jsonString = JSON.stringify({
        post_date: user.post_date,
        user_id: user.user_id,
        user_name: user.user_name,
        title: user.title,
        comment: user.comment,
        approval_status: approvalStatus,
        contents_kinds: user.contents_kinds,
        main_image_url: user.main_image_url,
        video_id: user.video_id,
        thumb_image_url: user.thumb_image_url,
        user_name_status: user.user_name_status,
        title_status: user.title_status,
        comment_status: user.comment_status,
        cta_status: user.cta_status,
        cta: user.cta,
        thumb_status: user.thumb_status,
        main_thumb_image_url: user.main_thumb_image_url,
        order: user.order,
        approvaledOrder: approvaledLength + 1,
      });

      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function () {});
    },
    /**
     * 承認待ち/承認済みの画面切り替え
     */
    approvingChange: function () {
      //承認待ち画面にcurrentタグを付与
      var approving = document.getElementById("approving");
      var approved = document.getElementById("approved");

      if (approved.classList.contains("current_approve") == true) {
        approved.classList.remove("current_approve");
      }
      this.flag = true;
      approving.classList.add("current_approve");
    },
    approvedChange: function () {
      //承認済み画面にcurrentタグを付与
      var approving = document.getElementById("approving");
      var approved = document.getElementById("approved");

      if (approving.classList.contains("current_approve") == true) {
        approving.classList.remove("current_approve");
      }
      this.flag = false;
      approved.classList.add("current_approve");
    },
    /**
     * ローディング表示
     */
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    /**
     * ローディング解除
     */
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
var hoverContentsType; //「画像」か「動画」が入る
var classVal; // classの値を取得
var classVals; // 取得した値を分割
$(function () {
  $(document).on(
    {
      mouseenter: function () {
        $(".itemlist_item")
          .not($(this))
          .find(".itemlist_item_play")
          .fadeIn(200);
        classVal = $(this).attr("class"); // classの値を取得
        classVals = classVal.split(" "); // 取得した値を分割
        console.log("projectApprovalSettingが発動しています");

        // 配列になっているのでforで一つずつ取得できる
        for (var i = 0; i < classVals.length; i++) {
          if (classVals[i] == "image") {
            hoverContentsType = "画像";
          } else {
            hoverContentsType = "動画";
          }
        }
        if (hoverContentsType == "動画") {
          if ($($(this)).find(".itemlist_item_play").next("div")[0].id == "") {
            $($(this)).find(".itemlist_item_play").fadeIn(200);
          } else {
            $($(this)).find(".itemlist_item_play").fadeOut(200);
          }

          var clickedId = $(this).find(".itemlist_item_play").next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.play();
        }
      },

      mouseleave: function () {
        if (hoverContentsType == "動画") {
          $(".itemlist_item")
            .not($(this))
            .find(".itemlist_item_play")
            .fadeIn(200);
          $(".itemlist_item")
            .find(".itemlist_item_play")
            .not($(this))
            .fadeIn(200);
          var clickedId = $(this).find(".itemlist_item_play").next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.pause();
        }
      },
    },
    ".itemlist_item"
  );
  $(document).on(
    {
      mouseenter: function () {
        $(".done_itemlist_item")
          .not($(this))
          .find(".itemlist_item_play")
          .fadeIn(200);
        classVal = $(this).attr("class"); // classの値を取得
        classVals = classVal.split(" "); // 取得した値を分割

        // 配列になっているのでforで一つずつ取得できる
        for (var i = 0; i < classVals.length; i++) {
          if (classVals[i] == "image") {
            hoverContentsType = "画像";
          } else {
            hoverContentsType = "動画";
          }
        }
        if (hoverContentsType == "動画") {
          $(this).find(".itemlist_item_play").fadeOut(200);
          var clickedId = $(this).find(".itemlist_item_play").next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.play();
        }
      },
      mouseleave: function () {
        if (hoverContentsType == "動画") {
          $(".done_itemlist_item")
            .not($(this))
            .find(".itemlist_item_play")
            .fadeIn(200);
          $(this).find(".itemlist_item_play").not($(this)).fadeIn(200);
          var clickedId = $(this).find(".itemlist_item_play").next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.pause();
        }
      },
    },
    ".done_itemlist_item"
  );
});
</script>
<style scoped>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 9999999999999999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgb(82, 80, 80);
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.945);
  color: rgb(0, 0, 0);
  margin: 20% auto;
  width: 30%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: 1s;
}

.modal-cancel-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  outline: 0;
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
  margin-top: 3%;
}

.btn-modal {
  text-align: center;
  margin-top: 3%;
  margin-bottom: 5%;
}

.btn-modal > div {
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}

.reserve-text {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 24px;
  margin-top: 5%;
}
.reserve-text-2 {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 24px;
  margin-top: 1%;
  margin-bottom: 30px;
}

.delete-button {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #a5a5a5;
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 25px;
  right: 5px;
}
.delete-button span {
  font-size: 7px;
  font-weight: bold;
  margin-right: 1px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
}

main {
  width: 100%;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: bottom;
  border-style: none;
  max-width: 100%;
  width: auto;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.itemlist {
  z-index: 0;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 20px;
}
@media (max-width: 991px) {
  .itemlist {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 575px) {
  .itemlist {
    max-width: 286px;
    padding: 0;
  }
}
.itemlist .slick-list {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}
.itemlist .slick-list::after {
  content: "";
  width: 100%;
  height: calc(100% - 4rem);
  position: absolute;
  left: 0;
  top: 2rem;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0) 95%,
    rgba(0, 0, 0, 0.5)
  );
}
.itemlist_item {
  position: relative;
  padding-bottom: 2rem;
}
.done_itemlist_item {
  position: relative;
  padding-bottom: 2rem;
}
.itemlist_item_wrap {
  width: 100%;
  padding-top: 177.777778%;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  transition: 0.3s;
}
.itemlist_item_name {
  color: #fff;
  padding: 0 0.75rem;
  position: absolute;
  left: 0;
  top: 0.75rem;
  font-size: 0.75rem;
  z-index: 1;
}

.date {
  color: black;
  font-size: 0.75rem;
  z-index: 1;
  display: block;
  margin-bottom: 0.5rem !important;
}

.close-button {
  min-width: 10px;
  max-width: 20px;
  max-height: 20px;
  padding: 0 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.itemlist_item_body {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 5%;
  width: 100%;
}
.itemlist_item_thumb {
  font-size: 0.75rem;
  width: calc(3em + 1rem);
  height: calc(3em + 1rem);
}
.itemlist_item_thumb img {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itemlist_item_thumb + .itemlist_item_txt {
  width: calc(100% - 3em + 1rem);
}
.itemlist_item_txt {
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
  pointer-events: none;
  width: 100%;
}
.itemlist_item_txt p {
  height: 3em;
  overflow: hidden;
  margin-bottom: 0;
}
.itemlist_item video,
.itemlist_item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}
.itemlist_item img {
  object-fit: cover;
  cursor: initial;
}

.done_itemlist_item_txt p {
  height: 3em;
  overflow: hidden;
  margin-bottom: 0;
}
.done_itemlist_item video,
.done_itemlist_item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}
.done_itemlist_item img {
  object-fit: cover;
  cursor: initial;
}

.itemlist_item_play {
  position: absolute;
  width: 38px;
  height: 38px;
  left: calc(50% - 19px);
  top: calc(50% - 19px);
  z-index: 1;
}
.itemlist_arrow_prev {
  position: absolute;
  width: 13px;
  height: 32px;
  left: 1rem;
  top: calc(50% - 16px);

  cursor: pointer;
}
.itemlist_arrow_next {
  position: absolute;
  width: 13px;
  height: 32px;
  right: 1rem;
  top: calc(50% - 16px);

  cursor: pointer;
}

.overray {
  width: 100%;
  height: 100%;
  background: #404040;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
}
.overray_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}
.overray_inner {
  position: relative;
  width: 408px;
  padding-top: calc(408px * 1.77777778);
  -webkit-perspective: 500px;
  perspective: 500px;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_inner {
    height: 80vh;
    padding-top: 0;
    width: calc(80vh * 0.56824513);
  }
}

@media (max-width: 575px) {
  .overray_inner {
    width: 100%;
    padding-top: 0;
    height: 100%;
  }
}
.overray_prev {
  position: absolute;
  left: -42px;
  top: calc(50% - 24px);
  cursor: pointer;
}
@media (max-width: 575px) {
  .overray_prev {
    display: none;
  }
}
.overray_next {
  position: absolute;
  right: -42px;
  top: calc(50% - 24px);
  cursor: pointer;
}
@media (max-width: 575px) {
  .overray_next {
    display: none;
  }
}
.overray_item {
  display: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.overray_item.is-current {
  display: block;
}
.overray_item.rtoIn {
  display: block;
  z-index: 0;
}
.overray_item.rtoIn .overray_item_wrap {
  transform: rotateY(90deg) translateZ(0px) translateX(100%);
  animation: rtoIn 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.rtoIn .overray_item_wrap {
    animation: rtoIn_short 1s ease-in-out forwards;
  }
}

.overray_item.rtoOut {
  z-index: -1;
}
.overray_item.rtoOut .overray_item_wrap {
  transform: rotateY(0deg) translateZ(0) translateX(0);
  animation: rtoOut 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.rtoOut .overray_item_wrap {
    animation: rtoOut_short 1s ease-in-out forwards;
  }
}

.overray_item.ltoIn {
  display: block;
  z-index: 0;
}
.overray_item.ltoIn .overray_item_wrap {
  transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
  animation: ltoIn 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.ltoIn .overray_item_wrap {
    animation: ltoIn_short 1s ease-in-out forwards;
  }
}

.overray_item.ltoOut {
  z-index: -1;
}
.overray_item.ltoOut .overray_item_wrap {
  transform: rotateY(0deg) translateZ(0) translateX(0);
  animation: ltoOut 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.ltoOut .overray_item_wrap {
    animation: ltoOut_short 1s ease-in-out forwards;
  }
}

.overray_item_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
}
.overray_item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overray_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overray_item_time {
  width: 90%;
  height: 4px;
  border-radius: 10px;
  background: #9b9ea5;
  position: absolute;
  left: 5%;
  top: 0.5rem;
}
.overray_item_time_bar {
  position: absolute;
  width: 0%;
  height: 100%;
  background: #fbfcfe;
  border-radius: 10px;
  left: 0;
  top: 0;
  transition: 0.1s;
}
.overray_item_name {
  position: absolute;
  font-size: 0.75rem;
  left: 5%;
  top: 1.5rem;
  color: #fff;
}
.overray_item_close {
  position: absolute;
  right: 5%;
  top: 18px;
  cursor: pointer;
  z-index: 10;
}
.overray_item_play {
  position: absolute;
  width: 72px;
  height: 72px;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
  z-index: 10;
  transition: 0.3s;
  transform-origin: center;
  cursor: pointer;
}
.overray_item_play img {
  width: 100%;
}
.overray_item_play:hover {
  transform: scale(1.2);
}
.overray_item_txt {
  position: absolute;
  left: 0;
  bottom: 76px;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
}
.overray_item_txt p {
  height: 5.5em;
  overflow: auto;
  margin-bottom: 0;
}
.overray_item_bottom {
  position: absolute;
  padding: 0 5%;
  bottom: 24px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.overray_item_bottom_btn {
  width: 50%;
  max-width: 136px;
}

@keyframes rtoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(-90deg) translateZ(51px) translateX(-100%);
    z-index: -1;
  }
}
@keyframes rtoIn {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}
@keyframes ltoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(90deg) translateZ(51px) translateX(100%);
    z-index: -1;
  }
}
@keyframes ltoIn {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: 1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}
@keyframes rtoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(-90deg) translateZ(calc(40vh / 8)) translateX(-100%);
    z-index: -1;
  }
}
@keyframes rtoIn_short {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: 1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}
@keyframes ltoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(90deg) translateZ(calc(40vh / 8)) translateX(100%);
    z-index: -1;
  }
}
@keyframes ltoIn_short {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: 1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

.card {
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 5px #ccc;
}

.card:hover {
  /*box-shadow: 0 10px 12px rgba(0, 0, 0, 0.3);
        transform: translateY(-10px);*/
}

.nav-header {
  background-color: white;
  width: 100%;
  background: white;
  z-index: 9999999999999999999999999;
}

.nav-header__menu {
  padding: 20px 0 10px 0;
  margin: 0;
  text-align: left;
}

.nav-header__menu li {
  list-style: none;
  display: inline-block;
  width: 100px;
  min-width: 90px;
}
.nav-header__menu li + li {
  margin-left: 0.5rem;
}

.nav-header__menu li a {
  text-decoration: none;
  color: #e3e3e4;
}

.nav-header__menu li.current_approve a {
  color: #43444a;
}

.nav-header__menu li a:hover {
  color: #b1b6be;
}
.nav-header__menu li.current_approve a:hover {
  color: #43444a;
}

.sticky {
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
}

.sticky_main {
  position: fixed;
  background-color: white;
}

.sticky_topic {
  position: fixed;
  top: 10;
  background-color: white;
}

.header {
  background-color: white;
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300,400;500;700&display=swap");
.tabbr {
  display: none;
}
@media (max-width: 1259px) {
  .tabbr {
    display: block;
  }
}

.spbr {
  display: none;
}
@media (max-width: 767px) {
  .spbr {
    display: block;
  }
}

@media (max-width: 767px) {
  .pcbr {
    display: none;
  }
}

.js-anime {
  opacity: 0;
  transition: 0.7s;
}
.js-anime.is-slide {
  transform: translateY(100px);
}
.js-anime.is-anime {
  opacity: 1;
  transform: translateY(0);
}

.v-application {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
}
.v-application .text-h6 {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif !important;
}

.v-navigation-drawer__content {
  padding-top: 2rem;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background: #eae1d9;
}

.proj_approval .v-main__wrap {
  padding: 0 3rem;
}
.proj .nav-header__menu {
  padding-left: 0;
  padding-right: 0;
}
.proj .header .container {
  padding-bottom: 0;
}

.proj-wrap {
  padding: 50px;
}

.proj-wrap .btn-ok {
  background: linear-gradient(#00979c, #068587);
  color: #fff;
  font-weight: bold;
  padding-bottom: 0.5rem 1rem;
  display: block;
  width: 100%;
  max-width: 130px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
}
.proj-wrap .btn-ok:hover {
  filter: brightness(110%);
}
.proj-wrap .btn-ng {
  background: linear-gradient(#b1b1b1, #8e8e8e);
  color: #fff;
  font-weight: bold;
  padding-bottom: 0.5rem 1rem;
  display: block;
  width: 100%;
  max-width: 130px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
}
.proj-wrap .btn-ng:hover {
  filter: brightness(110%);
}

/*# sourceMappingURL=style.css.map */

#loading {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #000;
  z-index: 1;
}
#spinner {
  display: none;
  color: #fff;
  z-index: 2;
}

#app {
  background: #fff;
  width: 100%;
}

.comment {
  height: 50px;
  overflow: scroll;
  -ms-overflow-style: none; /* これを追加 */
  scrollbar-width: none;
}
.comment::-webkit-scrollbar {
  display: none;
}
</style>
