<template>
  <div id="alert-block-js">
    <div class="video-loading-screen" v-if="storeUploadLoading">
      <div
        class="spinner-border upload-loading"
        role="status"
        id="allScreen-upload-loading"
      >
        <span class="visually-hidden"></span>
      </div>
      <p v-if="storeUploadLoading" class="alert-text">
        動画をダウンロード中です
      </p>
    </div>
    <div class="video-sucess-screen" v-if="uploadSuccess === true">
      <p class="success-text">動画アップロードが成功しました</p>
    </div>
    <div class="video-failed-screen" v-if="uploadSuccess === false">
      <p class="failed-text">動画アップロードが失敗しました</p>
    </div>
    <!--動画際ダウンロード中表示させるもの-->
    <div class="retry-video-loading-screen" v-if="retryUpload === true">
      <div
        class="spinner-border retry-upload-loading"
        role="status"
        id="allScreen-upload-loading"
      >
        <span class="visually-hidden"></span>
      </div>
      <p class="alert-text">動画を再ダウンロード中です</p>
    </div>
    <div
      class="retry-video-sucess-screen"
      v-if="successOneUploadVideo === true"
    >
      <p class="success-text">動画の再アップロードが成功しました</p>
    </div>
    <div
      class="retry-video-failed-screen"
      v-if="successOneUploadVideo === false"
    >
      <div class="close" @click="closeErrorBox">
        <p class="close-mark">×</p>
      </div>
      <p class="retry-failed-text">動画の再アップロードに失敗しました</p>
      <div class="wrap-error-copy-button">
        <button class="error-copy-button" v-on:click="callMixinsCopyFunc">
          エラーの詳細をコピー
        </button>
      </div>
    </div>
  </div>
</template>

<script type="module">
import { tikQModalMixin } from "../../utility/tikQModal";
export default {
  name: "uploading",
  mounted() {
    console.log("--------uploadingComponentが呼ばれました----------");
  },
  mixins: [tikQModalMixin],
  data: function () {
    return {};
  },
  computed: {
    //このコンポーネントを表示/非表示切り替え
    storeUploadLoading() {
      return this.$store.state.uploadLoading;
    },
    //ローディングアイコンの表示/非表示切り替え
    storeUploadLoadingIcon() {
      return this.$store.state.uploadLoading;
    },
    uploadSuccess() {
      return this.$store.state.csvUploadJudgement;
    },
    retryUpload() {
      return this.$store.state.isRetryUp;
    },
    successOneUploadVideo() {
      return this.$store.state.isRetryUpSuccess;
    },
  },
  methods: {
    /**
     * 現在のパスを確認しログイン画面ならnullを返す
     */
    checkPass: function () {
      console.log("----chekPassが呼ばれました----");
      var pass = this.$router.currentRoute.path;
      console.log(pass);
      if (pass == "/") {
        console.log("flaseです");
        return false;
      } else {
         console.log("trueです");
        return true;
      }
    },
    /**
     * エラーコピー
     */
    callMixinsCopyFunc: function () {
      this.errorDetailCopy();
    },
    /**
     * 閉じるボタン
     */
    closeErrorBox: function () {
      this.$store.commit("oneVideoRetrySuccess", "none");
    },
  },
};
</script>

<style scoped>
.video-loading-screen {
  width: 350px;
  height: 50px;
  background-color: black;
  opacity: 0.5;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13px;
  padding-top: 14px;
  z-index: 99999999;
  position: fixed;
  bottom: 0;
  left: 40%;
  text-align: center;
  display: flex;
}

.video-sucess-screen {
  width: 350px;
  height: 50px;
  background-color: #038f93;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13px;
  padding-top: 14px;
  z-index: 99999999;
  position: fixed;
  bottom: 0;
  right: 5%;
  text-align: center;
  display: flex;
  animation-name: smooth-fadein-out;
  animation-duration: 6.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
.retry-video-sucess-screen {
  width: 350px;
  height: 50px;
  background-color: #038f93;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13px;
  padding-top: 14px;
  z-index: 99999999;
  position: fixed;
  bottom: 0;
  right: 5%;
  text-align: center;
  display: flex;
}

.video-failed-screen {
  width: 350px;
  height: 50px;
  background-color: #fa8072;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13px;
  padding-top: 14px;
  z-index: 99999999;
  position: fixed;
  bottom: 0;
  right: 5%;
  text-align: center;
  display: flex;
  animation-name: failed-fadein-out;
  animation-duration: 6.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
.retry-video-failed-screen {
  width: 350px;
  height: 80px;
  background-color: red;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13px;
  padding-top: 14px;
  z-index: 99999999;
  position: fixed;
  bottom: 0;
  right: 5%;
  text-align: center;
  display: block;
}

@keyframes smooth-fadein-out {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes failed-fadein-out {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

.alert-text {
  z-index: 99999999;
  color: white;
  margin-left: 10px;
}

.failed-text {
  z-index: 99999999;
  color: white;
  margin-left: auto;
  margin-right: auto;
}
.retry-failed-text {
  z-index: 99999999;
  color: white;
  font-size: 15px;
  margin: 0px;
}

.success-text {
  z-index: 99999999;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

/**ローディングアイコン */
#allScreen-upload-loading {
  width: 20px;
  height: 20px;
  z-index: 999999999;
  color: white;
  margin-left: 60px;
  border-width: 1px;
}

.retry-video-loading-screen {
  width: 350px;
  height: 50px;
  background-color: black;
  opacity: 0.5;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13px;
  padding-top: 14px;
  z-index: 99999999;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  text-align: center;
  display: flex;
}

.wrap-error-copy-button {
  width: 150px;
  height: 23px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

/**エラー詳細のコピーボタン */
.error-copy-button {
  width: 124px;
  height: 23px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c1c1c1;
  opacity: 1;
  font: normal normal normal 9px/13px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
  outline: none;
}

/**閉じるボタン */
.close {
  position: absolute;
  border-radius: 100%;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: black;
  font-size: 20px;
  opacity: 1;
}
.close-mark {
  margin-left: 1px;
  margin-top: 6px;
  color: white;
  font-size: 15px;
}
</style>
