<template>
  <div>
    <ProjectBar />
    <ProjectListHome />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import ProjectListHome from "@/components/ProjectList/ProjectListHomeComponent.vue";
import ProjectBar from "@/components/ProjectBar/ProjectBarComponent.vue";
import VueRouter from "vue-router";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../utility/authStateCheck";
export default {
  name: "ProjectClientHome",
  components: {
    ProjectListHome,
    ProjectBar,
    VueRouter,
    VueLoading,
  },
  mounted() {
    //認証情報があるか確認
    this.authStateCheck(this.$store, this.$router);

    //プロジェクト情報の取得
    this.getProjectInfo();
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
  methods: {
    /**
     * プロジェクト情報の取得
     */
    getProjectInfo: async function () {
      //ローディング表示
      this.waitLoading();

      //onload内のコードではthisは使えないのであらかじめ変数で格納しておく
      var store = this.$store;
      var instance = this;
      //一度初期化する
      this.$store.commit("resetProjectInfo");

      var clientId = this.$store.state.clientId;

      var seacretId = this.$store.state.seacretClientId;

      //このパスからデータを取得する
      firebase
        .storage()
        .ref()
        .child("/project/" + clientId + "/" + seacretId + "/project.json")
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            var projectList = JSON.parse(blob);
            store.commit("setProjectInfo", projectList);
            instance.getProjectDB();
            instance.getAllPublishContents();
          };
          xhr.send();
        })
        .then((response) => {
          // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
          const data = response;
        });
    },
    /**
     * 各プロジェクトのDBを取得
     */
    getProjectDB: async function () {
      var instance = this;
      var clientId = this.$store.state.clientId;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/db/" + clientId + "/");
      var list = await listRef.listAll();
      var projectDB = [];
      var dbList = [];
      var count = 0;
      Promise.all(
        list.items.map(async (element) => {
          await projectDB.push(element);
        })
      );
      if (projectDB.length != 0) {
        for (var i = 0; i < projectDB.length; i++) {
          projectDB[i]
            .getDownloadURL()
            .then((url) => {
              var xhr = new XMLHttpRequest();
              xhr.open("GET", url);
              xhr.onload = function (event) {
                var blob = xhr.response;
                dbList.push(JSON.parse(blob));
                count += 1;
                if (projectDB.length == count) {
                  var projectInfo = store.state.projectInfo;
                  var totalDB = 0;
                  for (var dbCount = 0; dbCount < dbList.length; dbCount++) {
                    totalDB += Math.floor(dbList[dbCount].used_data_contents);
                  }
                  store.commit("setTotalDB", totalDB);
                  for (var j = 0; j < projectInfo[0].length; j++) {
                    for (var t = 0; t < projectInfo[0].length; t++) {
                      if (projectInfo[0][j].project_id == dbList[t].projectId) {
                        projectInfo[0][j].used_data_contents = Math.floor(
                          dbList[t].used_data_contents
                        );
                        projectInfo[0][j].used_number = dbList[t].used_number;
                      }
                    }
                  }
                  instance.getclientDB();
                }
              };
              xhr.send();
            })
            .then((response) => {
              // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
              const data = response;
            });
        }
      } else {
        store.commit("setTotalDB", 0);
        instance.getclientDB();
      }
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //クライアントDB/アップロード動画の本数を取得する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    getclientDB: function () {
      var clientId = this.$store.state.clientId;
      var store = this.$store;
      var instance = this;
      //このパスからデータを取得する
      firebase
        .storage()
        .ref()
        .child("/db/" + clientId + ".json")
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            var clientDBInfo = JSON.parse(blob);
            var clientDB = clientDBInfo.used_db;
            var uploadNum = clientDBInfo.used_number;
            store.commit("setClientDB", clientDB);
            store.commit("setUploadNumber", uploadNum);
        
            //ローディング非表示
            instance.downloaded();
          };
          xhr.send();
        });
    },
    getAllPublishContents: async function () {
      this.$store.commit("resetAllPublishingContents");
      var store = this.$store;
      var clientId = this.$store.state.clientId;
      var allProject = this.$store.state.projectInfo[0];
      var count = 0;

      for (var i = 0; i < allProject.length; i++) {
        //このパスからデータを取得する
        await firebase
          .storage()
          .ref()
          .child(
            "/publish/" +
              clientId +
              "/" +
              allProject[i].project_id +
              "/publishingUser.json"
          )
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              var publishingInfo = JSON.parse(blob);
              store.commit("setAllPublishingContents", {
                project_id: allProject[count].project_id,
                publishingNum: publishingInfo.length,
              });
              count += 1;
            };
            xhr.send();
          })
          .catch((error) => {
            console.log("エラー");
            console.log(error);
          });
      }
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999999;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
}
</style>