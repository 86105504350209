var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"proj_approval"},[_c('div',{staticClass:"header"},[_c('v-container',{staticStyle:{"padding-left":"50px","margin-top":"5px"},attrs:{"fluid":"","fill":""}},[_c('v-row',{staticStyle:{"height":"60px"}},[_c('v-col',{},[_c('h2',{staticStyle:{"font-weight":"bold","font-size":"23px"}},[_vm._v("承認設定")])])],1),_c('nav',{staticClass:"nav-header"},[_c('ul',{staticClass:"nav-header__menu"},[_c('li',{staticClass:"current_approve",attrs:{"id":"approving"}},[_c('a',{staticClass:"approve_a",on:{"click":function($event){return _vm.approvingChange()}}},[_c('div',{staticStyle:{"height":"25px","border-bottom":"solid"}},[_c('p',{staticClass:"text-center"},[_vm._v("承認待ち")])])])]),_c('li',{attrs:{"id":"approved"}},[_c('a',{staticClass:"approve_a",on:{"click":function($event){return _vm.approvedChange()}}},[_c('div',{staticStyle:{"height":"25px","border-bottom":"solid"}},[_c('p',{staticClass:"text-center"},[_vm._v("承認済み")])])])])])])],1)],1),_c('v-container',{staticClass:"proj-wrap",attrs:{"fluid":"","fill":"","id":"body"}},[_c('div',{staticClass:"itemlist"},[(_vm.flag)?_c('v-row',_vm._l((_vm.unApprovaled),function(user,index){return _c('v-col',{key:index + 'c',staticClass:"mr-0",attrs:{"cols":"3","sm":"4","lg":"3"}},[(user.approval_status == '0')?_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(user.post_date)+" ")]):_vm._e(),_c('v-btn',{staticClass:"delete-button",on:{"click":function($event){return _vm.deleteModalShow(user, index)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("close")])]),(user.approval_status == '0')?_c('div',{staticClass:"itemlist_item card",class:{
              image: user.contents_kinds == '画像',
              movie: user.contents_kinds == '動画',
            },attrs:{"data-target":"#item01"}},[_c('div',{staticClass:"itemlist_item_wrap"},[_c('p',{staticClass:"itemlist_item_name"},[_vm._v(_vm._s(user.user_name))]),(user.contents_kinds == '動画')?_c('div',{staticClass:"itemlist_item_play"},[_c('img',{attrs:{"src":require("../../assets/play.png")}})]):_vm._e(),(user.contents_kinds == '動画')?_c('div',{staticClass:"overray_item_video",staticStyle:{"width":"100%","height":"100%","z-index":"99999999999"},attrs:{"id":index}},[_c('iframe',{staticClass:"overray_item_video",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","z-index":"0"},attrs:{"src":'https://player.vimeo.com/video/' +
                    user.video_id +
                    '&controls=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=0&loop=1amp;muted=0',"width":"540","height":"960","frameborder":"0","allow":"autoplay; fullscreen;","allowfullscreen":""}})]):_vm._e(),(user.contents_kinds == '画像')?_c('div',{},[_c('img',{attrs:{"src":user.main_image_url}})]):_vm._e(),_c('div',{staticClass:"itemlist_item_body"},[(user.thumb_image_url != '')?_c('div',{staticClass:"itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),_c('div',{staticClass:"itemlist_item_txt"},[_c('p',[_vm._v(_vm._s(user.title))])])])]),_c('div',{staticClass:"mt-3 ml-3 mr-3"},[_vm._v("コメント")]),_c('div',{staticClass:"mt-3 ml-3 mr-3 comment"},[_vm._v(" "+_vm._s(user.comment)+" ")]),_c('v-btn',{staticClass:"mt-7 btn-ok",attrs:{"depressed":""},on:{"click":function($event){return _vm.setApprovaled(user, index)}}},[_vm._v(" 承認 ")])],1):_vm._e()],1)}),1):_vm._e(),(!_vm.flag)?_c('v-row',_vm._l((_vm.approvaled),function(user,index){return _c('v-col',{key:index + 'd',staticClass:"mr-0",attrs:{"cols":"3","sm":"4","lg":"3"}},[(user.approval_status == '1' || user.approval_status == '2')?_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(user.post_date)+" ")]):_vm._e(),(user.approval_status == '1' || user.approval_status == '2')?_c('div',{staticClass:"done_itemlist_item card",class:{
              image: user.contents_kinds == '画像',
              movie: user.contents_kinds == '動画',
            },attrs:{"data-target":"#item01"}},[_c('div',{staticClass:"itemlist_item_wrap"},[_c('p',{staticClass:"itemlist_item_name"},[_vm._v(_vm._s(user.user_name))]),(user.contents_kinds == '動画')?_c('div',{staticClass:"itemlist_item_play"},[_c('img',{attrs:{"src":require("../../assets/play.png")}})]):_vm._e(),(user.contents_kinds == '動画')?_c('div',{staticClass:"overray_item_video",staticStyle:{"width":"100%","height":"100%","z-index":"99999999999"},attrs:{"id":index}},[_c('iframe',{staticClass:"overray_item_video",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","z-index":"0"},attrs:{"src":'https://player.vimeo.com/video/' +
                    user.video_id +
                    '&controls=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=0&loop=1amp;muted=0',"width":"540","height":"960","frameborder":"0","allow":"autoplay; fullscreen;","allowfullscreen":""}})]):_vm._e(),(user.contents_kinds == '画像')?_c('div',{},[_c('img',{attrs:{"src":user.main_image_url}})]):_vm._e(),_c('div',{staticClass:"itemlist_item_body"},[(user.thumb_image_url != '')?_c('div',{staticClass:"itemlist_item_thumb"},[_c('img',{attrs:{"src":user.thumb_image_url,"alt":""}})]):_vm._e(),_c('div',{staticClass:"itemlist_item_txt"},[_c('p',[_vm._v(_vm._s(user.title))])])])]),_c('div',{staticClass:"mt-3 ml-3 mr-3"},[_vm._v("コメント")]),_c('div',{staticClass:"mt-3 ml-3 mr-3 comment"},[_vm._v(" "+_vm._s(user.comment)+" ")]),_c('v-btn',{staticClass:"btn-ng mt-7",attrs:{"depressed":""},on:{"click":function($event){return _vm.approvalLift(user, index)}}},[_vm._v(" 承認解除 ")])],1):_vm._e()])}),1):_vm._e()],1)]),_c('div',{staticClass:"modal",attrs:{"id":"deleteModal"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"text-content"},[_c('p',{staticClass:"reserve-text"},[_vm._v("削除後復元出来ません。")]),_c('p',{staticClass:"reserve-text-2"},[_vm._v("削除しますか？")]),_c('div',{staticClass:"btn-modal"},[_c('div',[_c('button',{staticClass:"modal-cancel-button modalClose",attrs:{"type":"submit"},on:{"click":function($event){return _vm.deleteContents()}}},[_vm._v(" 削除 ")])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }