<template>
  <v-app>
    <div class="errorList-modal" id="js-errorList-modal">
      <div class="dialog-subject-wrap">
        <p class="dialog-subject">エラー詳細の内容</p>
      </div>
      <div class="dialog-text-wrap">
        <p class="copy-text">コピーしました</p>
      </div>
      <div class="dialog-button-wrap">
        <button class="dialog-button" v-on:click="closeButton">OK</button>
      </div>
    </div>
    <Menu />
    <ProjectBar />
    <TikQAlignmentComponent />
  </v-app>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import TikQAlignmentComponent from "@/components/TikQAlignmentComponent/TikQAlignmentComponent.vue";
import ProjectBar from "@/components/ProjectInfoBar/ProjectInfoBar.vue";
import AuthStateCheckMixin from "../utility/authStateCheck";
import Menu from "@/components/Menu/MenuComponent.vue";
export default {
  name: "ProjectRewriteComponent",
  components: {
    Menu,
    ProjectBar,
    TikQAlignmentComponent,
  },
  mounted() {
    //認証情報があるか確認
    this.authStateCheck(this.$store, this.$router);
  },
  data: function () {
    return {};
  },
  mixins: [AuthStateCheckMixin],
  methods: {
    closeButton: function () {
      var errorListModalDiv = document.getElementById("js-errorList-modal");
      errorListModalDiv.style.display = "none";
    },
  },
};
</script>
<style scoped>
.errorList-modal {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  top: 0px;
  background-color: black;
  width: 400px;
  height: 100px;
  z-index: 1;
  display: none;
}
.dialog-subject-wrap {
  width: 400px;
  height: 50px;
  z-index: 1;
}
.dialog-text-wrap {
  width: 400px;
  height: 10px;
  z-index: 1;
}
.dialog-subject {
  color: white;
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 8px;
}
.copy-text {
  color: white;
  z-index: 1;
  position: absolute;
  top: 30px;
  left: 9px;
  font-size: 10px;
}

.dialog-button-wrap {
  width: 400px;
  height: 80px;
  z-index: 1;
}
.dialog-button {
  position: relative;
  left: 320px;
  width: 65px;
  height: 28px;
  z-index: 1;
  font-size: 14px;
  background-color: #8eb8ff;
}
</style>