import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectLogin from '../views/ProjectLogin.vue'
import ProjectListHome from '../views/ProjectListHome.vue'
import ProjectInfo from '../views/ProjectInfo.vue'
import ProjectApproval from '../views/ProjectApprovalSetting.vue'
import ProjectPublished from '../views/PublishedSetting.vue'
import "firebase/auth"
import store from '../store/index'
import TikQAlignment from '../views/TikQAlignment.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ProjectLogin',
    component: ProjectLogin
  },
  {
    path: '/projectManagiment',
    name: 'ProjectClientHome',
    component: ProjectListHome,
    meta: { requiresAuth: true }
  },
  {
    path: '/projectRewrite',
    name: 'ProjectRewrite',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectRewriteScreen.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/projectRegister',
    name: 'ProjectCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectCreate.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/managimentList',
    name: 'ProjectRewrite',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminListHome.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/managimentRegister',
    name: 'ProjectRewrite',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminCreate.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/projectInfo',
    name: 'ProjectInfoComponent',
    component: ProjectInfo,
    meta: { requiresAuth: true }
  },
  {
    path: '/approvalSetting',
    name: 'ProjectApproval',
    component: ProjectApproval,
    meta: { requiresAuth: true }
  },
  {
    path: '/publishingSetting',
    name: 'Published',
    component: ProjectPublished,
    meta: { requiresAuth: true }
  },
  {
    path: '/tikQAlignment',
    name: 'tikQ',
    component: TikQAlignment,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // let currentUser = firebase.auth().currentUser
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    if (store.state.user == "") {
      next({
        path: '/',
        query: { rrdwa: store.state.clientId, sec: store.state.seacretClientId }
      })
    } else {
      next()
    }
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
