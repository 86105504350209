/**
    isMovieDownloadSuccess:"",//動画のダウンロードが成功したかどうかの判定値
    isImageDownloadSuccess:"",//画像のダウンロードが成功したかどうかの判定値
    isMovieUploadSuccess:"",//動画のアップロードが成功したかどうかの判定値
    isImageUplaodSuccess:"",//画像のアップロードが成功したかどうかの判定値
    uploadImageName:"",//Firebase Storageに保存された画像の名前
    uploadImageUrl:"",//Firebase Storageに保存された画像の保存先url
    vimeoVideoUrl:"",//アップロードしたvimeoの動画url
    videoHash:"",//動画のハッシュ値
    isVimeoWhiteListRegisterSuccess:"",//viemoの動画にホワイトリストを登録したかどうかの判定値
    isReserveVimeoFolderSuccess:"",//vimeoの動画のフォルダ移動を行なったか
    isUserRegisterSuccess:"",//ユーザー登録が成功したかどうかの判定値
    isScreenChangeDBSuccess:"",//画面上のDB使用量を更新したかどうかの判定値
    isStorageDBChangeSuccess:"",//Firebase StorageのDB使用量を更新したかどうかの判定値
    isScreenUsedMovieNumberSuccess:"",//画面上の使用本数を更新したかどうかの判定値
    isStorageUsedMovieNumberSuccess:"",//Firebase StorageのDB使用量を更新したかどうかの判定値
    videoData:"",//ビデオデータ
    imageData:"",//画像データ
    uploadCount:0,//動画アップロード数
 */

    export const sessionName = {
        data: function () {
            return {
                sessionMovieDownloadSuccess:"sessionMovieDownloadSuccess",
                sessionImageDownloadSuccess:"sessionImageDownloadSuccess",
                sessionMovieUploadSuccess:"sessionMovieUploadSuccess",
                sessionImageUplaodSuccess:"sessionImageUplaodSuccess",
                sessionUploadImageName:"sessionUploadImageName",
                sessionUploadImageUrl:"sessionUploadImageUrl",
                sessionVimeoVideoUrl:"sessionVimeoVideoUrl",
                sessionVideoHash:"sessionVideoHash",
                sessionVimeoWhiteListRegisterSuccess:"sessionVimeoWhiteListRegisterSuccess",
                sessionReserveVimeoFolderSuccess:"sessionReserveVimeoFolderSuccess",
                sessionUserRegisterSuccess:"sessionUserRegisterSuccess",
                sessionScreenChangeDBSuccess:"sessionScreenChangeDBSuccess",
                sessionClientStorageDBChangeSuccess:"sessionClientStorageDBChangeSuccess",
                sessionProjectStorageDBChangeSuccess:"sessionProjectStorageDBChangeSuccess",
                sessionScreenUsedMovieNumberSuccess:"sessionScreenUsedMovieNumberSuccess",
                sessionStorageUsedMovieNumberSuccess:"sessionStorageUsedMovieNumberSuccess",
                sessionVideoData:"sessionVideoData",
                sessionImageData:"sessionImageData",
                sessionUploadCount:"sessionUploadCount",
                sessionClientId:"sessionClientId",
                sessionProjectId:"sessionProjectId",
                sessionUserFileName:"sessionUserFileName",
                sessionVideoDB:"sessionVideoDB",
                sessionAllUploadClear:"sessionAllUploadClear",
            }
        },
    }