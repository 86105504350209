<template>
  <ul class="topnav">
    <div class="navigation" v-for="(client, index) in clientInfo" :key="index">
      <li class="client-name">{{ client.client_name }}</li>
      <div class="data">
        <li class="used-content">
          <p>使用データ容量</p>
        </li>
        <li class="value">
          <p>{{ Math.floor(usedDB) }}DB/{{ planDB }}DB使用中</p>
        </li>
        <li class="used-content">
          <p>使用本数</p>
        </li>
        <li class="value">
          <p>{{ uploadNum }}本</p>
        </li>
        <li class="used-content">
          <p>契約期間</p>
        </li>
        <li class="value">
          <p>
            {{ client.contract_start_day }}~{{ client.contract_finished_day }}
          </p>
        </li>
        <li class="right">
          <button class="logout" v-on:click="logout()">ログアウト</button>
        </li>
      </div>
    </div>
  </ul>
</template>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
export default {
  name: "ProjectBarComponent",
  data: function () {
    return {
      temporaryData: [],
    };
  },
  computed: {
    clientInfo: {
      get() {
        return this.$store.state.clientInfo;
      },
      set() {
        return this.$store.state.clientInfo;
      },
    },
    usedDB: {
      get() {
        return this.$store.state.totalDB;
      },
      set() {
        return this.$store.state.totalDB;
      },
    },
    uploadNum: {
      get() {
        return this.$store.state.usedNumber;
      },
      set() {
        return this.$store.state.usedNumber;
      },
    },
    planDB: {
      get() {
        return this.$store.state.clientInfo[0].contract_plan;
      },
      set() {
        return this.$store.state.clientInfo[0].contract_plan;
      },
    },
  },
  mounted() {
    var clientId = this.$store.state.clientInfo[0].client_id;
    var projectInfo = this;
    var store = this.$store;
    var storage = firebase.storage();
    var storageRef = storage.ref();
    /*
    storageRef
      .child("/clientList/" + clientId + ".json")
      .getDownloadURL()
      .then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.onload = function (event) {
          var blob = xhr.response;
          projectInfo.temporaryData.push(JSON.parse(blob));
          store.commit("setClientsInfo", JSON.parse(blob));
          store.commit("changeClientDB");
        };
        xhr.send();
      })
      .then((response) => {
        // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
        const data = response;
      });
      */
  },
  methods: {
    /**
     * ログアウト
     */
    logout: function () {
      if (this.$store.state.allUploadClear == true) {
        firebase.auth().onAuthStateChanged((user) => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              this.$store.commit("allReset");
              this.$router.go({
                path: this.$router.currentRoute.path,
                force: true,
              });
            })
            .catch((error) => {
              console.log(`ログアウト時にエラーが発生しました (${error})`);
            });
        });
      } else {
        var result = window.confirm(
          "動画アップロード中です。データが損なわれる可能性がありますが、よろしいですか？"
        );
        if (result == true) {
          firebase.auth().onAuthStateChanged((user) => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                this.$store.commit("allReset");
                this.$router.go({
                  path: this.$router.currentRoute.path,
                  force: true,
                });
              })
              .catch((error) => {
                console.log(`ログアウト時にエラーが発生しました (${error})`);
              });
          });
        }
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
ul.topnav {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: #3f3d56 0% 0% no-repeat padding-box;
  height: 54px;
}
ul.topnav li {
  float: left;
  font-family: "Noto Sans JP", sans-serif;
}

ul.topnav li.right {
  float: right;
}

.used-content {
  position: relative;
  background: #de7b37 0% 0% no-repeat padding-box;
  height: 20px;
  top: 10px;
  border-radius: 2px;
  display: block;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 12px;
}

p {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  position: relative;
  top: -10px;
}

.value {
  position: relative;
  height: 20px;
  top: 12px;
  display: block;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.client-name {
  font-size: 16px;
  color: white;
  display: block;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.navigation {
  margin-top: 0.3%;
  margin-left: 9%;
}

.data {
  float: right;
}

.logout {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  width: 120px;
  height: 64px;
  background: #a7a7a7 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: -8px;
  color: white;
}
</style>
