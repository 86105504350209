import { reloadManageGetter } from "../utility/reloadManageGetter"
import { sessionName } from "../utility/sessionNameGetter";
import { setVuex } from "../utility/setVuex";
export const sessionRegister = {
    mixins: [reloadManageGetter, sessionName, setVuex],
    data: function () {
        return {
            vuexStore: this.$store
        }
    },
    methods: {
        /**
    * 動画ダウンロードが成功したかどうかの判定値をセッションに登録
    */
        registerSessionMovieDownloadSuccess: function (isValue) {
            this.setVuexMovieDownloadSuccess(isValue);
            sessionStorage.setItem(this.sessionMovieDownloadSuccess, this.getMovieDownloadSuccess());
        },
        /**
         * 画像ダウンロードが成功したかどうかの判定値をセッションに登録
         */
        registerSessionImageDownloadSuccess: function (isValue) {
            this.setVuexImageDownloadSuccess(isValue);
            sessionStorage.setItem(this.sessionImageDownloadSuccess, this.getImageDownloadSuccess());
        },
        /**
         * 動画のアップロードが成功したかどうかの判定値をセッションに登録
         */
        registerSessionMovieUploadSuccess: function (isValue) {
            this.setVuexMovieUploadSuccess(isValue);
            sessionStorage.setItem(this.sessionMovieUploadSuccess, this.getMovieUploadSuccess());
        },
        /**
        * 画像のアップロードが成功したかどうかの判定値をセッションに登録
        */
        registerSessionImageUplaodSuccess: function (isValue) {
            console.log("画像アップロード成功のセッション処理が呼ばれます");
            this.setVuexImageUplaodSuccess(isValue);
            console.log("セッション処理が正しく動作しました");
            sessionStorage.setItem(this.sessionImageUplaodSuccess, this.getImageUplaodSuccess());
        },
        /**
        * Firebase Storageに保存された画像の名前をセッションに登録
        */
        registerSessionUploadImageName: function (isValue) {
            console.log(isValue);
            this.setVuexUploadImageName(isValue);
            sessionStorage.setItem(this.sessionUploadImageName, this.getUploadImageName());
        },
        /**
        * Firebase Storageに保存された画像の保存先urlをセッションに登録
        */
        registerSessionUploadImageUrl: function (isValue) {
            this.setVuexUploadImageUrl(isValue);
            sessionStorage.setItem(this.sessionUploadImageUrl, this.getUploadImageUrl());
        },
        /**
        * アップロードしたvimeoの動画urlをセッションに登録
        */
        registerSessionVimeoVideoUrl: function () {
            sessionStorage.setItem(this.sessionVimeoVideoUrl, this.getVimeoVideoUrl());
        },
        /**
        * 動画のハッシュ値をセッションに登録
        */
        registerSessionVideoHash: function (isValue) {
            console.log("渡ってきたハッシュ値は"+isValue+"です");
            this.setVuexVideoHash(isValue);
            sessionStorage.setItem(this.sessionVideoHash, this.getVideoHash());
        },
        /**
        * viemoの動画にホワイトリストを登録したかどうかの判定値をセッションに登録
        */
        registerSessionVimeoWhiteListRegisterSuccess: function (isValue) {
            this.setVuexVimeoWhiteListRegisterSuccess(isValue);
            sessionStorage.setItem(this.sessionVimeoWhiteListRegisterSuccess, this.getVimeoWhiteListRegisterSuccess());
        },
        /**
        * vimeoの動画のフォルダ移動を行なったかの判定値をセッションに登録
        */
        registerSessionReserveVimeoFolderSuccess: function (isValue) {
            this.setVuexReserveVimeoFolderSuccess(isValue);
            sessionStorage.setItem(this.sessionReserveVimeoFolderSuccess, this.getReserveVimeoFolderSuccess());
        },
        /**
        * ユーザー登録が成功したかどうかの判定値をセッションに登録
        */
        registerSessionUserRegisterSuccess: function (isValue) {
            this.setVuexUserRegisterSuccess(isValue);
            sessionStorage.setItem(this.sessionUserRegisterSuccess, this.getUserRegisterSuccess());
        },
        /**
        * 画面上のDB使用量を更新したかどうかの判定値をセッションに登録
        */
        registerSessionScreenChangeDBSuccess: function (isValue) {
            console.log("画面上のDB使用量を更新したかどうかの判定値");
            console.log(isValue);
            this.setVuexScreenChangeDBSuccess(isValue);
            console.log(this.sessionScreenChangeDBSuccess);
            console.log(this.getScreenChangeDBSuccess());
            sessionStorage.setItem(this.sessionScreenChangeDBSuccess, this.getScreenChangeDBSuccess());
        },
        /**
        * Firebase StorageのクライアントDB使用量を更新したかどうかの判定値をセッションに登録
        */
        registerSessionClientStorageDBChangeSuccess: function (isValue) {
            console.log("クライアントDB使用量を更新したかどうかの判定値をセッションに登録します");
            console.log(isValue);
            this.setVuexClientStorageDBChangeSuccess(isValue);
            console.log(this.sessionClientStorageDBChangeSuccess);
            console.log(this.getClientStorageDBChangeSuccess());
            sessionStorage.setItem(this.sessionClientStorageDBChangeSuccess, this.getClientStorageDBChangeSuccess());
            console.log("クライアントDB使用量のセッション登録は"+sessionStorage.getItem("sessionClientStorageDBChangeSuccess"));
        },
        /**
       * Firebase StorageのプロジェクトDB使用量を更新したかどうかの判定値をセッションに登録
       */
        registerSessionProjectStorageDBChangeSuccess: function (isValue) {
            console.log("プロジェクトDB使用量を更新したかどうかの判定値をセッションに登録します");
            console.log(isValue);
            this.setVuexProjectStorageDBChangeSuccess(isValue);
            console.log(this.sessionProjectStorageDBChangeSuccess);
            console.log(this.getProjectStorageDBChangeSuccess());
            sessionStorage.setItem(this.sessionProjectStorageDBChangeSuccess, this.getProjectStorageDBChangeSuccess());
            console.log("プロジェクトDB使用量のセッション登録は"+sessionStorage.getItem("sessionProjectStorageDBChangeSuccess"));
        },
        /**
        * 画面上の使用本数を更新したかどうかの判定値をセッションに登録
        */
        registerSessionScreenUsedMovieNumberSuccess: function (isValue) {
            this.setVuexScreenUsedMovieNumberSuccess(isValue);
            sessionStorage.setItem(this.sessionScreenUsedMovieNumberSuccess, this.getScreenUsedMovieNumberSuccess());
        },
        /**
        * Firebase Storageの使用本数を更新したかどうかの判定値をセッションに登録
        */
        registerSessionStorageUsedMovieNumberSuccess: function () {
            sessionStorage.setItem(this.sessionStorageUsedMovieNumberSuccess, this.getStorageUsedMovieNumberSuccess());
        },
        /**
        * ビデオデータをセッションに登録
        */
        registerSessionVideoData: function () {
            sessionStorage.setItem(this.sessionVideoData, this.getVideoData());
        },
        /**
        * 画像データをセッションに登録
        */
        registerSessionImageData: function () {
            sessionStorage.setItem(this.sessionImageData, this.getImageData());
        },
        /**
        * 動画アップロード数をセッションに登録
        */
        registerSessionUploadCount: function () {
            sessionStorage.setItem(this.sessionUploadCount, this.getUploadCount());
        },
        /**
         * セッション用クライアントIDをセッションに登録
         */
        registerSessionClientId: function (data) {
            this.setVuexClientId(data);
            sessionStorage.setItem(this.sessionClientId, this.getClientId());
        },
        /**
         * セッション用プロジェクトIDをセッションに登録
         */
        registerSessionProjectId: function (data) {
            this.setVuexProjectId(data);
            sessionStorage.setItem(this.sessionProjectId, this.getProjectId());
        },
        /**
         * ユーザーファイル名をセッションに登録
         */
        registerSessionUserFileName: function (isValue) {
            this.setVuexUserFileName(isValue);
            sessionStorage.setItem(this.sessionUserFileName, this.getUserFileName());
        },
        /**
         * アップロードする動画容量をセッションに登録
         */
        registerUploadVideoDB: function (value) {
            this.setVuexVideoDB(value);
            sessionStorage.setItem(this.sessionVideoDB, this.getVideoDB());
            console.log("アップロードするセッションの動画容量は"+sessionStorage.getItem("sessionVideoDB")+"です。");
        },
        /**
         * アップロードがすべて完了しているかの真偽値をセッションに登録
         */
        registerAllUploadClear: function (isValue) {
            this.setVuexUploadAllSucessed(isValue);
            sessionStorage.setItem(this.sessionAllUploadClear, this.getUploadAllClear());
        },

    }
}