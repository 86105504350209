<template>
  <div class="overlay" id="tikq-overlay"></div>
</template>
<script type="module">
export default {
  mounted() {},
  data: function () {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.overlay {
  /* 位置を固定 */
  position: fixed;
  top: 0;
  left: 0;
  /* 画面いっぱいに広がるようにする */
  width: 100%;
  height: 100vh;
  /* rgbaを使って60%の黒いオーバーレイにする */
  background: rgba(0, 0, 0, 0.6);
  /* デフォルトでは見えないようにする */
  opacity: 0;
  visibility: none;
  /* 表示する際の変化の所要時間 */
  transition: 0.3s;
  z-index: -1;
}

/* activeクラスのついたオーバーレイ */
.overlay.active {
  /* activeクラスがついたときにオーバーレイを表示する */
  opacity: 1;
  visibility: visible;
}
</style>