<template>
  <v-app>
    <Menu />
    <ProjectBar />
    <DropDown />
    <ProjectInfo />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </v-app>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import ProjectInfo from "@/components/ProjectInfo/ProjectInfoComponent.vue";
import ProjectBar from "@/components/ProjectInfoBar/ProjectInfoBar.vue";
import DropDown from "@/components/DropDown/DropDownComponent.vue";
import Menu from "@/components/Menu/MenuComponent.vue";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../utility/authStateCheck";
export default {
  name: "ProjectInfoComponent",
  components: {
    ProjectInfo,
    ProjectBar,
    DropDown,
    Menu,
    VueLoading,
  },
  props: {
    index: {
      type: null,
    },
  },
  mounted() {
    //認証情報があるか確認
    this.authStateCheck(this.$store, this.$router);
    this.getAllPublishContents();
    var selectedProjectIndex = this.$store.state.selectedProject;
   // this.getUser(selectedProjectIndex);ひょっとしたらいらない
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
  methods: {
    /**
     * プロジェクトのすべてのユーザーを取得
     */
    getUser: async function (index) {
      this.$store.commit("resetApprovalInfo");
      this.$store.commit("getUserCheck", false);
      //クライアントIDを取得
      var clientId = this.$store.state.clientId;

      //プロジェクトIDを取得
      var projectId = this.$store.state.projectInfo[0][index].project_id;
    
      var instance = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/user/" + clientId + "/" + projectId);
      var list = await listRef.listAll();
      var imgList = [];
      var imgListCount = 0;
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
     
      for (var i = 0; i < imgList.length; i++) {
        const userData = await this.dowonloadUserLink(imgList[i]);
        instance.temporaryData.push(userData);
        store.commit("setApprovalInfo", userData);
        store.commit("getUserCheck", true);
        /*あとでコメントアウトはずす
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            console.log(`for文${i}回目のURLのダウンロードに成功`);
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              console.log(`for文${i}回目のURLからデータの取得に成功`);
              var blob = xhr.response;
              instance.temporaryData.push(JSON.parse(blob));
              store.commit("setApprovalInfo", JSON.parse(blob));
              store.commit("getUserCheck", true);
              imgListCount += 1;
              if (imgList.length == imgListCount) {
                //全てユーザーを取得し終えたら
                var select = document.getElementsByName("order");
                for (var i = 0; i < select.length; i++) {
                  select[i][i].selected = true;
                }
              }
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
          */
      }
      this.$store.commit("sortUserLists");
      var select = document.getElementsByName("order");
      for (var s = 0; i < select.length; s++) {
        select[s][s].selected = true;
      }
      if (imgList.length == 0) {
        this.$store.commit("getUserCheck", true);
      }
    },
    /**
     * ユーザーデータのダウンロードリンクを取得
     */
    dowonloadUserLink: function (userList) {
      var userData = new Promise((resolve) => {
        userList.getMetadata().then((metadata) => {
          userList.getDownloadURL().then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              var jsonParse = JSON.parse(blob);
              const date = new Date(metadata.timeCreated);
              jsonParse.timeCreated = date.getTime().toString();
              resolve(jsonParse);
            };
            xhr.send();
          });
        });
      });
      return userData;
    },
    getAllPublishContents: function () {
      this.$store.commit("resetAllPublishingContents");
      var store = this.$store;
      var clientId = this.$store.state.clientId;
      var allProject = this.$store.state.projectInfo[0];
      var count = 0;

      for (var i = 0; i < allProject.length; i++) {
        //このパスからデータを取得する
        firebase
          .storage()
          .ref()
          .child(
            "/publish/" +
              clientId +
              "/" +
              allProject[i].project_id +
              "/publishingUser.json"
          )
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              var publishingInfo = JSON.parse(blob);
              store.commit("setAllPublishingContents", {
                project_id: allProject[count].project_id,
                publishingNum: publishingInfo.length,
              });
              count += 1;
            };
            xhr.send();
          })
          .catch((error) => {
            console.log("エラー");
            console.log(error);
          });
      }
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999999;
  display: none;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
  display: none;
}
</style>