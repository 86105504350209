export const reloadManageGetter = {
    data: function () {
        return {
            vuexStore: this.$store

        }
    },
    methods: {
        /**
         * 動画ダウンロードが成功したかどうかの判定値をvuexから取得
         */
        getMovieDownloadSuccess: function () {
            return this.vuexStore.state.isMovieDownloadSuccess;
        },
        /**
         * 画像ダウンロードが成功したかどうかの判定値をvuexから取得
         */
        getImageDownloadSuccess: function () {
            return this.vuexStore.state.isImageDownloadSuccess
        },
        /**
         * 動画のアップロードが成功したかどうかの判定値をvuexから取得
         */
        getMovieUploadSuccess: function () {
            return this.vuexStore.state.isMovieUploadSuccess
        },
        /**
        * 画像のアップロードが成功したかどうかの判定値をvuexから取得
        */
        getImageUplaodSuccess: function () {
            return this.vuexStore.state.isImageUplaodSuccess
        },
        /**
        * Firebase Storageに保存された画像の名前をvuexから取得
        */
        getUploadImageName: function () {
            return this.vuexStore.state.uploadImageName
        },
        /**
        * Firebase Storageに保存された画像の保存先urlをvuexから取得
        */
        getUploadImageUrl: function () {
            return this.vuexStore.state.uploadImageUrl
        },
        /**
        * アップロードしたvimeoの動画urlをvuexから取得
        */
        getVimeoVideoUrl: function () {
            return this.vuexStore.state.vimeoVideoUrl
        },
        /**
        * 動画のハッシュ値をvuexから取得
        */
        getVideoHash: function () {
            return this.vuexStore.state.videoHash
        },
        /**
        * viemoの動画にホワイトリストを登録したかどうかの判定値をvuexから取得
        */
        getVimeoWhiteListRegisterSuccess: function () {
            return this.vuexStore.state.isVimeoWhiteListRegisterSuccess
        },
        /**
        * vimeoの動画のフォルダ移動を行なったかの判定値をvuexから取得
        */
        getReserveVimeoFolderSuccess: function () {
            return this.vuexStore.state.isReserveVimeoFolderSuccess
        },
        /**
        * ユーザー登録が成功したかどうかの判定値をvuexから取得
        */
        getUserRegisterSuccess: function () {
            return this.vuexStore.state.isUserRegisterSuccess
        },
        /**
        * 画面上のDB使用量を更新したかどうかの判定値をvuexから取得
        */
        getScreenChangeDBSuccess: function () {
            return this.vuexStore.state.isScreenChangeDBSuccess
        },
        /**
        * Firebase StorageのクライアントDB使用量を更新したかどうかの判定値をvuexから取得
        */
        getClientStorageDBChangeSuccess: function () {
            return this.vuexStore.state.isClientStorageDBChangeSuccess
        },
        /**
       * Firebase StorageのプロジェクトDB使用量を更新したかどうかの判定値をvuexから取得
       */
        getProjectStorageDBChangeSuccess: function () {
            return this.vuexStore.state.isProjectStorageDBChangeSuccess
        },
        /**
        * 画面上の使用本数を更新したかどうかの判定値をvuexから取得
        */
        getScreenUsedMovieNumberSuccess: function () {
            return this.vuexStore.state.isScreenUsedMovieNumberSuccess
        },
        /**
        * Firebase StorageのDB使用量を更新したかどうかの判定値をvuexから取得
        */
        getStorageUsedMovieNumberSuccess: function () {
            return this.vuexStore.state.isStorageUsedMovieNumberSuccess
        },
        /**
        * ビデオデータをvuexから取得
        */
        getVideoData: function () {
            return this.vuexStore.state.videoData
        },
        /**
        * 画像データをvuexから取得
        */
        getImageData: function () {
            return this.vuexStore.state.imageData
        },
        /**
        * 動画アップロード数をvuexから取得
        */
        getUploadCount: function () {
            return this.vuexStore.state.uploadCount
        },
        /**
         * セッション用クライアントIDをvuexから取得
         */
        getClientId: function () {
            return this.vuexStore.state.sessionClientId
        },
        /**
         * セッション用プロジェクトIDをvuexから取得
         */
        getProjectId: function () {
            return this.vuexStore.state.sessionProjectId
        },
        /**
         * ユーザーファイル名をvuexから取得
         */
        getUserFileName: function () {
            return this.vuexStore.state.userFileName
        },
        /**
         * アップロードする動画容量をvuexから取得
         */
        getVideoDB: function () {
            return this.vuexStore.state.videoDB
        },
        /**
         * 動画アップロードが全て完了したかどうかの真偽値をvuexから取得
         */
        getUploadAllClear: function () {
            return this.vuexStore.state.allUploadClear
        },

    }
}