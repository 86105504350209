<template>
  <div class="tikq-modal" id="tikq-modal">
    <div class="close" @click="closeModal"><p class="close-mark">×</p></div>
    <div class="wrap-error-box">
      <div class="excel-box">
        <table
          style="
            table-layout: fixed;
            width: 353px;
            height: 359;
            font-size: 12px;
          "
          class="js-table"
        ></table>
      </div>
    </div>
    <div class="wrap-error-copy-button">
      <button class="error-copy-button" v-on:click="callMixinsCopyFunc">
        エラーの詳細をコピー
      </button>
    </div>
  </div>
</template>
<script type="module">
import { tikQModalMixin } from "../../utility/tikQModal";
export default {
  mounted() {},
  mixins: [tikQModalMixin],
  data: function () {
    return {};
  },
  methods: {
    /**
     * 説明
     * エラーモーダルコンテンツを画面から非表示にするメソッド
     * @param なし
     * @return なし
     */
    closeModal: function () {
      //エラーエクセル表モーダルを非表示にするパラメータ
      const check = "nonDisplay";
      this.tikQModalManage(check);
    },
    callMixinsCopyFunc: function () {
      this.errorDetailCopy();
    },
  },
};
</script>
<style scoped>
/* モーダルウィンドウ */
.tikq-modal {
  width: 560px;
  height: 427px;
  padding: 15px 20px;
  background: #fff;
  /* 位置の調整(真ん中に表示) */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* デフォルトでは非表示にしておく */
  opacity: 0;
  visibility: none;
  /* 表示の変化にかかる時間 */
  transition: 0.3s;
  z-index: -1;
}
/* activeクラスのついたモーダルウィンドウ */
.tikq-modal.active {
  opacity: 1;
  visibility: visible;
}

/* モーダルウィンドウ内の閉じるボタン */
.close {
  position: absolute;
  border-radius: 100%;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: black;
  /*cursor: pointer;*/
  font-size: 20px;
  opacity: 1;
}

.close-mark {
  margin-left: 6px;
  margin-top: 5px;
  color: white;
}

.tikq-modal h2 {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.tikq-modal p {
  font-size: 13px;
}

.wrap-error-box {
  width: 511px;
  height: 330px;
  margin-top: 16px;
  background: #eff0f2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
}
.wrap-error-box::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

.wrap-error-box::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
}
.wrap-error-box::-webkit-scrollbar-track {
    background-color: #ddd;
}

.excel-box {
  position: relative;
  top: 0px;
  width: 511px;
  height: 330px;
  margin-left: auto;
  margin-right: auto;
}

::v-deep table {
  border-collapse: collapse;
  table-layout: fixed;
}
::v-deep th {
  border: 1px solid #eeeeee;
  padding: 3px 10px;
  background: #ddd;
  font-weight: bold;
  text-align: center;
}
::v-deep .item {
  border: 1px solid #eeeeee;
  padding: 3px 10px;
  background: #ddd;
  font-weight: bold;
  text-align: center;
}
::v-deep .value {
  border: 1px solid #eeeeee;
  padding: 3px 10px;
  cursor: pointer;
  width: 100px;
  background-color: white;
  text-align: center;
}

::v-deep .rowNumber {
  border: 1px solid #eeeeee;
  padding: 3px 10px;
  background: #ddd;
  font-weight: bold;
  text-align: center;
}

.wrap-error-copy-button {
  width: 150px;
  height: 50px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.error-copy-button {
  width: 124px;
  height: 23px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c1c1c1;
  opacity: 1;
  font: normal normal normal 9px/13px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
  outline: none;
}
</style>