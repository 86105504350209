<template>
  <v-main>
    <v-container class="tikQ" fluid fill style="padding-top: 0">
      <!--動画一括アップロードのブロック-->
      <div class="wrap">
        <div class="subject">
          <h3 style="font-weight: 500; font-size: 24px">
            動画一括アップロード
          </h3>
        </div>
      </div>
      <div class="wrap-page-explain">
        <p class="page-explain">
          SNS上に投稿している動画やクラウド上に保存している動画を、一括でアップロードすることが可能です。
        </p>
      </div>
      <!--SNS・クラウド動画のブロック-->
      <div class="wrap-sns-cloud-movie">
        <div class="wrap-sns-cloud-movie-subject">
          <div class="sns-cloud-movie-subject">SNS・クラウド動画</div>
          <div class="csv-upload-text">CSVアップロード</div>
        </div>
        <div class="wrap-question">
          <div class="wrap-question-mark">
            <p class="question-text">?</p>
          </div>
          <div class="temple-download-description">
            <p class="hover-explain-subject">
              ・SNS上に投稿している動画や、クラウド上に保存した動画がある方
            </p>
            <p class="hover-explain-text-row1">
              SNS上に投稿した動画のURL、またはクラウド上に保存している動画のURLを<br />CSVファイルの任意の場所に記憶し、アップロードする
            </p>
            <p class="hover-explain-text-row2">
              ※CSVファイルをお持ちでない場合、「テンプレートファイルをダウンロー<br />ド」ボタンからCSVテンプレートファイルをダウンロードしてご利用いただけ<br />ます。
            </p>
          </div>
        </div>
        <div class="templeFile">
          <span class="material-icons download-icon"> save_alt </span>
          <button
            class="download-button"
            type="button"
            v-on:click="csvTemplateDownload"
          >
            テンプレートファイルをダウンロード
          </button>
        </div>
      </div>
      <!--TikQ連携のブロック-->
      <div class="wrap-tikq-alignment">
        <div class="wrap-tikq-alignment-subject">
          <div class="tikq-alignment-subject">TikQ連携</div>
          <div class="csv-upload-text">CSVアップロード</div>
        </div>
        <div class="wrap-question-tkq">
          <div class="wrap-question-mark-tkq">
            <p class="question-text">?</p>
          </div>
          <div class="tkq-alignment-download-description">
            <p class="tkq-alignment-hover-explain-subject">
              ・TkTokキャンペーンサービス「TikQ」をご利用中の方
            </p>
            <p class="tkq-alignment-hover-explain-text-row1">
              TikQのCSVファイルをアップロードすることで、VIVEWとTikQとの連携設定を<br />行うことが可能です。
            </p>
            <p class="tkq-alignment-hover-explain-text-row2">
              ※CSVファイルをお持ちでない方は、「TikQにログインしてCSVをダウンロー<br />ド」ボタンからCSVファイルをダウンロードしてご利用ください。
            </p>
          </div>
        </div>
        <div class="templeFile-tkq">
          <span class="material-symbols-outlined download-icon-tkq">
            login
          </span>
          <button
            class="download-button-tkq"
            type="button"
            v-on:click="tikQLogin"
          >
            TikQにログインしてCSVをダウンロード
          </button>
        </div>
      </div>
      <!--csvアップロード可否を表示するブロック-->
      <div class="wrap-message" id="js-wrap-message">
        <div
          class="success-message-box sucessColor"
          v-if="csvUploadJudgement == true && isUploadProject == projectId"
        >
          <div class="checkmark-icon">
            <span class="material-symbols-outlined success-mark"> check </span>
          </div>
          <div class="success-message">
            <p class="success-message-text"></p>
            {{ sucessMessage1 }}【{{ uploadFileName }}】<br />{{
              sucessMessage2
            }}
          </div>
        </div>
        <div
          class="error-message-box failedColor"
          v-if="csvUploadJudgement === false && isUploadProject == projectId"
        >
          <div class="error-message-box-inner">
            <div class="error-mark-icon">
              <span class="material-symbols-outlined error-mark">
                priority_high
              </span>
            </div>
            <div class="error-message">
              <p class="error-message-text">
                {{ errorMessage1 }}【{{ uploadFileName }}】<br />・{{
                  $store.state.errorPartList.length
                }}{{ errorMessage2 }}
              </p>
            </div>
            <div class="error-button-box">
              <button class="error-button" v-on:click="errorExcelDisplay">
                エラーの詳細はこちら
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--csvファイルを選択するブロック-->
      <div class="wrap-select-file-block">
        <label
          for="csv_file"
          v-bind:class="{
            'selected-file-label': isUploadAllClear === true,
            'selected-file-label-uploading': isUploadAllClear === false,
          }"
        >
          ファイルを選択
        </label>
        <input
          class="select-file"
          type="file"
          id="csv_file"
          accept=".csv"
          v-on:change="fileWrite"
          :disabled="isUploadAllClear == false"
        />
        <div
          v-bind:class="{
            'selected-file-text': isUploadAllClear === true,
            'selected-file-text-uploading': isUploadAllClear === false,
          }"
        >
          <p class="selected-file-name">{{ selectedFileName }}</p>
        </div>
      </div>
      <div class="extension-alert-wrapper" id="js-extension-alert">
        <p class="extension-alert-text">
          ファイルの拡張子をCSVファイル形式にしてください
        </p>
      </div>
      <!--csvファイルをアップロードするブロック-->
      <div class="wrap-upload-button">
        <span class="material-icons upload-icon" id="upload-icon">
          logout
        </span>
        <button
          class="upload-button"
          id="upload-button"
          type="button"
          v-on:click="csvUpload"
        >
          CSVファイルをアップロードする
        </button>
      </div>
      <div
        class="spinner-border upload-loading"
        role="status"
        id="upload-loading-js"
        v-if="storeUploadLoadingIcon && isUploadProject == projectId"
      >
        <span class="visually-hidden"></span>
      </div>
      <TikQModalOverlay />
      <TikQModal />
    </v-container>
  </v-main>
</template>
<script src="https://bossanova.uk/jexcel/v4/jexcel.js"></script>
<script src="https://bossanova.uk/jsuites/v2/jsuites.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import { tiktokMethodsMixin } from "../../methods/tikqAlignmentComponent/tiktokMethods";
import { firebaseMethodsMixin } from "../../methods/tikqAlignmentComponent/firebaseRegisterMethods";
import TikQModal from "../Modal/TikQModal.vue";
import TikQModalOverlay from "../Modal/TikQModalOverlay.vue";
import { tikQModalMixin } from "../../utility/tikQModal";
import { tikQErrorManage } from "../../utility/tikQErrorManage";
import { VueLoading } from "vue-loading-template";
import Encoding from "encoding-japanese";
import { reloadManager } from "../../utility/reloadManage";
import { sessionGetter } from "../../utility/sessionGetter";

const VimeoAccount = require("vimeo").Vimeo;
const tus = require("tus-js-client");
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new VimeoAccount(
  VIMEO_CLIENT_ID, // ID
  VIMEO_CLIENT_SECRET, // 秘密鍵
  VIMEO_ACCESS_TOKEN // アクセストークン
);
export default {
  mixins: [
    tiktokMethodsMixin,
    firebaseMethodsMixin,
    tikQModalMixin,
    tikQErrorManage,
    reloadManager,
    sessionGetter,
  ],
  components: {
    TikQModal,
    TikQModalOverlay,
    VueLoading,
    Encoding,
  },
  computed: {
    //ローディングアイコンの表示/非表示真偽値を返す
    storeUploadLoadingIcon() {
      return this.$store.state.uploadLoading;
    },
    //アップロード後のメッセージの表示/非表示の真偽値を返す
    csvUploadJudgement() {
      return this.$store.state.csvUploadJudgement;
    },
    //アップロードするファイル名を取得する
    uploadFileName() {
      return this.$store.state.uplaodfileName;
    },
    //アップロード成功時のメッセージの配色
    isSucessedActive() {
      return this.$store.state.isUploadSucessColor;
    },
    //アップロード失敗時のメッセージの配色
    isfailedActive() {
      return this.$store.state.isUploadFailedColor;
    },
    //アップロード中のファイル選択の非活性
    isUploadAllClear() {
      return this.$store.state.allUploadClear;
    },
    //アップロードをおこなったプロジェクトID
    isUploadProject() {
      return this.$store.state.uploadingProjectId;
    },
  },
  destroyed() {},
  mounted() {
    window.addEventListener("beforeunload", () => {
      this.$store.commit("changeUploadedMessage", "");
      this.$store.commit("manageUploadIcon", false);
      this.$store.commit("initialCsvErrorData");
    });

    const uploadedProjectId = this.$store.state.uploadingProjectId;
    const selectedProjectId =
      this.$store.state.projectInfo[0][this.$store.state.selectedProject]
        .project_id;

    //タッチイベントを有効にする
    this.touchAndClickEvent();

    //アップロードボタンの活性非活性を設定する
    this.uploadButtonStateChange(true);

    //メッセージボックスのマージンの調整
    this.messageBoxMarginChange1();

    if (this.checkTableDisplay() == false) {
      if (uploadedProjectId == selectedProjectId) {
        this.dataCompensate1();
        this.boxColorChange1(false);
        this.$store.commit("changeUploadedMessage", false);
        this.messageBoxMarginChange1();
        this.createCSVDataTable1(this.$store.state.csvData);
      }
    } else if (this.checkTableDisplay() == true) {
      if (uploadedProjectId == selectedProjectId) {
        this.boxColorChange1(true);
      }
    }

    //セッションチェック
    this.videoUploadManager(this.firebase);

    this.$store.commit("oneVideoRetrySuccess", "none");
  },
  data: function () {
    return {
      clientPlan: this.$store.state.clientInfo[0].contract_plan, //クライアントの契約プラン
      vuexStore: this.$store, //vuexのストア
      clientInfo: [], //クライアントの情報
      projectInfo: [], //プロジェクトの情報
      clientDb: "", //クライアントのDB使用量
      clientContentsNum: "", //クライアントの使用本数
      projectDb: "", //プロジェクトのDB使用量
      projectContentsNum: "", //プロジェクトのDB使用量
      clientContractPlan: "", //契約プランのDB容量
      movieDataSize: "", //アップロードするビデオのデータを取得
      firebase: firebase, //mixinで利用する
      vimeoClient: client, //mixinで利用する
      vimeoTus: tus, //mixinで利用する
      clientId: this.$store.state.clientId, //クライアントID
      projectId:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .project_id, //プロジェクトID
      movieDataSize: "", //アップロードする動画容量サイズ
      folderId:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .vimeo_folder_pass, //フォルダID
      data: [],
      selectedFileName: "未選択です", //ファイル選択で表示させる初期値
      // uploadFileName: "", //アップロードするファイル名
      style: {
        A1: "background-color: orange;",
        B1: "background-color: orange;",
      },
      postMovieUrl: 6, //投稿動画リンク
      ctaLinkLine: 16, //cta遷移先リンクのcsvでの列数
      titleLine: 14, //タイトルのcsvでの列数
      commentLine: 15, //コメントのcsvでの列数
      userIDLine: 1, //ユーザIDのcsvでの列数(必須項目)
      postedDateLine: 10, //投稿日のcsvでの列数(必須項目)
      divisionLine: 17, //区分のcsvでの列数(必須項目)
      sucessMessage1: "アップロード成功", //アップロードが成功した際のメッセージ
      sucessMessage2: "csvアップロードが正常に完了しました", //アップロードが成功した際のメッセージ
      errorMessage1: "アップロードエラー",
      errorMessage2: "件のエラーが見つかりました。",
      headerData: [
        //csvヘッダのデータ
        "MUID",
        "ユーザID",
        "フォロワー",
        "総いいね",
        "動画数",
        "反応率",
        "投稿動画",
        "再生数",
        "アクション",
        "反応率",
        "投稿日",
        "投稿数",
        "当選",
        "メモ",
        "タイトル",
        "コメント",
        "CTA遷移先",
        "区分",
      ],
      excelHeaderAlphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      headerTotalNumberTikQFormat: 14, //ヘッダの数
      headerTotalNumberVIVEWFormat: 18, //ヘッダの数
      csvFormat: "", //アップロードしようとしているcsvのフォーマットがtikQなのかVIVEWなのか
      division: ["tikQ"], //動画の区分
    };
  },
  methods: {
    /**
     *
     */
    extensionAlert: function (isDisplay) {
      const extendionBlock = document.getElementById("js-extension-alert");
      if (isDisplay) {
        extendionBlock.style.display = "block";
      } else {
        extendionBlock.style.display = "none";
      }
    },
    /**
     * ファイルの拡張子をチェックし真偽値を返す
     */
    getExt: function (filename) {
      var pos = filename.lastIndexOf(".");
      var extension = "";
      if (pos === -1) {
        return "";
      } else {
        //拡張子
        extension = filename.slice(pos + 1);
        if (extension == "csv") {
          return true;
        } else {
          return false;
        }
      }
    },
    /**
     * テーブルの状態を維持するかどうかのチェック
     */
    checkTableDisplay: function () {
      const errorList = this.$store.state.errorPartList;
      const uploadSucess = this.$store.state.uploadSucess;
      if (errorList.length != 0) {
        return false;
      }
      if (uploadSucess === true) {
        return true;
      } else {
        if (errorList.length == 0) {
          return "なし";
        } else {
          return false;
        }
      }
    },

    /**
     * 説明
     * エラーを管理する
     * @param col エラー列（0が渡ってきた場合、列は関係なしor全列）
     * @param row エラー行 （0が渡ってきた場合、行は関係なしor全行）
     * @param errorId エラー番号
     * @return なし
     */
    errorManagement: function (colData, rowData, errorId) {
      var errorPoint = {
        col: colData,
        row: rowData,
        code: errorId,
      };
      this.$store.commit("setCsvErrorList", errorPoint);
    },
    /**
     * 説明
     * タッチイベントを有効にする
     * @param なし
     * @return なし
     */
    touchAndClickEvent: function () {
      document.body.style.overflow = "";
      document.getElementsByTagName("html")[0].style.overflow = "";
      document.getElementById("menuTop").style.pointerEvents = "";
      document.body.style.pointerEvents = "";
      document.getElementsByTagName("html")[0].style.pointerEvents = "";
    },
    /**
     * 説明
     * tikQのログイン画面を開く
     * @param なし
     * @return なし
     */
    tikQLogin: function () {
      window.open("https://campaign-manager.jp/login");
    },
    /**
     * このコンポーネントデータの初期化
     * @param なし
     * @return なし
     */
    deleteCsvData: function () {
      //エラー箇所を初期化
      this.$store.commit("initialCsvErrorData");

      //csvデータの初期化
      this.$store.commit("initCsvData");

      this.$store.commit("initRetryData");

      //メッセージ表示非表示初期化
      this.$store.commit("changeUploadedMessage", "");

      //エラーテーブルの要素を取得
      var target = document.querySelector(".js-table");

      //これをしないと要素が増え続ける
      if (target != null) {
        while (target.firstChild) {
          target.removeChild(target.firstChild);
        }
      }
    },
    /**
     * プロジェクトが切り替わるごとにテーブルデータの初期化をおこなう
     */
    deleteCsvData2: function () {},
    /**
     * アップロードの結果メッセージ欄の配色を変更する
     */
    changeColor: function () {
      document.getElementById("js-wrap-message").style.backgroundColor =
        "white";
    },
    /**
     * 説明
     * アップロードされたファイルの名前を取得し、画面上に表示する
     */
    fileWrite: function () {
      var selectedFile = document.getElementById("csv_file");
      var selectedFileName = selectedFile.files[0].name;
      this.selectedFileName = selectedFileName;
      if (this.getExt(this.selectedFileName)) {
        this.extensionAlert(false);
        this.uploadButtonStateChange(false);
      } else {
        this.extensionAlert(true);
        this.uploadButtonStateChange(true);
      }
    },
    /**
     * 説明
     * アップロードの活性非活性の制御
     */
    uploadButtonStateChange: function (isState) {
      var uploadButton = document.getElementById("upload-button");
      var uploadButtonIcon = document.getElementById("upload-icon");
      if (isState) {
        uploadButton.disabled = true;
        uploadButton.style.background = "";
        uploadButton.style.backgroundColor = "#E6E6E6";
        uploadButton.style.color = "#B9BCC4";
        uploadButtonIcon.style.color = "#B9BCC4";
      } else {
        uploadButton.disabled = false;
        uploadButton.style.background =
          "linear-gradient(180deg, #e07f3e 0%, #d66010 100%)";
        uploadButton.style.color = "white";
        uploadButtonIcon.style.color = "white";
      }
    },
    /**
     * 動画の再度アップロード中のステータスを更新
     */
    updateIsRetryStatus: function (isStatus) {
      this.$store.commit("setRetryUploadStatus", isStatus);
    },
    /**
     * 説明
     * csvファイルをアップロードする
     */
    csvUpload: async function () {
      var result = "";
      const _this = this;
      if (this.$store.state.isRetryUp == true) {
        console.log("再度アップロード中でした");
        result = window.confirm(
          "現在動画の再ダウンロード中です。中止して、CSVファイルをアップロードしますか。"
        );
      }
      if (result == true) {
        //再アップロード中止
        this.$store.commit("uploadState", true);
        if (_this.$store.state.isRetryUp == true) {
          console.log("再度アップロードが終わるまで待機");
          this.waitLoading();
        }
        let timerId = setInterval(async function () {
          if (_this.$store.state.isRetryUp == false) {
            clearInterval(timerId);
            _this.stopLoading();
            _this.$store.commit("oneVideoRetrySuccess", "none");
            //アップロードするプロジェクトIDをセット
            _this.$store.commit("setUploadingProjectId", _this.projectId);

            //ローディングアイコンを表示
            _this.$store.commit("manageUploadIcon", true);

            _this.$store.commit("setUplaodFileName", _this.selectedFileName);

            //アップロードボタンを非活性化
            _this.uploadButtonStateChange(true);

            //前データの初期化
            _this.deleteCsvData();

            //inputタグ要素を取得
            let fileInput = document.getElementById("csv_file").files[0];

            //データが全くない場合
            if (fileInput["size"] == 0) {
              _this.errorManagement(9999, 9999, 22);

              //ローディングアイコンを非表示
              _this.$store.commit("manageUploadIcon", false);
              _this.$store.commit("changeUploadedMessage", false);
            } else {
              //ファイルの読み込み
              let csvFile = await _this.readFile(fileInput);

              //csv形式のファイルを配列で取得
              await _this.csvDownload(csvFile);

              const csvData = _this.$store.state.csvData;

              //csvDataが空である真偽値
              var isCsvDataBlank = false;

              //csvDataが空かどうかチェック
              if (csvData.length == 1) {
                for (var k = 0; k < csvData[0].length; k++) {
                  if (csvData[0][k] != "") {
                    isCsvDataBlank = true;
                    break;
                  }
                }
              } else {
                isCsvDataBlank = true;
              }

              if (isCsvDataBlank == true) {
                //csvをアップロードする前の事前チェック
                var advancedCheckVal = _this.advancedCheck(csvData);

                if (advancedCheckVal == true) {
                  //ヘッダチェックの真偽値
                  var headerCheck = _this.headerCheck(csvData);
                  if (headerCheck == true) {
                    if (
                      csvData[0].length == _this.headerTotalNumberTikQFormat
                    ) {
                      _this.csvFormat = "TikQ";
                    } else {
                      _this.csvFormat = "VIVEW";
                    }
                    _this.formatCheck(csvData);
                  }
                } else {
                  //-1はヘッダ部分を差し引いている
                  var uploadDataLen = csvData.length - 1;
                  var dataPlan = _this.clientPlan;
                  for (var i = 0; i < uploadDataLen - 100; i++) {
                    _this.errorManagement(
                      "0",
                      uploadDataLen - dataPlan * 2,
                      14
                    );
                  }
                }

                //エラーがなかった場合
                if (_this.$store.state.errorPartList.length == 0) {
                  console.log("ダウンロード開始");
                  _this.userUploadMainFunc(csvData, _this.folderId);
                } else {
                  //エラーがあった場合
                  if (advancedCheckVal == true) {
                    _this.blankRowAdd();
                    //事前チェックには引っ掛からなかった場合
                    _this.dataCompensate1();
                    _this.boxColorChange1(false);
                    _this.$store.commit("changeUploadedMessage", false);
                    _this.createCSVDataTable1(csvData);

                    //ローディングアイコンを非表示
                    _this.$store.commit("manageUploadIcon", false);
                  } else {
                    //事前チェックに引っかかった場合
                    _this.dataCompensate1();
                    _this.boxColorChange1(false);
                    _this.$store.commit("changeUploadedMessage", false);
                    _this.createCSVDataTable1(csvData);
                    //ローディングアイコンを非表示
                    _this.$store.commit("manageUploadIcon", false);
                  }
                }
              } else {
                _this.errorManagement(9999, 9999, 22);

                //ローディングアイコンを非表示
                _this.$store.commit("manageUploadIcon", false);
                _this.$store.commit("changeUploadedMessage", false);
              }
            }
            //inputで読み取った値を削除する
            document.getElementById("csv_file").value = "";
          }
        }, 10);
      } else if (result == "") {
        //再アップロード処理中ではなかった場合
        this.$store.commit("uploadState", false);
        _this.$store.commit("oneVideoRetrySuccess", "none");
        //アップロードするプロジェクトIDをセット
        _this.$store.commit("setUploadingProjectId", _this.projectId);

        //ローディングアイコンを表示
        _this.$store.commit("manageUploadIcon", true);

        _this.$store.commit("setUplaodFileName", _this.selectedFileName);

        //this.uploadFileName = this.selectedFileName;

        //アップロードボタンを非活性化
        _this.uploadButtonStateChange(true);

        //前データの初期化
        _this.deleteCsvData();

        //inputタグ要素を取得
        let fileInput = document.getElementById("csv_file").files[0];

        console.log(fileInput);

        //データが全くない場合
        if (fileInput["size"] == 0) {
          _this.errorManagement(9999, 9999, 22);
          _this.$store.commit("changeUploadedMessage", false);
          //ローディングアイコンを非表示
          _this.$store.commit("manageUploadIcon", false);
        } else {
          //ファイルの読み込み
          let csvFile = await _this.readFile(fileInput);

          //csv形式のファイルを配列で取得
          await _this.csvDownload(csvFile);

          const csvData = _this.$store.state.csvData;

          //csvDataが空である真偽値
          var isCsvDataBlank = false;

          //csvDataが空かどうかチェック
          if (csvData.length == 1) {
            for (var k = 0; k < csvData[0].length; k++) {
              if (
                csvData[0][k]
                  .replace(/^\ufeff/, "")
                  .replace(/\r?\n/g, "")
                  .trim() != ""
              ) {
                console.log("データはからではありません");
                isCsvDataBlank = true;
                break;
              }
            }
          } else {
            isCsvDataBlank = true;
          }

          console.log(isCsvDataBlank);

          if (isCsvDataBlank == true) {
            //csvをアップロードする前の事前チェック
            var advancedCheckVal = _this.advancedCheck(csvData);

            if (advancedCheckVal == true) {
              //ヘッダチェックの真偽値
              var headerCheck = _this.headerCheck(csvData);
              if (headerCheck == true) {
                if (csvData[0].length == _this.headerTotalNumberTikQFormat) {
                  _this.csvFormat = "TikQ";
                } else {
                  _this.csvFormat = "VIVEW";
                }
                _this.formatCheck(csvData);
              }
            } else {
              /*
              //-1はヘッダ部分を差し引いている
              var uploadDataLen = csvData.length - 1;
              var dataPlan = _this.clientPlan;
              _this.errorManagement("0", uploadDataLen - dataPlan * 2, 14);
              */
              //-1はヘッダ部分を差し引いている
              var uploadDataLen = csvData.length - 1;
              var dataPlan = _this.clientPlan;
              for (var i = 0; i < uploadDataLen - 100; i++) {
                _this.errorManagement("0", uploadDataLen - dataPlan * 2, 14);
              }
            }

            //エラーがなかった場合
            if (_this.$store.state.errorPartList.length == 0) {
              console.log("ダウンロード開始");
              _this.userUploadMainFunc(csvData, _this.folderId);
            } else {
              //エラーがあった場合
              if (advancedCheckVal == true) {
                _this.blankRowAdd();
                //事前チェックには引っ掛からなかった場合
                _this.dataCompensate1();
                _this.boxColorChange1(false);
                _this.$store.commit("changeUploadedMessage", false);
                _this.createCSVDataTable1(csvData);

                //ローディングアイコンを非表示
                _this.$store.commit("manageUploadIcon", false);
              } else {
                //事前チェックに引っかかった場合
                _this.dataCompensate1();
                _this.boxColorChange1(false);
                _this.$store.commit("changeUploadedMessage", false);
                _this.createCSVDataTable1(csvData);
                //ローディングアイコンを非表示
                _this.$store.commit("manageUploadIcon", false);
              }
            }
          } else {
            _this.errorManagement(9999, 9999, 22);
            console.log("入りました！！");

            //ローディングアイコンを非表示
            _this.$store.commit("manageUploadIcon", false);
            _this.$store.commit("changeUploadedMessage", false);
          }
        }
        //inputで読み取った値を削除する
        document.getElementById("csv_file").value = "";
      }
    },
    /**
     * 説明
     * csvの事前チェック（アップロードする桁数が100件を超えていないかチェックする）
     */
    advancedCheck: function () {
      const csvData = this.$store.state.csvData;
      var dataMax = 100;

      //-1はヘッダ部分を差し引いている
      var uploadDataLen = csvData.length - 1;

      //csvのデータ行数が契約プラン×２までにおさまっているか
      var isCheckTopic = dataMax >= uploadDataLen;
      console.log("100件を超えているか" + isCheckTopic);

      if (isCheckTopic) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 選択されたcsvのデータを取得する
     * @param {*} csvFile csvのファイル
     * @returns 加工されたcsvのデータ
     */
    csvDownload: async function (csvFile) {
      const csvData = this.$store.state.csvData;
      // 改行で区切って配列化
      let fileResult = csvFile.split("\n");

      for (var i = 0; i < fileResult.length; i++) {
        var datas = fileResult[i].split(",");
        this.$store.commit("addCsvData", datas);
      }

      //コードとcsvファイルの関係上、ラストの空行もデータに入れてしまう可能生があるのでそれをデータから消す
      if (
        csvData[csvData.length - 1].length == 1 &&
        csvData[csvData.length - 1][0] == ""
      ) {
        this.$store.commit("deleteCsvData");
      }
    },
    /**
     * 説明
     * csvファイルを読み込む
     * @param {*} file 選択されたcsvのファイル
     */
    readFile: function (file) {
      var unicodeString;
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          // 8ビット符号なし整数値配列と見なす
          var array = new Uint8Array(fileReader.result);
          // 文字コードを取得
          switch (Encoding.detect(array)) {
            case "SJIS":
              unicodeString = Encoding.convert(array, {
                to: "UNICODE",
                from: "SJIS",
                type: "string", // 文字列で返るよう 'string' を指定
              });
              resolve(unicodeString);
              break;
            case "UTF8":
              unicodeString = Encoding.convert(array, {
                to: "UNICODE",
                from: "UTF8",
                type: "string", // 文字列で返るよう 'string' を指定
              });
              resolve(unicodeString);
              break;
          }
        };
        fileReader.onerror = reject;
        //ファイル読み込み
        //  fileReader.readAsText(file, "shift-jis");
        fileReader.readAsArrayBuffer(file);
      });
    },
    /**
     * 説明
     * エラー箇所をエクセル表形式で表示するモーダルを表示する
     */
    errorExcelDisplay: function () {
      //エラーエクセル表モーダルを表示するパラメータ
      const check = "display";
      this.tikQModalManage(check);
    },
    /**
     * 説明
     * csvファイルのテンプレートをダウンロードする
     */
    csvTemplateDownload: function () {
      var csv =
        "\ufeff" +
        "MUID,ユーザID,フォロワー,総いいね,動画数,反応率,投稿動画,再生数,アクション,反応率,投稿日,投稿数,当選,メモ,タイトル,コメント,CTA遷移先,区分\n";
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "tikq_template.csv";
      link.click();
    },
    /**
     * csvのヘッダチェックを行う
     * @param {*} csvData csvのデータ
     */
    headerCheck: function (csvData) {
      //ヘッダのトータル数が仕様と一致しているかチェック
      var headerTotalCheck = this.headerTotalNumCheck(csvData);

      if (headerTotalCheck == true) {
        //ヘッダーの位置が正しいかチェック
        var hederLocationCheck = this.headerLocationCheck(csvData);
        if (headerTotalCheck == true && hederLocationCheck == true) {
          return true;
        } else {
          return false;
        }
      }
    },
    /**
     * csvのヘッダの場所をチェック
     */
    headerLocationCheck: function (csvData) {
      //エラー箇所がなければtrueを返す
      var isHeaderLocationErrorJadge = true;
      console.log("読み込み時CSVのヘッダ名");
      for (var i = 0; i < csvData[0].length; i++) {
        console.log(csvData[0][i].replace(/^\ufeff/, ""));
        console.log(
          "文字列の長さは" +
            csvData[0][i]
              .replace(/^\ufeff/, "")
              .replace(/\r?\n/g, "")
              .trim().length
        );
        console.log(this.headerData[i]);
        console.log(this.headerData[i].length);
        //ヘッダの数だけヘッダをチェック
        if (
          csvData[0][i]
            .replace(/^\ufeff/, "")
            .replace(/\r?\n/g, "")
            .trim().length == this.headerData[i].length
        ) {
          //文字数が一致しているかチェック
          for (
            var t = 0;
            t <
            csvData[0][i]
              .replace(/^\ufeff/, "")
              .replace(/\r?\n/g, "")
              .trim().length;
            t++
          ) {
            //文字コードが一致しているか解析
            if (
              csvData[0][i]
                .replace(/^\ufeff/, "")
                .replace(/\r?\n/g, "")
                .trim()
                .charCodeAt(t) != this.headerData[i].charCodeAt(t)
            ) {
              this.errorManagement(i + 1, "1", "4");
              isHeaderLocationErrorJadge = false;
            }
          }
        } else {
          this.errorManagement(i + 1, "1", "4");
          isHeaderLocationErrorJadge = false;
        }
      }
      return isHeaderLocationErrorJadge;
    },
    /**
     * 説明
     * csvのヘッダの数をチェック
     * @param csvData csvのデータ
     * @return csvのヘッダが正しいかどうかの真偽値
     */
    headerTotalNumCheck: function (csvData) {
      //csvのTikQFormatのヘッダの長さ
      var csvFileTikQDataLen = this.headerTotalNumberTikQFormat;

      //csvTemplateファイルのヘッダの長さ
      var csvFileTempleateDataLen = this.headerTotalNumberVIVEWFormat;

      if (
        csvData[0].length == csvFileTikQDataLen ||
        csvData[0].length == csvFileTempleateDataLen
      ) {
        return true;
      } else {
        this.errorManagement("全列", 0, "20");
        return false;
      }
    },
    /**
     * 説明
     * csvデータのフォーマットをチェックする機能
     * @param csvData csvのデータが格納されている
     * @return なし
     */
    formatCheck: function (csvData) {
      //csvDataの中身が入っているかのチェック
      var isCsvExist = this.csvDataExist(csvData);

      if (isCsvExist == true) {
        //csv2行目のユーザIDが登録されているかチェック
        this.firstLineUserIdCheck(csvData);

        //データの個数チェック
        this.dataTotalCheck(csvData);

        //必須項目チェック
        this.requiredItemCheck(csvData);

        //投稿動画のURLチェック
        this.postMovieUrlCheck(csvData);

        //投稿日フォーマットのチェック
        this.dateFormatCheck(csvData);

        //csvフォーマットがVIVEWの場合
        if (this.csvFormat == "VIVEW") {
          //タイトルの文字数チェック
          this.titleCheck(csvData);

          //コメントの文字数チェック
          this.commentCheck(csvData);

          //cta遷移先のURLチェック
          this.ctaUrlCheck(csvData);
        }

        //区分のチェック
        // this.divisonCheck(csvData);
      }
    },
    /**
     * 説明
     * csvの二列目のユーザIDが記載されているかチェック
     * @param csvData csvのデータ
     */
    firstLineUserIdCheck: function (csvData) {
      if (csvData[1][1] != "") {
        return true;
      } else {
        this.errorManagement("2", "2", "13");
      }
    },
    /**
     * 説明
     * csvの中身があるかチェック
     * @param csvData csvのデータ
     * @return csvのデータがあるかどうかの真偽値
     */
    csvDataExist: function (csvData) {
      if (csvData.length == 1) {
        this.errorManagement("全列", 1, "12");
        return false;
      } else {
        return true;
      }
    },
    /**
     * 説明
     * csvのデータのURLのフォーマットチェックをおこなう
     * @param url URL
     * @return URLが正しいフォーマットだったかの真偽値
     */
    urlFormatCheck: function (url) {
      try {
        new URL(url);
        return true;
      } catch (err) {
        return false;
      }
    },
    /**
     * 説明
     * 必須項目が記載されているかどうかのチェックが行われる
     * @param csvData csvのデータ
     */
    requiredItemCheck: function (csvData) {
      for (var i = 0; i < csvData.length - 1; i++) {
        if (csvData[i + 1].length > this.postedDateLine) {
          if (csvData[i + 1][this.postedDateLine] == "") {
            this.errorManagement(this.postedDateLine + 1, i + 2, 11);
          }
        }
        if (csvData[i + 1].length > this.postMovieUrl) {
          if (csvData[i + 1][this.postMovieUrl] == "") {
            this.errorManagement(this.postMovieUrl + 1, i + 2, 11);
          }
        }
      }
    },
    /**
     * 説明
     * csvデータの項目数をチェックする（tikQのcsv形式かvivewのcsv形式か判断するため）
     * @param csvData csvのデータ
     */
    dataTotalCheck: function (csvData) {
      if (this.csvFormat == "TikQ") {
        this.csvformatInterFaceDataTotalCheck(
          csvData,
          this.headerTotalNumberTikQFormat
        );
      } else if (this.csvFormat == "VIVEW") {
        this.csvformatInterFaceDataTotalCheck(
          csvData,
          this.headerTotalNumberVIVEWFormat
        );
      }
    },
    /**
     * 説明
     * データの項目チェック
     * @param csvData csvのデータ
     */
    csvformatInterFaceDataTotalCheck: function (csvData, headerTotalLen) {
      for (var i = 0; i < csvData.length - 1; i++) {
        //データの数がヘッダよりも少なかったとき
        if (csvData[i + 1].length < headerTotalLen) {
          //どれだけデータが少ないかの差分
          var difference = headerTotalLen - csvData[i + 1].length;

          for (var t = 0; t < difference; t++) {
            this.errorManagement(csvData[i + 1].length + t + 1, i + 2, 5);
          }
          //データの数がヘッダよりも多かったとき
        } else if (csvData[i + 1].length > headerTotalLen) {
          //どれだけデータが多いのかの差分
          var laegeDifference = csvData[i + 1].length - headerTotalLen;

          for (var t = 0; t < laegeDifference; t++) {
            this.errorManagement(headerTotalLen + t + 1, i + 2, 6);
          }
        }
      }
    },
    /**
     * 説明
     * 投稿日のフォーマットチェック
     * @param csvData csvのデータ
     */
    dateFormatCheck: function (csvData) {
      for (var i = 0; i < csvData.length - 1; i++) {
        if (csvData[i + 1].length > this.postedDateLine) {
          //投稿日を取得
          var postedData = csvData[i + 1][this.postedDateLine];

          //形式に沿っているか確認
          var jadge = postedData.match(/\d{4}\/\d{2}\/\d{2}/); //スラッシュ
          var testJadge = postedData.match(/\d{4}\/\d{1,2}\/\d{1,2}/); //0埋めされていない場合（月と日ともに）
          var testJadge2 = postedData.match(/\d{4}\/\d{1,2}\/\d{1,2}/); //0埋めされていない場合（日のみ）
          var testJadge3 = postedData.match(/\d{4}\/\d{1,2}\/\d{1,2}/); //0埋めされていない場合（月のみ）
          txt = null;

          var txt;
          if (testJadge != null) {
            const date = new Date(testJadge[0]);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            txt = `${date.getFullYear()}/${month}/${day}`;
          } else if (testJadge2 != null) {
            const date = new Date(testJadge2[0]);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            txt = `${date.getFullYear()}/${month}/${day}`;
          } else if (testJadge3 != null) {
            const date = new Date(testJadge3[0]);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            txt = `${date.getFullYear()}/${month}/${day}`;
          }

          if (jadge == null) {
            if (txt != null) {
              //スラッシュで区切られている/0埋めした場合
              const sendData = {
                data: i + 1,
                replacePoint: this.postedDateLine,
                replaceNum: 1,
                replace: txt,
              };
              this.$store.commit("dateFormatChange", sendData);
            } else {
              //形式に沿っていない場合
              this.errorManagement(this.postedDateLine + 1, i + 2, 7);
            }
          } else if (jadge != null) {
            //スラッシュで区切られている/0埋めしていない場合
            const sendData = {
              data: i + 1,
              replacePoint: this.postedDateLine,
              replaceNum: 1,
              replace: jadge[0],
            };
            this.$store.commit("dateFormatChange", sendData);
          }
        }
      }
    },
    /**
     * 説明
     * タイトルの文字数チェックをおこなう
     * @param csvData csvのデータ
     * @return なし
     */
    titleCheck: function (csvData) {
      if (this.csvFormat == "VIVEW") {
        for (var i = 0; i < csvData.length - 1; i++) {
          if (csvData[i + 1].length > this.titleLine) {
            if (csvData[i + 1][this.titleLine].length > 20) {
              this.errorManagement(this.titleLine + 1, i + 2, 10);
            }
          }
        }
      }
    },
    /**
     * 説明
     * コメントの文字数チェックをおこなう
     * @param csvData
     */
    commentCheck: function (csvData) {
      if (this.csvFormat == "VIVEW") {
        for (var i = 0; i < csvData.length - 1; i++) {
          if (csvData[i + 1].length > this.commentLine) {
            if (csvData[i + 1][this.commentLine].length > 150) {
              this.errorManagement(this.commentLine + 1, i + 2, 9);
            }
          }
        }
      }
    },
    /**
     * 投稿動画のURLの形式をチェックする
     */
    postMovieUrlCheck: function (csvData) {
      for (var i = 0; i < csvData.length - 1; i++) {
        var url = csvData[i + 1][this.postMovieUrl];
        var isUrlCheck = this.urlFormatCheck(url);
        if (isUrlCheck != true) {
          console.log("URL形式がおかしいです!!");
          this.errorManagement(this.postMovieUrl + 1, i + 2, 8);
        }
      }
    },
    /**
     * 説明
     * cta遷移先のchekku
     * @param csvData csvデータ
     * @return なし
     */
    ctaUrlCheck: function (csvData) {
      if (this.csvFormat == "VIVEW") {
        for (var i = 0; i < csvData.length - 1; i++) {
          //cta遷移先の項目データが存在するか
          if (csvData[i + 1].length > this.ctaLinkLine) {
            //cta遷移先が空白の場合は処理をしない
            if (csvData[i + 1][this.ctaLinkLine] != "") {
              var urlCheck = this.urlFormatCheck(
                csvData[i + 1][this.ctaLinkLine]
              );
              if (urlCheck != true) {
                this.errorManagement(this.ctaLinkLine + 1, i + 2, 8);
              }
            }
          }
        }
      }
    },
    waitLoading: function () {
      document.getElementById("retry-loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    stopLoading: function () {
      document.getElementById("retry-loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
.tikQ {
  padding: 2.5rem 3rem;
}
@media (max-width: 1259px) {
  .tikQ {
    padding: 2.5rem 1rem;
  }
}
/*************動画一括アップロード文字のブロック**************** */
.wrap {
  display: flex;
  width: 700px;
  margin-top: 100px;
}

.subject {
  width: 700px;
  letter-spacing: 0px;
  color: #4a4a4a;
  font-size: 16px;
  font-family: "Noto Sans JP", sans-serif;
}
.wrap-page-explain {
  margin-top: 12px;
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #a1a1a1;
  opacity: 1;
}
/**********************SNS・クラウド動画文字のブロック**********************************/

.wrap-sns-cloud-movie {
  display: flex;
  margin-top: 56px;
  width: 2000px;
}
.sns-cloud-movie-subject {
  font: normal normal bold 14px/15px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
}
.wrap-sns-cloud-movie-subject {
  width: 130px;
}
.csv-upload-text {
  font-size: 10px;
  letter-spacing: 0px;
  color: #4a4a4a;
}

.wrap-question-mark {
  border-radius: 100%;
  background-color: #c1c1c1;
  width: 16px;
  height: 16px;
}

/**ホバー時に説明を表示する */
.temple-download-description {
  display: none;
  position: relative;
  padding: 10px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 1.6em;
  color: black;
  width: 460px;
  height: 180px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  opacity: 1;
}

/**ホバー時に表示する吹き出しの矢印部分 */
/*
.temple-download-description:before, .temple-download-description:after {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  left: 40px;
  top: 140px;
  width: 0;
  height: 0;
  border-top: 90px solid #f3f3f3;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  
  transform: rotate(55deg);
}
*/

.temple-download-description::before {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  left: 50px;
  top: 177px;
  width: 0;
  height: 0;
  border-top: 40px solid #d5d5d5;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  transform-origin: left top;
  transform: skewX(-53deg);
}

.temple-download-description::after {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  left: 50px;
  top: 177px;
  width: 0;
  height: 0;
  border-top: 35px solid #f3f3f3;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  transform-origin: left top;
  transform: skewX(-53deg);
}

.temple-download-description:hover {
  display: inline-block;
  top: -230px;
  left: 0px;
}

.wrap-question-mark:hover + .temple-download-description {
  display: inline-block;
  top: -230px;
  left: 0px;
}

.hover-explain-subject {
  font: normal normal bold 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 16px;
}

.hover-explain-text-row1 {
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;
}

.hover-explain-text-row2 {
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;
}

.question-text {
  position: relative;
  left: 4.5px;
  bottom: 3px;
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.wrap-question {
  margin-top: 5px;
  margin-left: 7px;
  width: 20px;
  height: 0px;
}

.templeFile {
  width: 260px;
  height: 32px;
  background: #eff0f2 0% 0% no-repeat padding-box;
  border: 0.5px solid #343434;
  border-radius: 4px;
  opacity: 1;
  padding-bottom: 10px;
  margin-left: 50px;
}

.download-button {
  position: relative;
  top: 1px;
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
  opacity: 1;
  display: inline-block;
  margin-left: 25px;
  font-size: 12px;
  outline: none;
}

.download-icon {
  position: relative;
  left: 10px;
  top: 4px;
  opacity: 1;
  display: inline-block;
  width: 6px;
  font-size: 15px; /* サイズの指定 */
}

/************************TikQ連携文字のブロック****************************** */
.wrap-tikq-alignment-subject {
  width: 80px;
}
.wrap-tikq-alignment {
  display: flex;
  margin-top: 56px;
  width: 2000px;
}
.tikq-alignment-subject {
  font: normal normal bold 14px/15px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
}

.wrap-question-tkq {
  margin-top: 5px;
  height: 0px;
  width: 78px;
}

.templeFile-tkq {
  width: 260px;
  height: 32px;
  background: #eff0f2 0% 0% no-repeat padding-box;
  border: 0.5px solid #343434;
  border-radius: 4px;
  opacity: 1;
  padding-bottom: 10px;
  margin-left: 50px;
}

.download-button-tkq {
  position: relative;
  top: 1px;
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
  opacity: 1;
  display: inline-block;
  margin-left: 25px;
  font-size: 12px;
  outline: none;
}

.download-icon-tkq {
  position: relative;
  left: 10px;
  top: 4px;
  opacity: 1;
  display: inline-block;
  width: 6px;
  font-size: 15px; /* サイズの指定 */
}

.wrap-question-mark-tkq {
  border-radius: 100%;
  background-color: #c1c1c1;
  width: 16px;
  height: 16px;
}

/**ホバー時に説明を表示する */
.tkq-alignment-download-description {
  display: none;
  position: relative;
  padding: 10px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 1.6em;
  color: black;
  width: 460px;
  height: 180px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  opacity: 1;
}

/**ホバー時に表示する吹き出しの矢印部分 */
/*
.temple-download-description:before, .temple-download-description:after {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  left: 40px;
  top: 140px;
  width: 0;
  height: 0;
  border-top: 90px solid #f3f3f3;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  
  transform: rotate(55deg);
}
*/

.tkq-alignment-download-description::before {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  left: 50px;
  top: 177px;
  width: 0;
  height: 0;
  border-top: 40px solid #d5d5d5;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  transform-origin: left top;
  transform: skewX(-53deg);
}

.tkq-alignment-download-description::after {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  left: 50px;
  top: 177px;
  width: 0;
  height: 0;
  border-top: 35px solid #f3f3f3;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  transform-origin: left top;
  transform: skewX(-53deg);
}

.tkq-alignment-download-description:hover {
  display: inline-block;
  top: -230px;
  left: 0px;
}

.wrap-question-mark-tkq:hover + .tkq-alignment-download-description {
  display: inline-block;
  top: -230px;
  left: 0px;
}

.tkq-alignment-hover-explain-subject {
  font: normal normal bold 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 16px;
}

.tkq-alignment-hover-explain-text-row1 {
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;
}

.tkq-alignment-hover-explain-text-row2 {
  font: normal normal normal 12px/18px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;
}

/*****************csvファイルを選択するブロック******************* */
.wrap-select-file-block {
  width: 620px;
  height: 24px;
  margin-top: 0px;
  margin-left: 10px;
  display: flex;
}

.extension-alert-wrapper {
  width: 620px;
  height: 24px;
  margin-top: 0px;
  margin-left: 390px;
  display: none;
}

.extension-alert-text {
  color: red;
  font-size: 10px;
}

.select-file-input {
  width: 200px;
  background-color: #d66010;
}

.select-file {
  display: none;
}

.selected-file-name {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 12px;
}

.selected-file-label {
  margin-left: 200px;
  width: 500px;
  height: 24px;
  background: #e0e1e4 0% 0% no-repeat padding-box;
  padding-top: 2px;
  padding-left: 5px;
  border: 0.5px solid #343434;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  font-size: 12px;
}

.selected-file-text {
  color: #a1a1a1;
  margin-left: 0px;
  height: 24px;
  width: 1500px;
  border: 0.5px solid #343434;
  border-left: none;
  border-radius: 0px 4px 0px 0px;
  opacity: 1;
  overflow: hidden;
}

/**アップロード中のファイル選択ボタンの非活性 */
.selected-file-text-uploading {
  color: #a1a1a1;
  margin-left: 0px;
  height: 24px;
  width: 1500px;
  border: 0.5px solid #b9bcc4;
  border-left: none;
  border-radius: 0px 4px 0px 0px;
  opacity: 1;
  overflow: hidden;
}
.selected-file-label-uploading {
  margin-left: 200px;
  width: 500px;
  height: 24px;
  background: #e0e1e4 0% 0% no-repeat padding-box;
  padding-top: 2px;
  padding-left: 5px;
  border: 0.5px solid #b9bcc4;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  font-size: 12px;
  color: #b9bcc4;
  background-color: #e6e6e6;
}
/********************************************** */

/*******************アップロードボタンのブロック*************************** */
.wrap-upload-button {
  width: 600px;
  height: 70px;
  margin-top: 24px;
}

.upload-button {
  width: 257px;
  height: 32px;
  position: relative;
  top: 0px;
  font: normal normal medium 14px/12px Noto Sans CJK JP;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  display: inline-block;
  margin-left: 195px;
  padding-left: 20px;
}

.upload-icon {
  transform: rotate(0.75turn);
  position: relative;
  left: 225px;
  top: 2px;
  opacity: 1;
  display: inline-block;
  z-index: 200;
  font-size: 15px; /* サイズの指定 */
}

/********************************************************** */

/*********************************************************** */
.wrap-message {
  width: 422px;
  height: 56px;
  display: flex;
  margin-top: 48px;
  margin-left: 210px;
  margin-bottom: 10px;
}

.success-message-box {
  width: 422px;
  height: 55px;
  display: flex;
}

.error-mark-icon {
  position: relative;
  width: 24px; /* (2√3)*h/3 */
  height: 21px; /* √3/2*w */
  border-bottom: 25px solid #cc474a; /* √3/2*w */
  border-right: 13px solid transparent; /* (2√3)*height/2/3 */
  border-left: 13px solid transparent; /* (2√3)*height/2/3 */
  box-sizing: border-box;
  margin-top: 8px;
  margin-left: 18px;
}

.error-mark {
  position: absolute;
  display: inline-block;
  left: -7.5px;
  top: 7px;
  margin-right: 10px;
  color: white;
  font-size: 15px;
}

.success-mark {
  color: white;
}

.error-message-box {
  width: 422px;
  height: 55px;
  padding-top: 6px;
  padding-right: 5px;
}

.error-message-text {
  margin-top: 15px;
  width: 222px;
  height: 35px;
  scrollbar-width: none; /*Firefox対応のスクロールバー非表示コード*/
  -ms-overflow-style: none; /*Internet Explore対応のスクロールバー非表示コード*/
  overflow-y: scroll;
}

.error-message-text::-webkit-scrollbar {
  display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
}

.error-message-box-inner {
  display: flex;
}

.error-button-box {
  position: relative;
  width: 124px;
  height: 23px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #c1c1c1;
  opacity: 1;
  margin-left: auto;
  margin-right: 12px;
  margin-top: 12px;
  padding-left: 12px;
}

.error-button {
  position: absolute;
  top: 18%;
  font: normal normal normal 9px/13px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4a4a4a;
  opacity: 1;
  outline: none;
}

.error-message {
  margin-left: 16px;
  font: normal normal 10px/14px Noto Sans CJK JP;
  margin-top: -8px;
  color: #333333;
  opacity: 1;
}

.checkmark-icon {
  width: 24px;
  height: 24px;
  background: #038f93 0% 0% no-repeat padding-box;
  border-radius: 100%;
  margin-top: 17px;
  margin-left: 13px;
}

.success-message {
  margin-left: 16px;
  font: normal normal 10px/14px Noto Sans CJK JP;
}

/**ローディングアイコン */
#upload-loading-js {
  width: 30px;
  margin-left: 210px;
}

.sucessColor {
  background-color: #ccffff;
}
.failedColor {
  background-color: #f9e9eb;
}
</style>