import { sessionName } from "./sessionNameGetter";
export const sessionGetter = {
    mixins: [sessionName],
    data: function () {
        return {
            vuexStore: this.$store
        }
    },
    methods: {
        /**
    * 動画ダウンロードが成功したかどうかの判定値のセッションを返す
    */
        getterSessionMovieDownloadSuccess: function () {
            return sessionStorage.getItem(this.sessionMovieDownloadSuccess);
        },
        /**
         * 画像ダウンロードが成功したかどうかの判定値をセッションに登録
         */
        getterSessionImageDownloadSuccess: function () {
            return sessionStorage.getItem(this.sessionImageDownloadSuccess);
        },
        /**
         * 動画のアップロードが成功したかどうかの判定値をセッションに登録
         */
        getterSessionMovieUploadSuccess: function () {
            return sessionStorage.getItem(this.sessionMovieUploadSuccess);
        },
        /**
        * 画像のアップロードが成功したかどうかの判定値をセッションに登録
        */
        getterSessionImageUplaodSuccess: function () {
            return sessionStorage.getItem(this.sessionMovieUploadSuccess);
        },
        /**
        * Firebase Storageに保存された画像の名前をセッションに登録
        */
        getterSessionUploadImageName: function () {
            return sessionStorage.getItem(this.sessionUploadImageName);
        },
        /**
        * Firebase Storageに保存された画像の保存先urlをセッションに登録
        */
        getterSessionUploadImageUrl: function () {
            return sessionStorage.getItem(this.sessionUploadImageUrl);
        },
        /**
        * アップロードしたvimeoの動画urlをセッションに登録
        */
        getterSessionVimeoVideoUrl: function () {
            return sessionStorage.getItem(this.sessionVimeoVideoUrl);
        },
        /**
        * 動画のハッシュ値をセッションに登録
        */
        getterSessionVideoHash: function () {
            return sessionStorage.getItem(this.sessionVideoHash);
        },
        /**
        * viemoの動画にホワイトリストを登録したかどうかの判定値をセッションに登録
        */
        getterSessionVimeoWhiteListRegisterSuccess: function () {
            return sessionStorage.getItem(this.sessionVimeoWhiteListRegisterSuccess);
        },
        /**
        * vimeoの動画のフォルダ移動を行なったかの判定値をセッションに登録
        */
        getterSessionReserveVimeoFolderSuccess: function () {
            return sessionStorage.getItem(this.sessionReserveVimeoFolderSuccess);
        },
        /**
        * ユーザー登録が成功したかどうかの判定値をセッションに登録
        */
        getterSessionUserRegisterSuccess: function () {
            return sessionStorage.getItem(this.sessionUserRegisterSuccess);
        },
        /**
        * 画面上のDB使用量を更新したかどうかの判定値をセッションに登録
        */
        getterSessionScreenChangeDBSuccess: function () {
            return sessionStorage.getItem(this.sessionScreenChangeDBSuccess);
        },
        /**
        * Firebase StorageのDB使用量を更新したかどうかの判定値をセッションに登録
        */
        getterSessionStorageDBChangeSuccess: function () {
            return sessionStorage.getItem(this.sessionStorageDBChangeSuccess);
        },
        /**
         * Firebas StorageのクライアントDB使用量のセッションを削除
         * @returns 
         */
        getterSessionClientStorageDBChangeSucess: function () {
            return sessionStorage.getItem(this.sessionClientStorageDBChangeSuccess);
        },
        /**
         * Firebase StorageのプロジェクトDB使用量のセッションを削除
         * @returns 
         */
        getterSessionProjectStorageDBChangeSucess: function () {
            return sessionStorage.getItem(this.sessionClientStorageDBChangeSuccess);
        },
        /**
        * 画面上の使用本数を更新したかどうかの判定値をセッションに登録
        */
        getterSessionScreenUsedMovieNumberSuccess: function () {
            return sessionStorage.getItem(this.sessionScreenUsedMovieNumberSuccess);
        },
        /**
        * Firebase StorageのDB使用量を更新したかどうかの判定値をセッションに登録
        */
        getterSessionStorageUsedMovieNumberSuccess: function () {
            return sessionStorage.getItem(this.sessionStorageUsedMovieNumberSuccess);
        },
        /**
        * ビデオデータをセッションに登録
        */
        getterSessionVideoData: function () {
            return sessionStorage.getItem(this.sessionVideoData);
        },
        /**
        * 画像データをセッションに登録
        */
        getterSessionImageData: function () {
            return sessionStorage.getItem(this.sessionImageData);
        },
        /**
        * 動画アップロード数をセッションに登録
        */
        getterSessionUploadCount: function () {
            return sessionStorage.getItem(this.sessionUploadCount);
        },
        /**
         * クライアントIDをセッションに登録
         */
        getterSessionClientId: function () {
            return sessionStorage.getItem(this.sessionClientId);
        },
        /**
         * プロジェクトIDをセッションに登録
         */
        getterSessionProjectId: function () {
            return sessionStorage.getItem(this.sessionProjectId);
        },
        /**
         * ユーザーファイル名をセッションに登録
         */
        getterSessionUserFileName: function () {
            return sessionStorage.getItem(this.sessionUserFileName);
        },
        /**
         * 動画容量をセッションに登録
         */
        getterSessionVideoDB: function () {
            return sessionStorage.getItem(this.sessionVideoDB);
        },
        /**
         * アップロードが全て完了したかどうかをセッションに登録
         */
        getterSessionAllUploadClear: function () {
            return sessionStorage.getItem(this.sessionAllUploadClear);
        },
    }
}