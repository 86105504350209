<template>
  <v-navigation-drawer width="200" app permanent>
    <v-list v-if="tikq === 'あり'" class="ml-3 menuTop" id="menuTop">
      <v-list-item v-for="(menu, index) in tikQMenuList" :key="index">
        <router-link
          :id="menu.name"
          class="routerlink"
          :to="menu.pageTransition"
          :name="menu.name"
          @click.native.prevent="alertDisplay(menu.name)"
          event=""
        >
          {{ menu.name }}
        </router-link>
      </v-list-item>
      <router-view />
    </v-list>
    <v-list v-if="tikq === 'なし'" class="ml-3 menuTop" id="menuTop">
      <v-list-item v-for="(menu, index) in menuList" :key="index">
        <router-link
          :id="menu.name"
          class="routerlink"
          :to="menu.pageTransition"
          :name="menu.name"
          @click.native.prevent="alertDisplay(menu.name)"
          event=""
        >
          {{ menu.name }}
        </router-link>
      </v-list-item>
      <router-view />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Menu",
  mounted() {

    var currentPass = this.$router.currentRoute.path;
    if (currentPass == "/projectManagiment") {
      document.getElementsByName("プロジェクト一覧")[0].style.fontWeight =
        "bold";
    } else if (currentPass == "/projectInfo") {
      document.getElementsByName("プロジェクト詳細")[0].style.fontWeight =
        "bold";
    } else if (currentPass == "/approvalSetting") {
      document.getElementsByName("承認設定")[0].style.fontWeight = "bold";
    } else if (currentPass == "/publishingSetting") {
      document.getElementsByName("公開設定")[0].style.fontWeight = "bold";
    } else if (currentPass == "/tikQAlignment") {
      document.getElementsByName("動画一括アップロード")[0].style.fontWeight = "bold";
    }
  },
  data: function () {
    return {
      drawer: null,
      //tikQと連携しているかの値
      tikq: this.$store.state.clientInfo[0].tikQ_alignment,
      menuList: [
        { pageTransition: "projectManagiment", name: "プロジェクト一覧" },
        { pageTransition: "projectInfo", name: "プロジェクト詳細" },
        { pageTransition: "approvalSetting", name: "承認設定" },
        { pageTransition: "publishingSetting", name: "公開設定" },
      ],
      tikQMenuList: [
        { pageTransition: "projectManagiment", name: "プロジェクト一覧" },
        { pageTransition: "projectInfo", name: "プロジェクト詳細" },
        { pageTransition: "approvalSetting", name: "承認設定" },
        { pageTransition: "publishingSetting", name: "公開設定" },
        { pageTransition: "tikQAlignment", name: "動画一括アップロード" },
      ],
    };
  },
  methods: {
    alertDisplay: function (route) {
      //新しいパスをセット
      this.$store.commit("setRoute", route);

      //画面遷移する
      this.changeRoute(route);
    },
    changeRoute: function (route) {
      if (route == "プロジェクト一覧") {
        this.$router.push("projectManagiment");
      } else if (route == "プロジェクト詳細") {
        this.$router.push("projectInfo");
      } else if (route == "承認設定") {
        this.$router.push("approvalSetting");
      } else if (route == "公開設定") {
        this.$router.push("publishingSetting");
      } else if (route == "動画一括アップロード") {
        this.$router.push("tikQAlignment");
      }
    },
  },
};
</script>

<style scoped>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300,400;500;700&display=swap");

@media (max-width: 767px) {
  .pcbr {
    display: none;
  }
}

.js-anime {
  opacity: 0;
  transition: 0.7s;
}
.js-anime.is-slide {
  transform: translateY(100px);
}
.js-anime.is-anime {
  opacity: 1;
  transform: translateY(0);
}

.v-application {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
}
.v-application .text-h6 {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background: #eae1d9;
}

.v-navigation-drawer__content {
  padding-top: 2rem;
}

a {
  color: black;
}
.routerlink {
  display: block;
  text-decoration: none;
  color: #4a4a4a;
  font-size: 14px;
}

.menuTop {
  margin-top: 70px;
}
</style>
