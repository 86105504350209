<template>
  <div id="app">
    <div class="errorList-modal" id="js-errorList-modal">
      <div class="dialog-subject-wrap">
        <p class="dialog-subject">エラー詳細の内容</p>
      </div>
      <div class="dialog-text-wrap">
        <p class="copy-text">コピーしました</p>
      </div>
      <div class="dialog-button-wrap">
        <button class="dialog-button" v-on:click="closeButton">OK</button>
      </div>
    </div>
    <router-view />
    <LoadingAlert />
    <div id="fadeLayer"></div>
    <div class="retry-loading">
      <div
        class="spinner-border retry-loading-icon"
        role="status"
        id="retry-loading"
      >
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>
</template>
<script type="module">
import LoadingAlert from "@/components/Loading/UploadVideoLoading.vue";
export default {
  name: "ProjectRewriteComponent",
  components: {
    LoadingAlert,
  },
  methods: {
    closeButton: function () {
      var errorListModalDiv = document.getElementById("js-errorList-modal");
      errorListModalDiv.style.display = "none";
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999;
  display: none;
}

.retry-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
  z-index: 999999999;
  display: none;
}

.retry-loading-icon {
  position: absolute;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.errorList-modal {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  top: 0px;
  background-color: black;
  width: 400px;
  height: 100px;
  z-index: 1;
  display: none;
}
.dialog-subject-wrap {
  width: 400px;
  height: 50px;
  z-index: 1;
}
.dialog-text-wrap {
  width: 400px;
  height: 10px;
  z-index: 1;
}
.dialog-subject {
  color: white;
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 8px;
}
.copy-text {
  color: white;
  z-index: 1;
  position: absolute;
  top: 30px;
  left: 9px;
  font-size: 10px;
}

.dialog-button-wrap {
  width: 400px;
  height: 80px;
  z-index: 1;
}
.dialog-button {
  position: relative;
  left: 320px;
  width: 65px;
  height: 28px;
  z-index: 1;
  font-size: 14px;
  background-color: #8eb8ff;
}
</style>
