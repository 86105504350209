<template>
  <div class="core">
    <slick ref="slick" :options="slickOptions" class="itemlist">
      <div
        v-for="(user, index) in publishedContents"
        :key="index"
        class="itemlist_item"
        :data-target="`#item0${index + 1}`"
        :class="{
          image: user.contents_kinds == '画像',
          movie: user.contents_kinds == '動画',
        }"
      >
        <div v-if="user.contents_kinds == '画像'" class="itemlist_item_wrap">
          <p v-if="user.user_name_status == '1'" class="itemlist_item_name">
            {{ user.user_name }}
          </p>
          <img class="main-img" :src="user.main_image_url" />
          <div class="itemlist_item_body">
            <div
              v-if="user.thumb_status == '1' && user.thumb_image_url != ''"
              class="itemlist_item_thumb"
            >
              <img class="thumb-img" :src="user.thumb_image_url" alt="" />
            </div>
            <div
              v-if="
                user.comment_status == '1' ||
                user.title_status == '1' ||
                (user.thumb_status == '1' && user.thumb_image_url != '')
              "
              class="itemlist_item_txt"
            >
              <!--サムネフラグ＝表示＋サムネURLがある＋タイトルフラグ＝表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url != '' &&
                  user.title_status == '1'
                "
              >
                {{ user.title }}
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝表示+コメントタイトル＝表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '1' &&
                  user.comment_status == '1'
                "
              >
                {{ user.title
                }}<!-- <br />{{ user.comment }}-->
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝表示+コメントタイトル＝非表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '1' &&
                  user.comment_status == '0'
                "
              >
                {{ user.title }}
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝非表示+コメントタイトル＝表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '0' &&
                  user.comment_status == '1'
                "
              >
                <!-- {{ user.comment }}-->
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝非表示+コメントタイトル＝非表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '0' &&
                  user.comment_status == '1'
                "
              ></p>
              <!--サムネフラグ＝非表示＋タイトルフラグ＝表示+コメントフラグ＝表示-->
              <p
                v-if="
                  user.thumb_status == '0' &&
                  user.title_status == '1' &&
                  user.comment_status == '1'
                "
              >
                {{ user.title
                }}<!-- <br />{{ user.comment }}-->
              </p>
              <!--サムネフラグ＝非表示＋タイトルフラグ＝非表示+コメントフラグ＝表示-->
              <p
                v-if="
                  user.thumb_status == '0' &&
                  user.title_status == '0' &&
                  user.comment_status == '1'
                "
              >
                <!-- <br />{{ user.comment }}-->
              </p>
              <!--サムネフラグ＝非表示＋タイトルフラグ＝表示+コメントフラグ＝非表示-->
              <p
                v-if="
                  user.thumb_status == '0' &&
                  user.title_status == '1' &&
                  user.comment_status == '0'
                "
              >
                {{ user.title }}<br />
              </p>
            </div>
          </div>
        </div>
        <div v-if="user.contents_kinds == '動画'" class="itemlist_item_wrap">
          <p v-if="user.user_name_status == 1" class="itemlist_item_name">
            {{ user.user_name }}
          </p>
          <div class="publish-itemlist_item_play">
            <img src="../../assets/play.png" />
          </div>
          <img :src="user.main_thumb_image_url" class="main_thumb" alt="" />
          <div class="itemlist_item_body">
            <div
              v-if="user.thumb_status == '1' && user.thumb_image_url != ''"
              class="itemlist_item_thumb"
            >
              <img class="thumb-img" :src="user.thumb_image_url" alt="" />
            </div>
            <div
              v-if="
                user.comment_status == '1' ||
                user.title_status == '1' ||
                (user.thumb_status == '1' && user.thumb_image_url != '')
              "
              class="itemlist_item_txt"
            >
              <!--サムネフラグ＝表示＋サムネURLがある＋タイトルフラグ＝表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url != '' &&
                  user.title_status == '1'
                "
                class="title-list"
              >
                {{ user.title }}
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝表示+コメントタイトル＝表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '1' &&
                  user.comment_status == '1'
                "
                class="title-list"
              >
                {{ user.title }}
                <!--<br />{{ user.comment }}-->
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝表示+コメントタイトル＝非表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '1' &&
                  user.comment_status == '0'
                "
                class="title-list"
              >
                {{ user.title }}
              </p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝非表示+コメントタイトル＝表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '0' &&
                  user.comment_status == '1'
                "
                class="title-list"
              ></p>
              <!--サムネフラグ＝表示＋サムネURLがない＋タイトルフラグ＝非表示+コメントタイトル＝非表示-->
              <p
                v-if="
                  user.thumb_status == '1' &&
                  user.thumb_image_url == '' &&
                  user.title_status == '0' &&
                  user.comment_status == '1'
                "
                class="title-list"
              ></p>
              <!--サムネフラグ＝非表示＋タイトルフラグ＝表示+コメントフラグ＝表示-->
              <p
                v-if="
                  user.thumb_status == '0' &&
                  user.title_status == '1' &&
                  user.comment_status == '1'
                "
                class="title-list"
              >
                {{ user.title
                }}<!-- <br />{{ user.comment }}-->
              </p>
              <!--サムネフラグ＝非表示＋タイトルフラグ＝非表示+コメントフラグ＝表示-->
              <p
                v-if="
                  user.thumb_status == '0' &&
                  user.title_status == '0' &&
                  user.comment_status == '1'
                "
                class="title-list"
              >
                <!-- <br />{{ user.comment }}-->
              </p>
              <!--サムネフラグ＝非表示＋タイトルフラグ＝表示+コメントフラグ＝非表示-->
              <p
                v-if="
                  user.thumb_status == '0' &&
                  user.title_status == '1' &&
                  user.comment_status == '0'
                "
                class="title-list"
              >
                {{ user.title }}<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </slick>

    <!--------------------------------------------------overray----------------------------------------------------------->
    <div class="overray">
      <div class="overray_wrap">
        <div class="overray_inner">
          <div class="overray_prev">
            <img src="../../assets/arrow_prev_l.png" />
          </div>
          <div class="overray_next">
            <img src="../../assets/arrow_next_l.png" />
          </div>
          <div
            class="overray_item"
            :id="`item0${index + 1}`"
            v-for="(user, index) in publishedContents"
            :key="index"
          >
            <!--動画-->
            <div v-if="user.contents_kinds == '動画'" class="overray_item_wrap">
              <div class="overray_item_time">
                <span
                  id="overray_item_time_bar"
                  class="overray_item_time_bar"
                ></span>
              </div>
              <p v-if="user.user_name_status == '1'" class="overray_item_name">
                {{ user.user_name }}
              </p>
              <div class="overray_item_close">
                <img
                  id="close_icon"
                  src="../../assets/ico_close.png"
                  alt="close"
                />
              </div>
              <div class="overray_item_play">
                <img src="../../assets/play.png" />
              </div>
              <div
                :id="index + 99999"
                class="overray_item_video"
                style="
                  width: 100%;
                  margin: 0%;
                  height: 100%;
                  z-index: 99999999999999999999;
                "
              >
                <iframe
                  class="overray_item_video"
                  :src="
                    'https://player.vimeo.com/video/' +
                    user.video_id +
                    '&amp;controls=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=0&amp;loop=1'
                  "
                  frameborder="0"
                  width="100%"
                  style="
                    margin: 0%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -99999999999999999;
                  "
                  allow="autoplay; fullscreen;"
                  allowfullscreen
                  title="hand1.mp4"
                ></iframe>
              </div>
              <div class="itemlist_item_body_overray">
                <div
                  v-if="user.thumb_status == '1' && user.thumb_image_url != ''"
                  class="overray_itemlist_item_thumb"
                >
                  <img :src="user.thumb_image_url" alt="" />
                </div>
                <div
                  class="overray_itemlist_item_txt"
                  v-if="
                    user.comment_status == '1' ||
                    user.title_status == '1' ||
                    (user.thumb_status == '1' && user.thumb_image_url != '')
                  "
                >
                  <!--タイトルフラグ＝表示+コメントフラグ＝表示-->
                  <div
                    v-if="
                      user.title_status == '1' && user.comment_status == '1'
                    "
                  >
                    <div>
                      <span style="font-weight: bold">{{ user.title }}</span>
                    </div>
                    <div>
                      <span class="comment-text">{{ user.comment }}</span>
                    </div>
                  </div>
                  <!--タイトルフラグ＝表示+コメントタイトル＝非表示-->
                  <div
                    v-if="
                      user.title_status == '1' && user.comment_status == '0'
                    "
                  >
                    <div>
                      <span style="font-weight: bold">{{ user.title }}</span>
                    </div>
                  </div>

                  <!--タイトルフラグ＝非表示+コメントタイトル＝表示-->
                  <div
                    v-if="
                      user.title_status == '0' && user.comment_status == '1'
                    "
                  >
                    <div>
                      <span class="comment-text">{{ user.comment }}</span>
                    </div>
                  </div>
                  <!--タイトルフラグ＝非表示+コメントタイトル＝非表示-->
                  <div
                    v-if="
                      user.title_status == '0' && user.comment_status == '0'
                    "
                  ></div>
                </div>
              </div>
              <div class="overray_item_bottom">
                <a
                  v-if="serviceLogo == '1'"
                  href="https://mindfree.jp/"
                  target="_blank"
                  class="overray_item_bottom_ico"
                  ><img src="../../assets/ico_mf.png" alt="MindFree"
                /></a>
                  <a
                  class="overray_item_bottom_ico"
                  v-if="serviceLogo == '0'"
                ></a>
                <a
                  :href="user.cta"
                  class="overray_item_bottom_btn"
                  v-if="user.cta_status == '1' && user.cta != ''"
                  ><img src="../../assets/btn.png" alt="詳しくはこちら"
                /></a>
              </div>
            </div>
            <!--画像-->
            <div v-if="user.contents_kinds == '画像'" class="overray_item_wrap">
              <p v-if="user.user_name_status == '1'" class="overray_item_name">
                {{ user.user_name }}
              </p>
              <div class="overray_item_close">
                <img
                  id="close_icon"
                  src="../../assets/ico_close.png"
                  alt="close"
                />
              </div>
              <img
                style="z-index: 9999999999999999999"
                :src="user.main_image_url"
                alt=""
              />
              <div class="itemlist_item_body_overray">
                <!--ローディング時にstyle追加-->
                <div
                  v-if="user.thumb_status == '1' && user.thumb_image_url != ''"
                  class="overray_itemlist_item_thumb"
                >
                  <img :src="user.thumb_image_url" alt="" />
                </div>
                <div
                  class="overray_itemlist_item_txt"
                  v-if="
                    (user.thumb_status == '1' && user.thumb_image_url != '') ||
                    user.title_status == '1' ||
                    user.comment_status == '1'
                  "
                >
                  <!--タイトルフラグ＝表示+コメントフラグ＝表示-->
                  <div
                    v-if="
                      user.title_status == '1' && user.comment_status == '1'
                    "
                  >
                    <div>
                      <span style="font-weight: bold">{{ user.title }}</span>
                    </div>
                    <div>
                      <span class="comment-text">{{ user.comment }}</span>
                    </div>
                  </div>
                  <!--タイトルフラグ＝表示+コメントタイトル＝非表示-->
                  <div
                    v-if="
                      user.title_status == '1' && user.comment_status == '0'
                    "
                  >
                    <div>
                      <span style="font-weight: bold">{{ user.title }}</span>
                    </div>
                  </div>

                  <!--タイトルフラグ＝非表示+コメントタイトル＝表示-->
                  <div
                    v-if="
                      user.title_status == '0' && user.comment_status == '1'
                    "
                  >
                    <div>
                      <span class="comment-text">{{ user.comment }}</span>
                    </div>
                  </div>
                  <!--タイトルフラグ＝非表示+コメントタイトル＝非表示-->
                  <div
                    v-if="
                      user.title_status == '0' && user.comment_status == '0'
                    "
                  ></div>
                </div>
              </div>
              <div class="overray_item_bottom">
                <a
                  v-if="serviceLogo == '1'"
                  href="https://mindfree.jp/"
                  target="_blank"
                  class="overray_item_bottom_ico"
                  ><img src="../../assets/ico_mf.png" alt="MindFree"
                /></a>
                  <a
                  class="overray_item_bottom_ico"
                  v-if="serviceLogo == '0'"
                ></a>
                <a
                  :href="user.cta"
                  class="overray_item_bottom_btn"
                  v-if="user.cta_status == '1' && user.cta != ''"
                  ><img src="../../assets/btn.png" alt="詳しくはこちら"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
import Slick from "vue-slick";
export default {
  computed: {
    publishedContents() {
      //公開中コンテンツの配列を渡す
      return this.$store.state.publishedContents;
    },
  },
  components: {
    Slick,
  },
  data() {
    return {
      serviceLogo:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .service_logo,
      slickOptions: {
        centerMode: true,
        centerPadding: "50px",
        arrows: true,
        autoplay: false,
        dots: false,
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 577,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    ///////////////////////////////////
    ///////説明
    ///////デスクトップ用画面に変える
    //
    //
    //引数:なし
    ///////////////////////////////////////////
    changeDesktop() {
      this.slickOptions.slidesToShow = "5";
    },
    ///////////////////////////////////
    ///////説明
    ///////スマートフォン用画面に変える
    //
    //
    //引数:なし
    ///////////////////////////////////////////
    changeMobile() {
      this.slickOptions.slidesToShow = "1";
    },
  },
};
$(function () {
  ///////////////////////////////////////////////////////////////最初のクリックが押された時/////////////////////////////
  $(document).on("click", ".itemlist_item", function () {
    this.overlay = "true";
    var video = $(this).find("video").length;
    var image = $(this).find("img").length;
    if (video == true) {
      $(this).find("video")[0].pause();
      $(".overray").fadeIn(300);
      var Target = $(this).data("target");
      $(Target).addClass("is-current");
      $(this).prev(".overray_item_play").fadeIn(300);
    } else {
      $(".overray").fadeIn(300);
      var Target = $(this).data("target");
      $(Target).addClass("is-current");
    }
  });
  ///////////////////////////////////////////////////////////////閉じるボタンが押された時/////////////////////////////
  $(document).on("click", ".overray_item_close", function () {
    //親ウィンドウを取得
    var parentWin = window.parent.postMessage("test", "*");
    if ($(".overray_item.is-current").find("video").length > 0) {
    }
    $(".overray").fadeOut(300);
    $(".overray_item_play").fadeIn();
    $(".overray_item").removeClass("is-current");
  });
  ///////////////////////////////////////////////////////////////動画再生/////////////////////////////
  $(document).on("click", ".overray_item_play", function () {
    var timeBarIdName = $(this).prev().prev().prev().children("span")[0];
    //var timeBarIdName = $(this).prev().prev().children("span")[0]
    var clickedId = $(this).next("div")[0].id;
    var iframe = document.getElementById(clickedId);
    var player = new Vimeo.Player(iframe);
    //var test = "test";
    $(this).fadeOut(300);
    player.play();
    //$(this).next('video')[0].play();
    player.on("timeupdate", function (data) {
      timeBarIdName.style.width = data.percent * 100 + "%";
      $(this)
        .parents(".overray_item_wrap")
        .find(".overray_item_time_bar")
        .css({ width: data.percent * 100 + "%" });
    });
  });
  ///////////////////////////////////////////////////////////////動画停止/////////////////////////////
  $(document).on("click", ".overray_item_video", function () {
    var clickedId = $(this)[0].id;
    let iframe = document.getElementById(clickedId);
    let player = new Vimeo.Player(iframe);
    player.pause();
    $(this).prev(".overray_item_play").fadeIn(300);
  });
  ///////////////////////////////////////////////////////////////前のコンテンツへ移動/////////////////////////////
  $(document).on("click", ".overray_prev", function () {
    $(this).css("pointer-events", "none");
    setTimeout(function () {
      $(".overray_prev").css("pointer-events", "auto");
    }, 1000);
    if ($(".overray_item.is-current").find("video").length > 0) {
      $(".overray_item.is-current").find("video")[0].pause();
    }
    $(".overray_item_play").each(function (index) {
      $(this).fadeIn(300);
    });
    if ($(".overray_item.is-current").prev(".overray_item").index() == -1) {
      $(".overray_item.is-current").addClass("ltoOut");
      $(".overray_item:last").addClass("ltoIn");
      setTimeout(function () {
        $(".overray_item.is-current").removeClass("is-current");
        $(".overray_item:last").addClass("is-current");
        $(".overray_item.ltoOut").removeClass("ltoOut");
        $(".overray_item.ltoIn").removeClass("ltoIn");
      }, 1000);
    } else {
      $(".overray_item.is-current")
        .addClass("ltoOut")
        .prev(".overray_item")
        .addClass("ltoIn");
      setTimeout(function () {
        $(".overray_item.is-current")
          .removeClass("is-current")
          .prev(".overray_item")
          .addClass("is-current");
        $(".overray_item.ltoOut").removeClass("ltoOut");
        $(".overray_item.ltoIn").removeClass("ltoIn");
      }, 1000);
    }
  });

  ///////////////////////////////////////////////////////////////次のコンテンツへ移動/////////////////////////////
  $(document).on("click", ".overray_next", function () {
    $(this).css("pointer-events", "none");
    setTimeout(function () {
      $(".overray_next").css("pointer-events", "auto");
    }, 1000);
    if ($(".overray_item.is-current").find("video").length > 0) {
      $(".overray_item.is-current").find("video")[0].pause();
    }
    $(".overray_item_play").each(function (index) {
      $(this).fadeIn(300);
    });
    if ($(".overray_item.is-current").next(".overray_item").index() == -1) {
      $(".overray_item.is-current").addClass("rtoOut");
      $(".overray_item:first").addClass("rtoIn");
      setTimeout(function () {
        $(".overray_item.is-current").removeClass("is-current");
        $(".overray_item:first").addClass("is-current");
        $(".overray_item.rtoOut").removeClass("rtoOut");
        $(".overray_item.rtoIn").removeClass("rtoIn");
      }, 1000);
    } else {
      $(".overray_item.is-current")
        .addClass("rtoOut")
        .next(".overray_item")
        .addClass("rtoIn");
      setTimeout(function () {
        $(".overray_item.is-current")
          .removeClass("is-current")
          .next(".overray_item")
          .addClass("is-current");
        $(".overray_item.rtoOut").removeClass("rtoOut");
        $(".overray_item.rtoIn").removeClass("rtoIn");
      }, 1000);
    }
  });
});
</script>

<style scoped>
@import "../../../public/css/slick-theme.css";
@import "../../../public/css/slick.css";
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
}

.main_thumb {
  width: 100% !important;
  height: auto !important;
  text-align: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translateY(-50%) translateX(-50%) !important;
  -webkit-transform: translateY(-50%) translateX(-50%) !important;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.core {
  width: 100%;
  height: 350px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.itemlist_item_body_overray {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 80px;
  width: 100%;
}

.overray_itemlist_item_thumb {
  font-size: 0.75rem;
  /*もともとはこのスタイル
  width: calc(3em + 4.5rem);
  height: calc(3em + 3rem);
  */
  width: calc(4rem + 4.5rem);
  height: calc(3.4rem + 3rem);
}
.overray_itemlist_item_thumb img {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overray_itemlist_item_thumb + .overray_itemlist_item_txt {
  width: calc(100% - 3em + 1rem);
}
.overray_itemlist_item_txt {
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0px;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}

.overray_itemlist_item_txt::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}

.comment-text {
  width: 100%;
  font-weight: normal;
  margin-top: 3px;
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-transform: full-width;
  overflow: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
  height: 65px;
}
.comment-text::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}

.overray_item_txt p {
  height: 5.5em;
  overflow: auto;
  font-weight: bold;
  margin-bottom: 0;
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
}

main {
  width: 100%;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: bottom;
  border-style: none;
  max-width: 100%;
  width: auto;
  height: auto;
  opacity: 1;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.l-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.itemlist {
  z-index: 0;
  width: 100%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -70px;
}
@media (max-width: 991px) {
  .itemlist {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 575px) {
  .itemlist {
    max-width: 286px;
    padding: 0;
  }
}
.itemlist .slick-list {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}
.itemlist .slick-list::after {
  content: "";
  width: 100%;
  height: calc(100% - 4rem);
  position: absolute;
  left: 0;
  top: 2rem;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0) 95%,
    rgba(0, 0, 0, 0.5)
  );
}
.itemlist_item {
  position: relative;
  margin-right: 12px;
  margin-left: 12px;
  padding: 2rem 0;
}
.itemlist_item_wrap {
  width: 100%;
  padding-top: 177.777778%;
  background-color: gainsboro;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  transition: 0.3s;
}
.itemlist_item_wrap:hover {
  box-shadow: 0 10px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
}
.itemlist_item_name {
  color: #fff;
  padding: 0 0.75rem;
  position: absolute;
  left: 0;
  top: 0.75rem;
  font-size: 0.75rem;
  z-index: 1;
}
.itemlist_item_body {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 5%;
  width: 100%;
}
.itemlist_item_thumb {
  font-size: 0.75rem;
  width: calc(3em + 2.53rem);
  height: calc(3em + 1rem);
}
.itemlist_item_thumb img {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itemlist_item_thumb + .itemlist_item_txt {
  width: calc(100% - 3em + 1rem);
}
.itemlist_item_txt {
  position: relative;
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 52px;
}
.itemlist_item_txt p {
  width: 100%;
  position: absolute;
  overflow: hidden;
  margin-left: 10px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.itemlist_item video,
.itemlist_item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}
.itemlist_item img {
  object-fit: cover;
  cursor: initial;
}
.itemlist_item_play {
  position: absolute;
  width: 38px;
  height: 38px;
  left: calc(50% - 19px);
  top: calc(50% - 19px);
  z-index: 1;
}
.itemlist_arrow_prev {
  position: absolute;
  width: 13px;
  height: 32px;
  left: 1rem;
  top: calc(50% - 16px);
  z-index: 100;
  cursor: pointer;
}
.itemlist_arrow_next {
  position: absolute;
  width: 13px;
  height: 32px;
  right: 1rem;
  top: calc(50% - 16px);
  z-index: 100;
  cursor: pointer;
}

.overray {
  width: 100%;
  height: 100%;
  background: #404040;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  z-index: 1;
}
.overray_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}
.overray_inner {
  position: relative;
  width: 408px;
  padding-top: calc(408px * 1.77777778);
  -webkit-perspective: 500px;
  perspective: 500px;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_inner {
    height: 80vh;
    padding-top: 0;
    width: calc(80vh * 0.56824513);
  }
}

@media (max-width: 575px) {
  .overray_inner {
    width: 100%;
    padding-top: 0;
    height: 100%;
  }
}
.overray_prev {
  position: absolute;
  left: -42px;
  top: calc(50% - 24px);
  cursor: pointer;
}
@media (max-width: 575px) {
  .overray_prev {
    display: none;
  }
}
.overray_next {
  position: absolute;
  right: -42px;
  top: calc(50% - 24px);
  cursor: pointer;
}
@media (max-width: 575px) {
  .overray_next {
    display: none;
  }
}
.overray_item {
  display: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.overray_item.is-current {
  display: block;
}
.overray_item.rtoIn {
  display: block;
  z-index: 0;
}
.overray_item.rtoIn .overray_item_wrap {
  transform: rotateY(90deg) translateZ(0px) translateX(100%);
  animation: rtoIn 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.rtoIn .overray_item_wrap {
    animation: rtoIn_short 1s ease-in-out forwards;
  }
}

.overray_item.rtoOut {
  z-index: -1;
}
.overray_item.rtoOut .overray_item_wrap {
  transform: rotateY(0deg) translateZ(0) translateX(0);
  animation: rtoOut 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.rtoOut .overray_item_wrap {
    animation: rtoOut_short 1s ease-in-out forwards;
  }
}

.overray_item.ltoIn {
  display: block;
  z-index: 0;
}
.overray_item.ltoIn .overray_item_wrap {
  transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
  animation: ltoIn 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.ltoIn .overray_item_wrap {
    animation: ltoIn_short 1s ease-in-out forwards;
  }
}

.overray_item.ltoOut {
  z-index: -1;
}
.overray_item.ltoOut .overray_item_wrap {
  transform: rotateY(0deg) translateZ(0) translateX(0);
  animation: ltoOut 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}
@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.ltoOut .overray_item_wrap {
    animation: ltoOut_short 1s ease-in-out forwards;
  }
}

.overray_item_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
}
.overray_item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overray_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 99999999999999999999999999999999;
}
.overray_item_time {
  width: 90%;
  height: 4px;
  border-radius: 10px;
  background: #9b9ea5;
  position: absolute;
  z-index: 999999999999;
  left: 5%;
  top: 0.5rem;
}
.overray_item_time_bar {
  position: absolute;
  width: 0%;
  height: 100%;
  background: #fbfcfe;
  border-radius: 10px;
  left: 0;
  top: 0;
  transition: 0.1s;
}
.overray_item_name {
  z-index: 9999999999999;
  position: absolute;
  font-size: 0.75rem;
  left: 5%;
  top: 1.5rem;
  color: #fff;
}
.overray_item_close {
  position: absolute;
  right: 5%;
  top: 18px;
  cursor: pointer;
  z-index: 999999999999999999999999999999999999;
}
#close_icon {
  filter: drop-shadow(1px 3px 1px rgba(80, 79, 79, 0.4)) contrast(500%)
    saturate(200%) brightness(500%);
}
.overray_item_play {
  position: absolute;
  width: 72px;
  height: 72px;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
  z-index: 10;
  transition: 0.3s;
  transform-origin: center;
  cursor: pointer;
}
.overray_item_play img {
  width: 100%;
}
.overray_item_play:hover {
  transform: scale(1.2);
}
.overray_item_txt {
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0px;
  width: 100%;
  pointer-events: none;
  word-wrap: break-word;
}
.overray_item_txt p {
  height: 5.5em;
  overflow: auto;
  margin-bottom: 0;
}
.overray_item_bottom {
  position: absolute;
  padding: 0 5%;
  bottom: 24px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.overray_item_bottom_btn {
  width: 50%;
  max-width: 136px;
}

@keyframes rtoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(-90deg) translateZ(51px) translateX(-100%);
    z-index: -1;
  }
}
@keyframes rtoIn {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}
@keyframes ltoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(90deg) translateZ(51px) translateX(100%);
    z-index: -1;
  }
}
@keyframes ltoIn {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: 1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}
@keyframes rtoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(-90deg) translateZ(calc(40vh / 8)) translateX(-100%);
    z-index: -1;
  }
}
@keyframes rtoIn_short {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: 1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}
@keyframes ltoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: -1;
  }
  100% {
    transform: rotateY(90deg) translateZ(calc(40vh / 8)) translateX(100%);
    z-index: -1;
  }
}
@keyframes ltoIn_short {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }
  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: 1;
  }
  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

.modal-wrap .btn-save {
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  margin-bottom: 5%;
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 100px;
  transition: 0.3s;
  border-radius: 0;
}

.change-vtn {
  background: linear-gradient(#e18140, #d6600f);
}

.change {
  width: 100px;
  color: #fff;
}
</style>