<template>
  <v-main class="publish-proj">
    <div class="header" id="header">
      <v-container class="proj-header" fluid fill>
        <v-row style="height: 60px">
          <v-col class="">
            <h2 style="font-weight: bold; font-size: 23px" class="proj-ttl">
              公開設定
            </h2>
            <p
              style="font-weight: bold; font-size: 10px; color: red"
              class="proj-ttl"
            >
              ※公開ボタンを押すまでは一時保存状態になっており、<br />リロード /
              プロジェクト切替 / 画面遷移 /
              ブラウザバックの操作により編集内容が削除されますのでご注意ください。
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div style="margin-top: 10px">
      <div style="width: 130px; position: absolute; top: 85px">
        公開中コンテンツ
      </div>
      <v-row no-gutters class="justify-content-end">
        <v-btn
          style="color: white; margin-left: 100px"
          tile
          depressed
          class="mr-3 publish-btn-edit"
          @click="allRewriteModalOpen()"
        >
          一括編集
        </v-btn>
        <v-btn
          tile
          depressed
          class="btn-preview"
          v-on:click="previewModalOpen()"
        >
          <img
            src="https://drive.google.com/uc?export=view&id=1L4db3mp4vf3wjS616qQatWgGQw3rvGnG&export=download"
            alt=""
          />プレビューを表示
        </v-btn>
        <v-btn
          style="color: white"
          tile
          depressed
          class="btn-release open"
          v-on:click="publishingModal"
        >
          公開
        </v-btn>
      </v-row>
    </div>
    <div class="publish-itemlist mt-3 releaseButton" id="publish-itemlist">
      <div>
        <div class="publish-itemlist-bg">
          <div
            class="publish-itemlist-bg-item"
            v-for="(number, index) in counter"
            :key="index + 'a'"
          >
            <p class="publish-itemlist-bg-item-num">{{ index + 1 }}</p>
            <p class="publish-itemlist-bg-item-txt">
              公開する投稿を<br class="pconlybr" />非公開コンテンツの中から<br
                class="pconlybr"
              />選択して下さい。
            </p>
            <p class="publish-itemlist-bg-item-txt">
              公開中コンテンツ内での<br class="pconlybr" />レイアウトの変更は<br
                class="pconlybr"
              />プルダウンで選択出来ます。
            </p>
          </div>
        </div>
        <!--公開中コンテンツ-->
        <v-row class="release">
          <li
            style="display: inline-block; z-index: -100"
            class="release-list-item"
            v-if="publishedContents.length === 0"
          >
            <div class="itemlist_item" data-target="#item01">
              <div class="itemlist_item_wrap"></div>
            </div>
          </li>
          <li
            style="display: inline-block"
            class="release-list-item"
            v-for="(info, index) in publishedContents"
            :key="info.user_id"
          >
            <div
              class="publish-itemlist_item publish-card"
              data-target="#item01"
              :class="{
                image: info.contents_kinds == '画像',
                movie: info.contents_kinds == '動画',
              }"
            >
              <div class="publish-itemlist_item_wrap">
                <v-btn
                  class="delete-button"
                  @click="deletepublished(info, index)"
                >
                  <span class="material-icons">close</span>
                </v-btn>
                <p
                  v-if="info.user_name_status == 1"
                  class="publish-itemlist_item_name"
                >
                  {{ info.user_name }}
                </p>
                <div
                  class="publish-itemlist_item_play"
                  v-if="info.contents_kinds == '動画'"
                >
                  <img src="../../assets/play.png" />
                </div>
                <div
                  v-if="info.contents_kinds == '動画'"
                  :id="index"
                  class="overray_item_video"
                  style="width: 100%; height: 100%; z-index: 0"
                >
                  <iframe
                    class="overray_item_video"
                    :src="
                      'https://player.vimeo.com/video/' +
                      info.video_id +
                      '&amp;controls=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=0&amp;loop=1amp;muted=0'
                    "
                    width="100%"
                    frameborder="0"
                    style="
                      margin: 0%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 0;
                    "
                    allow="autoplay; fullscreen;"
                    allowfullscreen
                  ></iframe>
                </div>
                <div
                  v-if="info.contents_kinds == '画像'"
                  :id="'video' + index"
                  class=""
                >
                  <img :src="info.main_image_url" />
                </div>
                <div class="publish-itemlist_item_body">
                  <div
                    v-if="
                      info.thumb_image_url != '' && info.thumb_status == '1'
                    "
                    id="published_thumb"
                    class="publish-itemlist_item_thumb"
                  >
                    <img :src="info.thumb_image_url" />
                  </div>
                  <div
                    v-if="info.title_status == 1 && info.comment_status == 1"
                    class="publish-itemlist_item_txt"
                  >
                    <p>{{ info.title }}<br />{{ info.comment }}</p>
                  </div>
                  <div
                    v-if="
                      info.title_status == 0 &&
                      info.comment_status == 0 &&
                      info.thumb_status == '1' &&
                      info.thumb_image_url != ''
                    "
                    class="publish-itemlist_item_txt"
                  ></div>
                  <div
                    v-if="info.title_status == 1 && info.comment_status == 0"
                    class="publish-itemlist_item_txt"
                  >
                    <p>{{ info.title }}<br /></p>
                  </div>
                  <div
                    v-if="info.title_status == 0 && info.comment_status == 1"
                    class="publish-itemlist_item_txt"
                  >
                    <p><br />{{ info.comment }}</p>
                  </div>
                </div>
              </div>
              <div style="margin-top: 1px">
                <v-btn
                  depressed
                  class="mt-5 publish-btn-edit2 white--text"
                  @click="open(info)"
                >
                  編集
                </v-btn>
                <div class="select-button-div">
                  <select
                    id="select"
                    @change="updateValue($event, index)"
                    name="order"
                    class="number-select"
                    style="display: inline-block"
                  >
                    <option v-for="n of publishedContents.length" :key="n">
                      {{ n }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </li>
        </v-row>
        <div
          id="overlay"
          v-show="publishingShowContent"
          v-on:click="publishingCloseModal"
        >
          <div id="content" align="center">
            <div class="publishing-check-icon">
              <img src="../../assets/ico_publishing.svg" alt="" />
            </div>
            <div>公開しました</div>
          </div>
        </div>
      </div>
    </div>

    <!--非公開中コンテンツ-->
    <div class="bottonList mt-10">
      <v-row no-gutters> 非公開コンテンツ（承認済） </v-row>
    </div>
    <div class="non-publish-itemlist mt-3 privateContentsList noRelease">
      <v-row>
        <div
          v-for="(info, index) in approvaled"
          class="noRelease-item"
          :key="index + 'b'"
        >
          <div
            class="non-publish-itemlist_item noRelease-item-inner"
            data-target="#item01"
            :class="{
              image: info.contents_kinds == '画像',
              movie: info.contents_kinds == '動画',
            }"
          >
            <div class="publish-itemlist_item_wrap">
              <p class="publish-itemlist_item_name">{{ info.user_name }}</p>
              <input
                type="checkbox"
                :id="info.user_id"
                :value="info"
                :key="index"
                class="btn-check"
                @click="publishedContentsChange(info, index, $event)"
                name="checkBox"
              />
              <label
                :id="index + 'a'"
                :for="info.user_id"
                class="btn-check-label"
              ></label>
              <div
                class="publish-itemlist_item_play"
                v-if="info.contents_kinds == '動画'"
              >
                <img src="../../assets/play.png" />
              </div>
              <div
                v-if="info.contents_kinds == '動画'"
                :id="index + 100"
                class="overray_item_video"
                style="width: 100%; height: 100%; margin: 0%; z-index: 0"
              >
                <iframe
                  class="overray_item_video"
                  :src="
                    'https://player.vimeo.com/video/' +
                    info.video_id +
                    '&amp;controls=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=0&amp;loop=1&amp;muted=0'
                  "
                  width="100%"
                  frameborder="0"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                  "
                  allow="autoplay; fullscreen;"
                  allowfullscreen
                ></iframe>
              </div>
              <div v-if="info.contents_kinds == '画像'" class="">
                <img :src="info.main_image_url" />
              </div>
              <div class="publish-itemlist_item_body">
                <div
                  v-if="info.thumb_image_url != ''"
                  class="publish-itemlist_item_thumb"
                >
                  <img :src="info.thumb_image_url" />
                </div>
                <div class="publish-itemlist_item_txt">
                  <p>{{ info.title }}<br />{{ info.comment }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </div>
    <RewriteModal :userInfo="selectedUser" />
    <AllRewriteModal />
    <PreviewModal />
    <PlanOverModal />
    <PublishingLimitModal />
  </v-main>
</template>
<script src="https://cdn.jsdelivr.net/npm/sortablejs@1.8.4/Sortable.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/Vue.Draggable/2.23.2/vuedraggable.umd.min.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
import RewriteModal from "@/components/Modal/RewriteModal.vue";
import AllRewriteModal from "@/components/Modal/AllRewriteModal.vue";
import PreviewModal from "@/components/Modal/PreviewModal.vue";
import PlanOverModal from "@/components/Modal/ContentsOverModal.vue";
import PublishingLimitModal from "@/components/Modal/ContentsLimitAlertModal.vue";
import Vue from "vue";
import VModal from "vue-js-modal";
Vue.use(VModal);
export default {
  name: "Home",
  components: {
    RewriteModal,
    AllRewriteModal,
    PreviewModal,
    PlanOverModal,
    PublishingLimitModal,
  },
  created() {
    if (this.$store.state.reloadCheck == true) {
      this.$store.commit("setReloadCheck", false);
    }
    this.noTouchAndClick();
  },
  mounted() {
    /////////////////初期化///////////
    this.$store.commit("resetPublishedContents");
    this.$store.commit("resetApprovalInfo");
    this.checked = [];
    var selectedProjectIndex = this.$store.state.selectedProject;
    this.getUser(selectedProjectIndex);
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    document.getElementById("menuTop").style.pointerEvents = "none";
  },
  data: () => ({
    drawer: null,
    checked: [], //非公開一覧のチェックボックスを管理
    toggle_exclusive: undefined,
    publishingShowContent: false,
    individual: true,
    selectedUser: [],
    counter: 5,
    temporaryData: [],
    width: window.innerWidth,
    height: window.innerHeight,
  }),
  updated() {
    var selectTag = document.getElementsByName("order");
    var selectTagLength = selectTag.length;
    for (var i = 0; i < selectTagLength; i++) {
      selectTag[i][i].selected = true;
    }
  },
  computed: {
    isOpen() {
      return this.$store.state.modal.isOpen;
    },
    approvaled() {
      //非公開中コンテンツの配列を渡す
      return this.$store.state.approvaled;
    },
    publishedContents() {
      //公開中コンテンツの配列を渡す
      return this.$store.state.publishedContents;
    },
  },
  watch: {
    approvaled() {},
  },
  methods: {
    handleResize: function () {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      if (this.width < 1260 && this.$store.state.approvaled.length >= 10) {
        var result = document.getElementsByClassName(
          "publish-itemlist-bg-item"
        );
        for (var i = 0; i < result.length; i++) {
          result[i].style.height = "75%";
        }
      } else if (
        this.width < 1260 &&
        this.$store.state.approvaled.length < 10
      ) {
        var result = document.getElementsByClassName(
          "publish-itemlist-bg-item"
        );
        for (var i = 0; i < result.length; i++) {
          result[i].style.height = "75%";
        }
      }
      if (this.width >= 1260) {
        var result = document.getElementsByClassName(
          "publish-itemlist-bg-item"
        );
        for (var t = 0; t < result.length; t++) {
          result[t].style.height = "85%";
        }
      }
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////タッチイベント及びクリックイベントを禁止する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    noTouchAndClick: function () {
      document.body.style.overflow = "hidden";
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      document.body.style.pointerEvents = "none";
      document.getElementsByTagName("html")[0].style.pointerEvents = "none";
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////タッチイベント及びクリックイベントを解除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    approveTouchAndClickEvent: function () {
      document.body.style.overflow = "";
      document.getElementsByTagName("html")[0].style.overflow = "";
      document.getElementById("menuTop").style.pointerEvents = "";
      document.body.style.pointerEvents = "";
      document.getElementsByTagName("html")[0].style.pointerEvents = "";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを削除している間にローディングを表示
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクトの全ユーザー情報を取得する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    getUser: async function (index) {
      this.waitLoading();

      this.$store.commit("getUserCheck", false);
      //クライアントIDを取得
      var clientId = this.$store.state.clientId;

      //プロジェクトIDを取得
      var projectId = this.$store.state.projectInfo[0][index].project_id;

      var instance = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/user/" + clientId + "/" + projectId);
      var list = await listRef.listAll();
      var imgList = [];
      var imgListCount = 0;
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              instance.temporaryData.push(JSON.parse(blob));
              store.commit("setApprovalInfo", JSON.parse(blob));
              store.commit("getUserCheck", true);
              imgListCount += 1;
              if (imgList.length == imgListCount) {
                //全てユーザーを取得し終えたら
                var select = document.getElementsByName("order");
                for (var i = 0; i < select.length; i++) {
                  select[i][i].selected = true;
                }
                //承認済みコンテンツを承認した順番に並べ直す
                instance.$store.commit("changeApprovaledOrder");

                //チェックボックスにチェックをつける処理
                instance.$nextTick(function () {
                  instance.checkedMethods();
                });
                instance.downloaded();
                instance.approveTouchAndClickEvent();
              } else {
                instance.downloaded();
              }
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
      }
      if (imgList.length == 0) {
        this.$store.commit("getUserCheck", true);
        instance.downloaded();
        instance.approveTouchAndClickEvent();
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////個別編集用モーダルの表示
    //////
    //////引数:info:選択したユーザーのステータス情報が格納
    ////////////////////////////////////////////////////////////////
    open(info) {
      this.selectedUser = [];
      this.selectedUser.push(info);
      this.$modal.show("user-modal");
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開ボタンを押した際、上限アラートを表示させるかどうかのチェック
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    contentsOverCheck() {
      var contentsType = ["50", "100", "500"];
      //契約しているプラン
      var contentsPlan = this.$store.state.clientInfo[0].contract_plan;
      var planLimit = "000"; //ご契約プランの限界値を超えた場合
      var publicationsNumber = "111"; //掲載本数の上限10本を超えた場合
      var success = "1"; //公開OKの場合
      var publishingNum = ["5", "10", "50"];

      //公開予定本数（現在公開中に選択されているコンテンツの総数）
      var publishingNumber = this.$store.state.publishedContents.length;

      //全てのプロジェクトで公開しているコンテンツの数
      var allPublishedContents = this.$store.state.allPublishingContents;

      //選択中のプロジェクトのID
      var selectedProject =
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .project_id;

      //公開中コンテンツの総数
      var totalpublishingContents = 0;

      //現状の公開中コンテンツの数
      var nowPublisingNum = 0;

      var match = false;

      //新しく公開したい公開コンテンツ数に書き換える
      for (var t = 0; t < allPublishedContents.length; t++) {
        if (allPublishedContents[t].project_id == selectedProject) {
          //現状の公開中コンテンツの数を保管しておく
          nowPublisingNum = allPublishedContents[t].publishingNum;

          //新しい公開中コンテンツの数の書き換える
          allPublishedContents[t].publishingNum = publishingNumber;
          this.$store.commit("changePublisingNum", {
            pub: publishingNumber,
            index: t,
          });
          match = true;
          break;
        }
      }
      if (match == false) {
        this.$store.commit("setAllPublishingContents", {
          project_id: selectedProject,
          publishingNum: publishingNumber,
        });
      }

      //公開中コンテンツの全合計を集計
      for (var i = 0; i < allPublishedContents.length; i++) {
        totalpublishingContents += allPublishedContents[i].publishingNum;
      }
      if (contentsPlan == contentsType[0]) {
        //50DBプランの場合
        if (
          publishingNumber <= 5 &&
          totalpublishingContents <= publishingNum[0]
        ) {
          return success;
        } else if (
          publishingNumber <= 5 &&
          totalpublishingContents > publishingNum[0]
        ) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return planLimit;
            }
          }
        } else if (publishingNumber > 5) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return planLimit;
            }
          }
        }
      } else if (contentsPlan == contentsType[1]) {
        //100DBプラン
        if (
          publishingNumber <= 10 &&
          totalpublishingContents <= publishingNum[1]
        ) {
          return success;
        } else if (
          publishingNumber <= 10 &&
          totalpublishingContents > publishingNum[1]
        ) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return planLimit;
            }
          }
        } else if (publishingNumber > 10) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return publicationsNumber;
            }
          }
        }
      } else if (contentsPlan == contentsType[2]) {
        //500DBプラン
        if (
          publishingNumber <= 10 &&
          totalpublishingContents <= publishingNum[2]
        ) {
          return success;
        } else if (
          publishingNumber <= 10 &&
          totalpublishingContents > publishingNum[2]
        ) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return planLimit;
            }
          }
        } else if (publishingNumber > 10) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return publicationsNumber;
            }
          }
        }
      } else {
        //500DBプラン以上もしくは50DB以下の処理

        //掲載本数の限界値を取得
        var pubNumLimit = Math.floor(contentsPlan / 10);
        if (
          publishingNumber <= 10 &&
          totalpublishingContents <= pubNumLimit &&
          publishingNumber <= pubNumLimit
        ) {
          return success;
        } else if (
          publishingNumber <= 10 &&
          totalpublishingContents > pubNumLimit
        ) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return planLimit;
            }
          }
        } else if (publishingNumber > 10) {
          //新しく公開したい公開コンテンツ数に書き換える
          for (var t = 0; t < allPublishedContents.length; t++) {
            if (allPublishedContents[t].project_id == selectedProject) {
              //新しい公開中コンテンツの数の書き換える
              allPublishedContents[t].publishingNum = publishingNumber;
              this.$store.commit("changePublisingNum", {
                pub: nowPublisingNum,
                index: t,
              });
              return publicationsNumber;
            }
          }
        } else if (publishingNumber <= 10 && publishingNumber > pubNumLimit) {
          //新しく公開したい公開コンテンツ数に書き換える
          return publicationsNumber;
        } else {
          return publicationsNumber;
        }
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////一括編集モーダル表示
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    allRewriteModalOpen() {
      this.$modal.show("all-user-modal");
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////プレビューモーダル表示
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    previewModalOpen() {
      this.$modal.show("preview-modal");
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開中コンテンツのコンテンツの順番が入れ替わる際に呼ばれ、順番を入れ替える処理
    //////
    //////引数:value:選択したドロップボックスの情報,index:変更するコンテンツのインデックス
    ////////////////////////////////////////////////////////////////
    updateValue: function (value, index) {
      //公開中コンテンツの順番を移動
      this.$store.commit("changeOrders", {
        index: index,
        orderNumber: value.target.value - 1,
      });
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開中コンテンツ→非公開コンテンツに移動する処理
    //////
    //////引数:info:コンテンツ情報,index:移動させるコンテンツのインデックス番号
    ////////////////////////////////////////////////////////////////
    deletepublished: function (info, index) {
      for (var i = 0; i < this.$store.state.approvaled.length; i++) {
        if (this.$store.state.approvaled[i].user_id == info.user_id) {
          var checkBoxContents = document.getElementsByName("checkBox");
          checkBoxContents[i].checked = false;
        }
      }
      this.$store.commit("deletePublishedContents", index); //公開中コンテンツから削除
      //公開中コンテンツの枠の調整をおこなう
      this.publishedContentsDesignChange();
      if (
        this.$store.state.publishedContents.length % 5 == 0 &&
        5 <= this.$store.state.publishedContents.length &&
        this.counter != 5
      ) {
        this.counter -= 5;
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////非公開コンテンツ→公開中コンテンツに移動する処理
    //////
    //////引数:contents:コンテンツ情報,index:移動させるコンテンツのインデックス番号
    ////////////////////////////////////////////////////////////////
    publishedContentsChange: function (contents, index, event) {
      if (event.target.checked === false) {
        //チェックボックスのチェックを外したとき
        for (var t = 0; t < this.$store.state.approvaled.length; t++) {
          for (var i = 0; i < this.$store.state.publishedContents.length; i++) {
            if (
              this.$store.state.publishedContents[i].user_id == contents.user_id
            ) {
              this.$store.commit("deletePublishedContents", i); //公開中コンテンツから削除
              if (
                this.$store.state.publishedContents.length % 5 == 0 &&
                5 <= this.$store.state.publishedContents.length &&
                this.counter != 5
              ) {
                this.counter -= 5;
              }

              //公開中コンテンツの枠の調整をおこなう
              this.publishedContentsDesignChange();
              break;
            }
          }
        }
      } else {
        //チェックボックスにチェックをつけたとき
        if (
          this.$store.state.publishedContents.length % 5 == 0 &&
          this.$store.state.publishedContents.length != 0
        ) {
          this.counter += 5;
        }
        this.$store.commit("addPublishedContents", contents); //公開コンテンツに移す

        //公開中コンテンツの枠の調整をおこなう
        this.publishedContentsDesignChange();
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////チェックボックスにチェックをつける処理
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    checkedMethods: function () {
      var checkBox = document.getElementsByName("checkBox");

      //並び順
      var order = [];

      //並び順だけ格納する
      for (var i = 0; i < this.$store.state.approvaled.length; i++) {
        if (this.$store.state.approvaled[i].approval_status == "2") {
          order.push(this.$store.state.approvaled[i].order);
        }
      }

      //番号順にソートをかける
      var sortOrder = order.sort((a, b) => a - b);

      for (var i = 0; i < sortOrder.length; i++) {
        for (var j = 0; j < this.$store.state.approvaled.length; j++) {
          if (
            this.$store.state.approvaled[j].order == sortOrder[i] &&
            this.$store.state.approvaled[j].approval_status == "2"
          ) {
            for (var t = 0; t < checkBox.length; t++) {
              if (checkBox[t].id == this.$store.state.approvaled[j].user_id) {
                checkBox[t].checked = true;
                if (
                  this.$store.state.publishedContents.length % 5 == 0 &&
                  this.$store.state.publishedContents.length != 0
                ) {
                  this.counter += 5;
                }
                this.$store.commit(
                  "addPublishedContents",
                  this.$store.state.approvaled[j]
                ); //公開コンテンツに移す

                //公開中コンテンツの枠の調整をおこなう
                this.publishedContentsDesignChange();
                document.body.style.overflow = "";
                document.getElementsByTagName("html")[0].style.overflow = "";
              }
            }
          }
        }
      }
      if (sortOrder.length == 0) {
        //公開中コンテンツの枠の調整をおこなう
        this.publishedContentsDesignChange();
        document.body.style.overflow = "";
        document.getElementsByTagName("html")[0].style.overflow = "";
        document.body.style.pointerEvents = "";
        document.getElementsByTagName("html")[0].style.pointerEvents = "";
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開する上限コンテンツを越えようとした場合にアラート表示
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    publishedContentsDesignChange() {
      var publishedContents = this.$store.state.publishedContents;
      var approvaledContents = this.$store.state.approvaled;
      if (publishedContents.length <= 5 && approvaledContents.length > 10) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(25% - 10px)");
      } else if (
        publishedContents.length > 10 &&
        approvaledContents.length > 10
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(20% - 10px)");
      } else if (
        publishedContents.length > 5 &&
        approvaledContents.length > 10
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(24% - 10px)");
      } else if (
        publishedContents.length > 5 &&
        approvaledContents.length > 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(29% - 10px)");
      } else if (
        publishedContents.length <= 5 &&
        approvaledContents.length > 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(35% - 10px)");
      } else if (
        publishedContents.length <= 5 &&
        approvaledContents.length <= 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(45% - 10px)");
      } else if (
        publishedContents.length > 5 &&
        approvaledContents.length >= 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(29% - 10px)");
      }
      if (publishedContents.length == 0 && approvaledContents.length > 5) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(50% - 8px)");
      } else if (
        publishedContents.length == 0 &&
        approvaledContents.length <= 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(60% - 25px)");
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開する上限コンテンツを越えようとした場合にアラート表示
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    alertLimit: function () {
      //公開中コンテンツの上限を達した際呼ばれる
      this.$modal.show("alertLimit");
    },
    hide: function () {
      this.$modal.hide("alertLimit");
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開ボタンを押した際に表示させるモーダル
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    publishingModal: function () {
      var planLimit = "000"; //ご契約プランの限界値を超えた場合
      var publicationsNumber = "111"; //掲載本数の上限10本を超えた場合
      var success = "1"; //公開OKの場合

      //上限値のアラート表示
      if (this.contentsOverCheck() == planLimit) {
        this.$modal.show("contents-over-modal");
      } else if (this.contentsOverCheck() == publicationsNumber) {
        this.$modal.show("contents-publishing-limit-modal");
      } else if (success) {
        this.publishingShowContent = true;
        //storageに公開のコンテンツの順番を保存する
        this.registerOrderStorage();

        //storageの公開フォルダにコンテンツを格納する
        this.publishingFolderSet();

        //vue側のデータを更新する
        this.$store.commit("reflectUserInfo");

        //公開するユーザー情報の上書き
        this.reserveRewriteContents();
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開するコンテンツのユーザー情報をfirebase storageに上書きする
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    reserveRewriteContents: function () {
      //公開するコンテンツ（ユーザー情報を上書きするコンテンツ）
      var publishedContents = this.$store.state.publishedContents;
      //公開するコンテンツ分、ループ回して、内容を上書きする
      for (var i = 0; i < publishedContents.length; i++) {
        //上書きするユーザーのパスを取得
        var userRef = this.overwriteUserPass(publishedContents[i].user_id);

        //上書きするユーザーの情報を登録し、json形式に変換する
        var userParseJson = this.getOverwriteUserData(publishedContents[i]);

        //Blob形式に変換
        const blob = new Blob([userParseJson], { type: "application/json" });

        //上書き
        userRef.put(blob).then(function () {});
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開する際、上書きするユーザーのパスを取得
    //////
    //////引数:number 公開するユーザーの配列番号 userId 上書きするユーザーのID
    ////////////////////////////////////////////////////////////////
    overwriteUserPass: function (userId) {
      //上書きするユーザーのパスを取得
      var userRef = firebase
        .storage()
        .ref()
        .child(
          "/user/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.projectInfo[0][this.$store.state.selectedProject]
              .project_id +
            "/" +
            userId +
            ".json"
        );
      return userRef;
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////公開する際、上書きするユーザーの情報を登録し、取得
    //////
    //////引数:user 公開するユーザーの情報
    ////////////////////////////////////////////////////////////////
    getOverwriteUserData: function (user) {
      //ユーザー情報
      const jsonString = JSON.stringify({
        post_date: user.post_date,
        user_id: user.user_id,
        user_name: user.user_name,
        title: user.title,
        comment: user.comment,
        approval_status: user.approval_status,
        contents_kinds: user.contents_kinds,
        main_image_url: user.main_image_url,
        video_id: user.video_id,
        thumb_image_url: user.thumb_image_url,
        user_name_status: user.user_name_status,
        title_status: user.title_status,
        comment_status: user.comment_status,
        cta_status: user.cta_status,
        cta: user.cta,
        thumb_status: user.thumb_status,
        main_thumb_image_url: user.main_thumb_image_url,
        order: user.order,
      });
      return jsonString;
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////storageにコンテンツの公開順を登録する
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    registerOrderStorage: function () {
      var approvaled = this.$store.state.approvaled;

      //公開順を登録していく
      for (var i = 0; i < approvaled.length; i++) {
        //保存先パス
        const ref = firebase
          .storage()
          .ref()
          .child(
            "/user/" +
              this.$store.state.clientId +
              "/" +
              this.$store.state.projectInfo[0][
                this.$store.state.selectedProject
              ].project_id +
              "/" +
              approvaled[i].user_id +
              ".json"
          );

        const jsonString = JSON.stringify(approvaled[i]);

        const blob = new Blob([jsonString], { type: "application/json" });
        ref.put(blob).then(function () {});
      }
    },
    /////////////////////////////////////////////////
    /////////
    ///////説明
    //////storageの公開フォルダに保存する
    //////
    //////引数:なし
    ////////////////////////////////////////////////////////////////
    publishingFolderSet: function () {
      //////////////////////////////////////公開フォルダの中身を一旦削除する////////////////////////////////
      var publishedContents = this.$store.state.publishedContents;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listPublishedInfo = storageRef.child(
        "/publish/" +
          this.$store.state.clientId +
          "/" +
          this.$store.state.projectInfo[0][this.$store.state.selectedProject]
            .project_id +
          "/" +
          "publishingUser.json"
      );

      const jsonString = JSON.stringify(publishedContents);
      const blob = new Blob([jsonString], {
        type: "application/json",
      });
      try {
        listPublishedInfo.put(blob).then(function (task) {
          console.log(task);
        });
      } catch (error) {
        console.log("公開フォルダへのエラーキャッチ", error);
      }
    },
    publishingCloseModal: function () {
      this.publishingShowContent = false;
    },
  },
};
var hoverContentsType; //「画像」か「動画」が入る
var classVal; // classの値を取得
var classVals; // 取得した値を分割
$(function () {
  $(document).on(
    {
      mouseenter: function () {
        $(".publish-itemlist_item")
          .not($(this))
          .find(".publish-itemlist_item_play")
          .fadeIn(200);
        classVal = $(this).attr("class"); // classの値を取得
        classVals = classVal.split(" "); // 取得した値を分割

        // 配列になっているのでforで一つずつ取得できる
        for (var i = 0; i < classVals.length; i++) {
          if (classVals[i] == "image") {
            hoverContentsType = "画像";
          } else {
            hoverContentsType = "動画";
          }
        }

        if (hoverContentsType == "動画") {
          if (
            $($(this)).find(".publish-itemlist_item_play").next("div")[0].id ==
            ""
          ) {
            $($(this)).find(".publish-itemlist_item_play").fadeIn(200);
          } else {
            $($(this)).find(".publish-itemlist_item_play").fadeOut(200);
          }

          var clickedId = $(this)
            .find(".publish-itemlist_item_play")
            .next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.play();
        }
      },

      mouseleave: function () {
        if (hoverContentsType == "動画") {
          $(".publish-itemlist_item")
            .not($(this))
            .find(".publish-itemlist_item_play")
            .fadeIn(200);
          $(".publish-itemlist_item")
            .find(".publish-itemlist_item_play")
            .not($(this))
            .fadeIn(200);
          var clickedId = $(this)
            .find(".publish-itemlist_item_play")
            .next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.pause();
        }
      },
    },
    ".publish-itemlist_item"
  );
  $(document).on(
    {
      mouseenter: function () {
        $(".publish-itemlist_item")
          .not($(this))
          .find(".publish-itemlist_item_play")
          .fadeIn(200);
        classVal = $(this).attr("class"); // classの値を取得
        classVals = classVal.split(" "); // 取得した値を分割

        // 配列になっているのでforで一つずつ取得できる
        for (var i = 0; i < classVals.length; i++) {
          if (classVals[i] == "image") {
            hoverContentsType = "画像";
          } else {
            hoverContentsType = "動画";
          }
        }
        if (hoverContentsType == "動画") {
          $(this).find(".publish-itemlist_item_play").fadeOut(200);
          var clickedId = $(this)
            .find(".publish-itemlist_item_play")
            .next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.play();
        }
      },
      mouseleave: function () {
        if (hoverContentsType == "動画") {
          $(".noRelease-item-inner")
            .not($(this))
            .find(".publish-itemlist_item_play")
            .fadeIn(200);
          $(this).find(".publish-itemlist_item_play").not($(this)).fadeIn(200);
          var clickedId = $(this)
            .find(".publish-itemlist_item_play")
            .next("div")[0].id;
          var iframe = document.getElementById(clickedId);
          var player = new Vimeo.Player(iframe);
          player.pause();
        }
      },
    },
    ".noRelease-item-inner"
  );
});
</script>

<style>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300,400;500;700&display=swap");

.v-application {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
}

.v-application .text-h6 {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif !important;
}

.v-navigation-drawer__content {
  padding-top: 2rem;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background: #eae1d9;
}

@media (max-width: 1259px) {
  .pconlybr {
    display: none;
  }
}

.publish-proj .v-main__wrap {
  padding: 0 3rem;
}

@media (max-width: 1259px) {
  .publish-proj .v-main__wrap {
    padding: 0 3rem;
  }
}

.publish-proj-header {
  margin-top: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.publish-proj-header h3 {
  font-size: 1.17em;
  font-weight: bold;
}

.publish-proj-header-item {
  margin-right: 0;
}

.publish-proj-ttl {
  font-size: 1.5em;
  font-weight: bold;
}

.publish-proj .publish-btn-edit {
  background: linear-gradient(#e18140, #d6600f);
  padding: 0.5rem 1rem;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 106px;
  transition: 0.3s;
  border-radius: 0;
}

.publish-proj .publish-btn-edit2 {
  background: linear-gradient(#e18140, #d6600f);
  padding: 0.5rem 1rem;
  font-weight: bold;
  display: block;
  transition: 0.3s;
  border-radius: 0;
  margin-right: 2%;
  margin-bottom: 28px;
  display: inline-block;
  width: 57%;
}

.publish-proj .v-btn__content {
  display: block;
}

.publish-proj .publish-btn-edit:hover {
  filter: brightness(110%);
}

.publish-proj .btn-preview {
  background: #e6e6e6 !important;
  padding: 0.5rem 1rem;
  color: #4a4a4a;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 186px;
  transition: 0.3s;
}

.publish-proj .btn-preview .v-btn__content {
  display: block;
}

.publish-proj .btn-preview img {
  margin-right: 0.5rem;
}

.publish-proj .btn-preview:hover {
  background: #e6e6e6 !important;
  filter: brightness(105%);
}

.publish-proj .btn-preview:hover:before {
  display: none;
}

.publish-proj .btn-release {
  background: linear-gradient(#00979c, #068587);
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 80px;
  transition: 0.3s;
}

select {
  /* styling */
  background-color: white;
  border: thin solid grey;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  height: 37px;
  /* reset */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.number-select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  z-index: 2147483647;
}

.select-button-div {
  display: inline-block;
  width: 1%;
  z-index: 2147483647;
}

.publish-proj .btn-release .v-btn__content {
  display: block;
}

.publish-proj .btn-release img {
  margin-right: 0.5rem;
}

.publish-proj .btn-release:hover {
  filter: brightness(110%);
}

.publish-proj .publish-itemlist {
  position: relative;
}

.non-publish-itemlist {
  z-index: 0;
  width: 100%;
  border: 1px solid #9f9f9f;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 10px;
  position: relative;
  /*
  margin-left: auto;
  margin-right: auto; */
}
@media (max-width: 991px) {
  .non-publish-itemlist {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.publish-proj .publish-itemlist-bg {
  position: absolute;
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap; /*--折り返しの指定--*/
  z-index: -1;
}

@media (min-width: 1260px) {
  .publish-proj .publish-itemlist-bg-item {
    width: 20%;
    height: 85%;
    /* height: 480px;*/ /*コメントアウト外す*/
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 2px dashed #bababa;
    margin-bottom: 0%;
    margin-top: 10px;
  }
}

@media (max-width: 1259px) {
  .publish-proj .publish-itemlist-bg-item {
    width: 20%;
    height: 74%;
    /* height: 480px;*/ /*コメントアウト外す*/
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 2px dashed #bababa;
    margin-bottom: 0%;
    margin-top: 10px;
  }
}

.publish-proj .publish-itemlist-bg-item-num {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 1rem;
}

@media (max-width: 1259px) {
  .publish-proj .publish-itemlist-bg-item-num {
    font-size: 20px;
  }
}

.publish-proj .publish-itemlist-bg-item-txt {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1259px) {
  .publish-proj .publish-itemlist-bg-item-txt {
    font-size: 10px;
  }
}

.publish-proj .publish-itemlist-bg-item-txt + .publish-itemlist-bg-item-txt {
  margin-top: 1em;
}

.release {
  margin: 0 !important;
  position: relative;
}

#release-list {
  padding: 0 !important;
  width: 100%;
}
.release-list-item {
  width: calc(20% - 4px);
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 2%;
  padding-bottom: 0%;
  padding-right: 2%;
  padding-left: 2%;
  background: #f9f9f9;
}

.release-list-item .delete-button {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #a5a5a5;
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -12px;
  right: -8px;
}

.release-list-item .delete-button span {
  font-size: 7px;
  font-weight: bold;
  margin-right: 1px;
}

.release {
  margin-left: auto;
  margin-right: auto;
}

.publish-btn-edit {
  margin-left: 0px;
  margin-right: 0px;
}

.noRelease .row {
  margin: 0;
}

.noRelease-item {
  width: 20%;
  padding: 10px;
}

@media (max-width: 1259px) {
  .noRelease-item {
    width: 25%;
  }
}

.noRelease-item-inner {
  width: 100%;
}

.noRelease .btn-check {
  display: none;
}

.noRelease .btn-check-label {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: linear-gradient(#f6f6f6, #c5c5c5);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  cursor: pointer;
}

.noRelease .btn-check-label::after {
  content: "\e5ca";
  font-family: "Material Icons";
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.noRelease .btn-check:checked + .btn-check-label::after {
  display: block;
}

.modal-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 40px;
  flex-wrap: wrap;
}

.modal-wrap .btn-wrap {
  width: 100%;
}

.modal-wrap .btn-wrap .btn-save {
  margin-left: auto;
  margin-right: auto;
}

.modal-wrap input.siteURL {
  border: 1px solid #b9bcc4;
  padding: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
}
.modal-wrap input.modal-siteURL {
  border: 1px solid #b9bcc4;
  padding: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
}

.modal-wrap .modal-inputTtl {
  font-size: 0.75rem;
}

.modal-wrap .thumbImg {
  /* padding-top: 100%;*/
}
.modal-wrap .thumbImg_all {
  /*padding-top: 100%;*/
}

.thumbImg_all {
  background-color: lavenderblush;
  width: 60px;
  height: 60px;
}

.thumbImg {
  width: 50px;
  height: 50px;
}

#user_thumbnail_img {
  margin-top: 0;
  width: 50px;
  height: 50px;
  background-color: red;
}

#img-thum {
  margin-top: 0;
  width: 60px;
  height: 60px;
}

#user-thumb {
  /* width:50px;*/
  /* height:50px;*/
  background-color: lavenderblush;
  /* padding-top: 100%;*/
}

.thumbImg_img {
  width: 50px;
  height: 50px;
  background-color: red;
}

.modal-wrap .btn-save {
  background: linear-gradient(#00979c, #068587);
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 100px;
  transition: 0.3s;
  border-radius: 0;
}

#user-thumbnail-img {
  width: 50;
  height: 50;
}

.modal-wrap .btn-save .v-btn__content {
  display: block;
}

.modal-wrap .btn-save img {
  margin-right: 0.5rem;
}

.modal-wrap .btn-save:hover {
  filter: brightness(110%);
}

@media (max-width: 1259px) {
  .v--modal-overlay .v--modal-box {
    width: 80% !important;
    left: 10% !important;
  }
}

.btn-modalClose {
  background: linear-gradient(#b1b1b1, #8e8e8e);
  padding: 0.5rem 1rem;
  color: #fff !important;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 100px;
  transition: 0.3s;
  border-radius: 0;
}

.btn-modalClose .v-btn__content {
  display: block;
}

.btn-modalClose img {
  margin-right: 0.5rem;
}

.btn-modalClose:hover {
  filter: brightness(110%);
}

.v--modal-overlay .v--modal-box {
  height: auto !important;
  width: 80% !important;
  max-width: 720px !important;
  left: initial !important;
  top: initial !important;
}

#previewModal .v--modal-box {
  max-width: 960px !important;
  padding: 20px;
}

#overModal .v--modal-box {
  max-width: 488px !important;
  padding: 20px;
}

.v--modal-overlay .v--modal-background-click {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*# sourceMappingURL=publishingSetting.css.map */

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  width: 100%;
}

main {
  width: 100%;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: bottom;
  border-style: none;
  max-width: 100%;
  width: auto;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*.l-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  */

#overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
/*公開しましたのアラート*/
#content {
  width: 80%;
  max-width: 240px;
  z-index: 2;
  border-radius: 5px;
  padding: 2.25rem;
  background: #fff;
}

.publishing-check-icon {
  margin-bottom: 10px;
}

#circle-icon {
  font-size: 80px;
}

.publish-itemlist {
  z-index: 0;
  width: 100%;
  border: 1px solid #9f9f9f;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 10px;
  position: relative;
  overflow-y: scroll;
  height: calc(45% - 10px); /**calc(29% - 10px) */
  /*
  margin-left: auto;
  margin-right: auto; */
}
@media (max-width: 991px) {
  .publish-itemlist {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575px) {
  .publish-itemlist {
    max-width: 286px;
    padding: 0;
  }
}

.publish-itemlist .slick-list {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.publish-itemlist .slick-list::after {
  content: "";
  width: 100%;
  height: calc(100% - 4rem);
  position: absolute;
  left: 0;
  top: 2rem;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0) 95%,
    rgba(0, 0, 0, 0.5)
  );
}

.publish-itemlist_item {
  position: relative;
  /*margin-right: 12px;
    margin-left: 12px;
    padding: 2rem 0; */
}

.non-publish-itemlist_item {
  position: relative;
  /*margin-right: 12px;
    margin-left: 12px;
    padding: 2rem 0; */
}

.publish-itemlist_item_wrap {
  width: 100%;
  padding-top: 177.777778%;
  position: relative;
  transition: 0.3s;
}

/*
      .publish-itemlist_item_wrap:hover {
        box-shadow: 0 10px 12px rgba(0, 0, 0, 0.3);
        transform: translateY(-10px); }
        */
.publish-itemlist_item_name {
  color: #fff;
  padding: 0 0.75rem;
  position: absolute;
  left: 0;
  top: 0.75rem;
  font-size: 0.75rem;
  z-index: 1;
}

.non-publish-itemlist_item_name {
  color: #fff;
  padding: 0 0.75rem;
  position: absolute;
  left: 0;
  top: 0.75rem;
  font-size: 0.75rem;
  z-index: 1;
}

.close-button {
  width: 19px;
  height: 19px;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  padding: 0 0.75rem;
  position: absolute;
  right: 0;
  top: 0.75rem;
  z-index: 1;
}

.publish-itemlist_item_body {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 5%;
  width: 100%;
}

.publish-itemlist_item_thumb {
  font-size: 0.75rem;
  width: calc(3em + 2.53rem);
  height: calc(3em + 1rem);
}

.publish-itemlist_item_thumb img {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.publish-itemlist_item_thumb + .publish-itemlist_item_txt {
  width: calc(100% - 3em + 1rem);
}

.publish-itemlist_item_txt {
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
  pointer-events: none;
  width: 100%;
  overflow: hidden;
}

.publish-itemlist_item_txt p {
  height: 3em;
  overflow: hidden;
  margin-bottom: 0;
}

.publish-itemlist_item video,
.publish-itemlist_item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}

.publish-itemlist_item img {
  object-fit: cover;
  cursor: initial;
}

.publish-itemlist_item_play {
  position: absolute;
  width: 38px;
  height: 38px;
  left: calc(50% - 19px);
  top: calc(50% - 19px);
  z-index: 1;
}

.non-publish-itemlist_item_body {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 5%;
  width: 100%;
}

.non-publish-itemlist_item_thumb {
  font-size: 0.75rem;
  width: calc(3em + 1rem);
  height: calc(3em + 1rem);
}

.non-publish-itemlist_item_thumb img {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.non-publish-itemlist_item_thumb + .non-publish-itemlist_item_txt {
  width: calc(100% - 3em + 1rem);
}

.non-publish-itemlist_item_txt {
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
  pointer-events: none;
  width: 100%;
}

.non-publish-itemlist_item_txt p {
  height: 3em;
  overflow: hidden;
  margin-bottom: 0;
}

.non-publish-itemlist_item video,
.non-publish-itemlist_item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}

.non-publish-itemlist_item img {
  object-fit: cover;
  cursor: initial;
}

.non-publish-itemlist_item_play {
  position: absolute;
  width: 38px;
  height: 38px;
  left: calc(50% - 19px);
  top: calc(50% - 19px);
  z-index: 1;
}

.publish-itemlist_arrow_prev {
  position: absolute;
  width: 13px;
  height: 32px;
  left: 1rem;
  top: calc(50% - 16px);
  z-index: 100;
  cursor: pointer;
}

.publish-itemlist_arrow_next {
  position: absolute;
  width: 13px;
  height: 32px;
  right: 1rem;
  top: calc(50% - 16px);
  z-index: 100;
  cursor: pointer;
}

.overray {
  width: 100%;
  height: 100%;
  background: #404040;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
}

.overray_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

.overray_inner {
  position: relative;
  width: 408px;
  padding-top: calc(408px * 1.77777778);
  -webkit-perspective: 500px;
  perspective: 500px;
}

@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_inner {
    height: 80vh;
    padding-top: 0;
    width: calc(80vh * 0.56824513);
  }
}

@media (max-width: 575px) {
  .overray_inner {
    width: 100%;
    padding-top: 0;
    height: 100%;
  }
}

.overray_prev {
  position: absolute;
  left: -42px;
  top: calc(50% - 24px);
  cursor: pointer;
}

@media (max-width: 575px) {
  .overray_prev {
    display: none;
  }
}

.overray_next {
  position: absolute;
  right: -42px;
  top: calc(50% - 24px);
  cursor: pointer;
}

@media (max-width: 575px) {
  .overray_next {
    display: none;
  }
}

.overray_item {
  display: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.overray_item.is-current {
  display: block;
}

.overray_item.rtoIn {
  display: block;
  z-index: 0;
}

.overray_item.rtoIn .overray_item_wrap {
  transform: rotateY(90deg) translateZ(0px) translateX(100%);
  animation: rtoIn 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}

@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.rtoIn .overray_item_wrap {
    animation: rtoIn_short 1s ease-in-out forwards;
  }
}

.overray_item.rtoOut {
  z-index: -1;
}

.overray_item.rtoOut .overray_item_wrap {
  transform: rotateY(0deg) translateZ(0) translateX(0);
  animation: rtoOut 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}

@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.rtoOut .overray_item_wrap {
    animation: rtoOut_short 1s ease-in-out forwards;
  }
}

.overray_item.ltoIn {
  display: block;
  z-index: 0;
}

.overray_item.ltoIn .overray_item_wrap {
  transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
  animation: ltoIn 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}

@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.ltoIn .overray_item_wrap {
    animation: ltoIn_short 1s ease-in-out forwards;
  }
}

.overray_item.ltoOut {
  z-index: -1;
}

.overray_item.ltoOut .overray_item_wrap {
  transform: rotateY(0deg) translateZ(0) translateX(0);
  animation: ltoOut 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}

@media screen and (min-width: 576px) and (max-height: 750px) {
  .overray_item.ltoOut .overray_item_wrap {
    animation: ltoOut_short 1s ease-in-out forwards;
  }
}

.overray_item_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
}

.overray_item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overray_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overray_item_time {
  width: 90%;
  height: 4px;
  border-radius: 10px;
  background: #9b9ea5;
  position: absolute;
  left: 5%;
  top: 0.5rem;
}

.overray_item_time_bar {
  position: absolute;
  width: 0%;
  height: 100%;
  background: #fbfcfe;
  border-radius: 10px;
  left: 0;
  top: 0;
  transition: 0.1s;
}

.overray_item_name {
  position: absolute;
  font-size: 0.75rem;
  left: 5%;
  top: 1.5rem;
  color: #fff;
}

.overray_item_close {
  position: absolute;
  right: 5%;
  top: 18px;
  cursor: pointer;
  z-index: 10;
}

.overray_item_play {
  position: absolute;
  width: 72px;
  height: 72px;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
  z-index: 10;
  transition: 0.3s;
  transform-origin: center;
  cursor: pointer;
}

.overray_item_play img {
  width: 100%;
}

.overray_item_play:hover {
  transform: scale(1.2);
}

.overray_item_txt {
  position: absolute;
  left: 0;
  bottom: 76px;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
}

.overray_item_txt p {
  height: 5.5em;
  overflow: auto;
  margin-bottom: 0;
}

.overray_item_bottom {
  position: absolute;
  padding: 0 5%;
  bottom: 24px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overray_item_bottom_btn {
  width: 50%;
  max-width: 136px;
}

@keyframes rtoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(-90deg) translateZ(51px) translateX(-100%);
    z-index: -1;
  }
}

@keyframes rtoIn {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

@keyframes ltoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(90deg) translateZ(51px) translateX(100%);
    z-index: -1;
  }
}

@keyframes ltoIn {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: 1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

@keyframes rtoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(-90deg) translateZ(calc(40vh / 8)) translateX(-100%);
    z-index: -1;
  }
}

@keyframes rtoIn_short {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: 1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

@keyframes ltoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(90deg) translateZ(calc(40vh / 8)) translateX(100%);
    z-index: -1;
  }
}

@keyframes ltoIn_short {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: 1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

.publish-card {
  width: 100%;
  background: none;
  border-radius: 0;
  border: none;
}
/*
.publish-card:hover {
  transform: translateY(-10px);
}
*/

.nav-header__menu {
  padding: 20px 0;
  margin: 0;
  text-align: left;
}

.nav-header__menu li {
  list-style: none;
  display: inline-block;
  width: 100px;
  min-width: 90px;
}

/*
	.nav-header__menu li a {
		text-decoration: none;
		color: #333;
	}
	.nav-header__menu li.current a {
		color: #3280f5;
	}

	.nav-header__menu li a:hover {
		color: #b1b6be;
	}
*/
.sticky {
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 99999999999999999999999;
}

.sticky_main {
  position: fixed;
  background-color: white;
  z-index: 999;
}

.sticky_topic {
  position: fixed;
  top: 10;
  background-color: white;
  z-index: 999;
}

.screen2 {
  margin-top: 80px;
  background: white;
}

.bottonList {
  z-index: 0;
  width: 100%;
  max-width: 1200px;
}

.v_line_fix {
  margin-left: 40px;
  width: 2px;
  height: 400px;
  border-left: dotted 3px grey;
  margin-right: 0;
  padding-right: 0;
}

.allUser-thumb_image {
  border: 1px solid #b9bcc4;
  border-radius: 5px;
  font-size: 12px;
}

.allUser-thumb_image + p {
  font-size: 12px;
}

.thumb_image {
  border: 1px solid #b9bcc4;
  border-radius: 5px;
  font-size: 12px;
}

.thumb_image + p {
  font-size: 12px;
}

::-webkit-file-upload-button {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: 1px solid #b9bcc4;
  padding: 0.25rem 0.5rem;
}

.privateContentsList {
  padding-left: 80px;
  padding-right: 80px;
}

@media (max-width: 1259px) {
  .privateContentsList {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.modalInner {
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}
</style>
