<template>
  <div>
    <modal
      name="user-modal"
      :width="730"
      :height="470"
      class="user-modal"
      id="user-modal"
      @opened="onModalOpened"
      :reset="true"
    >
      <div class="modal-wrap modal-wrapper">
        <div class="modal-video"><!--本来ここにはcol="4"があった-->
          <div
            class="publish-itemlist_item"
            :class="{
              image: contents_kinds == '画像',
              movie: contents_kinds == '動画',
            }"
            data-target="#item01"
            style="width: 180px; margin-right: 50px"
            v-if="contents_kinds == '画像'"
          >
            <div class="publish-itemlist_item_wrap">
              <p
                v-if="user_name_status == 1"
                class="publish-itemlist_item_name"
              >
                {{ user_name }}
              </p>
              <div>
                <img :src="main_image_url" />
              </div>
              <div class="publish-itemlist_item_body">
                <div
                  v-if="thumb_image_url != '' && thumb_image_status == 1"
                  class="publish-itemlist_item_thumb"
                >
                  <img :src="thumb_image_url" alt="" />
                </div>
                <div
                  v-if="title_status == 1 && comment_status == 1"
                  class="re-publish-itemlist_item_txt"
                >
                  <p>{{ title }}<br />{{ comment }}</p>
                </div>
                <div
                  v-if="title_status == 1 && comment_status == 0"
                  class="re-publish-itemlist_item_txt"
                >
                  <p>{{ title }}<br /></p>
                </div>
                <div
                  v-if="title_status == 0 && comment_status == 1"
                  class="re-publish-itemlist_item_txt"
                >
                  <p><br />{{ comment }}</p>
                </div>
                <div
                  v-if="
                    title_status == 0 &&
                    comment_status == 0 &&
                    thumb_image_status == '1' &&
                    thumb_image_url != ''
                  "
                  class="re-publish-itemlist_item_txt"
                ></div>
              </div>
            </div>
          </div>
          <div
            class="publish-itemlist_item-rewritemodal"
            data-target="#item01"
            style="width: 180px; margin-right: 50px"
            v-if="contents_kinds == '動画'"
          >
            <div class="publish-itemlist_item_wrap">
              <p
                v-if="user_name_status == 1"
                class="publish-itemlist_item_name"
              >
                {{ user_name }}
              </p>
              <div class="publish-itemlist_item_play">
                <img
                  src="https://drive.google.com/uc?export=view&id=1YT-YG3xIy1wfrWti31K5hPZzGBxU1gZa&export=download"
                />
              </div>
              <div
                v-if="contents_kinds == '動画'"
                id="movie"
                class="overray_item_video"
                style="width: 100%; height: 100%; z-index: 0"
              >
                <iframe
                  class="overray_item_video"
                  :src="
                    'https://player.vimeo.com/video/' +
                    video_id +
                    '&amp;controls=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=0&amp;loop=1'
                  "
                  frameborder="0"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                  "
                  allow="autoplay; fullscreen;"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="publish-itemlist_item_body">
                <div
                  class="publish-itemlist_item_thumb"
                  v-if="thumb_image_status == 1 && thumb_image_url != ''"
                >
                  <img :src="thumb_image_url" />
                </div>
                <div
                  v-if="title_status == 1 && comment_status == 1"
                  class="re-publish-itemlist_item_txt"
                >
                  <p>{{ title }}<br />{{ comment }}</p>
                </div>
                <div
                  v-if="title_status == 1 && comment_status == 0"
                  class="re-publish-itemlist_item_txt"
                >
                  <p>{{ title }}<br /></p>
                </div>
                <div
                  v-if="title_status == 0 && comment_status == 1"
                  class="re-publish-itemlist_item_txt"
                >
                  <p><br />{{ comment }}</p>
                </div>
                <div
                  v-if="
                    title_status == 0 &&
                    comment_status == 0 &&
                    thumb_image_status == '1' &&
                    thumb_image_url != ''
                  "
                  class="re-publish-itemlist_item_txt"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <v-row align-content="center" justify="center">
            <v-col cols="3" class="modal-inputTtl"> CTA </v-col>
            <v-col>
              <div
                class="btn-group btn-group-toggle btn-group-sm"
                data-toggle="buttons"
              >
                <label
                  :class="[
                    cta_status === '1'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="cta"
                    id="cta_display"
                    value="1"
                    autocomplete="off"
                  />
                  表示
                </label>
                <label
                  :class="[
                    cta_status === '0'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="cta"
                    id="cta_non_display"
                    value="0"
                    autocomplete="off"
                  />
                  非表示
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> CTA遷移先 </v-col>
            <v-col cols="9">
              <input class="siteURL" id="cta-url" :value="cta" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> ユーザー名 </v-col>
            <v-col>
              <div
                class="btn-group btn-group-toggle btn-group-sm"
                data-toggle="buttons"
              >
                <label
                  name="user-name-label"
                  :class="[
                    user_name_status === '1'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="userName"
                    id="option1"
                    value="1"
                    autocomplete="off"
                  />
                  表示
                </label>
                <label
                  :class="[
                    user_name_status === '0'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                  name="user-name-label"
                >
                  <input
                    type="radio"
                    name="userName"
                    id="option2"
                    value="0"
                    autocomplete="off"
                  />
                  非表示
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> タイトル </v-col>
            <v-col>
              <div
                class="btn-group btn-group-toggle btn-group-sm"
                data-toggle="buttons"
              >
                <label
                  :class="[
                    title_status === '1'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="title"
                    id="option1"
                    value="1"
                    autocomplete="off"
                  />
                  表示
                </label>
                <label
                  :class="[
                    title_status === '0'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="title"
                    id="option2"
                    value="0"
                    autocomplete="off"
                  />
                  非表示
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> コメント </v-col>
            <v-col>
              <div
                class="btn-group btn-group-toggle btn-group-sm"
                data-toggle="buttons"
              >
                <label
                  :class="[
                    comment_status === '1'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="comment"
                    id="option1"
                    value="1"
                    autocomplete="off"
                  />
                  表示
                </label>
                <label
                  :class="[
                    comment_status === '0'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="comment"
                    id="option2"
                    value="0"
                    autocomplete="off"
                  />
                  非表示
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> 製品画像 </v-col>
            <v-col>
              <div
                class="btn-group btn-group-toggle btn-group-sm"
                data-toggle="buttons"
              >
                <label
                  :class="[
                    thumb_image_status === '1'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="productImage"
                    id="option1"
                    value="1"
                    autocomplete="off"
                  />
                  表示
                </label>
                <label
                  :class="[
                    thumb_image_status === '0'
                      ? 'btn btn-secondary active'
                      : 'btn btn-secondary',
                  ]"
                >
                  <input
                    type="radio"
                    name="productImage"
                    id="option2"
                    value="0"
                    autocomplete="off"
                  />
                  非表示
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> 製品画像設定 </v-col>
            <v-col cols="2" class="thumb-box" id="thumb-box">
              <div class="thumbImg" style="border-color: white">
                <img
                  style="width: 50px; height: 50px; object-fit: cover"
                  id="preview"
                />
              </div>
            </v-col>
            <v-col class="" cols="7">
              <input
                class="thumb_image"
                id="thumb-image"
                type="file"
                name="image"
                autofocus
                @change="displayThumbImage"
              />
              <p class="modal-inputTtl mt-1">※最大画像サイズは50KBです。</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> タイトル </v-col>
            <v-col cols="9">
              <input
                class="title-topic"
                id="title"
                :value="title"
                @keyup="titleAlert()"
                maxlength="20"
              />
            </v-col>
            <div class="title-limit" id="title-limit">
              <span>20文字以内にしてください</span>
            </div>
          </v-row>
          <v-row>
            <v-col cols="3" class="modal-inputTtl"> コメント </v-col>
            <v-col cols="9">
              <textarea
                class="comment-topic"
                id="comment"
                name="t_name"
                placeholder="150文字以内で入力してください"
                maxlength="150"
                :value="comment"
                wrap="hard"
                @keyup="commentAlert()"
              ></textarea>
            </v-col>
            <div class="comment-limit" id="comment-limit">
              <span>150文字以内にしてください</span>
            </div>
          </v-row>
        </div>
        <div class="btn-wrap mt-6">
          <v-btn
            class="btn-save"
            id="btn-save-alert"
            depressed
            @click="reserveDisplayFlag()"
          >
            保存
          </v-btn>
        </div>
      </div>
    </modal>
    <thumbNailContentsOverModal />
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
import thumbNailContentsOverModal from "@/components/Modal/thumbNailContentsOverModal.vue";
export default {
  name: "RewriteModal",
  components: {
    thumbNailContentsOverModal,
  },
  props: {
    userInfo: {
      type: null,
      default: "",
      required: false,
    },
  },
  data: function () {
    return {
      cta_status: "", //cta遷移先の表示/非表示
      user_name_status: "", //ユーザー名表示/非表示
      title_status: "", //タイトル表示/非表示
      comment_status: "", //コメント表示/非表示
      thumb_image_status: "", //製品画像表示/非表示
      user_id: "", //ユーザーID
      user_name: "", //ユーザー名
      contents_kinds: "", //コンテンツ
      main_image_url: "", //画像URL
      title: "", //タイトル
      comment: "", //コメント
      post_date: "", //投稿日
      approval_status: "", //承認ステータス
      video_id: "", //vimeoのid
      order: "", //公開の順番
      main_thumb_image_url: "", //動画の代わりのサムネ
      cta: "", //ctaのurl
      thumb_image_url: "", //動画/画像の帯につくサムネ
      checkApproval_status: "", //保存する際の承認ステータス
      thumb_check: false,
      titleTextOverFlag: false, //タイトルの字数オーバーした際のアラート表示用フラグ
    };
  },
  watch: {
    userInfo(user) {
      this.user_id = user[0].user_id;
      this.cta_status = user[0].cta_status;
      this.user_name_status = user[0].user_name_status;
      this.title_status = user[0].title_status;
      this.comment_status = user[0].comment_status;
      this.thumb_image_status = user[0].thumb_status;
      this.user_name = user[0].user_name;
      this.contents_kinds = user[0].contents_kinds;
      this.main_image_url = user[0].main_image_url;
      this.title = user[0].title;
      this.comment = user[0].comment;
      this.post_date = user[0].post_date;
      this.approval_status = user[0].approval_status;
      this.video_id = user[0].video_id;
      this.order = user[0].order;
      this.main_thumb_image_url = user[0].main_thumb_image_url;
      this.cta = user[0].cta;
      this.thumb_image_url = user[0].thumb_image_url;
      this.checkApproval_status = user[0].checkApproval_status;
    },
  },
  methods: {
    onModalOpened: function () {
      if (this.thumb_image_url != "") {
        document.getElementById("thumb-box").style.visibility = "visible";
        document
          .getElementById("preview")
          .setAttribute("src", this.thumb_image_url);
      }
    },
    ///////////////////////////////////////
    ///////説明
    ///////タイトルの文字数をリアタイで測定し、字数オーバーしたらアラート表示
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    titleAlert: function () {
      var title = document.getElementById("title");
      if (title.value.length > 20) {
        this.titleTextOverFlag = true;
        document.getElementById("title-limit").style.visibility = "visible";
      } else {
        this.titleTextOverFlag = false;
        document.getElementById("title-limit").style.visibility = "hidden";
      }
    },
    ///////////////////////////////////////
    ///////説明
    ///////コメントの文字数をリアタイで測定し、字数オーバーしたらアラート表示
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    commentAlert: function () {
      var commentElement = document.getElementById("comment");
      if (commentElement.value.length > 150) {
        this.titleTextOverFlag = true;
        document.getElementById("comment-limit").style.visibility = "visible";
      } else {
        this.titleTextOverFlag = false;
        document.getElementById("comment-limit").style.visibility = "hidden";
      }
    },
    ///////////////////////////////////////
    ///////説明
    ///////コメントの値を返す
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    getComment: function () {
      var commentValue = document.getElementById("comment").value;
      return commentValue;
    },
    ///////////////////////////////////////
    ///////説明
    ///////タイトルの値を返す
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    getTitle: function () {
      var titleValue = document.getElementById("title").value;
      return titleValue;
    },
    ///////////////////////////////////////
    ///////説明
    ///////表示上のコメントの文字を変更する
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    changeCommentWord: function () {
      var changeInfoJson = {
        user_id: this.user_id, //値を変更するユーザーを特定する際に利用
        comment: this.getComment(), //コメント
      };
      this.$store.commit("changeCommentWord", changeInfoJson);
    },
    ///////////////////////////////////////
    ///////説明
    ///////表示上のタイトルの文字を変更する
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    changeTitleWord: function () {
      var changeInfoJson = {
        user_id: this.user_id, //値を変更するユーザーを特定する際に利用
        title: this.getTitle(), //タイトル
      };
      this.$store.commit("changeTitleWord", changeInfoJson);
    },

    ///////////////////////////////////////
    ///////説明
    ///////表示/非表示ステータスの編集を登録する
    //
    //
    //引数：なし
    /////////////////////////////////////////////////////
    reserveDisplayFlag: function () {
      this.$nextTick(function () {
        //現在選択中の値を全取得
        var activeNode = document.getElementsByClassName("active");

        var ctaUrl = document.getElementById("cta-url");

        var ctaUrlValue = ctaUrl.value;

        var thumbNailUrl = this.thumb_image_url;

        var nowThumbNailUrl = document
          .getElementById("preview")
          .getAttribute("src");
        if (nowThumbNailUrl != null) {
          thumbNailUrl = nowThumbNailUrl;
        }
        var statusListJson = {
          user_id: this.user_id,
          cta_status: "",
          user_name_status: "",
          title_status: "",
          comment_status: "",
          product_image_status: "",
          thumb_image_url: thumbNailUrl,
          cta: ctaUrlValue,
        };
        if (ctaUrlValue == "") {
          statusListJson["cta_status"] = "0";
        } else if (
          activeNode.item(0).children[0].value == 0 &&
          ctaUrlValue != ""
        ) {
          statusListJson["cta_status"] = "0";
        } else {
          statusListJson["cta_status"] = "1";
        }
        statusListJson["user_name_status"] =
          activeNode.item(1).children[0].value;
        statusListJson["title_status"] = activeNode.item(2).children[0].value;

        statusListJson["comment_status"] = activeNode.item(3).children[0].value;

        statusListJson["product_image_status"] =
          activeNode.item(4).children[0].value;

        this.$store.commit("changeStatus", statusListJson);

       // this.rewriteAlertUser();不要

        this.$modal.hide("user-modal");

        //vueのデータを変更
        this.changeCommentWord();

        //vueのデータを変更
        this.changeTitleWord();

        //storageに保存する
        //  this.reserveStatusFirebaseStorage(statusListJson);
      });
    },
    rewriteAlertUser: function () {
      alert(
        " 一時保存（設定）されたデータはリロード / プロジェクト切替 / 画面遷移/ ブラウザバックの操作により削除されますのでご注意ください。"
      );
    },
    /*
    rewriteAlertUser: function () {
      document
        .querySelector(`#rewriteSituationAlert`)
        .animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: 3000,
          fill: "forwards",
        });
        /*
      document
        .querySelector(`#rewriteSituationAlert`)
        .animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: 3000,
          fill: "forwards",
        });
    }*/
    ///////////////////////////////////////
    ///////説明
    ///////サムネイル画像を表示させる
    //
    //
    //引数：e:inputのイベント
    /////////////////////////////////////////////////////
    displayThumbImage: function (e) {
      var fileContents = e.target.files[0].size; //ファイルサイズ
      var limitSize = 51200;
      if (fileContents > limitSize) {
        ///////////////////ファイルの上限値を超えた場合の処理
        document.getElementById("thumb-image").value = "";
        document.getElementById("user-modal").style.display = "none";
        this.$modal.show("thumb-over-modal");
      } else {
        var reader = new FileReader();

        // 3. 準備が終わったら、id=sample1のsrc属性に選択した画像ファイルの情報を設定
        reader.onload = function (e) {
          document
            .getElementById("preview")
            .setAttribute("src", e.target.result);
          this.thumb_image_url = e.target.result;
        };

        // 4. 読み込んだ画像ファイルをURLに変換
        reader.readAsDataURL(e.target.files[0]);

        var thumbBox = document.getElementById("thumb-box");
        thumbBox.style.visibility = "visible";
      }
    },
    //////////////////////////////////////////////
    ////////説明：登録するサムネイル画像の登録IDを自動生成する
    /////
    /////
    /////
    /////引数:myStrong:実際は何も入らない
    /////////////////////////////////////////////////////////
    getUniqueStr: function (myStrong) {
      //クライアントIDを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      return (
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16)
      );
    },
    ///////////////////////////////////////
    ///////説明
    ///////firebaseStorageにステータスの変更を保存する
    //
    //
    //引数：e:inputのイベント
    /////////////////////////////////////////////////////
    reserveStatusFirebaseStorage: function (statusList) {
      var ctaUrl = document.getElementById("cta-url").value;
      var imageName = this.getUniqueStr();
      var data = {
        post_date: this.post_date,
        user_id: this.user_id,
        user_name: this.user_name,
        title: this.getTitle(),
        comment: this.getComment(),
        contents_kinds: this.contents_kinds,
        main_image_url: this.main_image_url,
        video_id: this.video_id,
        cta: ctaUrl,
        main_thumb_image_url: this.main_thumb_image_url,
        order: this.order,
        approval_status: this.checkApproval_status,
      };
      var statusListJson;
      statusListJson = statusList;
      var files = document.getElementById("thumb-image").files;
      var image = files[0];
      if (files.length != 0) {
        ////////////////////製品画像を選択している場合の処理/////////
        var ref = firebase
          .storage()
          .ref()
          .child(
            "/img/" +
              this.$store.state.clientId +
              "/" +
              this.$store.state.projectInfo[0][
                this.$store.state.selectedProject
              ].project_id +
              "/" +
              imageName
          );

        const userRef = firebase
          .storage()
          .ref()
          .child(
            "/user/" +
              this.$store.state.clientId +
              "/" +
              this.$store.state.projectInfo[0][
                this.$store.state.selectedProject
              ].project_id +
              "/" +
              data.user_id +
              ".json"
          );
        ref.put(image).then(function (snapshot) {
          snapshot.ref.getDownloadURL().then((url) => {
            //ユーザー情報
            const jsonString = JSON.stringify({
              post_date: data.post_date,
              user_id: data.user_id,
              user_name: data.user_name,
              title: this.getTitle(),
              comment: this.getComment(),
              approval_status: data.approval_status,
              contents_kinds: data.contents_kinds,
              main_image_url: data.main_image_url,
              video_id: data.video_id,
              thumb_image_url: url,
              user_name_status: statusListJson.user_name_status,
              title_status: statusListJson.title_status,
              comment_status: statusListJson.comment_status,
              cta_status: statusListJson.cta_status,
              cta: data.cta,
              thumb_status: statusListJson.product_image_status,
              main_thumb_image_url: data.main_thumb_image_url,
              order: data.order,
            });

            const blob = new Blob([jsonString], { type: "application/json" });
            userRef.put(blob).then(function () {});
          });
        });
      } else {
        /////////////////////////製品画像が選択されていない場合の処理///////////
        const userRef = firebase
          .storage()
          .ref()
          .child(
            "/user/" +
              this.$store.state.clientId +
              "/" +
              this.$store.state.projectInfo[0][
                this.$store.state.selectedProject
              ].project_id +
              "/" +
              data.user_id +
              ".json"
          );
        //ユーザー情報
        const jsonString = JSON.stringify({
          post_date: data.post_date,
          user_id: data.user_id,
          user_name: data.user_name,
          title: data.title,
          comment: data.comment,
          approval_status: data.approval_status,
          contents_kinds: data.contents_kinds,
          main_image_url: data.main_image_url,
          video_id: data.video_id,
          thumb_image_url: this.thumb_image_url,
          user_name_status: statusListJson.user_name_status,
          title_status: statusListJson.title_status,
          comment_status: statusListJson.comment_status,
          cta_status: statusListJson.cta_status,
          cta: data.cta,
          thumb_status: statusListJson.product_image_status,
          main_thumb_image_url: data.main_thumb_image_url,
          order: data.order,
        });

        const blob = new Blob([jsonString], { type: "application/json" });
        userRef.put(blob).then(function () {});
      }
    },
  },
};
var hoverContentsType; //「画像」か「動画」が入る
var classVal; // classの値を取得
var classVals; // 取得した値を分割
$(document).on(
  {
    mouseenter: function () {
      $(".publish-itemlist_item-rewritemodal")
        .not($(this))
        .find(".publish-itemlist_item_play")
        .fadeIn(200);
      classVal = $(this).attr("class"); // classの値を取得
      classVals = classVal.split(" "); // 取得した値を分割
      // 配列になっているのでforで一つずつ取得できる
      for (var i = 0; i < classVals.length; i++) {
        if (classVals[i] == "image") {
          hoverContentsType = "画像";
        } else {
          hoverContentsType = "動画";
        }
      }
      if (hoverContentsType == "動画") {
        if (
          $($(this)).find(".publish-itemlist_item_play").next("div")[0].id == ""
        ) {
          $($(this)).find(".publish-itemlist_item_play").fadeIn(200);
        } else {
          $($(this)).find(".publish-itemlist_item_play").fadeOut(200);
        }

        var clickedId = $(".publish-itemlist_item-rewritemodal")
          .find(".publish-itemlist_item_play")
          .next("div")[0].id;
        var iframe = document.getElementById(clickedId);
        var player = new Vimeo.Player(iframe);
        player.play();
      }
    },
    mouseleave: function () {
      if (hoverContentsType == "動画") {
        $(".publish-itemlist_item-rewritemodal")
          .not($(this))
          .find(".publish-itemlist_item_play")
          .fadeIn(200);
        $(".publish-itemlist_item-rewritemodal")
          .find(".publish-itemlist_item_play")
          .not($(this))
          .fadeIn(200);
        var clickedId = $(".publish-itemlist_item-rewritemodal")
          .find(".publish-itemlist_item_play")
          .next("div")[0].id;
        var iframe = document.getElementById(clickedId);
        var player = new Vimeo.Player(iframe);
        player.pause();
      }
    },
  },
  ".publish-itemlist_item-rewritemodal"
);
</script>

<style scoped>
@charset "UTF-8";

.user-modal {
  z-index: 9999999999999999999999999999999999999999999999999;
}

#thumb-box {
  visibility: hidden;
}

.v-application {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
}

.v-application .text-h6 {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif !important;
}

.v-navigation-drawer__content {
  padding-top: 2rem;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background: #eae1d9;
}

@media (max-width: 1259px) {
  .pconlybr {
    display: none;
  }
}

.publish-proj .publish-itemlist {
  position: relative;
}

.publish-proj .publish-itemlist-bg {
  position: absolute;
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  z-index: -1;
}

.publish-proj .publish-itemlist-bg-item {
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.publish-proj .publish-itemlist-bg-item + .publish-itemlist-bg-item {
  border-left: 2px dashed #bababa;
}

.publish-proj .publish-itemlist-bg-item-num {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 1rem;
}

@media (max-width: 1259px) {
  .publish-proj .publish-itemlist-bg-item-num {
    font-size: 20px;
  }
}

.publish-proj .publish-itemlist-bg-item-txt {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1259px) {
  .publish-proj .publish-itemlist-bg-item-txt {
    font-size: 10px;
  }
}

.publish-proj .publish-itemlist-bg-item-txt + .publish-itemlist-bg-item-txt {
  margin-top: 1em;
}

.modal-wrapper ::v-deep {
  display: flex !important;/**本来この属性はない */
  width: 100% !important;
  height: 100% !important;
  justify-content: center !important;
  overflow: auto !important;
  padding: 20px 40px !important;
  flex-wrap: wrap !important;
}

.modal-wrap .btn-wrap {
  width: 100%;
}

.modal-wrap .btn-wrap .btn-save {
  margin-left: auto;
  margin-right: auto;
}

.modal-wrap .btn-secondary {
  background: #fff;
  color: #4a4a4a;
  border: 1px solid #b9bcc4;
  font-size: 0.75rem;
}

.modal-wrap .btn-secondary:not(:disabled):not(.disabled).active,
.modal-wrap .btn-secondary:not(:disabled):not(.disabled):active,
.modal-wrap .show > .btn-secondary.dropdown-toggle {
  background: linear-gradient(#e18140, #d6600f);
  color: #fff;
  border: 1px solid #b9bcc4;
  font-size: 0.75rem;
}

.modal-wrap input.siteURL {
  border: 1px solid #b9bcc4;
  padding: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
}
.modal-wrap input.modal-siteURL {
  border: 1px solid #b9bcc4;
  padding: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
}

.modal-wrap input.title-topic {
  border: 1px solid #b9bcc4;
  padding: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
}

.modal-wrap textarea.comment-topic {
  border: 1px solid #b9bcc4;
  padding: 0.25rem;
  width: 100%;
  height: 162px;
  font-size: 0.75rem;
  resize: none;
}

.title-limit {
  position: relative;
  left: 120px;
  font-size: 12px;
  color: red;
  visibility: hidden;
}

.comment-limit {
  position: relative;
  left: 120px;
  font-size: 12px;
  color: red;
  visibility: hidden;
}

.modal-wrap .modal-inputTtl {
  font-size: 0.75rem;
}

.thumbImg {
  width: 50px;
  height: 50px;
}

#user_thumbnail_img {
  margin-top: 0;
  width: 50px;
  height: 50px;
  background-color: red;
}

#img-thum {
  margin-top: 0;
  width: 60px;
  height: 60px;
}

#user-thumb {
  background-color: lavenderblush;
}

.thumbImg_img {
  width: 50px;
  height: 50px;
  background-color: red;
}

.modal-wrap .btn-save {
  background: linear-gradient(#00979c, #068587);
  margin-bottom: 5%;
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 100px;
  transition: 0.3s;
  border-radius: 0;
}

#user-thumbnail-img {
  width: 50;
  height: 50;
}

.modal-wrap .btn-save .v-btn__content {
  display: block;
}

.modal-wrap .btn-save img {
  margin-right: 0.5rem;
}

.modal-wrap .btn-save:hover {
  filter: brightness(110%);
}

@media (max-width: 1259px) {
  .v--modal-overlay .v--modal-box {
    width: 80% !important;
    left: 10% !important;
  }
}

.btn-modalClose {
  background: linear-gradient(#b1b1b1, #8e8e8e);
  padding: 0.5rem 1rem;
  color: #fff !important;
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 100px;
  transition: 0.3s;
  border-radius: 0;
}

.btn-modalClose .v-btn__content {
  display: block;
}

.btn-modalClose img {
  margin-right: 0.5rem;
}

.btn-modalClose:hover {
  filter: brightness(110%);
}

.v--modal-overlay .v--modal-box {
  height: auto !important;
  width: 80% !important;
  max-width: 720px !important;
  left: initial !important;
  top: initial !important;
}

#previewModal .v--modal-box {
  max-width: 960px !important;
  padding: 20px;
}

#overModal .v--modal-box {
  max-width: 488px !important;
  padding: 20px;
}

.v--modal-overlay .v--modal-background-click {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*# sourceMappingURL=publishingSetting.css.map */

body {
  margin: 0;
  font-family: "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  width: 100%;
}

main {
  width: 100%;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: bottom;
  border-style: none;
  max-width: 100%;
  width: auto;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  display: none; /* ラジオボタンを非表示にする */
}
input[type="radio"]:checked + label {
  color: linear-gradient(
    #e18140,
    #d6600f
  ); /* マウス選択時のフォント色を指定する */
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

#content {
  width: 80%;
  max-width: 240px;
  z-index: 2;
  border-radius: 5px;
  padding: 2.25rem;
  background: #fff;
}

.publishing-check-icon {
  margin-bottom: 10px;
}

#circle-icon {
  font-size: 80px;
}

.publish-itemlist {
  z-index: 0;
  width: 100%;
  border: 1px solid #9f9f9f;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 10px;
  position: relative;
  overflow-y: scroll;
  height: calc(50% - 19%);
}
@media (max-width: 991px) {
  .publish-itemlist {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575px) {
  .publish-itemlist {
    max-width: 286px;
    padding: 0;
  }
}

.publish-itemlist .slick-list {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.publish-itemlist .slick-list::after {
  content: "";
  width: 100%;
  height: calc(100% - 4rem);
  position: absolute;
  left: 0;
  top: 2rem;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0) 95%,
    rgba(0, 0, 0, 0.5)
  );
}

.publish-itemlist_item-rewritemodal {
  position: relative;
}

.publish-itemlist_item_wrap {
  width: 100%;
  padding-top: 177.777778%;
  position: relative;
  transition: 0.3s;
}

.publish-itemlist_item_name {
  color: #fff;
  padding: 0 0.75rem;
  position: absolute;
  left: 0;
  top: 0.75rem;
  font-size: 0.75rem;
  z-index: 1;
}

.close-button {
  width: 19px;
  height: 19px;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  padding: 0 0.75rem;
  position: absolute;
  right: 0;
  top: 0.75rem;
  z-index: 1;
}

.publish-itemlist_item_body {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 5%;
  width: 100%;
}

.publish-itemlist_item_thumb {
  font-size: 0.75rem;
  width: calc(3em + 2.53rem);
  height: calc(3em + 1rem);
}

.publish-itemlist_item_thumb img {
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.publish-itemlist_item_thumb + .re-publish-itemlist_item_txt {
  width: calc(100% - 3em + 1rem);
}

.re-publish-itemlist_item_txt {
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  width: 100%;
  margin-bottom: 0;
  pointer-events: none;
}

.re-publish-itemlist_item_txt p {
  height: 3em;
  overflow: hidden;
  margin-bottom: 0;
}

.publish-itemlist_item-rewritemodal video,
.publish-itemlist_item-rewritemodal img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}

.publish-itemlist_item-rewritemodal img {
  object-fit: cover;
  cursor: initial;
}

.publish-itemlist_item_play {
  position: absolute;
  width: 38px;
  height: 38px;
  left: calc(50% - 19px);
  top: calc(50% - 19px);
  z-index: 1;
}

.publish-itemlist_arrow_prev {
  position: absolute;
  width: 13px;
  height: 32px;
  left: 1rem;
  top: calc(50% - 16px);
  z-index: 100;
  cursor: pointer;
}

.publish-itemlist_arrow_next {
  position: absolute;
  width: 13px;
  height: 32px;
  right: 1rem;
  top: calc(50% - 16px);
  z-index: 100;
  cursor: pointer;
}

@keyframes rtoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(-90deg) translateZ(51px) translateX(-100%);
    z-index: -1;
  }
}

@keyframes rtoIn {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

@keyframes ltoOut {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(45deg) translateZ(-204px) translateX(100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(90deg) translateZ(51px) translateX(100%);
    z-index: -1;
  }
}

@keyframes ltoIn {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(-204px) translateX(-100%);
    z-index: 1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

@keyframes rtoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(-90deg) translateZ(calc(40vh / 8)) translateX(-100%);
    z-index: -1;
  }
}

@keyframes rtoIn_short {
  0% {
    transform: rotateY(90deg) translateZ(0px) translateX(100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: 1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

@keyframes ltoOut_short {
  0% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }

  50% {
    transform: rotateY(45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(100%);
    z-index: -1;
  }

  100% {
    transform: rotateY(90deg) translateZ(calc(40vh / 8)) translateX(100%);
    z-index: -1;
  }
}

@keyframes ltoIn_short {
  0% {
    transform: rotateY(-90deg) translateZ(0px) translateX(-100%);
    z-index: -1;
  }

  50% {
    transform: rotateY(-45deg) translateZ(calc(-40vh * 0.56824513))
      translateX(-100%);
    z-index: 1;
  }

  100% {
    transform: rotateY(0deg) translateZ(0) translateX(0);
    z-index: 1;
  }
}

.publish-card {
  width: 100%;
  background: none;
  border-radius: 0;
  border: none;
}

.thumb_image {
  border: 1px solid #b9bcc4;
  border-radius: 5px;
  font-size: 12px;
}

.thumb_image + p {
  font-size: 12px;
}
</style>