<template>
  <v-container class="proj" fluid fill>
    <v-row
      style="height: 50px"
      justify="end"
      align="baseline"
      class="proj-head"
    >
      <div class="ml-1 mt-10" style="width: 300px; margin: 0 0 0 auto">
        <v-select
          class="ml-3"
          height="10px"
          :items="dropdownItems"
          label="プロジェクト"
          @change="changeProject"
          dense
          outlined
          v-model="selectedValue"
        ></v-select>
      </div>
    </v-row>
  </v-container>
</template>
<script src="https://unpkg.com/vue-select@latest"></script> 
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
export default {
  name: "Dropdown",
  mounted() {
    //初期化
    this.dropdownItems = [];

    //storeからプロジェクト情報を取得
    var projectList = this.$store.state.projectInfo[0];

    this.addSelectableProject(projectList);

    if (this.$store.state.selectedProject !== "") {
      if (this.selectedValue == "") {
        this.selectedValue =
          this.$store.state.projectInfo[0][
            this.$store.state.selectedProject
          ].project_name;
      }
    }
  },
  data: function () {
    return {
      dropdownItems: [], //ドロップダウンのアイテム
      temporaryData: [],
      selectedValue: "", //選択中のプロジェクト
      checked: [], //非公開一覧のチェックボックスを管理
    };
  },
  computed: {
    selectedProject() {
      return this.$store.state.selectedProject;
    },
  },
  watch: {
    selectedProject(val, old) {
      this.selectedValue =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].project_name;
    },
  },
  methods: {
    /**
     * プロジェクト選択にプロジェクト名を追加
     */
    addSelectableProject: function (projectList) {
      //ドロップボックスの値を選択したプロジェクト名にする
      for (var i = 0; i < projectList.length; i++) {
        this.dropdownItems.push(projectList[i].project_name);
      }
    },
    /**
     * プロジェクトを変更
     */
    changeProject: function (event) {
      for (var i = 0; i < this.dropdownItems.length; i++) {
        if (this.dropdownItems[i] == event) {
          this.$store.commit("selectedProjectSetter", i);
        }
      }
      var nowView = this.checkNowScreen();
      if (nowView == "approvalSetting" || nowView == "publishingSetting") {
        this.waitLoading();
        this.getUser();
      }
    },
    alertDisplay: function () {
      //現在のパス
      var nowRoute = this.$store.state.nowRounte;
      if (nowRoute == "公開設定") {
        var alrtResult = window.confirm(
          "一時保存（設定）されたデータは保存されません。よろしいですか"
        );
        if (alrtResult == true) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    /**
     * 現在のパスを取得
     */
    checkNowScreen: function () {
      var currentPass = this.$router.currentRoute.path;
      if (currentPass == "/projectManagiment") {
        return "projectManagiment";
      } else if (currentPass == "/projectInfo") {
        return "projectInfo";
      } else if (currentPass == "/approvalSetting") {
        return "approvalSetting";
      } else if (currentPass == "/publishingSetting") {
        return "publishingSetting";
      }
    },
    /**
     * プロジェクトの全ユーザー情報を取得
     */
    getUser: async function (index) {
      this.$store.commit("changeProject", true);
      this.$store.commit("resetApprovalInfo");
      this.$store.commit("getUserCheck", false);
      var clientId = this.$store.state.clientId;
      var projectId =
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .project_id;
      var instance = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/user/" + clientId + "/" + projectId);
      var list = await listRef.listAll();
      var imgList = [];
      var count = 0;
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        const userData = await this.dowonloadUserLink(imgList[i]);
        store.commit("setApprovalInfo", userData);
        store.commit("getUserCheck", true);
        /*
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              instance.temporaryData.push(JSON.parse(blob));
              store.commit("setApprovalInfo", JSON.parse(blob));
              store.commit("getUserCheck", true);
              count += 1;
              if (count == imgList.length) {
                store.commit("resetPublishedContents");
                instance.checked = [];

                var select = document.getElementsByName("order");
                for (var i = 0; i < select.length; i++) {
                  select[i][i].selected = true;
                }
                //承認済みコンテンツを承認した順番に並べ直す
                instance.$store.commit("changeApprovaledOrder");
                //チェックボックスにチェックをつける処理
                instance.$nextTick(function () {
                  instance.checkedMethods();
                });
                store.commit("changeProject", false);
              }
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
          */
      }
      store.commit("resetPublishedContents");
      instance.checked = [];

      var select = document.getElementsByName("order");
      for (var i = 0; i < select.length; i++) {
        select[i][i].selected = true;
      }
      //承認済みコンテンツを承認した順番に並べ直す
      instance.$store.commit("changeApprovaledOrder");
      //チェックボックスにチェックをつける処理
      instance.$nextTick(function () {
        instance.checkedMethods();
      });
      store.commit("changeProject", false);
      this.$store.commit("getUserCheck", true);
      this.$store.commit("changeProject", false);
      this.downloaded();
      this.$nextTick(function () {
        document.body.style.overflow = "";
        document.getElementsByTagName("html")[0].style.overflow = "";
      });
      /*
      if (imgList.length == 0) {
        this.$store.commit("getUserCheck", true);
        this.$store.commit("changeProject", false);
      }
      */
    },
    /**
     * ユーザーデータのダウンロードリンクを取得
     */
    dowonloadUserLink: function (userList) {
      var userData = new Promise((resolve) => {
        userList.getMetadata().then((metadata) => {
          userList.getDownloadURL().then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              var jsonParse = JSON.parse(blob);
              const date = new Date(metadata.timeCreated);
              jsonParse.timeCreated = date.getTime().toString();
              resolve(jsonParse);
            };
            xhr.send();
          });
        });
      });
      return userData;
    },
    /**
     * ローディング表示
     */
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    /**
     * ローディング解除
     */
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    /**
     * 公開設定のユーザーにチェックをつける
     */
    checkedMethods: function () {
      var checkBox = document.getElementsByName("checkBox");

      //並び順
      var order = [];

      //並び順だけ格納する
      for (var i = 0; i < this.$store.state.approvaled.length; i++) {
        if (this.$store.state.approvaled[i].approval_status == "2") {
          order.push(this.$store.state.approvaled[i].order);
        }
      }

      //番号順にソートをかける
      var sortOrder = order.sort((a, b) => a - b);

      for (var i = 0; i < sortOrder.length; i++) {
        for (var j = 0; j < this.$store.state.approvaled.length; j++) {
          if (
            this.$store.state.approvaled[j].order == sortOrder[i] &&
            this.$store.state.approvaled[j].approval_status == "2"
          ) {
            for (var t = 0; t < checkBox.length; t++) {
              if (checkBox[t].id == this.$store.state.approvaled[j].user_id) {
                checkBox[t].checked = true;
                if (
                  this.$store.state.publishedContents.length % 5 == 0 &&
                  this.$store.state.publishedContents.length != 0
                ) {
                  this.counter += 5;
                }
                this.$store.commit(
                  "addPublishedContents",
                  this.$store.state.approvaled[j]
                ); //公開コンテンツに移す
                this.publishedContentsDesignChange();
              }
            }
          }
        }
      }
    },
    /**
     * 公開上限値を超えた際のアラート
     */
    publishedContentsDesignChange() {
      var publishedContents = this.$store.state.publishedContents;
      var approvaledContents = this.$store.state.approvaled;
      if (publishedContents.length <= 5 && approvaledContents.length > 5) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(35% - 10px)");
      } else if (
        publishedContents.length <= 5 &&
        approvaledContents.length <= 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(45% - 10px)");
      } else if (
        publishedContents.length > 5 &&
        approvaledContents.length >= 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(29% - 10px)");
      }
      if (publishedContents.length == 0 && approvaledContents.length > 5) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(50% - 8px)");
      } else if (
        publishedContents.length == 0 &&
        approvaledContents.length <= 5
      ) {
        var publishedContentsNode = document.getElementById("publish-itemlist");
        publishedContentsNode.style.setProperty("height", "calc(60% - 25px)");
      }
    },
  },
};
</script>

<style scoped>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300,400;500;700&display=swap");
.tabbr {
  display: none;
}
@media (max-width: 1259px) {
  .tabbr {
    display: block;
  }
}

.spbr {
  display: none;
}
@media (max-width: 767px) {
  .spbr {
    display: block;
  }
}

@media (max-width: 767px) {
  .pcbr {
    display: none;
  }
}

.js-anime {
  opacity: 0;
  transition: 0.7s;
}
.js-anime.is-slide {
  transform: translateY(100px);
}
.js-anime.is-anime {
  opacity: 1;
  transform: translateY(0);
}

.v-application {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
}
.v-application .text-h6 {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background: #eae1d9;
}

.v-navigation-drawer__content {
  padding-top: 2rem;
}

.proj {
  padding: 2.5rem 3rem;
  z-index: 0;
}
.proj-row {
  margin-bottom: 0;
  width: 100%;
}
.proj-head {
  margin-top: 0;
  margin-right: 0;
}
.proj .col-ttl {
  font-weight: bold;
}
.proj .col-txt {
  font-weight: 300;
  width: 30%;
}
.proj .col-txt p {
  word-break: break-all;
  margin-bottom: 0;
}
.proj .col-txt input {
  width: 100%;
  padding: 0.25rem 0.5rem;
}
.proj-help {
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  color: #a5a5a5;
  position: relative;
}
.proj-help-item {
  padding: 0.75rem 1rem;
  background: #f3f3f3;
  color: #4a4a4a;
  font-size: 0.75rem;
  font-weight: bold;
  width: 400px;
  position: absolute;
  left: 0;
  top: -146px;
  border: 1px solid #707070;
  display: none;
}
.proj-help-item::after {
  content: "";
  border-top: 16px solid #f3f3f3;
  border-left: 8px solid rgba(255, 255, 255, 0);
  border-right: 8px solid rgba(255, 255, 255, 0);
  position: absolute;
  left: 4px;
  bottom: -15px;
}
.proj-help-item::before {
  content: "";
  border-top: 18px solid #707070;
  border-left: 9px solid rgba(255, 255, 255, 0);
  border-right: 9px solid rgba(255, 255, 255, 0);
  position: absolute;
  left: 3px;
  bottom: -18px;
}
.proj-help-item p {
  margin-bottom: 0;
  line-height: 1.5;
}
.proj-help-item p + p {
  margin-top: 1em;
}
.proj-help_btn {
  cursor: pointer;
  font-size: 1.25rem;
}
.proj-help_btn:hover + .proj-help-item {
  display: block;
}
.proj .clip {
  border: 1px solid #707070;
  padding: 0.25rem 0.75rem;
  border-radius: 0;
  background-color: #fff !important;
}
.proj .btn-edit {
  border-radius: 0;
  background: linear-gradient(#e18140, #d6600f);
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
}
.proj .btn-edit:hover {
  filter: brightness(110%);
}
.proj .btn-save {
  border-radius: 0;
  background: linear-gradient(#00979c, #068587);
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
}
.proj .btn-save:hover {
  filter: brightness(110%);
}
</style>
