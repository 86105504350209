<template>
  <modal
    name="thumb-over-modal"
    width="488px"
    height="203px"
    style="background: #1c1c1c 0% 0% no-repeat padding-box"
  >
    <div
      class="vm--modal"
      style="background: #FFFFFF 0% 0% no-repeat padding-box; width=100%;height:100%"
    >
      <div class="contents-container">
        <p class="text">
          ※画像のサイズが大きすぎます。<br />※<span
            ><a href="https://resizer.myct.jp/" target="_blank">画像圧縮はこちら</a></span
          >（50px指定で圧縮して下さい。）
        </p>
      </div>
      <div class="button-container">
        <button class="button" v-on:click="hide()">閉じる</button>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  computed: {
    publishedContents() {
      //公開中コンテンツの配列を渡す
      return this.$store.state.publishedContents;
    },
  },
  data() {
    return {
      screenFlag: true,
    };
  },
  methods: {
    hide: function () {
        console.log(document.getElementById("user-modal"))
      if (document.getElementById("user-modal") == null) {
        document.getElementById("all-user-modal").style.display = "block";
      } else {
        document.getElementById("user-modal").style.display = "block";
      }
      this.$modal.hide("thumb-over-modal");
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
.button {
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Noto Sans JP", sans-serif;
  width: 100px;
  height: 40px;
  margin-left: 100px;
}

.contents-container {
  width: 400px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 46px;
  margin-bottom: -10px;
}

.button-container {
  width: 320px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}

.text {
  width: 400px;
  font-family: "Noto Sans JP", sans-serif;
}

span {
  text-decoration: underline;
}
</style>