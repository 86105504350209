<template>
  <div class="home">
    <ProjectLogin @child-emit="loginUser" />
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
// @ is an alias to /src
import ProjectLogin from "@/components/ProjectLogin/ProjectLoginComponent.vue";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export default {
  name: "ProjectLoginPage",
  components: {
    ProjectLogin,
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  methods: {
    loginUser() {
      if (this.checkFormValue() == true) {
        //ローディング表示
        document.getElementById("loading").style.display = "block";
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            firebase
              .auth()
              .signInWithEmailAndPassword(
                this.$store.state.loginEmail,
                this.$store.state.loginPassword
              )
              .then(
                async (user) => {
                  // 成功時の処理
                  this.$store.commit("setUser", "true");
                  var authAlert = document.getElementById("auth-alert-text");
                  authAlert.style.display = "none";

                  //クライアントを取得
                  await this.getClient();
                },
                (error) => {
                  var authAlert = document.getElementById("auth-alert-text");
                  authAlert.style.display = "block";
                  //ローディング表示
                  document.getElementById("loading").style.display = "none";
                }
              );
          });
      }
    },
    ///////////////////////////////////
    ////////////////////////
    //////////////
    ///説明
    ///
    ///クライアント情報を取得する
    ///////
    /////////
    //////////////////////////////////////////
    getClient: function () {
      console.log("----getClientが呼び出されました-----");
      var clientId = this.getQueryParam("rrdwa");
      var seacretClientId = this.getQueryParam("sec");

      //クエリパラメータで取得したIDをstoreにセットする
      this.$store.commit("setClientId", clientId);
      this.$store.commit("setSeacretPram", seacretClientId);

      //ストレージからクライアント情報を取得する
      this.getStorageClient();
    },
    ///////////////////////////////////
    ////////////////////////
    //////////////
    ///説明
    ///
    ///ストレージからクライアント情報を取得する
    ///////
    /////////
    //////////////////////////////////////////
    getStorageClient: function () {
      console.log("----getstorageClientが呼び出されました-----");
      var instance = this;
      var router = this.$router;
      var store = this.$store;

      firebase
        .storage()
        .ref()
        .child(
          "/client/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.seacretClientId +
            "/" +
            this.$store.state.clientId +
            ".json"
        )
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            instance.temporaryData.push(JSON.parse(blob));
            store.commit("setClientsInfo", JSON.parse(blob));
            //ローディング非表示
            document.getElementById("loading").style.display = "none";

            router.push("/projectManagiment");
          };
          xhr.send();
        })
        .catch((error) => {
          console.log("error");
          //ローディング非表示
          document.getElementById("loading").style.display = "none";
          var authAlert = document.getElementById("auth-alert-text");
          authAlert.style.display = "block";
        })
        .then((response) => {
          // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
          const data = response;
        });
    },
    checkFormValue: function () {
      if (
        //メール及びパスが共に未入力
        this.$store.state.loginEmail == "" &&
        this.$store.state.loginPassword == ""
      ) {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("pass-alert").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "block";
        return false;
      } else if (
        //メールアドレスのみ未入力
        this.$store.state.loginEmail == "" &&
        this.$store.state.loginPassword != ""
      ) {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "none";
        document.getElementById("pass-alert").style.display = "none";
        document.getElementById("mail-alert").style.display = "block";
        return false;
      } else if (
        this.$store.state.loginEmail != "" &&
        this.$store.state.loginPassword == ""
      ) {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "none";
        document.getElementById("pass-alert").style.display = "block";
        return false;
      } else {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "none";
        document.getElementById("pass-alert").style.display = "none";
        return true;
      }
    },
    //////////////////////////////
    ////説明
    ////ログイン画面URLのクエリパラメータを取得する
    ////
    ////
    ///////////
    /////////////////
    ///////////////////////////////////////
    getQueryParam: function (name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authenticatedUser = true;
      } else {
        this.authenticatedUser = false;
      }
    });
  },
};
</script>