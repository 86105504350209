const VimeoAccount = require("vimeo").Vimeo;
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new VimeoAccount(
    VIMEO_CLIENT_ID, // ID
    VIMEO_CLIENT_SECRET, // 秘密鍵
    VIMEO_ACCESS_TOKEN // アクセストークン
);
const tus = require("tus-js-client");
import { tikQErrorManage } from "../../utility/tikQErrorManage"
import { sessionRegister } from "../../utility/sessionRegister"
import { sessionGetter } from "../../utility/sessionGetter";
import { sessionDelete } from "../../utility/sessionDelete";
import { firebaseStorageDelete } from "../../utility/firebaseStorageDelete";
export const tiktokMethodsMixin = {
    mixins: [tikQErrorManage, sessionRegister, sessionGetter, sessionDelete, firebaseStorageDelete],
    data: function () {
        return {
        }
    },
    methods: {
        /**
         * TikTokから動画をダウンロードし、その動画をVIVEWに取り込む処理の大枠
         * @param {*} userInfoLists ユーザーリスト（csvデータ）
         * @param {*} vimeoFolder VimeoのフォルダーID
         */
        userUploadMainFunc: async function (userInfoLists, vimeoFolder) {
            const clientId = this.$store.state.clientId;
            const projectId = this.$store.state.projectInfo[0][this.$store.state.selectedProject]
                .project_id;
            const uploadingProjectIndex = this.$store.state.selectedProject;

            //アップロードが始まったことを登録
            this.registerAllUploadClear(false);

            //アップロードを行うプロジェクトを登録
            this.vuexStore.commit("setUploadingProjectId", projectId);

            //クライアントIDとプロジェクトIDのセッションを登録
            this.registerSessionClientId(clientId);
            this.registerSessionProjectId(projectId);

           

            //処理を進める
            this.movieDownloadAllProcess(userInfoLists, vimeoFolder, clientId, projectId, uploadingProjectIndex);

        },
       
        /**
         * TikTokから動画をダウンロードしvimeo・storageにアップロードする処理の中身
         * @param {*} userInfo tiktokから動画をダウンロードしてくるユーザ情報のリスト（csvから読み取ったもの）
         * @param {*} vimeoFolder vimeoへアップロードする際のフォルダID
         */
        movieDownloadAllProcess: async function (userInfo, vimeoFolder, clientId, projectId, uploadingProjectIndex) {
            var userId = "";

            for (var i = 0; i < userInfo.length - 1; i++) {

                //ユーザーID取得
                if (userInfo[i + 1][this.userIDLine] != "") {
                    userId = userInfo[i + 1][this.userIDLine];
                }

                //ビデオIDの取得処理
                const videoUrl = userInfo[i + 1][this.postMovieUrl];
                const videoId = this.getTiktokVideoUrlParam(videoUrl);
                try {

                    //動画・画像のダウンロード
                    const contentsData = await Promise.all([
                        this.videoDownload(userId, videoId),
                        this.imageDownload(userId, videoId),
                    ]);

                    //動画・画像のデータ
                    const videoData = contentsData[0];
                    const imageData = contentsData[1];

                    //動画のアクセス権エラー（こちらでの対応は厳しい...）
                    if (videoData == "403") {
                        console.log("アクセス拒否される動画URLが返ってきました");
                        console.log(i + 1);
                        this.accessErrorManager(videoData, i + 1);

                        //再度動画をアップロードするために格納しておく
                        this.setRetryData(userInfo[i + 1], i + 1);
                        continue;
                    }


                    //動画のプライバシーエラー
                    if (videoData == "動画アクセスエラー" || imageData == "画像アクセスエラー") {
                        this.accessErrorManager("動画のプライバシー設定またはアクセスエラー", i + 1);
                    } else {

                        //セッションに動画・画像のデータが取得できたことを登録
                        this.registerSessionMovieDownloadSuccess(true);
                        this.registerSessionImageDownloadSuccess(true);

                        try {


                            //動画のアップロード/及び画像のアップロード処理
                            var getData = await this.mainFirebaseStorageProcess(
                                videoData,
                                imageData,
                                vimeoFolder,
                                clientId,
                                projectId
                            );

                            //firebase storageにユーザ情報を登録する
                            await this.registerUser(getData.videoId, getData.imageUrl, userInfo[i + 1][this.postedDateLine], userId, userInfo[i + 1][this.titleLine], userInfo[i + 1][this.commentLine], this.movieDataSize, clientId, projectId,userInfo[i + 1][this.ctaLinkLine]);

                            var dbRegisterFunc = [this.updateClientInfo(clientId), this.updateProjectInfo(clientId, projectId)];

                            //dbを更新する関数を実行する
                            await Promise.allSettled(dbRegisterFunc);

                            //プロジェクト詳細画面の使用量DBと使用本数を更新する
                            this.projectInfoScreenUpdate(uploadingProjectIndex, this.movieDataSize, 1);

                            //セッションを削除
                            this.deleteUploadSession();


                        } catch (e) {
                            console.log("DBエラー");
                            console.log(e.message);
                            this.dbErrorManager(e.message, i + 1);
                            //セッションを削除
                            this.deleteUploadSession();
                        }
                    }
                } catch (error) {
                    console.log("tiktok動画にアクセスできませんでした");
                    this.dbErrorManager(error.message, i + 1);
                    //セッションを削除
                    this.deleteUploadSession();
                }
            }
            console.log("アップロードの処理が全て終了しました");

            this.registerAllUploadClear(true);

            //アップロードがおこなわれたプロジェクトID
            const uploadedProjectId = this.vuexStore.state.uploadingProjectId;

            //選択中のプロジェクトID
            const selectedProjectId = this.vuexStore.state.projectInfo[0][this.vuexStore.state.selectedProject]
                .project_id;

            if (this.vuexStore.state.nowRounte == "動画一括アップロード" && uploadedProjectId == selectedProjectId) {
                //エラーチェックが入る
                this.checkError();
            }

            if (this.$store.state.retryUserData.length > 0) {
                this.retryUpload(vimeoFolder, clientId, projectId, uploadingProjectIndex);
            }

            //ローディングアイコンを非表示
            this.vuexStore.commit("manageUploadIcon", false);
            if (this.vuexStore.state.errorPartList.length == 0) {
                this.vuexStore.commit("changeUploadedMessage", true);
            } else {
                this.vuexStore.commit("changeUploadedMessage", false);
            }
        },
        /**
         * 動画データのダウンロード
         * @returns 動画データ
         */
        videoDownload: async function (userId, videoId) {
            console.log("動画のスクレイピングが始まりました");
            const userAgent = "0";
            var postParam = `tiktok_user_id=${userId}&video_id=${videoId}&userAgent_id=${userAgent}`;
            var responseData = new Promise((resolve) => {
                var xhr = new XMLHttpRequest();
                xhr.open(
                    "post",
                    "https://us-central1-admin-short-video.cloudfunctions.net/puppeteerTikTok"
                );
                xhr.responseType = "blob";
                xhr.setRequestHeader(
                    "Content-Type",
                    "application/x-www-form-urlencoded"
                );
                xhr.onload = function () {
                    if (xhr.status == 200) {
                        var tiktokVideoBlobData = xhr.response;
                        var blobDataMp4 = new Blob([tiktokVideoBlobData], {
                            type: "video/mp4",
                        });
                        console.log(blobDataMp4);
                        console.log("データが返ってきました....動画アクセス成功");
                        //resolve(403);//テスト用
                        resolve(blobDataMp4);//本番用
                    } else {
                        //エラー処理
                        console.log("エラー");
                        console.log(xhr);
                        console.log(`ステータスコードは${xhr.status}です`);
                        resolve(403);
                    }
                };
                xhr.onerror = function () {
                    console.log("動画アクセスエラーですよ");
                    resolve("動画アクセスエラー");
                }
                xhr.send(postParam);
            });
            return responseData;
        },
        /**
        * 説明
        * 画像データのダウンロード
        * @returns 動画データ
        */
        imageDownload: async function (userId, videoId) {
            console.log("画像のスクレイピングが始まりました");
            const userAgent = "0";
            var postParam = `tiktok_user_id=${userId}&video_id=${videoId}&userAgent_id=${userAgent}`;
            var responseData = new Promise((resolve) => {
                var xhr = new XMLHttpRequest();
                xhr.open(
                    "post",
                    "https://us-central1-admin-short-video.cloudfunctions.net/puppeteerTikTokImage"
                );
                xhr.responseType = "blob";
                xhr.setRequestHeader(
                    "Content-Type",
                    "application/x-www-form-urlencoded"
                );
                xhr.onload = function () {
                    if (xhr.status == 200) {
                        var tiktokVideoBlobData = xhr.response;
                        var blobDataMp4 = new Blob([tiktokVideoBlobData], {
                            type: "image/jpeg",
                        });
                        console.log("データが返ってきました....画像アクセス成功");
                        resolve(blobDataMp4);
                    } else {
                        //エラー処理
                        console.log("エラー");
                        console.log(xhr);
                        console.log(`ステータスコードは${xhr.status}です`);
                        resolve("画像アクセスエラー");
                    }
                };
                xhr.onerror = function () {
                    console.log("画像アクセスエラーです");
                    resolve("画像アクセスエラー");
                }
                xhr.send(postParam);
            });
            return responseData;
        },
        /**
         * tiktokの動画をダウンロードからアップロードまで
         * @param {videoUrl} tiktokの投稿動画URL
         * @return tiktokの動画ID
         */
        getTiktokVideoUrlParam: function (videoUrl) {
            const param = new URL(videoUrl).searchParams;
            const videoIdParam = param.get("id");
            //TikQからダウンロードしたcsvファイルではなく、TikTokページからコピー貼り付けしたURLが渡ってきたとき
            if (videoIdParam == null) {
                const index = videoUrl.indexOf("video/") + 6;
                const str = videoUrl.substr(index);
                const id = str.replace(/\?.*$/, "");
                return id;
            }

            return videoIdParam;
        },
        /**
    * エラーを管理する
    * @param col エラー列（0が渡ってきた場合、列は関係なしor全列）
    * @param row エラー行 （0が渡ってきた場合、行は関係なしor全行）
    * @param errorId エラー番号
    * @return なし
    */
        errorManagement: function (colData, rowData, errorId) {
            var errorPoint = {
                col: colData,
                row: rowData,
                code: errorId,
            };
            this.vuexStore.commit("setCsvErrorList", errorPoint);
        },
        /**
         * 取得した動画・画像をviemo/ストレージにアップロード
         * @param {*} videoData 
         * @param {*} imageData 
         * @param {*} vimeoFolder 
         * @returns 
         */
        mainFirebaseStorageProcess: async function (videoData, imageData, vimeoFolder, clientId, projectId) {

            //動画のアップロード情報
            var uploadResults = [this.vimeoUploadAllProcess(videoData, clientId, projectId)];

            //動画をアップロードできるか確かめて、できるならアップロード。
            var uloadResultData = await Promise.allSettled(uploadResults);

            //アップロードが不可ならエラーを出す
            if (uloadResultData[0].value == "容量オーバー") {
                console.log("容量オーバーです");
                throw new Error("容量オーバー");
            } else if (uloadResultData[0].value == "5MB制限") {
                console.log("5MB制限です");
                throw new Error("5MB制限");
            } else if (uloadResultData[0].value == "downloadError") {
                console.log("ダウンロードエラーです");
                throw new Error("downloadError");
            }
            else {//動画が正しくアップロードされた場合
                console.log("動画がアップロードされました");

                //ホワイトリストへの登録時に必要なvimeoの動画URL
                var vimeoVideoUri = uloadResultData[0]["value"].uri;

                //ビデオのハッシュ値を取得（動画の表示/削除時に必要）
                var hash = this.getVideoId(uloadResultData[0]["value"]);

                //ビデオのハッシュ値をセッションに登録
                this.registerSessionVideoHash(hash);

                //動画のアップロードが成功したことをセッションに登録
                this.registerSessionMovieUploadSuccess(true);


                //画像名
                const imageName = this.getUniqueStr() + ".jpg"

                //セッションに画像名を登録
                this.registerSessionUploadImageName(imageName);

                //画像アップロード処理
                var imageResult = [
                    this.storageUploadToGetImageUrl(
                        imageData,
                        imageName,
                        clientId,
                        projectId
                    ),
                ];

                //画像保存処理
                var thumNailResultsData = await Promise.allSettled(imageResult);

                //画像URL
                var thumImageUrl = thumNailResultsData[0]["value"];

                //画像処理成功をセッションに登録
                this.registerSessionImageUplaodSuccess(true);

                //画像URLを取得できたことセッションに登録
                this.registerSessionUploadImageUrl(true);

                var reponseData = {
                    videoId: hash,
                    imageUrl: thumImageUrl
                }

                //動画をホワイトリストに登録/動画のフォルダ移動
                var asyncMehods = [
                    this.registerWhiteList(vimeoVideoUri),
                    this.registerWhiteList2(vimeoVideoUri),
                    this.reserveVideoFolder(vimeoFolder, vimeoVideoUri),
                ];

                await Promise.allSettled(asyncMehods);


                //ホワイトリストに登録/フォルダ移動が成功したことをセッションに登録
                this.registerSessionVimeoWhiteListRegisterSuccess(true);
                this.registerSessionReserveVimeoFolderSuccess(true);

                return reponseData;

            }
        },
        /**
         * 画像をFirebase Storageにアップロード
         * @param {*} imageData 
         * @param {*} imageName 
         * @returns 画像保管場所リンク
         */
        storageUploadToGetImageUrl: async function (imageData, imageName, clientId, projectId) {
            const uploadImageName = await this.uploadImage(imageData, imageName, clientId, projectId);

            const downloadedUrl = await this.getImageUrl(uploadImageName);

            return downloadedUrl;
        },
        /**
         * 説明
         * 画像をFiebase Storageにアップロードする
         * @param {*} imageBlob 
         * @param {*} imageName 
         * @returns 
         */
        uploadImage: function (imageBlob, imageName, clientId, projectId) {
            var p = new Promise((resolve) => {
                var storageRef = this.firebase.storage().ref();
                var tiktokRef = storageRef.child("img/" +
                    clientId +
                    "/" +
                    projectId +
                    "/" +
                    imageName);
                tiktokRef
                    .put(imageBlob)
                    .then((snapshot) => {
                        resolve(snapshot);
                    })
                    .catch(() => {
                        //エラー処理
                        resolve(
                            this.errorHandling(
                                new Error(
                                    "[firebase upload error]failed upload to firebase storage"
                                )
                            )
                        );
                    });
            });
            return p;
        },
        /**
         * 説明
         * 画像リンクを取得する
         * @param {*} uloadImageName 
         * @returns 
         */
        getImageUrl: function (uloadImageName) {
            var p = new Promise((resolve) => {
                uloadImageName.ref.getDownloadURL().then((imageUrl) => {
                    resolve(imageUrl);
                })
                    .catch(() => {
                        //エラー処理
                        resolve(
                            this.errorHandling(
                                new Error(
                                    "[firebase download error]failed download to firebase storage image url"
                                )
                            )
                        );
                    });
            });
            return p;
        },

        ////////////////////////////////////////////////////ここまでstorageUploadToGetImageUrlの処理のかたまり//////////////////////////////////////////////////////////////

        /**
         * 説明
         * 動画をVIMEOにアップロード
         * @param {*} videoData 
         * @returns vimeoから返ってきた値
         */
        vimeoUploadAllProcess: async function (videoData, clientId, projectId) {

            //アップロードするビデオの容量
            this.movieDataSize = Math.ceil(Number(videoData.size / 1048576));

            //動画容量をセッションに登録
            this.registerUploadVideoDB(this.movieDataSize);

            //アップロードできるかのチェックが入る（動画の容量が5MBを超えていないか？あるいは容量残高を超えないか？のチェック）
            const movieUploadCheck = await this.movieContentsCheck(this.movieDataSize, clientId, projectId);

            //アップロード可能の場合の処理
            if (movieUploadCheck == true) {
                var vimeoVideoInfo = await this.uploadVimeo(videoData);
                return vimeoVideoInfo;

            } else {//アップロード不可であれば
                var notUpload = new Promise((resolve) => {
                    resolve("容量オーバー");
                })
                return notUpload;
            }
        },
        /**
         * 説明
         * 動画が正しくダウンロードできたのかをチェックする
         * @param movieContents 動画の容量（MB単位で）
         * @return 動画が正しくダウンロードできたかどうかの真偽値
         */
        downloadSucessCheck: function (movieContents) {
            //動画容量が0.1MBより小さかったら
            if (movieContents < 0.1) {
                return false;
            } else {
                return true;
            }
        },
        /**
         * 説明
         * 動画容量が制限を超えていないかチェックする
         * @param movieDataSize アップロードしようとしている動画サイズ
         * @return 動画容量が超えているか超えていないかの真偽値
         */
        movieContentsCheck: async function (movieDataSize, clientId, projectId) {
            var firebaseDBResults = [
                this.getClientInfo(clientId),
                this.getProjectInfo(clientId, projectId)
            ];

            //非同期処理の結果が格納
            var resultsData = await Promise.allSettled(firebaseDBResults);

            //クライアントの情報をセットする
            this.clientInfo = resultsData[0].value;

            //クライアントの使用DB容量を設定
            this.clientDb = resultsData[0].value.used_db;

            //クライアントの使用本数を設定
            this.clientContentsNum = resultsData[0].value.used_number;

            //契約プランの容量
            this.clientContractPlan = resultsData[0].value.contract_plan;

            //プロジェクトの情報を登録する
            this.projectInfo = resultsData[1].value;

            //プロジェクトのDB容量を設定
            this.projectDb = resultsData[1].value.used_data_contents;

            //プロジェクトの使用本数を設定
            this.projectContentsNum = resultsData[1].value.used_number;

            //アップロードする動画と容量残高の大小関係を計算（真偽値で返す）
            const checkValue = (this.clientContractPlan - this.clientDb) > movieDataSize;

            if (checkValue == true) {
                return true
            } else {
                console.log("ここで引っかかりました");
                return false;
            }
        },
        /**
         * 説明
         * vimeoに動画をアップロードする
         * @param {*} tiktokVideoBlobData 
         * @returns 
         */
        uploadVimeo: function (tiktokVideoBlobData) {
            const file = new File([tiktokVideoBlobData], "tiktok.mp4", {
                type: "video/mp4",
            });
            return new Promise((resolve) => {
                client.request(
                    {
                        method: "POST",
                        path: "/me/videos",
                        query: {
                            file_name: file.name,
                            upload: {
                                status: "in_progress",
                                size: file.size,
                                approach: "tus",
                            },
                            privacy: { view: "disable" },
                        },
                    },

                    function (error, body, status_code, headers) {
                        console.log(error);
                        console.log(status_code);
                        console.log(headers);
                        const options = {
                            endpoint: "none",
                            retryDelays: [0, 3000, 5000, 10000, 20000],
                            onError: function (error) {
                                console.log(error);
                                resolve("エラー");
                            },
                            onSuccess: function () {
                                resolve(body);
                            },
                        };

                        const upload = new tus.Upload(file, options);
                        upload.url = body.upload.upload_link;
                        upload.start();
                    }
                );
            });
        },
        urlencode: function (str) {
            str = (str + "").toString();
            return encodeURIComponent(str)
                .replace("!", "%21")
                .replace("'", "%27")
                .replace("(", "%28")
                .replace(")", "%29")
                .replace("*", "%2A")
                .replace("%20", "+");
        },
        ///////////////////////////////////////ここまでがvimeoUploadAllProcessの処理のかたまり///////////////////////////////////////////////////////////////////////

        /**
         * vimeoの埋め込みタグから特定の文字列を抜き取る
         * @param vimeoの動画をアップロードした際に返ってくるvideo情報
         * @return hash値
         */

        getVideoId: function (body) {
            var iframeURL = body.embed.html; //埋め込みifrmaeタグ
            var idstart;
            var idgoal;
            var hash;
            //文字列検索で?h=となっている文字列位置を検索し、その位置数を代入
            idstart = iframeURL.indexOf("video/");

            //文字列検索で&amp=となっている文字列位置を検索し、その位置数を代入
            idgoal = iframeURL.indexOf("&amp;");

            //文字列を切り出す
            hash = iframeURL.substring(idstart + 6, idgoal);

            return hash;
        },
        /**
         * vimeoの動画をホワイトリストに登録する
         * @param vimeo動画のuri
         */

        registerWhiteList: function (uri) {
            return new Promise((resolve) => {
                client.request(
                    {
                        method: "PATCH",
                        path: uri,
                        query: {
                            privacy: {
                                embed: "whitelist",
                            },
                        },
                    },
                    function () {
                        client.request({
                            method: "PUT",
                            path:
                                //uri + "/privacy/domains/iframe-93448.web.app?privacy.embed=whitelist",
                            uri + "/privacy/domains/short-video.jp?privacy.embed=whitelist",
                        });
                        resolve("ホワイトリストに登録しました");
                    }
                );
            });
        },
        registerWhiteList2: function (uri) {
            return new Promise((resolve) => {
                client.request(
                    {
                        method: "PATCH",
                        path: uri,
                        query: {
                            privacy: {
                                embed: "whitelist",
                            },
                        },
                    },
                    function () {
                        client.request({
                            method: "PUT",
                            path:
                                //uri + "/privacy/domains/client-3e3c0.web.app?privacy.embed=whitelist",
                            uri + "/privacy/domains/client.short-video.jp?privacy.embed=whitelist",
                        });
                        resolve("ホワイトリストに登録しました2");
                    }
                );
            });
        },
        /**
         * 特定のフォルダにvideoを登録する
         * @param フォルダID
         * @param ビデオのURI
         *
         *
         * */
        reserveVideoFolder: function (folderId, videoUri) {
            return new Promise((resolve) => {
                client.request({
                    method: "PUT",
                    path: folderId + videoUri,
                });
                resolve("特定フォルダに移動しました");
            });
        },
        /**
         * すべてのエラー処理を対応する関数
         * @param エラーオブジェクト（エラー内容）
         *
         * */
        errorHandling: function (errorObj) {
            console.log(errorObj);
        },

        getUniqueStr: function (myStrong) {
            //クライアントIDを生成する
            var strong = 1000;
            if (myStrong) strong = myStrong;
            return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
        },
        /**
        * 説明
        * クライアントの情報を取得する（動画容量がマックスを超えていないか確認するため）
        * @param なし
        * @return 容量DB
        * 
        */
        getClientInfo: async function (clientId) {

            var db = new Promise((resolve) => {

                //取得したいjsonのパスを設定
                const storageRef = this.firebase.storage().ref("/db/" + clientId + ".json");

                //jsonのURLを取得
                storageRef.getDownloadURL().then((url) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", url);
                    xhr.onload = function () {
                        var blob = xhr.response;

                        resolve(JSON.parse(blob));
                    };

                    //jsonURLにアクセスする
                    xhr.send();
                })
            })
            return db;
        },
        /**
        * 説明
        * プロジェクトの情報を取得する（動画容量がマックスを超えていないか確認するため）
        * @param なし
        * @return 容量DB
        * 
        */
        getProjectInfo: async function (clientId, projectId) {

            var projectDb = new Promise((resolve) => {

                //jsonまでのパスを取得する
                var projectInfoRef = this.firebase.storage().ref(
                    "/db/" + clientId + "/" + projectId + ".json"
                );

                //jsonのURLを取得
                projectInfoRef.getDownloadURL().then((url) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", url);
                    xhr.onload = function () {
                        var blob = xhr.response;
                        resolve(JSON.parse(blob));

                    };

                    //プロジェクトの情報を取得する
                    xhr.send();
                })
            });
            return projectDb;
        },
        retryUploadMain: function (vimeoFolder) {
            const clientId = this.$store.state.clientId;
            const projectId = this.$store.state.projectInfo[0][this.$store.state.selectedProject]
                .project_id;
            const uploadingProjectIndex = this.$store.state.selectedProject;

            //アップロードが始まったことを登録
            this.registerAllUploadClear(false);

            //アップロードを行うプロジェクトを登録
            this.vuexStore.commit("setUploadingProjectId", projectId);

            //クライアントIDとプロジェクトIDのセッションを登録
            this.registerSessionClientId(clientId);
            this.registerSessionProjectId(projectId);

            //処理を進める
            this.retryUpload(vimeoFolder, clientId, projectId, uploadingProjectIndex);
        },
        /**
         * アクセスエラーになった動画を再度アップロードする
         */
        retryUpload: async function (vimeoFolder, clientId, projectId, uploadingProjectIndex) {
            console.log("-------called retryUpload function -----------");

            //配列を複製
            var retryData = this.$store.state.retryUserData.concat();

            const roopIndex = 5;//ここの回数リトライを繰り返す

            var upFailed = true;//trueだとアップロードのエラーがでていない状態

            //アップロードするユーザーID
            var userId = "";

            //再アップロード中であることを設定する
            this.updateIsRetryStatus(true);

            //再アップロード
            for (var i = 0; i < this.$store.state.retryUserData.length; i++) {
                console.log("動画を再度再度アップロードします");
                console.log(this.$store.state.retryUserData.length);
                console.log(i+"回目");

                //再アタックOKかどうかの状態
                var isStatus = this.$store.state.isRetryUp;

                //csvアップロードボタンが途中で押されたかのステータス
                var csvUploadBtnPushState = this.$store.state.csvUploadBtn;

                //再アタックが中止された場合
                if (isStatus == false) {
                    break;
                }

                //csvアップロードボタンが途中で押された場合処理抜ける
                if (csvUploadBtnPushState == true) {
                    break;
                }

                //個々のユーザーに再アップを繰り返す
                for (var t = 0; t < roopIndex; t++) {
                    console.log("個々の動画をアップロードします");

                    //再アタックが中止された場合このfor文の処理を抜ける
                    if (this.$store.state.isRetryUp == false) {
                        break;
                    }

                    //csvアップロードボタンが途中で押された場合処理抜ける
                    if (this.$store.state.csvUploadBtn == true) {
                        break;
                    }

                    //ユーザーID取得
                    if (retryData[i][0][this.userIDLine] != "") {
                        userId = retryData[i][0][this.userIDLine];
                    }
                    //ビデオIDの取得処理
                    const videoUrl = retryData[i][0][this.postMovieUrl];
                    const videoId = this.getTiktokVideoUrlParam(videoUrl);
                    try {
                        //動画・画像のダウンロード
                        const contentsData = await Promise.all([
                            this.videoDownload(userId, videoId),
                            this.imageDownload(userId, videoId),
                        ]);

                        //動画・画像のデータ
                        const videoData = contentsData[0];
                        const imageData = contentsData[1];

                        //動画のアクセス権エラー
                        if (videoData == "403") {
                            if (roopIndex == t + 1) {
                                console.log("再度アップロードエラー");
                                console.log("エラー行は" + retryData[i][1] + "です");
                                this.accessErrorManager("retryError", retryData[i][1]);
                                upFailed = false;
                            }
                            continue;
                        }

                        //セッションに動画・画像のデータが取得できたことを登録
                        this.registerSessionMovieDownloadSuccess(true);
                        this.registerSessionImageDownloadSuccess(true);

                        try {
                            //動画のアップロード/及び画像のアップロード処理
                            var getData = await this.mainFirebaseStorageProcess(
                                videoData,
                                imageData,
                                vimeoFolder,
                                clientId,
                                projectId
                            );

                            //firebase storageにユーザ情報を登録する
                            await this.registerUser(getData.videoId, getData.imageUrl, retryData[i][0][this.postedDateLine], userId, retryData[i][0][this.titleLine], retryData[i][0][this.commentLine], this.movieDataSize, clientId, projectId,retryData[i][0][this.ctaLinkLine]);

                            var dbRegisterFunc = [this.updateClientInfo(clientId), this.updateProjectInfo(clientId, projectId)];

                            //dbを更新する関数を実行する
                            await Promise.allSettled(dbRegisterFunc);

                            //プロジェクト詳細画面の使用量DBと使用本数を更新する
                            this.projectInfoScreenUpdate(uploadingProjectIndex, this.movieDataSize, 1);

                            //セッションを削除
                            this.deleteUploadSession();

                            console.log(i + "回目の再度アップロード成功");

                            this.setRetryUploadIndex(i + 1);

                            break;//ここまできたらアップロード完了

                        } catch (e) {
                            if (roopIndex == t + 1) {
                                console.log("DBエラー");
                                this.dbErrorManager(e.message, retryData[i][1]);
                                upFailed = false;
                            }
                            //セッションを削除
                            this.deleteUploadSession();
                        }
                    } catch (error) {
                        if (roopIndex == t + 1) {
                            console.log("tiktok動画にアクセスできませんでした");
                            this.dbErrorManager(error.message, retryData[i][1]);
                            upFailed = false;
                        }
                        //セッションを削除
                        this.deleteUploadSession();
                    }

                    this.registerAllUploadClear(true);

                    //アップロードがおこなわれたプロジェクトID
                    const uploadedProjectId = this.vuexStore.state.uploadingProjectId;

                    //選択中のプロジェクトID
                    const selectedProjectId = this.vuexStore.state.projectInfo[0][this.vuexStore.state.selectedProject]
                        .project_id;

                    if (this.vuexStore.state.nowRounte == "動画一括アップロード" && uploadedProjectId == selectedProjectId) {
                        //エラーチェックが入る
                        this.checkError();
                    }
                }
            }

            //失敗or成功のアラート表示
            if (upFailed == false) {
                this.oneVideoRetrySuccess(false);
            } else {
                this.oneVideoRetrySuccess(true);
            }


            //再アップロード中ではないことを登録する
            this.updateIsRetryStatus(false);

            //ローディングアイコンを非表示
            this.nonDisplayLoadingIcon();

            //初期化
            this.initRetryData();
        },
        /**
         * 動画の再度アップロード中のステータスを更新
         */
        updateIsRetryStatus: function (isStatus) {
            this.$store.commit("setRetryUploadStatus", isStatus);
        },
        /**
         * アクセスエラーが出た動画を格納する
         */
        setRetryData: function (userData, errorRowIndex) {
            var data = {
                "userData": userData,
                "errorRowIndex": errorRowIndex,
            }
            this.$store.commit("setRetryUserData", data);
        },
        /**
         * 再度動画アップロード中にcsvアップロードボタンが押された際の処理
         */
        stopUpload: function () { },
        /**
         * ローディングアイコンを非表示にする
         */
        nonDisplayLoadingIcon: function () {
            //ローディングアイコンを非表示
            this.vuexStore.commit("manageUploadIcon", false);
            if (this.vuexStore.state.errorPartList.length == 0) {
                this.vuexStore.commit("changeUploadedMessage", true);
            } else {
                this.vuexStore.commit("changeUploadedMessage", false);
            }
        },
        /**
         * 再度アップロードするデータ
         */
        initRetryData: function () {
            this.$store.commit("initRetryData");
        },
        /**
         * 1つの動画の再アップロードが成功したかどうかのステータス
         * @param {*} isSuccess 
         */
        oneVideoRetrySuccess: function (isSuccess) {
            this.$store.commit("oneVideoRetrySuccess", isSuccess);
        },
    }
}
