<template>
  <v-main>
    <v-container class="proj" fluid fill style="padding-top: 0">
      <v-row style="">
        <v-col>
          <h3 style="font-weight: bold; font-size: 30px">プロジェクト詳細</h3>
        </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="3" sm="4" md="4" lg="3" xl="3" class="col-ttl">
          クライアントID
        </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5"> {{ client_id }} </v-col>
        <v-col> </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="12" sm="4" md="4" lg="3" xl="3" class="col-ttl">
          プロジェクト期間
        </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5">
          {{ contract_start_day }}~{{ contract_finished_day }}
        </v-col>
        <v-col> </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="12" sm="4" md="4" lg="3" xl="3" class="col-ttl">
          使用本数
          <div class="proj-help">
            <span class="material-icons proj-help_btn">help</span>
            <div class="proj-help-item">
              <p>
                ご契約のデータ容量の残数を元に、<br />想定の格納可能コンテンツ本数を表示します。
              </p>
              <p>
                ここで想定されている“1本辺り”のデータサイズは、一般的なスマートフォンで、10秒間の動画を撮影した際のサイズ（約20MB）を想定し算出しています。
              </p>
            </div>
          </div>
        </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5">
          {{ usedNumber }}本 使用中（{{
            Math.floor(Math.floor(used_data_contents))
          }}DB使用中）
        </v-col>
        <v-col> </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="12" sm="4" md="4" lg="3" xl="3" class="col-ttl">
          埋め込み用URL（自動生成）
          <div class="proj-help-emb">
            <span class="material-icons proj-help_btn-emb">help</span>
            <div class="proj-help-item-emb">
              <v-row>
                <v-col>
                  <p style="font-weight: bold; font-size: 14px">
                    ▼ 任意のサイト内に動画を埋め込む方法
                  </p>
                  <p>
                    任意のサイト内に動画を埋め込むには、「iframeタグ」と「scriptタグ」の２種類のタグをhtml内に埋め込む必要があります。
                  </p>
                  <p style="font-weight: bold; font-size: 14px">
                    ▼ Iframeタグを埋め込む手順
                  </p>
                  <p>
                    ①「iframeタグをコピー」ボタンを押下し、iframeタグをコピーする。
                  </p>
                  <p style="margin-top: -1px">
                    ②掲載予定のサイトのhtmlを開く。
                  </p>
                  <p style="margin-top: -1px">
                    ③コピーしたiframeタグを、&lt;body&gt;タグ内の任意の場所に貼り付ける。
                  </p>
                  <p>実際のIframeタグ（赤文字部分）</p>
                  <div style="background-color: white; padding: 6px">
                    <p style="font-size: 10px">&lt;body&gt;</p>
                    <p style="color: red; font-size: 10px">
                      &lt;div id=iframeBlock-sdrhgcsihgs style="height:
                      100%;"&gt;<br />&lt;iframe id="firebase-fiyjdo54mufg"
                      src="{{ embedded_url }}"width="100%" height="380px"
                      allow="autoplay" frameborder="0"
                      scrolling="no"&gt;&lt;/iframe&gt;<br />&lt;/div&gt;
                    </p>
                    <p style="font-size: 10px">&lt;/body&gt;</p>
                  </div>
                </v-col>
                <v-col style="margin-top: 80px">
                  <p style="font-weight: bold; font-size: 14px">
                    ▼ scriptタグを埋め込む手順
                  </p>
                  <p>
                    ①「scriptタグをコピー」ボタンを押下し、scriptタグをコピーする。
                  </p>
                  <p style="margin-top: -1px">
                    ②掲載予定のサイトのhtmlを開く。
                  </p>
                  <p style="margin-top: -1px">
                    ③コピーしたscriptタグを、bodyの閉じタグ&lt;/body&gt;の直前に貼り付ける。
                  </p>
                  <p>実際のscriptタグ（赤文字部分）</p>
                  <div style="background-color: white; padding: 10px">
                    <p style="color: red; font-size: 10px">
                      <!--さらに新しく追加-->
                      &lt;script&gt;<br />
                      const userAgent= window.navigator.userAgent;<br />
                      var iframeDivTagStyleHeight;<br />
                      if (userAgent.indexOf("iPhone") > 0) {<br />
                      iframeDivTagStyleHeight = "100vh"<br />
                      } else if(userAgent.indexOf("Android") > 0) {<br />
                      iframeDivTagStyleHeight = "100%" <br />
                      } else {<br />
                      iframeDivTagStyleHeight = "100%" <br />
                      }<br />
                      window.onpageshow = function (event) {<br />
                      if (event.persisted) {<br />
                      window.location.reload(); } }; var iframeHight =
                      document.getElementById( "firebase-fiyjdo54mufg"
                      ).style.height;<br />
                      var iframeWidth = document.getElementById(
                      "firebase-fiyjdo54mufg" ).style.width; var flag =
                      false;<br />
                      const javascriptParents = function () {<br />
                      const parents = [];<br />
                      const parentElem = function (elem) {<br />
                      if ( elem.parentNode&amp;&amp; elem.parentNode.nodeName !=
                      "BODY" ) { parents.push(elem.parentNode);<br />
                      parentElem(elem.parentNode); } }; const baseElem =
                      document.getElementById( "firebase-fiyjdo54mufg" );
                      parents["prevObject"] = baseElem;<br />
                      parentElem(baseElem);<br />
                      return parents; };<br />
                      const javascriptParentsZindex = function () {<br />
                      const parents = [];<br />
                      const parentElem = function (elem) {<br />
                      if ( elem.parentNode &amp;&amp; elem.parentNode.nodeName
                      != "BODY" ) {<br />
                      parents.push(elem.parentNode.style.zIndex);<br />
                      parentElem(elem.parentNode);<br />
                      } };<br />
                      const baseElem = document.getElementById(
                      "firebase-fiyjdo54mufg" );<br />
                      parents["prevObject"] = baseElem;<br />
                      parentElem(baseElem);<br />
                      return parents; };<br />
                      var parents = javascriptParents();<br />
                      var parentsZindex = javascriptParentsZindex();<br />
                      window.addEventListener("message", function (e) {<br />
                      if (event.origin != "short-video.jp") {<br />
                      return; } <br />
                      var iframeDivTagStyle = document.getElementById(
                      "iframeBlock-sdrhgcsihgs" ).style; if (e.data != "") {<br />
                      window.location.href = e.data;<br />
                      } else if (flag == true) {<br />
                      disableFullScreen(iframeDivTagStyle);<br />
                      flag = false;<br />
                      } else {<br />
                      ableFullScreen(iframeDivTagStyle);<br />
                      flag = true; } });<br />
                      function disableScroll(event) { event.preventDefault();
                      }<br />
                      function disableFullScreen(iframeDivTagStyle) {<br />
                      for (var index = 0; index &lt; parents.length; index++)
                      {<br />
                      parents[index].style.zIndex = parentsZindex[index];<br />
                      }<br />
                      var iframe = document.getElementById(
                      "firebase-fiyjdo54mufg" ); iframe.style.height =
                      iframeHight;<br />
                      iframeDivTagStyle.position = "";<br />
                      iframeDivTagStyle.width = "100%";<br />
                      iframeDivTagStyle.height = "";<br />
                      iframeDivTagStyle.top = "";<br />
                      iframeDivTagStyle.left = "";<br />
                      iframeDivTagStyle.zIndex = "0";<br />
                      document.removeEventListener( "touchmove", disableScroll,
                      { passive: false, } );<br />
                      document.body.classList.remove("overflow-hidden");<br />
                      document.body.style.overflow = "";<br />
                      document.body.style.position = bodyPositionStyle;<br />
                      }<br />
                      function ableFullScreen(iframeDivTagStyle) {<br />
                      parentsZindex = javascriptParentsZindex();<br />
                      parents = javascriptParents();<br />
                      for (var index = 0; index &lt; parents.length; index++)
                      {<br />
                      parents[index].style.zIndex = 2147483647 - index;<br />
                      }<br />
                      var iframe = document.getElementById(
                      "firebase-fiyjdo54mufg" ); iframe.style.height =
                      "100%";<br />
                      iframe.style.width = "100%";<br />
                      var iframeDivTagStyle = document.getElementById(
                      "iframeBlock-sdrhgcsihgs" ).style;
                      iframeDivTagStyle.position = "fixed";<br />
                      iframeDivTagStyle.width = "100%";<br />
                      iframeDivTagStyle.height = "100%";<br />
                      iframeDivTagStyle.top = "0px";<br />
                      iframeDivTagStyle.left = "0px";<br />
                      iframeDivTagStyle.zIndex = "2147483647";<br />
                      document.addEventListener( "touchmove", disableScroll, {
                      passive: false,<br />
                      }<br />
                      ); <br />
                      bodyPositionStyle = document.body.style.position;<br />
                      if(userAgent.indexOf("Android") > 0) {<br />
                      document.body.style.position = "fixed"; }<br />
                      document.body.classList.add("overflow-hidden");<br />
                      document.body.style.overflow = "hidden";<br />
                      }<br />
                      &lt;/script&gt;
                    </p>
                    <p style="font-size: 10px">&lt;/body&gt;</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5">
          <v-btn
            height="24"
            width="140"
            depressed
            class="clip"
            v-on:click="EmbeddedCopy()"
          >
            iframeタグをコピー
          </v-btn>
          <v-btn
            height="24"
            width="140"
            style="margin-left: 24px"
            depressed
            class="clip"
            v-on:click="EmbeddedCopyScriptTag()"
          >
            scriptタグをコピー
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="12" sm="4" md="4" lg="3" xl="3" class="col-ttl">
          インフルエンサー用投稿フォームURL
        </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5">
          <p id="formurl-target">{{ post_form }}</p>
        </v-col>
        <v-col>
          <v-btn
            height="30"
            width="50"
            depressed
            class="clip"
            v-on:click="formurlCopy()"
          >
            コピー
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="12" sm="4" md="4" lg="3" xl="3" class="col-ttl">
          掲載サイトURL
        </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5">
          <p id="embedded-target" class="site-url">
            {{ site_url }}
          </p>
        </v-col>
      </v-row>
      <v-row class="proj-row">
        <v-col cols="12" sm="4" md="4" lg="3" xl="3" class="col-ttl"> </v-col>
        <v-col class="col-txt" md="5" lg="5" xl="5">
          <v-btn
            height="40"
            width="100"
            depressed
            class="mr-3 btn-edit"
            style="color: white"
            v-on:click="changeRewriteScreen()"
          >
            編集
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue-js-modal@1.3.31/dist/index.min.js"></script> 
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
export default {
  name: "ProjectListHomeComponent",
  data: function () {
    return {
      client_id: this.$store.state.clientId,
      project_id: "",
      project_name: "",
      contract_start_day: "",
      contract_finished_day: "",
      used_number: "",
      used_data_contents: "",
      site_url: "",
      post_form: "",
      embedded_url: "",
      plan: this.$store.state.clientInfo[0].contract_plan,
      usedNumber: "",
    };
  },
  computed: {
    selectedProject() {
      return this.$store.state.selectedProject;
    },
  },
  watch: {
    selectedProject(val, old) {
      this.project_id =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].project_id;
      this.project_name =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].project_name;
      this.contract_start_day =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].contract_start_day;
      this.contract_finished_day =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].contract_finished_day;
      var remaining = Math.round(this.$store.state.totalDB / this.standardDB);
      this.used_number = remaining;
      this.used_data_contents =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].used_data_contents;
      this.site_url =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].site_url;
      this.post_form =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].post_form;
      this.embedded_url =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].embedded_url;
      this.usedNumber =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].used_number;
    },
  },
  mounted() {
    if (this.$store.state.userCheck == false) {
      //ローディング表示
      this.waitLoading();
    }
    this.waitLoading();
    document.getElementById("menuTop").style.pointerEvents = "none";
    document.body.style.overflow = "";
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.body.className = "login";
    document.querySelector("body").style.background = "";
    document.querySelector("body").style.margin = "0%";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
    if (this.$store.state.selectedProject !== "") {
      this.project_id =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].project_id;
      this.project_name =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].project_name;
      this.contract_start_day =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].contract_start_day;
      this.contract_finished_day =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].contract_finished_day;
      this.used_data_contents =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].used_data_contents;
      var remaining = Math.round(this.$store.state.totalDB / this.standardDB);
      this.used_number = remaining;
      this.site_url =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].site_url;
      this.post_form =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].post_form;
      this.embedded_url =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].embedded_url;
      this.usedNumber =
        this.$store.state.projectInfo[0][
          this.$store.state.selectedProject
        ].used_number;
    }
    setTimeout(() => {
      this.downloaded();
      this.approveTouchAndClickEvent();
    }, 2000);
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
  methods: {
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////タッチイベント及びクリックイベントを解除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    approveTouchAndClickEvent: function () {
      document.body.style.overflow = "";
      document.getElementsByTagName("html")[0].style.overflow = "";
      document.getElementById("menuTop").style.pointerEvents = "";
      document.body.style.pointerEvents = "";
      document.getElementsByTagName("html")[0].style.pointerEvents = "";
    },
    ///////////////////////////////////////////
    //////////////////////////////
    ///////説明
    ///////埋め込み用URLのiframeタグをコピーする
    //
    /////////////////////////////////
    //////////////////////////////////////////////////////
    EmbeddedCopy: function () {
      var embeddedText = document.getElementById("embedded-target").textContent;
      var text = document.createElement("textarea");
      text.value =
        '<div id= "iframeBlock-sdrhgcsihgs" style="height: 100%;"><iframe id="firebase-fiyjdo54mufg" src="' +
        this.embedded_url +
        '" width="100%"height="380px" allow="autoplay" frameborder="0" scrolling="no"></iframe></div>';
      document.body.appendChild(text);
      text.select();
      document.execCommand("copy");
      document.body.removeChild(text);
      alert("コピーしました");
    },
    ///////////////////////////////////////////
    //////////////////////////////
    ///////説明
    ///////埋め込み用URLのスクリプトタグをコピーする
    //
    /////////////////////////////////
    //////////////////////////////////////////////////////
    EmbeddedCopyScriptTag: function () {
      var embeddedText = document.getElementById("embedded-target").textContent;
      var text = document.createElement("textarea");
      text.value =
        //一番最新
        "<script>\n" +
        "const userAgent=window.navigator.userAgent;\n" +
        'if (userAgent.indexOf("iPhone") > 0) {\n' +
        'iframeDivTagStyleHeight = "100vh"\n' +
        '} else if (userAgent.indexOf("Android") > 0) {\n' +
        'iframeDivTagStyleHeight = "100%"\n' +
        "} else {\n" +
        'iframeDivTagStyleHeight = "100%"\n' +
        "}\n" +
        "window.onpageshow = function (event) {\n" +
        "if (event.persisted) {\n" +
        "window.location.reload();\n" +
        "}\n" +
        "};\n" +
        'var iframeHight = document.getElementById("firebase-fiyjdo54mufg").style.height;\n' +
        'var iframeWidth = document.getElementById("firebase-fiyjdo54mufg").style.width;\n' +
        "var flag = false;\n" +
        "const javascriptParents = function () {\n" +
        "const parents = [];\n" +
        "const parentElem = function (elem) {\n" +
        'if (elem.parentNode && elem.parentNode.nodeName != "BODY") {\n' +
        "parents.push(elem.parentNode);\n" +
        "parentElem(elem.parentNode);\n" +
        "}\n" +
        "};\n" +
        'const baseElem = document.getElementById("firebase-fiyjdo54mufg");\n' +
        'parents["prevObject"] = baseElem;\n' +
        "parentElem(baseElem);\n" +
        "return parents;\n" +
        "};\n" +
        "const javascriptParentsZindex = function () {\n" +
        "const parents = [];\n" +
        "const parentElem = function (elem) {\n" +
        'if (elem.parentNode && elem.parentNode.nodeName != "BODY") {\n' +
        "parents.push(elem.parentNode.style.zIndex);\n" +
        "parentElem(elem.parentNode);\n" +
        "}\n" +
        "};\n" +
        'const baseElem = document.getElementById("firebase-fiyjdo54mufg");\n' +
        'parents["prevObject"] = baseElem;\n' +
        "parentElem(baseElem);\n" +
        "return parents;\n" +
        "};\n" +
        "var parents = javascriptParents();\n" +
        "var parentsZindex = javascriptParentsZindex();\n" +
        'window.addEventListener("message", function (e) {\n' +
        'if (event.origin != "https://short-video.jp") {\n' +//本番用URLはhttps://short-video.jp
        "return;\n" +
        "}\n" +
        "var iframeDivTagStyle = document.getElementById(\n" +
        '"iframeBlock-sdrhgcsihgs"\n' +
        ").style;\n" +
        'if (e.data != "") {\n' +
        "window.location.href = e.data;\n" +
        "} else if (flag == true) {\n" +
        "disableFullScreen(iframeDivTagStyle);\n" +
        "flag = false;\n" +
        "} else {\n" +
        " ableFullScreen(iframeDivTagStyle);\n" +
        "flag = true;\n" +
        "}\n" +
        "});\n" +
        "function disableScroll(event) {\n" +
        "event.preventDefault();\n" +
        "}\n" +
        "function disableFullScreen(iframeDivTagStyle) {\n" +
        "for (var index = 0; index < parents.length; index++) {\n" +
        "parents[index].style.zIndex = parentsZindex[index];\n" +
        "}\n" +
        'var iframe = document.getElementById("firebase-fiyjdo54mufg");\n' +
        "iframe.style.height = iframeHight;\n" +
        'iframeDivTagStyle.position = "";\n' +
        'iframeDivTagStyle.width = "100%";\n' +
        'iframeDivTagStyle.height = "";\n' +
        'iframeDivTagStyle.top = "";\n' +
        'iframeDivTagStyle.left = "";\n' +
        'iframeDivTagStyle.zIndex = "0";\n' +
        'document.removeEventListener("touchmove", disableScroll, {\n' +
        "passive: false,\n" +
        "});\n" +
        'document.body.classList.remove("overflow-hidden");\n' +
        'document.body.style.overflow = "";\n' +
        "document.body.style.position = bodyPositionStyle;\n" +
        "}\n" +
        "function ableFullScreen(iframeDivTagStyle) {\n" +
        "parentsZindex = javascriptParentsZindex();\n" +
        "parents = javascriptParents();\n" +
        "for (var index = 0; index < parents.length; index++) {\n" +
        " parents[index].style.zIndex = 2147483647 - index;\n" +
        "}\n" +
        ' var iframe = document.getElementById("firebase-fiyjdo54mufg");\n' +
        ' iframe.style.height = "100%";\n' +
        ' iframe.style.width = "100%";\n' +
        " var iframeDivTagStyle = document.getElementById(\n" +
        '"iframeBlock-sdrhgcsihgs"\n' +
        " ).style;\n" +
        ' iframeDivTagStyle.position = "fixed";\n' +
        ' iframeDivTagStyle.width = "100%";\n' +
        ' iframeDivTagStyle.height = "100%";\n' +
        ' iframeDivTagStyle.top = "0px";\n' +
        ' iframeDivTagStyle.left = "0px";\n' +
        ' iframeDivTagStyle.zIndex = "2147483647";\n' +
        ' document.addEventListener("touchmove", disableScroll, {\n' +
        "   passive: false,\n" +
        " });\n" +
        "bodyPositionStyle = document.body.style.position;\n" +
        'if (userAgent.indexOf("Android") > 0) {\n' +
        'document.body.style.position = "fixed";\n' +
        "}\n" +
        ' document.body.classList.add("overflow-hidden");\n' +
        ' document.body.style.overflow = "hidden";\n' +
        " }\n" +
        "<" +
        "/" +
        "script>";

      ////////////////////////////////////////////
      document.body.appendChild(text);
      text.select();
      document.execCommand("copy");
      document.body.removeChild(text);
      alert("コピーしました");
    },
    ///////////////////////////////////////////
    //////////////////////////////
    ///////説明
    ///////インフルエンサー用投稿フォームURLをコピーする
    //
    /////////////////////////////////
    //////////////////////////////////////////////////////
    formurlCopy: function () {
      var embeddedText = document.getElementById("formurl-target").textContent;
      var text = document.createElement("textarea");
      text.value = embeddedText;
      document.body.appendChild(text);
      text.select();
      document.execCommand("copy");
      document.body.removeChild(text);
      alert("コピーしました");
    },
    ///////////////////////////////////////////
    //////////////////////////////
    ///////説明
    ///////プロジェクト編集画面に遷移する
    //
    /////////////////////////////////
    //////////////////////////////////////////////////////
    changeRewriteScreen: function () {
      this.$router.push("/projectRewrite");
    },
  },
};
</script>
<style scoped>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
.tabbr {
  display: none;
}
@media (max-width: 1259px) {
  .tabbr {
    display: block;
  }
}

.spbr {
  display: none;
}
@media (max-width: 767px) {
  .spbr {
    display: block;
  }
}

@media (max-width: 767px) {
  .pcbr {
    display: none;
  }
}

.js-anime {
  opacity: 0;
  transition: 0.7s;
}
.js-anime.is-slide {
  transform: translateY(100px);
}
.js-anime.is-anime {
  opacity: 1;
  transform: translateY(0);
}

.v-application {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif;
}
.v-application .text-h6 {
  font-family: "Noto Sans JP", "游ゴシック Medium", YuGothic, YuGothicM,
    "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    sans-serif !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background: #eae1d9;
}

.v-navigation-drawer__content {
  padding-top: 2rem;
}

.proj {
  padding: 2.5rem 3rem;
}
@media (max-width: 1259px) {
  .proj {
    padding: 2.5rem 1rem;
  }
}
.proj-row {
  margin-bottom: 0;
  width: 100%;
}
.proj-head {
  margin-top: 0;
  margin-right: 0;
}
.proj .col-ttl {
  font-weight: bold;
}
.proj .col-txt {
  font-weight: 300;
  width: 30%;
}
.proj .col-txt p {
  word-break: break-all;
  margin-bottom: 0;
}
.proj .col-txt input {
  width: 100%;
  padding: 0.25rem 0.5rem;
}
.proj-help {
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  color: #a5a5a5;
  position: relative;
}
.proj-help-item {
  padding: 0.75rem 1rem;
  background: #f3f3f3;
  color: #4a4a4a;
  font-size: 0.75rem;
  font-weight: bold;
  width: 400px;
  position: absolute;
  left: 0;
  top: -146px;
  border: 1px solid #707070;
  display: none;
}

.proj-help-emb {
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  color: #a5a5a5;
  position: relative;
}
.proj-help-item-emb {
  padding: 0.75rem 1rem;
  background: #f3f3f3;
  color: #4a4a4a;
  font-size: 0.75rem;
  font-weight: bold;
  width: 1100px;
  height: 1650px;
  position: absolute;
  left: 30px;
  top: -350px;
  border: 1px solid #707070;
  display: none;
  z-index: 1;
  /*overflow: scroll;/*追加*/
}

.proj-help-item-emb::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 11px 11px 11px 0;
  border-color: transparent #707070 transparent transparent;
  bottom: 1280px;
  left: -13px;
  margin-top: -10px;
  display: block;
  width: 0px;
  height: 0px;
  z-index: -1;
}
.proj-help-item-emb::before {
  content: "";
  /*
  border-top: 18px solid #707070;
  border-left: 9px solid rgba(255, 255, 255, 0);
  border-right: 9px solid rgba(255, 255, 255, 0);
  */
  border: 20px solid transparent;
  border-right: 15px solid #f3f3f3;
  bottom: 1271px;
  left: -30px;
  position: absolute;
  /*left: 3px;*/
  /* bottom: -18px;*/
}
.proj-help-item-emb p {
  margin-bottom: 0;
  line-height: 1.5;
}
.proj-help-item-emb p + p {
  margin-top: 1em;
}

.proj-help_btn-emb {
  cursor: pointer;
  font-size: 1.25rem;
}
.proj-help_btn-emb:hover + .proj-help-item-emb {
  display: block;
}

.proj-help-item-emb:hover {
  display: block;
}

.proj-help-item::after {
  content: "";
  border-top: 16px solid #f3f3f3;
  border-left: 8px solid rgba(255, 255, 255, 0);
  border-right: 8px solid rgba(255, 255, 255, 0);
  position: absolute;
  left: 4px;
  bottom: -15px;
}
.proj-help-item::before {
  content: "";
  border-top: 18px solid #707070;
  border-left: 9px solid rgba(255, 255, 255, 0);
  border-right: 9px solid rgba(255, 255, 255, 0);
  position: absolute;
  left: 3px;
  bottom: -18px;
}
.proj-help-item p {
  margin-bottom: 0;
  line-height: 1.5;
}
.proj-help-item p + p {
  margin-top: 1em;
}
.proj-help_btn {
  cursor: pointer;
  font-size: 1.25rem;
}
.proj-help_btn:hover + .proj-help-item {
  display: block;
}
.proj .clip {
  /*
  border: 1px solid #707070;
  padding: 0.25rem 0.75rem;
  border-radius: 0;
  background-color: #fff !important;
  */
  font-size: 12px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #c1c1c1;
  opacity: 1;
}
.proj .btn-edit {
  border-radius: 0;
  margin-top: 86px;
  background: linear-gradient(#e18140, #d6600f);
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  font-family: "Noto Sans JP", sans-serif;
  padding: 0.5rem 0.75rem;
}
.proj .btn-edit:hover {
  filter: brightness(110%);
}
.proj .btn-save {
  border-radius: 0;
  background: linear-gradient(#00979c, #068587);
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
}
.proj .btn-save:hover {
  filter: brightness(110%);
}

/*# sourceMappingURL=style.css.map */
</style>
